import { Button } from "@fluentui/react-button";
import { TargetAddRegular } from "@fluentui/react-icons";
import {
  JobGroupSettings,
  parseJsonStrOptional,
  uniqByObject,
} from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../../../components/Wrappers/ToasterProvider";
import { addExistingJobToGroup } from "../../../../../../helpers/apiHelper";
import { isFeatureEnabled } from "../../../../../../selectors/features";
import { switchSelectedJobAction } from "../../../../actions/groupDetailActions";
import { updateGroupJobs } from "../../../../mutators/groupDetailMutators";
import { notGroupOwner } from "../../../../selectors/groupJob";
import { groupDetailStore } from "../../../../store/groupDetailStore";
import { MyJobListDialog } from "../../../Dialog/MyJobListDialog";

export const AddJobToGroupButton = observer(() => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const toast = useToast();

  const isJobGroupStage2 = isFeatureEnabled("job-group-stage-2");

  const notOwner = notGroupOwner.get();
  if (!isJobGroupStage2 || notOwner) {
    return <></>;
  }

  const currentGroupTemplate = groupDetailStore.groupTemplates.find(
    (item) => item.Id === groupDetailStore.group?.GroupTemplateId,
  );
  const currentGroupTemplateSettings = parseJsonStrOptional(
    currentGroupTemplate?.Settings,
    JobGroupSettings,
  );
  const isPredefined = currentGroupTemplate?.Type === "Predefined";
  const requiredTemplates = isPredefined
    ? currentGroupTemplateSettings?.map((item) => item.templateName)
    : [];
  return (
    <div>
      <Button icon={<TargetAddRegular />} onClick={() => setIsDialogOpen(true)}>
        Add Jobs
      </Button>
      <MyJobListDialog
        existingJobs={groupDetailStore.jobs}
        isOpen={isDialogOpen}
        groupName={groupDetailStore.group?.Name ?? "Unknown"}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        requiredTemplates={requiredTemplates}
        onAddJobs={(jobs) => {
          const groupID = groupDetailStore.group?.ID;
          const jobIDs = jobs.map((job) => job.ID);
          if (!groupID || jobIDs.length === 0) {
            return;
          }
          toast.onToastStart("Adding jobs to group...");
          addExistingJobToGroup({
            GroupId: groupID,
            JobIds: jobIDs,
          })
            .then(() => {
              const newJobList = uniqByObject([
                ...groupDetailStore.jobs,
                ...jobs,
              ]);
              updateGroupJobs(newJobList);
              switchSelectedJobAction(newJobList[0]);
              toast.onToastSuccess("Jobs added to group successfully.");
            })
            .catch((error) => {
              toast.onToastFailure(
                "Failed to add jobs to group.Error: " + error,
              );
            });
        }}
      />
    </div>
  );
});
