import { makeStyles, Text } from "@fluentui/react-components";
import { Open16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import { getScorecardMetrics } from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import { FilterButton } from "../../../OfflineEval/components/Other/FilterButton";
import type { ScorecardMetrics } from "../../models/Experiment";
import { formatMetricsNumber } from "../../utils/format";
import { PaginationTable } from "../common/PaginationTable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  filter: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "16px 0",
    gap: "8px",
  },
  list: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "18px",
    backgroundColor: "#FFF",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #D1D1D1",
  },
  tableContainer: {
    minHeight: "auto",
  },
  link: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#115EA3",
    margin: "0 4px",
  },
  colorGreen: {
    color: "#0E700E",
    fontWeight: 600,
  },
  colorRed: {
    color: "#B10E1C",
    fontWeight: 600,
  },
});
const Key_Metrics_Name = new Set([
  "Sample Ratio",
  "SBSLeo/Turn",
  "Shadow_citation_rate_3s_search_triggered/Turn",
  "Shadow_citation_rate/Turn",
  "Shadow_cite_dcg_num_cites/Turn",
  "Shadow_cite_dcg_one_centric/Turn",
  "Shadow_num_citations_per_utterance/Turn",
]);
interface IScorecardMetricsProps {
  scorecardId: string;
  stepId?: string;
}
interface IScorecardMetricsListItem {
  Name: string;
  Category: Array<string | undefined>;
  Rollup?: string;
  Control?: number;
  Treatment?: number;
  Delta?: number;
  DeltaPercent?: number;
  Status?: number;
  PValue?: number;
  PMove?: number;
}
export const ExperimentScorecardMetrics = observer(
  (props: IScorecardMetricsProps) => {
    const styles = useStyles();
    const { scorecardId, stepId } = props;
    const [loadingMetrics, setLoadingMetrics] = React.useState(false);
    const [type, setType] = React.useState<string>("key");
    const [metrics, setMetrics] = React.useState<ScorecardMetrics[]>([]);
    const formattedMetrics: IScorecardMetricsListItem[] = React.useMemo(() => {
      const showResults = metrics.filter((metric) => {
        if (type === "key") {
          return Key_Metrics_Name.has(metric.metricName ?? "");
        } else {
          return metric.testResult?.pValue && metric.testResult?.pValue < 0.05;
        }
      });
      const results: IScorecardMetricsListItem[] = showResults.map((metric) => {
        return {
          Key: metric.metricLabel
            ? `${metric.metricLabel}-${metric.rollupType ?? ""}`
            : Math.random().toString(),
          Name: metric.metricName ?? "",
          Status:
            (metric.desirableDirection === "Down" ? -1 : 1) *
            (metric.delta?.absolute ?? 0),
          Category: [
            metric.scorecardLayout,
            metric.scorecardLayoutTab,
            ...(metric.metricCategories ?? []),
          ],
          Rollup: metric.rollupType,
          Control: metric.controlVariant?.average,
          Treatment: metric.treatmentVariant?.average,
          Delta: metric.delta?.absolute,
          DeltaPercent: metric.delta?.relative,
          PValue: metric.testResult?.pValue,
          PMove:
            (metric.testResult?.pMove?.increaseProbability ?? 0) +
            (metric.testResult?.pMove?.decreaseProbability ?? 0),
        };
      });

      return results;
    }, [type, metrics]);

    const params = React.useMemo(() => {
      return {
        workspaceId: "f082eddd-3c30-4fa7-83e7-ae506a831a40",
        "api-version": "2024-04-01-preview",
        scorecardId,
      };
    }, []);
    React.useEffect(() => {
      if (params.scorecardId) {
        setLoadingMetrics(true);
        perfWrapper(
          "LoadExperimentScorecardMetric",
          getScorecardMetrics(params)
            .then((scorecardMetrics) => {
              setLoadingMetrics(false);
              setMetrics(scorecardMetrics.value);
            })
            .catch(() => {
              setLoadingMetrics(false);
              setMetrics([]);
            }),
        );
      }
    }, [params, scorecardId]);

    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "Name",
          title: "Metric",
          minWidth: 220,
          width: 280,
          render: (item: IScorecardMetricsListItem) => (
            <>{`${item.Name} ${
              item.Rollup ? `[by ${item.Rollup.toLowerCase()}]` : ""
            }`}</>
          ),
        },
        {
          key: "Control",
          title: "Control",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.Control)}</>
          ),
        },
        {
          key: "Treatment",
          title: "Treatment",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.Treatment)}</>
          ),
        },
        {
          key: "Delta",
          title: "Delta",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => {
            const colorStyle =
              (item.PValue ?? 0) >= 0.05
                ? ""
                : (item.Status ?? 0) > 0
                  ? styles.colorGreen
                  : styles.colorRed;
            return (
              <>
                {item.Delta && item.DeltaPercent ? (
                  <>
                    {`${formatMetricsNumber(item.Delta, { sign: true })}(`}
                    <span className={colorStyle}>
                      {`${formatMetricsNumber(item.DeltaPercent, {
                        percent: true,
                        sign: true,
                      })}`}
                    </span>
                    {`)`}
                  </>
                ) : (
                  "--"
                )}
              </>
            );
          },
        },
        {
          key: "PValue",
          title: "P-Value",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.PValue)}</>
          ),
        },
        {
          key: "PMove",
          title: "P-Move",
          minWidth: 110,
          width: 150,
          render: (item: IScorecardMetricsListItem) => (
            <>{formatMetricsNumber(item.PMove, { percent: true })}</>
          ),
        },
      ];
      return {
        header,
        data: formattedMetrics,
        keyName: "Key",
        loading: loadingMetrics,
        options: {
          innerBorder: true,
          containerStyle: styles.tableContainer,
        },
      };
    }, [formattedMetrics, loadingMetrics, styles]);

    return (
      <div className={styles.root}>
        <div className={styles.filter}>
          <FilterButton
            value={"key"}
            selectedTab={type}
            text="Key metrics"
            activeIcon={undefined}
            inactionIcon={undefined}
            clickTab={() => setType("key")}
          />
          <FilterButton
            data-testid="movement"
            value={"movement"}
            selectedTab={type}
            text="Metrics with significant movements"
            activeIcon={undefined}
            inactionIcon={undefined}
            clickTab={() => setType("movement")}
          />
        </div>
        <div className={styles.list} key={type}>
          <PaginationTable {...listProps} />
          {stepId && (
            <Text block={true}>
              To view full metrics, you can access the full scorecard
              <Link
                className={styles.link}
                to={`https://exp.microsoft.com/scorecard?stepId=${stepId}&scorecardIds=${scorecardId}&workspaceId=${Exp_Workspace_Id}`}
                target="_blank"
              >
                <Text>{scorecardId}</Text>
                <Open16Regular />
              </Link>
            </Text>
          )}
        </div>
      </div>
    );
  },
);
