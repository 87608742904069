export type ISydneyAdditionalSettings = {
  option_sets: string;
  variants?: string;
};

export type IUnifiedMetaData = {
  commonExpConfigs: {
    work: ISydneyAdditionalSettings & {
      url: string;
      plugins?: object[];
    } & Record<string, string | object>;
    web: ISydneyAdditionalSettings & {
      url: string;
      plugins?: object[];
    } & Record<string, string | object>;
  };
  commonOptions: string[];
  querySetTypes: {
    name: string;
    isDefault?: boolean;
    scenario?: string;
    work: ISydneyAdditionalSettings;
    web: ISydneyAdditionalSettings;
    global_config?: Record<string, string | boolean>;
    metrics: {
      single_turn: Array<{
        name: string;
        default?: boolean;
      }>;
      multi_turn: Array<{
        name: string;
        default?: boolean;
      }>;
    };
    options?: {
      single_turn: string[];
      multi_turn: string[];
    };
  }[];
};

export const UN_SELECTED_FILE_NAME = "<Select new test set>";

export const unifiedMetaData: IUnifiedMetaData = {
  commonExpConfigs: {
    work: {
      url: "https://substrate.office.com/m365Copilot",
      option_sets:
        "enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation",
      variants: "3s.enablelssqfp,feature.IsEaPEnabled",
      plugins: [
        {
          Version: "1.0",
          Source: "1PBuiltIn",
          id: "EnterpriseSearch",
          data: {
            SerializedOptions:
              '{"SubstrateFlights":["enablelssqfp", "DisableRMS", "DisableCAP","DoNotUseSiteCap", "SubstrateSearchDisableWaitableExperimentsFlight", "SubstrateSearchDisableFireAndForgetExperimentsFlight", "SevalFlight", "nwsevalflight", "nwEnableItemClientForQuery"]}',
          },
        },
      ],
    },
    web: {
      url: "https://substrate.office.com/m365Copilot",
      option_sets: "cwc_paid",
      extra_headers: {
        "X-Variants": "feature.cwcallowedos",
        "X-TargetPartition": "CLUSTERSET/MNZ\\SDFv2",
        "X-Scenario": "Chat",
        "X-TrafficType": "ClientSideExp",
      },
      extra_params: {
        debug: "True",
        fulldebug3s: "True",
        scenario: "OfficeWebPaidCopilot",
        mockAppId: "b0ed7d93-0983-4f91-8e5a-3916778c09d5",
        FlightingOptionsetMode: "NoOptionsSets",
      },
      config_params: {
        chat_api_version: "v3",
      },
      plugins: [
        {
          source: "BuiltIn",
          id: "BingWebSearch",
        },
      ],
    },
  },
  commonOptions: [
    "enable_gl_for_nosearch",
    // "skip_scrapping",
    "enable_grounding_for_ruleleo",
    "disable_tools_for_ruleleo",
    "enable_tool_def_for_stewieleosbs",
    "enable_tool_def_for_sbsleo",
    "enable_ruleleo_per_rule",
    "enable_ruleleo_binaryscore",
    "enable_defensive_jailbreak_style_transfer_for_query_gen",
    "enable_defensive_dataset_post_process_for_query_gen",
    "enable_raw_data_creation",
    "enable_goldenset_evaluation",
    "enable_suggestionchips_for_ruleleo",
    "enable_shopping_drilldown_metrics",
    "enable_scraping_on_chathub",
    "disable_bg_noise_for_tts",
    "enable_scraping_on_voice",
    "disable_extra_grounding_data",
    "disable_success_threshold",
    "disable_discard_conversation",
    "inject_turn_memory_in_ruleleo",
    "enable_gpt_evals",
    "enable_plugin_evals",
    "inject_query_in_groundleo_searches",
    "enable_3s_cache",
    "enable_contextual_windows_evals",
    "enable_contextual_evals",
    "enable_last_turn_evaluations",
    "disable_cache",
    "enable_scrape_only",
    "enable_load_conversation_api",
    "enable_bic_on_ui",
    "dev_debug",
    "enable_scraping_on_ui",
    "process_exps_in_parallel",
    "bot_turn_data_generation_echo",
    "bot_turn_data_generation_sc",
    // "enable_json_query",
    "enable_ruleleo_per_turn",
    "enable_canvas_evaluation",
    "disable_scraper_service_in_control",
    "enable_scraper_service_in_exp",
  ],
  querySetTypes: [
    {
      name: "general_purpose",
      isDefault: true,
      work: {
        option_sets:
          "enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation,evaluation_data_extension",
        variants: "3s.enablelssqfp,feature.IsEaPEnabled",
      },
      web: {
        option_sets: "cwc_paid,evaluation_data_extension",
      },
      metrics: {
        single_turn: [
          { name: "sbsleov2" },
          { name: "groundleo_claimbreak" },
          { name: "scleo" },
          { name: "lmchecklist" },
          { name: "sbsleo" },
          { name: "stewieleov5" },
        ],
        multi_turn: [],
      },
    },
    {
      name: "image_creation",
      scenario: "multimodal",
      global_config: {
        use_bizchat_parallel_batch_inference: false,
      },
      work: {
        option_sets:
          "enterprise_flux_image_v1,enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation,evaluation_data_extension",
        variants: "3s.enablelssqfp,feature.IsEaPEnabled",
      },
      web: {
        option_sets: "cwc_paid,evaluation_data_extension",
      },
      metrics: {
        single_turn: [{ name: "sbsleov2" }],
        multi_turn: [],
      },
    },
    {
      name: "image_upload",
      scenario: "multimodal",
      global_config: {
        use_bizchat_parallel_batch_inference: false,
      },
      work: {
        option_sets:
          "enterprise_flux_image_v1,enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation,evaluation_data_extension",
        variants: "3s.enablelssqfp,feature.IsEaPEnabled",
      },
      web: {
        option_sets:
          "cwc_paid,cwcgptv,cwcgptv4oilu,cwcgptvpolysan,evaluation_data_extension",
      },
      metrics: {
        single_turn: [{ name: "sbsleov2" }],
        multi_turn: [],
      },
    },
    {
      name: "file_understanding_CIQ",
      work: {
        option_sets:
          "enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation,evaluation_data_extension",
        variants: "3s.enablelssqfp,feature.IsEaPEnabled",
      },
      web: {
        option_sets: "cwc_paid,evaluation_data_extension",
      },
      metrics: {
        single_turn: [
          { name: "groundleo_claimbreak" },
          { name: "stewieleov5" },
          { name: "sbsleo" },
          { name: "sbsleov2" },
        ],
        multi_turn: [],
      },
    },
    {
      name: "code_interpreter",
      work: {
        option_sets:
          "enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation,evaluation_data_extension",
        variants: "3s.enablelssqfp,feature.IsEaPEnabled",
      },
      web: {
        option_sets: "cwc_paid,evaluation_data_extension",
      },
      metrics: {
        single_turn: [
          { name: "codeleo" },
          { name: "groundleo_claimbreak" },
          { name: "stewieleov5" },
          { name: "sbsleo" },
          { name: "sbsleov2" },
        ],
        multi_turn: [],
      },
    },
    {
      name: "voice",
      scenario: "voice",
      global_config: {
        use_bizchat_parallel_batch_inference: false,
      },
      work: {
        option_sets:
          "multimodal_realtime_enterprise_search,multimodal_realtime_eval,multimodal_realtime_prod,evaluation_data_extension,multimodal_realtime_model_devgpt4o1217",
        variants: "feature.realtimeEnterpriseSearchEnabled",
      },
      web: {
        option_sets:
          "multimodal_realtime_prod,multimodal_realtime_shimmer_voice,multimodal_realtime_eval,evaluation_data_extension,multimodal_realtime_model_devgpt4o1217",
      },
      metrics: {
        single_turn: [
          { name: "groundleo_claimbreakv1_1", default: true },
          { name: "sbsleov2", default: true },
          { name: "voiceleo", default: true },
        ],
        multi_turn: [],
      },
      options: {
        single_turn: [
          "enable_scraping_on_voice",
          "disable_extra_grounding_data",
          "enable_scraping_on_chathub",
          "enable_gl_for_nosearch",
        ],
        multi_turn: [
          "enable_scraping_on_voice",
          "disable_extra_grounding_data",
          "enable_scraping_on_chathub",
          "enable_gl_for_nosearch",
        ],
      },
    },
  ],
};
