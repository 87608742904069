import { num, object, optional, str } from "@seval-portal/shared";

export const JobPreValidationResponse = object({
  verificationStatusCode: num,
  response: optional(str),
  oid: optional(str),
  user: optional(str),
  client_request_id: optional(str),
  sydney_url: optional(str),
});

export type JobPreValidationResponse = ReturnType<
  typeof JobPreValidationResponse
>;
