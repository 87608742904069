import { array, num, object, optional, str } from "@seval-portal/shared";

export const LMChecklistAssertionScore = object({
  control: optional(num),
  experiment: optional(num),
});

export const LMChecklistPromptAndResponse = object({
  control: optional(str),
  experiment: optional(str),
});

export const LMChecklistAssertion = object({
  query: str,
  segment: str,
  queryHash: str,
  assertion: str,
  level: str,
  score: LMChecklistAssertionScore,
  prompt: LMChecklistPromptAndResponse,
  response: LMChecklistPromptAndResponse,
  sydneyReply: LMChecklistPromptAndResponse,
  owner: optional(str),
});

export const LMChecklistAssertionsResponse = array(LMChecklistAssertion);

export type LMChecklistAssertionScore = ReturnType<
  typeof LMChecklistAssertionScore
>;
export type LMChecklistPromptAndResponse = ReturnType<
  typeof LMChecklistPromptAndResponse
>;
export type LMChecklistAssertionsResponse = ReturnType<
  typeof LMChecklistAssertionsResponse
>;
export type LMChecklistAssertion = ReturnType<typeof LMChecklistAssertion>;
