import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  makeStyles,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import type { IncidentList } from "@seval-portal/shared";
import { getIncident, setIncident } from "../../helpers/apiHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { isBingAdmin, isDRI, isDev } from "../../selectors/userPermission";
import { useToast } from "../Wrappers/ToasterProvider";

type IncidentListDialogProps = {
  isOpen: boolean;
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  onComplete: () => void;
};

const useStyles = makeStyles({
  closeButton: {
    display: "flex",
    flexDirection: "row-reverse",
  },
});
export const IncidentListDialog = (props: IncidentListDialogProps) => {
  const [incidentList, setIncidentList] = React.useState<IncidentList>([]);
  const toaster = useToast();
  const styles = useStyles();

  useEffect(() => {
    getIncident().then((res) => {
      setIncidentList(res ?? []);
    });
  }, [props.isOpen]);

  const permissionCheck = (scope: string) => {
    if (isDRI.get() || isDev.get()) {
      return true;
    }

    if (isBingAdmin.get()) {
      return scope.indexOf("Bing") > -1;
    }
    return false;
  };

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{`Manage Incidents`}</DialogTitle>
          <DialogContent>
            <div>
              <Accordion multiple={true}>
                {incidentList.map((incident) => (
                  <AccordionItem key={incident.title} value={incident.title}>
                    <AccordionHeader>{incident.title}</AccordionHeader>
                    <AccordionPanel>
                      <div>
                        <strong>Created By:</strong> {incident.creator}
                      </div>
                      <div>
                        <strong>Created At:</strong> {incident.createdAt}
                      </div>
                      <strong>Description:</strong>
                      <div>{incident.description}</div>
                      <br />
                      <div className={styles.closeButton}>
                        {permissionCheck(incident.product) && (
                          <Button
                            appearance="primary"
                            onClick={() => {
                              toaster.onToastStart("Close incident...");
                              props.onComplete();
                              setIncident({
                                Incident: incidentList.filter(
                                  (item) => item.title !== incident.title,
                                ),
                              })
                                .then(() => {
                                  toaster.onToastSuccess(
                                    "Incident closed successfully.",
                                  );
                                  telemetryHelper.logDevEvent(
                                    "CleanIncident",
                                    incident,
                                  );
                                })
                                .catch((error) => {
                                  toaster.onToastFailure(error.message);
                                });
                            }}
                          >
                            Close Incident
                          </Button>
                        )}
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                ))}

                {incidentList.length === 0 && <div>No incidents found.</div>}
              </Accordion>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              appearance="secondary"
              onClick={() => {
                props.onComplete();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
