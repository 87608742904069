import type {
  CreateGroupJobRouterParser,
  CreateJobRouterRequestData,
} from "@seval-portal/shared";
import {
  JobConfiguration,
  JobGroupSettings,
  parseJsonStrOptional,
} from "@seval-portal/shared";
import { compact } from "lodash";
import { orchestrator } from "satcheljs";
import { createJobGroup, getJobTemplates } from "../../../helpers/apiHelper";
import { getRandomUUID } from "../../../helpers/getRandomUUID";
import { updateCurrentPath } from "../../../mutators/updateContributions";
import {
  addEditSubJobAction,
  addExistingJobToCreationGroupAction,
  backToGroupListAction,
  createJobGroupAction,
  deleteSubJobAction,
  duplicateGroupAction,
  dupSubJobAction,
  saveSubJobAction,
  submitJobGroupAction,
  updateSelectedGroupTemplatesAction,
} from "../actions/jobGroupActions";
import { convertProductExperimentNameToBasicExperimentName } from "../helpers/jobCloneHelper";
import { Job } from "../models/Job";
import { resetJobCreationStore } from "../mutators/jobCreationMutators";
import {
  resetGroupCreationStore,
  updateCreationMode,
  updateCurrentLocalJobId,
  updateGroupJobs,
  updateGroupName,
  updateIsSubmitGroup,
  updateSelectedGroupTemplate,
  updateSelectedGroupTemplateSettings,
  updateToastStatus,
} from "../mutators/jobGroupCreationMutators";
import {
  resetJobStore,
  updateAvailableTemplates,
} from "../mutators/jobMutators";
import type { IJobProperties } from "../selectors/generateCurrentJob";
import {
  generateCurrentJob,
  generateCurrentJobViaProp,
} from "../selectors/generateCurrentJob";
import {
  jobGroupCreationStore,
  type LocalJobSettings,
} from "../store/jobGroupCreationStore";
import { jobShareStore } from "../store/jobShareStore";
import { jobStore } from "../store/jobStore";
orchestrator(createJobGroupAction, () => {
  updateCreationMode("group");
});

orchestrator(updateSelectedGroupTemplatesAction, ({ selected }) => {
  updateSelectedGroupTemplate(selected);
  const setting = parseJsonStrOptional(selected.Settings, JobGroupSettings);
  updateSelectedGroupTemplateSettings(setting);
  updateGroupName(selected.Name);
  // Fetch job group templates
  if (setting) {
    const predefinedJobs = new Array<LocalJobSettings>();
    setting.forEach((item) => {
      // Fetch job templates
      if (item.isRequired) {
        const minJobCount = parseInt(item.minJobCount ?? "1") ?? 1;
        for (let i = 0; i < minJobCount; i++) {
          predefinedJobs.push({
            jobName: item.displayName,
            templateName: item.templateName,
            configuration: undefined,
            localId: getRandomUUID(),
            type: "required",
          });
        }
      } else {
        predefinedJobs.push({
          jobName: item.displayName,
          templateName: item.templateName,
          configuration: undefined,
          localId: getRandomUUID(),
          type: "optional",
        });
      }
    });
    updateGroupJobs(predefinedJobs);
  } else {
    updateGroupJobs([]);
  }
});

orchestrator(duplicateGroupAction, async ({ groupData }) => {
  updateCreationMode("group");
  const availableTemplates = jobGroupCreationStore.groupTemplates;
  const initTemplate = availableTemplates.find(
    (item) => item.Id === groupData.GroupTemplateId,
  );

  if (initTemplate === undefined) {
    updateSelectedGroupTemplatesAction(availableTemplates[0]);
    return;
  }

  updateToastStatus({
    message: "Copy group information...",
    state: "update",
  });
  if (jobStore.availableTemplates.length === 0) {
    await getJobTemplates().then((templates) => {
      updateAvailableTemplates(templates);
    });
  }

  updateSelectedGroupTemplate(initTemplate);
  const setting = parseJsonStrOptional(initTemplate.Settings, JobGroupSettings);
  updateSelectedGroupTemplateSettings(setting);
  updateGroupName(groupData.Name);

  const getAllJobs =
    groupData.Jobs?.map((item) => {
      const currentJob = Job(item, "copy job");
      const currentJobConfig = parseJsonStrOptional(
        currentJob.Settings,
        JobConfiguration,
      );
      const currentJobTemplate = setting?.find(
        (template) =>
          template.templateName === (currentJob.ExperimentName ?? ""),
      );
      const baseExperimentName =
        convertProductExperimentNameToBasicExperimentName(
          currentJob.ExperimentName ?? "",
        );
      const overrideExperimentName = currentJob.ExperimentName?.includes(
        "Product_",
      )
        ? currentJob.ExperimentName
        : undefined;
      const selectedJobTempalte = jobStore.availableTemplates.find(
        (template) => template.Name === baseExperimentName,
      );
      const jobProp: IJobProperties = {
        jobName: currentJob.JobName ?? "Job-" + currentJob.ID,
        selectedTemplate: selectedJobTempalte,
        dataSetsString: currentJob.DataSets,
        configuration: currentJobConfig,
        configurationJsonString: currentJob.Settings ?? "",
        isPriorityJob: false,
        jobPriorityADOLink: undefined,
        permissionList: [],
      };
      const submitData = generateCurrentJobViaProp(jobProp).then((job) => {
        const localData: LocalJobSettings = {
          jobName: currentJob.JobName ?? "Job-" + currentJob.ID,
          templateName: currentJob.ExperimentName ?? "",
          configuration: currentJobConfig,
          localId: getRandomUUID(),
          type: currentJobTemplate?.isRequired ? "required" : "optional",
          PermissionObjects: jobShareStore.permissionList.map(
            (user) => user.ObjectId,
          ),
          submitData: {
            ...job,
            ExperimentNameOverride: overrideExperimentName,
          },
        };
        return localData;
      });
      return submitData;
    }) ?? [];
  Promise.all(getAllJobs).then((resolvedJobList) => {
    updateGroupJobs(resolvedJobList);

    updateToastStatus({
      message: "Copy group information successfully",
      state: "success",
    });
  });
});

orchestrator(addEditSubJobAction, ({ localId, mode, templateName }) => {
  const currentJobList = jobGroupCreationStore.jobs;
  const targetJob = currentJobList.find((item) => item.localId === localId);
  if (targetJob === undefined) {
    // Fetch job templates
    const newJobId = getRandomUUID();
    currentJobList.push({
      jobName: templateName ?? "",
      templateName: templateName ?? "",
      configuration: undefined,
      localId: newJobId,
      type: "optional",
    });
    updateCreationMode(mode);
    updateCurrentLocalJobId(newJobId);
  } else {
    updateCreationMode(mode);
    updateCurrentLocalJobId(localId);
  }
});

orchestrator(dupSubJobAction, async ({ localId }) => {
  updateToastStatus({
    message: "Duplicating job...",
    state: "start",
  });

  if (jobStore.availableTemplates.length === 0) {
    await getJobTemplates().then((templates) => {
      updateAvailableTemplates(templates);
    });
  }
  const targetJob = jobGroupCreationStore.jobs.find(
    (item) => item.localId === localId,
  );
  if (targetJob === undefined) {
    return;
  }
  const newJobId = getRandomUUID();

  const selectedJobTemplate = jobStore.availableTemplates.find(
    (template) => template.Name === targetJob.templateName,
  );
  const jobProp: IJobProperties = {
    jobName: newJobId,
    selectedTemplate: selectedJobTemplate,
    dataSetsString: targetJob.submitData?.DataSets,
    configuration: targetJob.configuration,
    configurationJsonString: JSON.stringify(targetJob.configuration) ?? "",
    isPriorityJob: false,
    jobPriorityADOLink: undefined,
    permissionList: [],
  };
  const preparedJob = generateCurrentJobViaProp(jobProp).then((job) => {
    const localData: LocalJobSettings = {
      jobName: newJobId,
      templateName: targetJob.templateName ?? "",
      configuration: targetJob.configuration,
      localId: getRandomUUID(),
      type: targetJob.type,
      PermissionObjects: jobShareStore.permissionList.map(
        (user) => user.ObjectId,
      ),
      submitData: job,
    };
    return localData;
  });
  preparedJob.then((newJob) => {
    const currentJobList = jobGroupCreationStore.jobs;
    updateGroupJobs([...currentJobList, newJob]);

    updateToastStatus({
      message: "Job duplicated successfully",
      state: "success",
    });
  });
});

orchestrator(backToGroupListAction, () => {
  const localId = jobGroupCreationStore.currentLocalJobId;
  const currentJob = jobGroupCreationStore.jobs.find(
    (item) => item.localId === localId,
  );
  if (
    currentJob?.type !== "required" &&
    currentJob?.configuration === undefined
  ) {
    const targetJob = jobGroupCreationStore.jobs.filter(
      (item) => item.localId !== localId,
    );
    updateGroupJobs(targetJob);
  }
  updateCreationMode("group");
  updateCurrentLocalJobId(undefined);
  resetJobCreationStore();
  resetJobStore();
});

orchestrator(deleteSubJobAction, ({ localId }) => {
  const targetJob = jobGroupCreationStore.jobs.filter(
    (item) => item.localId !== localId,
  );
  updateGroupJobs(targetJob);
});

orchestrator(saveSubJobAction, () => {
  // Fetch job group templates
  const newJobList = jobGroupCreationStore.jobs.map((item) => {
    if (item.localId === jobGroupCreationStore.currentLocalJobId) {
      return generateCurrentJob().then((job) => {
        return {
          ...item,
          jobName: jobStore.jobName,
          templateName: job.ExperimentName,
          configuration: jobStore.configuration,
          PermissionObjects: jobShareStore.permissionList.map(
            (user) => user.ObjectId,
          ),
          submitData: job,
        };
      });
    }
    return Promise.resolve(item);
  });
  Promise.all(newJobList).then((resolvedJobList) => {
    updateGroupJobs(resolvedJobList);
    updateCreationMode("group");
    updateCurrentLocalJobId(undefined);
    resetJobStore();
    resetJobCreationStore();
  });
});

orchestrator(submitJobGroupAction, () => {
  if (jobGroupCreationStore.isSubmitting) {
    return;
  }
  updateIsSubmitGroup(true);
  // Fetch job group templates
  const jobsData: Record<string, CreateJobRouterRequestData> = {};
  jobGroupCreationStore.jobs.forEach((item) => {
    if (item.linkedJob !== undefined) {
      return;
    }
    if (item.configuration === undefined && item.type === "optional") {
      return;
    }

    if (item.submitData === undefined) {
      return;
    }
    jobsData[item.localId] = item.submitData;
  });

  const linkedJobIds = compact(
    jobGroupCreationStore.jobs.map((item) => item.linkedJob?.ID.toString()),
  );

  const submitData: CreateGroupJobRouterParser = {
    GroupName: jobGroupCreationStore.groupName,
    Settings: jobGroupCreationStore.selectedGroupTemplateSettings,
    GroupTemplateId: jobGroupCreationStore.selectedGroupTemplate?.Id,
    Jobs: jobsData,
    LinkJobs: linkedJobIds,
  };
  createJobGroup(submitData).then(() => {
    updateCurrentPath("/?mode=group");
    resetGroupCreationStore();
  });
});

orchestrator(
  addExistingJobToCreationGroupAction,
  ({ jobs, currentLocalJob }) => {
    let currentJobList = jobGroupCreationStore.jobs;
    const isPredefined =
      jobGroupCreationStore.selectedGroupTemplate?.Type === "Predefined";
    const requiredTemplates = isPredefined
      ? jobGroupCreationStore.selectedGroupTemplateSettings?.map(
          (item) => item.templateName,
        )
      : [];
    for (const job of jobs) {
      const baseExperimentName =
        convertProductExperimentNameToBasicExperimentName(
          job.ExperimentName ?? "",
        );
      if (
        isPredefined &&
        requiredTemplates &&
        !requiredTemplates.includes(baseExperimentName)
      ) {
        continue;
      }
      const newJobId = getRandomUUID();
      const jobConfig = parseJsonStrOptional(job.Settings, JobConfiguration);
      currentJobList.push({
        jobName: job.JobName ?? "Job-" + job.ID,
        templateName: job.ExperimentName ?? "",
        configuration: jobConfig,
        localId: newJobId,
        type: "optional",
        linkedJob: job,
      });
    }
    if (currentLocalJob) {
      const targetJob = currentJobList.find(
        (item) => item.localId === currentLocalJob,
      );
      const hasExistingJob = currentJobList.find(
        (item) =>
          item.localId !== currentLocalJob &&
          item.templateName === targetJob?.templateName,
      );
      if (targetJob?.configuration === undefined && hasExistingJob) {
        currentJobList = currentJobList.filter(
          (item) => item.localId !== currentLocalJob,
        );
      }
    }
    updateGroupJobs(currentJobList);
  },
);
