import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { updateCurrentPath } from "../../../../../mutators/updateContributions";
import {
  resetGroupCreationStore,
  updateIsDismissDialogOpen,
} from "../../../mutators/jobGroupCreationMutators";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { useGroupStyles } from "../groupStyles";

export const GroupDismissDialog = observer(() => {
  const styles = useGroupStyles();

  const dismissPage = () => {
    updateCurrentPath("/");
    resetGroupCreationStore();
  };

  return (
    <Dialog
      open={jobGroupCreationStore.isDismissDialogOpen ?? false}
      onOpenChange={() => {
        updateIsDismissDialogOpen(false);
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Leave Page?</DialogTitle>
          <DialogContent>
            You haven’t submitted your jobs yet. Do you want to leave without
            submitting jobs?
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button className={styles.dismissButton} appearance="primary">
                Stay on this page
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button
                className={styles.dismissButton}
                appearance="secondary"
                onClick={dismissPage}
              >
                Leave this page
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
