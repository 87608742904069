import { array, num, object, str } from "@seval-portal/shared";

export const JobPriorityResponse = array(
  object({
    Id: num,
    PriorityLevel: num,
    Status: str,
  }),
);

export type JobPriorityResponse = ReturnType<typeof JobPriorityResponse>;
