import { Button } from "@fluentui/react-components";
import { ArrowLeft24Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react";
import { updateCurrentPath } from "../../../../../mutators/updateContributions";
import { groupDetailStore } from "../../../store/groupDetailStore";
import { ShareButton } from "../../JobDetail/components/ShareButton";
import { useGroupDetailPageStyles } from "../sharedStyles";
import { AddJobToGroupButton } from "./ActionButtons/AddJobToGroupButton";
import { DownloadButton } from "./ActionButtons/DownloadButton";
import { DuplicateGroupButton } from "./ActionButtons/DuplicateGroupButton";

export const GroupDetailHeader = observer(() => {
  const styles = useGroupDetailPageStyles();
  const currentGroup = groupDetailStore.group;
  const renderBackArrowButton = () => {
    return (
      <Button
        appearance="transparent"
        onClick={() => {
          updateCurrentPath("/?mode=group");
        }}
        icon={<ArrowLeft24Regular />}
      />
    );
  };

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        {renderBackArrowButton()}
        [Group] {currentGroup?.Name}
      </div>
      <div className={styles.actionButtons}>
        <AddJobToGroupButton />
        <ShareButton />
        <DuplicateGroupButton />
        <DownloadButton />
      </div>
    </div>
  );
});
