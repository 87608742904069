import { jobCreationStore } from "../../../store/jobCreationStore";
import { jobStore } from "../../../store/jobStore";

export const checkBingCluster = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return { path, message: "cluster should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "cluster name should not be empty!" };
  }

  return jobStore.availableClusters.includes(value)
    ? undefined
    : {
        path,
        message: "Cluster value is not valid!",
      };
};

export const checkBingScenario = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return { path, message: "Scenario should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Scenario name should not be empty!" };
  }

  const availableScenario = jobCreationStore.bingMetaData?.scenarios;
  return availableScenario && availableScenario.includes(value)
    ? undefined
    : {
        path,
        message: "Scenario value is not valid!",
      };
};
