import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React, { useEffect, useRef } from "react";

import { observer } from "mobx-react-lite";
import { uploadFolderAction } from "../../actions/uploadFolderAction";

const useStyles = makeStyles({
  noticeInfo: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontStyle: "italic",
    lineHeight: "22px",
    color: "red",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    lineHeight: "22px",
  },
  errorMsg: {
    color: "red",
  },
  successMsg: {
    color: "green",
  },
});

interface IUploadingAIHubDialogProps {
  folderName: string;
  isOpen: boolean;
  onStart?: (folderName: string) => void;
  onSuccess?: (currentFolderName: string, folderName: string) => void;
  onFailure?: (error: Error) => void;
  onCancel?: () => void;
}

export const UploadingAIHubDialog = observer(
  (props: IUploadingAIHubDialogProps) => {
    const inputFolder = useRef<HTMLInputElement | null>(null);
    const { folderName, isOpen } = props;
    const styles = useStyles();

    const [currentFolderName, setCurrentFolderName] =
      React.useState<string>("");
    const [selectedFolder, setSelectedFolder] = React.useState<
      File[] | undefined
    >(undefined);
    const [validationMessage, setValidationMessage] = React.useState<
      string | undefined
    >(undefined);

    useEffect(() => {
      setCurrentFolderName("");
      setSelectedFolder(undefined);
      setValidationMessage(undefined);
    }, [isOpen]);

    const handleFileDialogConfirm = () => {
      if (selectedFolder !== undefined && selectedFolder.length > 0) {
        props.onStart?.(folderName);

        uploadFolderAction(
          "UniversalSearch",
          folderName,
          selectedFolder,
          currentFolderName,
          () => {
            props.onSuccess?.(currentFolderName, folderName);
          },
          props.onFailure,
        );
      } else {
        setValidationMessage(
          `Selected folder is empty or does not contain valid JSON files. Please select a valid folder.`,
        );
      }
    };

    return (
      <Dialog
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === false) {
            props.onCancel?.();
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{"Upload input Folder"}</DialogTitle>
            <DialogContent>
              <Field>
                <div className={styles.block}>
                  <div className={styles.noticeInfo}>
                    Please upload the eyes-on data of Userp/Glean Responses in
                    JSON format. The input data and evaluation results will be
                    public for all users in SEVAL.
                  </div>
                </div>
                <div className={styles.block}>
                  <div className={styles.blockTitle}>
                    You can upload your input folder here.
                  </div>

                  {validationMessage && (
                    <div className={styles.errorMsg}>{validationMessage}</div>
                  )}

                  {selectedFolder && validationMessage === undefined && (
                    <div className={styles.successMsg}>
                      <Body1>
                        {`This folder `}
                        <strong>{`${currentFolderName}`}</strong>
                        {` will be stored with name `}
                        <strong>{`${folderName}`}</strong>
                      </Body1>
                    </div>
                  )}
                  <div>
                    <input
                      type="file"
                      ref={(node) => {
                        inputFolder.current = node;

                        if (node) {
                          [
                            "webkitdirectory",
                            "directory",
                            "mozdirectory",
                          ].forEach((attr) => {
                            node.setAttribute(attr, "");
                          });
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        if (event.target?.files) {
                          const fileList = Array.from(
                            event.target?.files,
                          ).filter((file) => {
                            return file.name.endsWith(".json");
                          });

                          if (fileList.length > 0) {
                            setCurrentFolderName(
                              fileList[0].webkitRelativePath?.split("/")[0],
                            );
                          }
                          setSelectedFolder(fileList);
                        }
                      }}
                      style={{ display: "none" }}
                    />
                    <Button
                      appearance="primary"
                      onClick={() => {
                        inputFolder.current?.click();
                      }}
                    >
                      Select Folder
                    </Button>
                  </div>
                </div>
              </Field>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleFileDialogConfirm}
                disabled={
                  selectedFolder === undefined ||
                  validationMessage !== undefined
                }
                appearance="primary"
              >
                Confirm
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
