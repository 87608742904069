import {
  Button,
  Combobox,
  InfoLabel,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { lmChecklistFile } from "../../../../../constants";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import type { JobConfigurationUpdateAction } from "../../../models/JobConfigurationAction";
import { getValue } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { UploadingFileDialog } from "../../Dialog/UploadingFileDialog";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    listbox: {
      maxHeight: "300px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

interface JobUnifiedLLMChecklistDatasetViewProps {
  publicLMChecklistSets: string[];
  customLMChecklistSets: string[];
  setPublicLMChecklistSets: (querySets: string[]) => void;
  customChecklistPathString?: JobConfigurationUpdateAction["prop"];
}

export const JobUnifiedLLMChecklistDatasetView = observer(
  (props: JobUnifiedLLMChecklistDatasetViewProps) => {
    const styles = useStyles();
    const {
      publicLMChecklistSets,
      customLMChecklistSets,
      setPublicLMChecklistSets,
    } = props;

    const checkListPath = "queries.lmchecklist_input_file";

    const toast = useToast();
    const [isLMChecklistSetFileDialogOpen, setIsLMChecklistFileDialogOpen] =
      React.useState(false);

    // SET CHECKLIST TO TRUE TEMPORARILY
    const checklistEnabled = true;

    const testFileName = getValue(checkListPath);

    const getLMChecklistSets = () => {
      switch (jobStore.selectedEvaluationType) {
        case "PersonalAccount":
          return [
            lmChecklistFile.personalAccount,
            ...publicLMChecklistSets,
            ...customLMChecklistSets,
          ];
        case "FakeTenant":
          return [
            lmChecklistFile.fakeTenant,
            ...publicLMChecklistSets,
            ...customLMChecklistSets,
          ];
        default:
          return [];
      }
    };

    const displayedOptions = checklistEnabled
      ? getLMChecklistSets().sort()
      : [];

    const onFileUploadStart = () => {
      toast.onToastStart("Uploading file...");
    };

    const onFileUploadSuccess = () => {
      toast.onToastSuccess("File uploaded successfully");
    };

    const onFileUploadFailure = (error: Error) => {
      toast.onToastFailure(`File upload failed with message: ${error.message}`);
    };

    return !jobStore.selectedTemplate?.Name.includes("Sydney_Scraping_Only") ? (
      <div className={styles.block}>
        <InfoLabel
          className={styles.blockTitle}
          required
          info={
            "Specify the file path of input file from SEVAL LM checklist set storage"
          }
        >
          LM checklist set
        </InfoLabel>
        <div className={styles.rowWithSmallGap}>
          <Combobox
            aria-label="Specify the file path of input file from SEVAL LM checklist set storage"
            style={{ flex: 1 }}
            listbox={{ className: styles.listbox }}
            value={checklistEnabled ? testFileName : ""}
            disabled={!checklistEnabled}
            onOptionSelect={(_, data) => {
              if (data.optionValue !== undefined) {
                updatePropValueActionV2({
                  prop: checkListPath,
                  newData: data.optionValue,
                });
              }
            }}
          >
            {displayedOptions.map((dataset, index) => {
              return <Option key={index}>{dataset}</Option>;
            })}
          </Combobox>
          <Button
            appearance="primary"
            onClick={() => {
              setIsLMChecklistFileDialogOpen(true);
            }}
            disabled={!checklistEnabled}
          >
            Upload File
          </Button>
        </div>

        <UploadingFileDialog
          type="LM Checklist"
          isOpen={isLMChecklistSetFileDialogOpen}
          onCancel={() => setIsLMChecklistFileDialogOpen(false)}
          onStart={onFileUploadStart}
          onSuccess={(fileName) => {
            updatePropValueActionV2({
              prop: checkListPath,
              newData: fileName,
            });
            setPublicLMChecklistSets([...publicLMChecklistSets, fileName]);
            setIsLMChecklistFileDialogOpen(false);
            onFileUploadSuccess();
          }}
          onFailure={(error) => {
            setIsLMChecklistFileDialogOpen(false);
            onFileUploadFailure(error);
          }}
        />
      </div>
    ) : (
      <></>
    );
  },
);
