import {
  Body1,
  Button,
  Field,
  InfoLabel,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { TemplateType } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { getRandomUUID } from "../../../../../helpers/getRandomUUID";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getValue } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { UploadingAIHubDialog } from "../../Dialog/UploadingAIHubDialog";
import { UploadingFolderDialog } from "../../Dialog/UploadingFolderDialog";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  uploadButton: {
    width: "100px",
  },
  successMsg: {
    color: "green",
  },
});

interface JobUploadFolderViewProps {
  label?: string;
}

export const JobUploadFolderView = observer(
  ({ label = "Upload Folder" }: JobUploadFolderViewProps) => {
    const styles = useStyles();
    const [isFileDialogOpen, setIsFileDialogOpen] = React.useState(false);
    const [currentFolderName, setCurrentFolderName] =
      React.useState<string>("");
    const [uploadedFolderName, setUploadedFolderName] =
      React.useState<string>("");

    const toast = useToast();

    const handleUpload = () => {
      setIsFileDialogOpen(true);
    };

    const onFileUploadStart = () => {
      toast.onToastStart("Uploading folder...");
    };

    const onFileUploadSuccess = () => {
      toast.onToastSuccess("Folder uploaded successfully");
    };

    const onFileUploadFailure = (error: Error) => {
      toast.onToastFailure(
        `Folder upload failed with message: ${error.message}`,
      );
    };

    const error = getJobErrorByPath(
      "configs_in_json.conversations.input_folder",
    );

    React.useEffect(() => {
      setUploadedFolderName("");
    }, [jobStore.selectedTemplate]);

    if (getValue("configs_in_json.conversations.input_folder") === undefined) {
      return <></>;
    }

    return (
      <div className={styles.block}>
        <InfoLabel
          required
          className={styles.blockTitle}
          info={"Select the input folder"}
        >
          {label}
        </InfoLabel>
        <Field
          validationState={error === undefined ? "none" : "error"}
          validationMessage={error?.message}
        >
          <Button
            className={styles.uploadButton}
            appearance="primary"
            onClick={handleUpload}
          >
            Upload
          </Button>
          {uploadedFolderName && (
            <Body1>
              Folder{" "}
              <strong className={styles.successMsg}>
                {" "}
                {currentFolderName}
              </strong>{" "}
              uploaded to{" "}
              <strong className={styles.successMsg}>
                {uploadedFolderName}
              </strong>{" "}
              successfully!
            </Body1>
          )}
        </Field>

        {jobStore.selectedTemplate?.Type === TemplateType.AIHub ? (
          <UploadingAIHubDialog
            isOpen={isFileDialogOpen}
            onCancel={() => setIsFileDialogOpen(false)}
            onStart={onFileUploadStart}
            onSuccess={(currentName, newName) => {
              setIsFileDialogOpen(false);
              onFileUploadSuccess();
              setCurrentFolderName(currentName);
              setUploadedFolderName(newName);
              updatePropValueActionV2({
                prop: "configs_in_json.conversations.input_folder",
                newData: newName,
              });
            }}
            onFailure={(e) => {
              setIsFileDialogOpen(false);
              onFileUploadFailure(e);
            }}
            folderName={getRandomUUID()}
          />
        ) : (
          <UploadingFolderDialog
            isOpen={isFileDialogOpen}
            onCancel={() => setIsFileDialogOpen(false)}
            onStart={onFileUploadStart}
            onSuccess={(currentName, newName) => {
              setIsFileDialogOpen(false);
              onFileUploadSuccess();
              setCurrentFolderName(currentName);
              setUploadedFolderName(newName);
              updatePropValueActionV2({
                prop: "configs_in_json.conversations.input_folder",
                newData: newName,
              });
            }}
            onFailure={(e) => {
              setIsFileDialogOpen(false);
              onFileUploadFailure(e);
            }}
            folderName={getRandomUUID()}
          />
        )}
      </div>
    );
  },
);
