import { mutatorAction } from "satcheljs";
import { store } from "../store/store";

export const updateIsSettingSubstrateToken = mutatorAction(
  "updateIsSettingSubstrateToken",
  (isSetting: boolean) => {
    store.develop.isSettingSubstrateToken = isSetting;
  },
);

export const updateIsSettingScrapingServiceToken = mutatorAction(
  "updateIsSettingScrapingServiceToken",
  (isSetting: boolean) => {
    store.develop.isSettingScrapingServiceToken = isSetting;
  },
);

export const updateIsSettingHeronAccessToken = mutatorAction(
  "updateIsSettingHeronAccessToken",
  (isSetting: boolean) => {
    store.develop.isSettingHeronAccessToken = isSetting;
  },
);

export const updateTokenResolver = mutatorAction(
  "updateSubstrateTokenResolver",
  (resolver: undefined | ((token: string) => void)) => {
    store.develop.tokenResolver = resolver;
  },
);

export const updateIsSettingAvalonAccessToken = mutatorAction(
  "updateIsSettingAvalonAccessToken",
  (isSetting: boolean) => {
    store.develop.isSettingAvalonAccessToken = isSetting;
  },
);
