import { computed } from "mobx";
import { store } from "../../../store/store";
import { groupDetailStore } from "../store/groupDetailStore";

export const notGroupOwner = computed(() => {
  return groupDetailStore.group?.CreatorSmtpAddress !== store.account?.username;
});

export const canRemoveJobFromGroup = computed(() => {
  const isGroupOwner = !notGroupOwner.get();
  return isGroupOwner && groupDetailStore.jobs.length > 1;
});
