import { object, optional } from "@seval-portal/shared";
import { getRandomUUID } from "./getRandomUUID";
import { retryable } from "./retryable";

const cachedUrls = new Map<string, string | undefined>();
const cachedPromises = new Map<string, Promise<string | undefined>>();

const GraphApiError = optional(
  object({
    error: object({
      code: optional(String),
      message: optional(String),
    }),
  }),
);

export const createAvatarPromise = (email: string, accessToken: string) => {
  const graphUrl = `https://graph.microsoft.com/v1.0/users/${email}/photo/$value`;

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${accessToken}`);

  const options = {
    method: "GET",
    headers: headers,
  };

  return retryable(
    () =>
      fetch(graphUrl, options).then(async (response) => {
        // If the response status is not 200, try parse the response message
        if (response.status !== 200) {
          const errorRawObject: unknown = await response.json();
          const errorRawJson = JSON.stringify(errorRawObject);
          const error = GraphApiError(errorRawObject, "GraphApiError");

          if (error === undefined) {
            throw new Error(
              `Get User Photo Value error: ${response.status}, message: ${errorRawJson}`,
            );
          } else {
            switch (error.error.code) {
              // For some user this error is expected since user not set avatar, in this case we just use the default avatar
              case "ImageNotFound":
                cachedUrls.set(email, undefined);
                return undefined;

              // Handle the failback case, treat it as an error
              default:
                throw new Error(
                  `Get User Photo Value error: ${response.status}, code: ${error.error.code}, message: ${error.error.message}`,
                );
            }
          }
        }

        // For 200 response, return the blob url
        return response
          .blob()
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            cachedUrls.set(email, url);
            return url;
          });
      }),
    {
      api: "getUserPhotoValue",
      url: graphUrl,
      scenarioId: getRandomUUID(),
    },
  );
};

export const getUserAvatarUrl = (email: string, accessToken: string) => {
  const cachedUrl = cachedUrls.get(email);
  const cachedPromise = cachedPromises.get(email);

  if (cachedUrl) {
    return Promise.resolve(cachedUrl);
  }

  if (cachedPromise) {
    return cachedPromise;
  }

  const newPromise = createAvatarPromise(email, accessToken).catch(() => {
    cachedUrls.delete(email);
    cachedPromises.delete(email);
    return undefined;
  });

  cachedPromises.set(email, newPromise);
  return newPromise;
};
