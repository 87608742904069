import { Button, makeStyles, tokens } from "@fluentui/react-components";
import { Warning20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { Tip } from "../../../../../components/Shared/Tip";

const useStyles = makeStyles({
  button: {
    backgroundColor: "rgba(221, 66, 66, 0.4)",
    "&:hover": {
      backgroundColor: "rgba(221, 66, 66, 0.5)",
    },
  },
  warningIcon: {
    marginRight: "2px",
  },
});

type LMChecklistCriticalFailureBannerProps = {
  customizedWarningText?: string;
};

export const LMCheckListWarningIcon = () => {
  return (
    <Tip
      content={"Warning: This job has critical LM Checklist regressions."}
      relationship="label"
    >
      <Warning20Regular color={tokens.colorStatusWarningForeground1} />
    </Tip>
  );
};

export const LMChecklistCriticalFailureBanner = observer(
  (props: LMChecklistCriticalFailureBannerProps) => {
    const { customizedWarningText } = props;
    const styles = useStyles();

    return (
      <Button className={styles.button}>
        <Warning20Regular
          data-testid="warning-icon"
          className={styles.warningIcon}
        />
        <span>{customizedWarningText}</span>
      </Button>
    );
  },
);
