import {
  Link,
  makeStyles,
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { getBingAMLFileDownloadLink } from "../../../../../helpers/apiHelper";
import { checkUrlValidation } from "../../../helpers/formatHelper";
export const markDownStyles = makeStyles({
  markdown: {
    "> pre": {
      width: "100%",
      overflow: "scroll",
    },
  },
});
interface ITreeCompnent {
  content: unknown;
  parent: string;
  renderTitle?: (item: unknown, index: number) => string;
  renderScore?: (node: unknown, parent: string) => string;
}

interface ITreeAudioItemCompnent {
  amlPath: string;
}

export const TreeItemAudioComponent = observer(
  (props: ITreeAudioItemCompnent) => {
    const [audioSrc, setAudioSrc] = useState("");
    const [canPlay, setCanPlay] = useState(false);

    useEffect(() => {
      setAudioSrc("");
      setCanPlay(false);
      getBingAMLFileDownloadLink({ filename: props.amlPath }).then((result) => {
        setAudioSrc(result);
      });
    }, [props.amlPath]);

    const handleCanPlay = () => {
      setCanPlay(true);
    };

    if (audioSrc === "") {
      return <></>;
    }
    return (
      <TreeItem itemType="leaf" style={{ display: canPlay ? "block" : "none" }}>
        <TreeItemLayout>
          <audio
            role="audio"
            onCanPlay={handleCanPlay}
            controls
            controlsList="nodownload"
          >
            <source src={audioSrc} type="audio/wav" />
          </audio>
        </TreeItemLayout>
      </TreeItem>
    );
  },
);

export const TreeComponent = observer((props: ITreeCompnent) => {
  const markdownStyles = markDownStyles();
  const isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const renderLeaf = (node: unknown, parent: string) => {
    if (typeof node === "string" && isJson(node)) {
      return (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(JSON.parse(node), null, 2).replace(
            /\\n|\\t|\\r/g,
            "",
          )}
        </pre>
      );
    }

    if (typeof node === "string" && checkUrlValidation(node)) {
      return (
        <Link
          style={{ lineBreak: "anywhere" }}
          href={`${node}`}
        >{`${node}`}</Link>
      );
    }

    if (parent === "LlmLuOutput" || parent === "Summary") {
      return `${node}`;
    }

    return (
      <ReactMarkdown
        className={markdownStyles.markdown}
      >{`${node}`}</ReactMarkdown>
    );
  };

  const renderAudioTree = (
    node: unknown,
    audio_path: string,
    parent: string,
  ) => {
    return (
      <Tree>
        <TreeItem itemType="leaf">
          <TreeItemLayout>{renderLeaf(node, parent)}</TreeItemLayout>
        </TreeItem>
        <TreeItemAudioComponent amlPath={audio_path} />
      </Tree>
    );
  };

  const renderTree = (node: unknown, parent: string) => {
    if (node === undefined || node === null) {
      return <></>;
    }

    if (Array.isArray(node)) {
      return (
        <Tree>
          {node.map((item, index) => {
            if (typeof item === "object") {
              return (
                <TreeItem key={index} itemType="branch">
                  <TreeItemLayout>
                    {props.renderTitle
                      ? props.renderTitle(item, index)
                      : "Object"}
                  </TreeItemLayout>
                  {renderTree(
                    item,
                    props.renderTitle
                      ? props.renderTitle(item, index)
                      : "Object",
                  )}
                </TreeItem>
              );
            } else {
              return (
                <TreeItem key={index} itemType="leaf">
                  <TreeItemLayout>{renderLeaf(item, parent)}</TreeItemLayout>
                </TreeItem>
              );
            }
          })}
        </Tree>
      );
    }

    if (typeof node === "object") {
      return (
        <Tree>
          {Object.entries(node)
            .filter((entry) => {
              const value: unknown = entry[1];
              return value !== null && value !== undefined;
            })
            .map(([key, value], index) => {
              if (typeof value === "number") {
                return (
                  <TreeItem key={index} itemType="leaf">
                    <TreeItemLayout>
                      {key}: {value.toFixed(2)}
                    </TreeItemLayout>
                  </TreeItem>
                );
              }

              if (key === "audio_paths") {
                return;
              }

              if (
                key === "Request" &&
                "audio_paths" in node &&
                typeof node.audio_paths === "object" &&
                node.audio_paths !== null &&
                "user" in node.audio_paths &&
                typeof node.audio_paths.user === "string" &&
                node.audio_paths.user !== null &&
                node.audio_paths.user !== ""
              ) {
                return (
                  <TreeItem key={index} itemType="branch">
                    <TreeItemLayout>
                      {key}
                      {props.renderScore ? props.renderScore(value, key) : ""}
                    </TreeItemLayout>
                    {renderAudioTree(value, node.audio_paths.user, key)}
                  </TreeItem>
                );
              }

              if (
                key === "Response" &&
                "audio_paths" in node &&
                typeof node.audio_paths === "object" &&
                node.audio_paths !== null &&
                "bot" in node.audio_paths &&
                typeof node.audio_paths.bot === "string" &&
                node.audio_paths.bot !== null &&
                node.audio_paths.bot !== ""
              ) {
                return (
                  <TreeItem key={index} itemType="branch">
                    <TreeItemLayout>
                      {key}
                      {props.renderScore ? props.renderScore(value, key) : ""}
                    </TreeItemLayout>
                    {renderAudioTree(value, node.audio_paths.bot, key)}
                  </TreeItem>
                );
              }

              return (
                <TreeItem key={index} itemType="branch">
                  <TreeItemLayout>
                    {key}
                    {props.renderScore ? props.renderScore(value, key) : ""}
                  </TreeItemLayout>
                  {renderTree(value, key)}
                </TreeItem>
              );
            })}
        </Tree>
      );
    }

    return (
      <Tree>
        <TreeItem itemType="leaf">
          <TreeItemLayout>{renderLeaf(node, parent)}</TreeItemLayout>
        </TreeItem>
      </Tree>
    );
  };

  return renderTree(props.content, props.parent);
});
