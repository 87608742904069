import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { ArrowDownload16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { Tip } from "../../../../../../components/Shared/Tip";
import { getJobOutput } from "../../../../../../helpers/apiHelper";
import { isFeatureEnabled } from "../../../../../../selectors/features";
import { getOutputBlobSource } from "../../../../helpers/bingResultHelper";
import { hasDownloadPermission } from "../../../../helpers/downloadHelper";
import type { BingJobFile } from "../../../../models/BingJobFile";
import type { Job } from "../../../../models/Job";
import { groupDetailStore } from "../../../../store/groupDetailStore";
import { BingDownloadButton } from "../../../ResultsComparison/components/BingDownloadButton";
import { OutputFilesView } from "../../../ResultsComparison/components/OutputFilesView";

export const DownloadButton = observer(() => {
  const displayJobs = groupDetailStore.jobs;
  const [jobFiles, setJobFiles] = React.useState<Map<string, BingJobFile[]>>(
    new Map<string, BingJobFile[]>(),
  );

  const getBingOutpoutFiles = (job: Job) => {
    const id = job.StudioUrl?.split("?")[0].split("runs/")[1];
    if (!id) {
      return;
    }

    if (jobFiles.has(id)) {
      return jobFiles.get(id);
    }
    const blobSource = getOutputBlobSource(job);
    getJobOutput(id, blobSource)
      .then((result) => {
        const output = result.items.map((item, index) => {
          const splits = item.split("/");
          const fileName = splits[splits.length - 1];
          const folderName = splits[splits.length - 2];
          return {
            folderName: folderName,
            fileName: fileName,
            fullPath: item,
            sizeInBytes: result.sizeInBytes[index],
          };
        });
        setJobFiles((prev) => {
          const newJobFiles = new Map(prev);
          newJobFiles.set(id, output);
          return newJobFiles;
        });
      })
      .catch(() => {
        setJobFiles((prev) => {
          const newJobFiles = new Map(prev);
          newJobFiles.set(id, []);
          return newJobFiles;
        });
      });
  };
  const renderDownloadButton = (job: Job) => {
    const templateType = job?.JobTemplateType;
    const showDownloadButton =
      templateType !== "BingV2" &&
      templateType !== "BingGenerator" &&
      job?.ExperimentName !== "BizChat-Personalized-Query-Set-Generator-V2" &&
      job?.ExperimentName !== "GPT_Scraping_Only" &&
      templateType !== "CWC" &&
      (job?.Status === "Completed" ||
        job?.Status === "Running" ||
        job?.Status === "Failed");
    if (showDownloadButton) {
      const checkDownloadPermission = hasDownloadPermission(job);
      if (!checkDownloadPermission) {
        return (
          <Tip
            key={`${job.ID}-tip`}
            content={
              "You don't have access to download the output. Please ask the job owner to share it offline if you need it."
            }
            relationship={"label"}
          >
            <MenuItem disabled>{job.JobName}</MenuItem>
          </Tip>
        );
      }
      return (
        <OutputFilesView
          key={job.ID}
          job={job}
          hasDownloadPermission={checkDownloadPermission}
          scenario="Job Group"
        />
      );
    }
    const shouldShowBingOrCWCDownloadButton =
      (templateType === "BingV2" ||
        templateType === "CWC" ||
        job?.ExperimentName === "GPT_Scraping_Only") &&
      job?.Status === "Completed";
    const id = job.StudioUrl?.split("?")[0].split("runs/")[1];
    if (shouldShowBingOrCWCDownloadButton && id) {
      getBingOutpoutFiles(job);
      return (
        <BingDownloadButton
          key={id}
          job={job}
          outputFiles={jobFiles.get(id) ?? []}
          scenario="Job Group"
        />
      );
    } else {
      return <React.Fragment key={job.ID}></React.Fragment>;
    }
  };

  const isJobGroupStage2 = isFeatureEnabled("job-group-stage-2");

  if (!isJobGroupStage2) {
    return <></>;
  }

  return (
    <Menu positioning={{ autoSize: true }}>
      <MenuTrigger disableButtonEnhancement>
        <Button icon={<ArrowDownload16Regular />}>Download</Button>
      </MenuTrigger>
      <MenuPopover key={"group-jobs-download"}>
        <MenuList key={"group-jobs"}>
          {displayJobs.map((job) => renderDownloadButton(job))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
});
