import { constStr, or } from "@seval-portal/shared";

// Top 9 metrics displayed in the MChat page header, predefined by PM
export const MChatDisplayedMetrics = [
  "sbsleo_sbsleo_score",
  "sbsleov2_sbsleov2_score",
  "one_centric",
  "search_enterprise_calendar_NDCG@10",
  "search_enterprise_files_NDCG@10",
  "search_enterprise_people_NDCG@10",
  "search_enterprise_emails_NDCG@10 ",
  "search_enterprise_teams_NDCG@10",
  "groundleo_claimbreak_score",
  "groundleo_claimbreakv1_1_score",
  "voiceleo_score",
  "hasCitation",
];

export const MetricJudgementViewMetricKey = or([
  constStr("groundleo"),
  constStr("tcr"),
  constStr("stewieleo"),
  constStr("stewieleosbs"),
  constStr("sbsleo"),
  constStr("sbsleov2"),
  constStr("sbsleov2_score"),
  constStr("stewieleov5v5"),
  constStr("reasoningleo"),
  constStr("pileo"),
  constStr("contextleo"),
  constStr("opgsummleo"),
  constStr("groundleo_scorebreak"),
  constStr("teamsgroundleo"),
  constStr("codeleo"),
  constStr("scleo"),
  constStr("pptstorytellerquality"),
  constStr("voiceleo"),
]);

export type MetricJudgementViewMetricKey = ReturnType<
  typeof MetricJudgementViewMetricKey
>;

type DiagnosisPageMetrics = { [key: string]: string };

// In diagnosis page: key is the key in the database, displayName is the name displayed in the UI
// metric_key is from
// Old table: `SELECT metric_key FROM [query_metrics]`
// New table`select JobId,query,query_hash,metrics from seval_metrics`
// display_name is the name displayed in the diagnosi page UI
// If metrics not defined here, it will display as it is in the database
export const DiagnosisPageMetrics: DiagnosisPageMetrics = {
  "mean_EE-Success(RaisedBar)": "ee-success",
  "stewieleosbs_perceived intelligence": "stewieleosbs_perceived_intelligence",
  contextleo_score: "contextleo_score",
  groundleo_claimbreak_score: "groundleo_claimbreak_score",
  lmchecklist_lmchecklist_score: "lmchecklist_score",
  mean_query_dcg: "dcg",
  mean_query_idcg: "idcg",
  mean_query_ndcg: "ndcg",
  mean_query_zero_results: "search-nrr",
  opgsummleo_score: "opgsummleo_score",
  pileo_pileo_score: "pileo_score",
  pptstorytellerquality_pptstorytellerquality_score:
    "pptstorytellerquality_score",
  sbsleo_sbsleo_score: "sbsleo_score",
  sbsleo_score: "sbsleo_score",
  sbsleov2_sbsleov2_score: "sbsleov2_score",
  stewieleo_stewieleo_score: "stewieleo_score",
  stewieleosbs_score: "stewieleosbs_score",
  stewieleosbs_stewieleosbs_score: "stewieleosbs_score",
  stewieleov5_stewieleov5_score: "stewieleov5v5",
  sydney_latency: "sydney_e2e_latency",
  tcr_score: "tcr_score",
  teamsgroundleo_score: "teamsgroundleo_score",
  voiceleo_score: "voiceleo_score",
};

type MetricsReasoningMapType = { [key: string]: MetricJudgementViewMetricKey };

// In diagnpsis page: displayName is the name displayed in the UI, reasoningKey is the key in the database
// The reasoningKey is from the database
// Old table: SELECT metric_key FROM [leo_details]
// New table: SELECT metric_key FROM [leo_details_v2]
// If reasoningKey not defined here, it won't show the link to side panel
// If new reasoningKey is added, add it here MetricJudgementViewMetricKey also
export const MetricsReasoning: MetricsReasoningMapType = {
  contextleo_score: "contextleo",
  groundleo_claimbreak_score: "groundleo_scorebreak",
  groundleo: "groundleo",
  opgsummleo_score: "opgsummleo",
  pileo: "pileo",
  pptstorytellerquality_score: "pptstorytellerquality",
  sbsleo_score: "sbsleo",
  sbsleov2_score: "sbsleov2_score",
  stewieleo: "stewieleo",
  stewieleosbs_score: "stewieleosbs",
  stewieleov5v5: "stewieleov5v5",
  tcr_score: "tcr",
  teamsgroundleo_score: "teamsgroundleo",
  voiceleo_score: "voiceleo",
};
