import {
  Button,
  Combobox,
  Field,
  InfoLabel,
  Option,
  Radio,
  RadioGroup,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import type { JobConfiguration } from "@seval-portal/shared";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { getSyntheticQuerySets } from "../../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../../helpers/telemetryHelper";
import { updateJsonStringAction } from "../../../actions/jobActions";
import { refreshCustomQuerySets } from "../../../helpers/jobCreationHelper";
import {
  updateCustomizedQuerySets,
  updatePublicLMChecklistSets,
} from "../../../mutators/jobCreationFileMutators";
import {
  setAccountType,
  setQuerySets,
  setQueryType,
  setSelectedQuerySet,
  setSelectedUser,
} from "../../../mutators/jobCreationSyntheticMutators";
import { updateSelectedEvaluationType } from "../../../mutators/jobMutators";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import {
  AccountType,
  jobCreationSyntheticStore,
  QueryType,
} from "../../../store/jobCreationSyntheticStore";
import { jobStore } from "../../../store/jobStore";
import JobSelectSyntheticUserDialog from "../../Dialog/JobSelectSyntheticUserDialog";
import { JobLLMChecklistDatasetView } from "./JobLLMChecklistDatasetView";
import { JobSelectedFileView } from "./JobSelectedFileView";
import { JobUserIdView } from "./JobUserIdView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      borderRadius: "8px",
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    selectedUserText: {
      flex: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginLeft: "8px",
    },
    querySetCombobox: {
      width: "100%",
    },
    dismissButton: {
      minWidth: "30px",
      minHeight: "30px",
      background: "none",
      border: "none",
      cursor: "pointer",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      border: "1px solid #d1d1d1",
      borderRadius: "4px",
      backgroundColor: "#f3f3f3",
      maxWidth: "600px",
      gap: "8px",
    },
    horizontalLayout: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "20px",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobSelectSyntheticUserView = observer(() => {
  const styles = useStyles();

  const updateConfiguration = () => {
    const jobConfig = jobStore.configuration as JobConfiguration;
    if (!jobConfig?.configs_in_json?.conversations) {
      return;
    }

    const conversations = jobConfig.configs_in_json.conversations;
    if (
      jobCreationSyntheticStore.accountType ===
        AccountType.UseSyntheticTenant &&
      jobCreationSyntheticStore.selectedUser
    ) {
      const email = jobCreationSyntheticStore.selectedUser.userId;
      conversations.user_id = email;
      conversations.is_synthetic = true;
      conversations.input_file = jobCreationSyntheticStore.selectedQuerySet;
    } else {
      conversations.user_id = jobCreationSyntheticStore.personalAccount;
      conversations.is_synthetic = false;
      conversations.input_file = jobCreationSyntheticStore.templateQuerySet;
    }
    updateJsonStringAction(JSON.stringify(jobConfig, null, 2));
  };

  const onUserSelected = () => {
    updateSelectedEvaluationType("FakeTenant");
    updateConfiguration();

    // TODO: will fetch the different query sets from API based on the selected user
    const fetchSyntheticQuerySets = () => {
      perfWrapper(
        "LoadSyntheticQueySets",
        Promise.resolve().then(() =>
          Promise.all([getSyntheticQuerySets()]).then(([querySets]) => {
            setQuerySets(querySets);
          }),
        ),
      );
    };
    fetchSyntheticQuerySets();
  };

  const selectedUser = jobCreationSyntheticStore.selectedUser
    ? `${jobCreationSyntheticStore.selectedUser.userName} (${jobCreationSyntheticStore.selectedUser.tenantName})`
    : "";

  return (
    <div className={styles.block}>
      <Field
        label={
          <>
            <div className={styles.blockTitle}>Data</div>
          </>
        }
      >
        <div className={styles.container}>
          <RadioGroup
            className={styles.radioGroup}
            value={jobCreationSyntheticStore.accountType}
            onChange={(_, data) => {
              setAccountType(data.value as AccountType);
              if (
                jobCreationSyntheticStore.selectedUser &&
                data.value === AccountType.UseSyntheticTenant
              ) {
                updateSelectedEvaluationType("FakeTenant");
              } else {
                updateSelectedEvaluationType("PersonalAccount");
              }
              updateConfiguration();
            }}
          >
            <Radio
              value={AccountType.UseSyntheticTenant}
              label="Use synthetic tenant"
            />
            <Radio value={AccountType.InputManually} label="Input manually" />
          </RadioGroup>
          {jobCreationSyntheticStore.accountType ===
            AccountType.UseSyntheticTenant && (
            <div className={styles.subContainer}>
              {jobCreationSyntheticStore.virtualTenants?.length === 0 && (
                <Text>
                  No tenant data to use, please ingest data from API first. Or
                  upload query set from personal account.{" "}
                </Text>
              )}
              <div className={styles.horizontalLayout}>
                <JobSelectSyntheticUserDialog onUserSelected={onUserSelected} />
                {jobCreationSyntheticStore.selectedUser && (
                  <div className={styles.buttonContainer}>
                    <Text className={styles.selectedUserText}>
                      {selectedUser}
                    </Text>
                    <Button
                      className={styles.dismissButton}
                      onClick={() => {
                        setSelectedUser(undefined);
                      }}
                      aria-label="Close"
                    >
                      <Dismiss12Regular />
                    </Button>
                  </div>
                )}
              </div>

              <JobLLMChecklistDatasetView
                publicLMChecklistSets={
                  jobCreationFileStore.publicLMChecklistSets
                }
                customLMChecklistSets={
                  jobCreationFileStore.customLMChecklistSets
                }
                setPublicLMChecklistSets={updatePublicLMChecklistSets}
              />

              {jobCreationSyntheticStore.selectedUser && (
                <>
                  <InfoLabel className={styles.blockTitle} required>
                    Query set
                  </InfoLabel>
                  <RadioGroup
                    className={styles.radioGroup}
                    value={jobCreationSyntheticStore.queryType}
                    onChange={(_, data) => {
                      setQueryType(data.value as QueryType);
                    }}
                  >
                    <Radio
                      value={QueryType.LinkSyntheticTenant}
                      label="Linked with Synthetic Tenant"
                    />
                    <Radio
                      value={QueryType.UploadQuerySet}
                      label="Upload query set"
                    />
                  </RadioGroup>
                  {jobCreationSyntheticStore.queryType ===
                    QueryType.LinkSyntheticTenant && (
                    <Combobox
                      className={styles.querySetCombobox}
                      value={jobCreationSyntheticStore.selectedQuerySet}
                      onOptionSelect={(_, data) => {
                        setSelectedQuerySet(data.optionValue as string);
                        updateConfiguration();
                      }}
                      defaultValue={jobCreationSyntheticStore.selectedQuerySet}
                      placeholder="Select a query set"
                    >
                      {jobCreationSyntheticStore.querySets.map(
                        (querySet, index) => (
                          <Option key={index} value={querySet}>
                            {querySet}
                          </Option>
                        ),
                      )}
                    </Combobox>
                  )}
                  {jobCreationSyntheticStore.queryType ===
                    QueryType.UploadQuerySet && (
                    <JobSelectedFileView
                      customQuerySets={jobCreationFileStore.customQuerySets}
                      setCustomQuerySets={updateCustomizedQuerySets}
                      refreshCustomQuerySets={refreshCustomQuerySets}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {jobCreationSyntheticStore.accountType ===
            AccountType.InputManually && (
            <>
              <JobSelectedFileView
                customQuerySets={jobCreationFileStore.customQuerySets}
                setCustomQuerySets={updateCustomizedQuerySets}
                refreshCustomQuerySets={refreshCustomQuerySets}
              />
              <JobLLMChecklistDatasetView
                publicLMChecklistSets={
                  jobCreationFileStore.publicLMChecklistSets
                }
                customLMChecklistSets={
                  jobCreationFileStore.customLMChecklistSets
                }
                setPublicLMChecklistSets={updatePublicLMChecklistSets}
              />
              <JobUserIdView />
            </>
          )}
        </div>
      </Field>
    </div>
  );
});
