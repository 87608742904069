import type {
  CreateJobRouterRequestData,
  unifiedBizChatConfiguration,
} from "@seval-portal/shared";
import { TemplateType } from "@seval-portal/shared";
import {
  bingAzureStorageUrlRoot,
  folderPath_bingJobConfig,
  folderPath_CWCJobConfig,
} from "../../../constants/constants";
import { convertUnifiedConfigToBizchat } from "../../../helpers/configConvertHelper";
import type { SubstrateSingleUserInfor } from "../../../models/SearchUserByIdResponse";
import { store } from "../../../store/store";
import { uploadFile } from "../actions/uploadFile";
import { generateFormattedDateString } from "../helpers/formatHelper";
import { jobPriorityStore } from "../store/jobPriorityStore";
import { jobShareStore } from "../store/jobShareStore";
import type { IJobBasicInfo } from "../store/jobStore";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";
import { getTemplateType } from "./getTemplateType";

export interface IJobProperties extends IJobBasicInfo {
  isPriorityJob: boolean;
  jobPriorityADOLink: string | undefined;
  permissionList: SubstrateSingleUserInfor[];
}
const uploadConfigAndReturnJob = async (
  folder: string,
  props: IJobProperties,
) => {
  const jsonString = JSON.stringify(jobStore.configuration, null, 2);
  const jsonFile = new File([jsonString], jobStore.jobName + ".json", {
    type: "text/plain",
  });
  const jobName = props.jobName + "_" + generateFormattedDateString();
  const settingsFileName = `${folder}/${store.account?.localAccountId}/${jobName}.json`;
  const amlPath = bingAzureStorageUrlRoot + settingsFileName;
  const job = {
    JobName: jobName,
    CreatedBy: store.account?.name ?? "Unknown",
    ExperimentName: props.selectedTemplate?.ExperimentName ?? "",
    ExperimentNameOverride: getExperimentOverride(
      props.selectedTemplate?.ProductId,
      props.selectedTemplate?.ProductPipelineId,
    ),
    JobTemplateId: props.selectedTemplate?.Id ?? 0,
    Settings: JSON.stringify({
      config_file: {
        type: "uri_file",
        path: amlPath,
      },
    }),
    IsPriorityJob: props.isPriorityJob,
    PriorityJobADOLink: props.jobPriorityADOLink,
  };

  await uploadFile(settingsFileName, jsonFile, "Bing", undefined, () => {
    throw new Error("Fail to upload settings to storage.");
  });

  return job;
};

/** Get Experiment Override From product id and product pipeline id
 * @param productId product id
 * @param productPipelineId product pipeline id
 * @returns experiment override string
 * * for built-in products, return undefined
 * * for custom products, return experiment override string like "Product_MeetingChatCopilot_Sydney_Evaluation_Skip_Scraping"
 */
export const getExperimentOverride = (
  productId: string | undefined,
  productPipelineId: string | undefined,
) => {
  if (productId === undefined || productPipelineId === undefined) {
    return undefined;
  }

  const targetProduct = productSettingsStore.products.find(
    (_) => _.id === productId,
  );

  if (targetProduct === undefined) {
    return undefined;
  }

  const targetPipeline = targetProduct.templates.find(
    (_) => _.id === productPipelineId,
  );

  if (targetPipeline === undefined) {
    return undefined;
  }

  return `Product_${targetProduct.displayName.replace(" ", "")}_${
    targetPipeline.pipelineExpName
  }`;
};

export const convertBizchatConfigAndReturnJob = (props: IJobProperties) => {
  const unifiedBizChatConfig =
    jobStore.configuration as unifiedBizChatConfiguration;
  const bizchatConfig = convertUnifiedConfigToBizchat(unifiedBizChatConfig);
  const configString = JSON.stringify(
    {
      ...unifiedBizChatConfig,
      ...bizchatConfig,
    },
    null,
    2,
  );
  return {
    JobName: props.jobName + "-" + generateFormattedDateString(),
    ExperimentName: props.selectedTemplate?.ExperimentName ?? "",
    ExperimentNameOverride: getExperimentOverride(
      props.selectedTemplate?.ProductId,
      props.selectedTemplate?.ProductPipelineId,
    ),
    JobTemplateId: props.selectedTemplate?.Id ?? 0,
    Settings: configString,
    DataSets: props.dataSetsString,
    PermissionObjects: jobShareStore.permissionList.map(
      (item) => item.ObjectId,
    ),
    IsPriorityJob: props.isPriorityJob,
    PriorityJobADOLink: props.jobPriorityADOLink,
  };
};

export const generateCurrentJobViaProp = async (
  props: IJobProperties,
): Promise<CreateJobRouterRequestData> => {
  switch (getTemplateType.get()) {
    case TemplateType.BingV2:
      return await uploadConfigAndReturnJob(folderPath_bingJobConfig, props);
    case TemplateType.CWC:
      return await uploadConfigAndReturnJob(folderPath_CWCJobConfig, props);
    case TemplateType.UnifiedBizChat:
    case TemplateType.UnifiedBizChatFlightReview:
      return convertBizchatConfigAndReturnJob(props);
    default:
      return {
        JobName: props.jobName + "-" + generateFormattedDateString(),
        ExperimentName: props.selectedTemplate?.ExperimentName ?? "",
        ExperimentNameOverride: getExperimentOverride(
          props.selectedTemplate?.ProductId,
          props.selectedTemplate?.ProductPipelineId,
        ),
        JobTemplateId: props.selectedTemplate?.Id ?? 0,
        Settings: props.configurationJsonString,
        DataSets: props.dataSetsString,
        PermissionObjects: props.permissionList.map((item) => item.ObjectId),
        IsPriorityJob: props.isPriorityJob,
        PriorityJobADOLink: props.jobPriorityADOLink,
      };
  }
};

export const generateCurrentJob = (): Promise<CreateJobRouterRequestData> => {
  const props: IJobProperties = {
    ...jobStore,
    isPriorityJob: jobPriorityStore.isPriorityJob,
    jobPriorityADOLink: jobPriorityStore.jobPriorityADOLink,
    permissionList: jobShareStore.permissionList,
  };
  return generateCurrentJobViaProp(props);
};
