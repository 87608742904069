import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  ArrowDownload16Filled,
  ArrowDownload16Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react";
import { getBingAMLFileDownloadLink } from "../../../../../helpers/apiHelper";
import { getOutputBlobSource } from "../../../helpers/bingResultHelper";
import type { BingJobFile } from "../../../models/BingJobFile";
import type { Job } from "../../../models/Job";

interface BingDownloadButtonProps {
  job: Job | undefined;
  outputFiles: BingJobFile[];
  scenario: "Single Job" | "Job Group";
}

export const BingDownloadButton = observer((props: BingDownloadButtonProps) => {
  const DownloadIcon = bundleIcon(
    ArrowDownload16Filled,
    ArrowDownload16Regular,
  );
  const blobSource = getOutputBlobSource(props.job);
  const files =
    props.outputFiles.filter(
      (item) =>
        item.fileName.endsWith("details.csv") ||
        item.fileName.endsWith("conversations.tsv") ||
        item.fileName.endsWith("dashboard.html") ||
        item.fileName.endsWith(".zip"),
    ) ?? [];

  const download = (file: BingJobFile) => {
    getBingAMLFileDownloadLink({
      filename: file.fullPath,
      blob: blobSource,
    }).then((result) => window.open(result, "_blank"));
  };

  const finalGroup =
    files?.reduce((acc, file) => {
      if (acc.has(file.folderName)) {
        acc.get(file.folderName)?.push(file);
      } else {
        acc.set(file.folderName, [file]);
      }
      return acc;
    }, new Map<string, BingJobFile[]>()) ?? new Map();

  return (
    <div>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          {props.scenario === "Single Job" ? (
            <Button icon={<DownloadIcon />}>Download</Button>
          ) : (
            <Button style={{ width: "fit-content" }} appearance="transparent">
              {props.job?.JobName}
            </Button>
          )}
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {finalGroup.size != 0 ? (
              [...finalGroup.entries()].map(
                ([key, value]: [string, BingJobFile[]]) => (
                  <Menu key={key}>
                    <MenuTrigger disableButtonEnhancement>
                      <MenuItem> {key}</MenuItem>
                    </MenuTrigger>

                    <MenuPopover>
                      <MenuList>
                        {value.map((file) => (
                          <MenuItem
                            key={file.fileName}
                            onClick={() => download(file)}
                          >
                            {file.fileName}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ),
              )
            ) : (
              <MenuItem disabled>No File Available</MenuItem>
            )}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
});
