import {
  Field,
  InfoLabel,
  Input,
  shorthands,
  Switch,
  tokens,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getExpConfigs } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      columnGap: "8px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

type Time = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const JobEvalTimeOverrideView = observer(() => {
  const styles = useStyles();
  const timeCellWidth = { maxWidth: "64px" };

  const [enabled, setEnabled] = useState(false);
  const [overrideValue, setOverrideValue] = useState("");

  const [date, setDate] = useState<Date | null | undefined>(null);
  const [time, setTime] = useState({
    hours: "0",
    minutes: "0",
    seconds: "0",
  });

  const resetFields = () => {
    setOverrideValue("");
    setDate(null);
    setTime({
      hours: "0",
      minutes: "0",
      seconds: "0",
    });
  };

  useEffect(() => {
    setEnabled(false);
    resetFields();
  }, [jobStore.selectedTemplate]);

  useEffect(() => {
    setOverrideValue(getOverrideValue(date, time));
  }, [date, time]);

  useEffect(() => {
    updateEvalTimeOverride(enabled);
  }, [overrideValue]);

  const updateEvalTimeOverride = (isEnabled: boolean) => {
    if (!isEnabled) {
      resetFields();
    }
    updatePropValueActionV2({
      prop: "configs_in_json.conversations.eval_time_override",
      newData: isEnabled ? overrideValue : undefined,
    });
  };

  const updateVariants = (index: number, variantsSet: Set<string>) => {
    const variants = Array.from(variantsSet).join(",");
    updatePropValueActionV2({
      prop: `configs_in_json.conversations.exp_configs[${index}].sydney.variants`,
      newData: variants,
    });
  };

  const toggleEvalTimeOverride = (index: number, isEnabled: boolean) => {
    const expConfigs = getExpConfigs.get();
    const variantsString = expConfigs?.[index]?.sydney?.variants;
    const temporalVariants = [
      "feature.EnableUseRelativeDateTimeForSearchResults",
    ];

    if (variantsString !== undefined) {
      const variantsSet = new Set(
        variantsString ? variantsString.split(",") : [],
      );
      temporalVariants.forEach((variant) => {
        isEnabled ? variantsSet.add(variant) : variantsSet.delete(variant);
      });

      updateEvalTimeOverride(isEnabled);
      updateVariants(index, variantsSet);
    }
  };

  const onTimeInputChange = (value: string, timePart: keyof Time) => {
    if (isNaN(parseInt(value))) {
      setTime({ ...time, [timePart]: "0" });
      return;
    }

    value =
      timePart === "hours"
        ? (parseInt(value) % 24).toString()
        : (parseInt(value) % 60).toString();

    setTime({ ...time, [timePart]: value });
  };

  const errors = getJobErrorByPath(
    "configs_in_json.conversations.eval_time_override",
  );

  return (
    <div className={styles.block}>
      <div
        className={styles.row}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InfoLabel
          className={styles.blockTitle}
          info="Datetime to evaluate queries"
        >
          Eval time override:
        </InfoLabel>
        <Switch
          checked={enabled}
          onChange={() => {
            setEnabled(!enabled);
            getExpConfigs.get()?.forEach((_, index) => {
              toggleEvalTimeOverride(index, !enabled);
            });
          }}
          data-testid="eval-time-override-switch"
        />
      </div>
      {enabled && (
        <Field
          style={{ width: "100%" }}
          validationState={errors === undefined ? "none" : "error"}
          validationMessage={errors?.message}
          data-testid="eval-time-override-field"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              columnGap: "4px",
              alignItems: "center",
            }}
          >
            <Field label="Date" style={{ width: "75%" }}>
              <DatePicker
                value={date}
                placeholder="YYYY-MM-DD"
                onSelectDate={setDate}
                formatDate={formatDate}
              />
            </Field>
            <Field label="Hours">
              <Input
                style={timeCellWidth}
                size="medium"
                placeholder="HH"
                type="number"
                min={0}
                max={23}
                value={time.hours}
                onChange={(_, data) => {
                  onTimeInputChange(data.value, "hours");
                }}
              />
            </Field>
            <Field label="Minutes">
              <Input
                style={timeCellWidth}
                size="medium"
                placeholder="MM"
                type="number"
                min={0}
                max={59}
                value={time.minutes}
                onChange={(_, data) => {
                  onTimeInputChange(data.value, "minutes");
                }}
              />
            </Field>
            <Field label="Seconds">
              <Input
                style={timeCellWidth}
                size="medium"
                placeholder="SS"
                type="number"
                min={0}
                max={59}
                value={time.seconds}
                onChange={(_, data) => {
                  onTimeInputChange(data.value, "seconds");
                }}
              />
            </Field>
          </div>
        </Field>
      )}
    </div>
  );
});

export const formatDate = (date?: Date): string => {
  if (!date) {
    return "";
  }

  return date.toISOString().split("T")[0];
};

export const getOverrideValue = (
  date: Date | null | undefined,
  time: Time,
): string => {
  if (!date) {
    return "";
  }

  const newDate = new Date(date);
  newDate.setUTCHours(
    parseInt(time.hours),
    parseInt(time.minutes),
    parseInt(time.seconds),
  );

  const dateTimeString = newDate.toISOString().split(".")[0] + "Z";

  return dateTimeString;
};
