import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Link,
} from "@fluentui/react-components";
import { Copy16Regular } from "@fluentui/react-icons";
import clipboardCopy from "clipboard-copy";
import { observer } from "mobx-react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";

export interface IJavisDialogProps {
  isOpen: boolean;
  onComplete: () => void;
  link: string;
  linkName: string;
}

const JavisLink =
  "https://portal.microsoftgeneva.com/dashboard/SubstrateSearchService/" as const;
export const JavisDialog = observer((props: IJavisDialogProps) => {
  const { isOpen, link, linkName } = props;
  const toast = useToast();

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{linkName}</DialogTitle>
          <DialogContent>
            <div>
              Please login <Link href={JavisLink}>Jarvis</Link>
              <Button
                data-testid="copy-javis-link"
                appearance="transparent"
                size="small"
                onClick={() => {
                  toast.onToastStart("copy...");
                  clipboardCopy(JavisLink);
                  toast.onToastSuccess("Javis link copied!");
                }}
                icon={<Copy16Regular />}
              ></Button>{" "}
              first and then click on the <Link href={link}>{linkName}</Link>
              <Button
                data-testid="copy-link"
                appearance="transparent"
                size="small"
                onClick={() => {
                  toast.onToastStart("copy...");
                  clipboardCopy(link);
                  toast.onToastSuccess(`${linkName} link copied!`);
                }}
                icon={<Copy16Regular />}
              ></Button>
              to access it.
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              data-testid="login-button"
              onClick={() => {
                window.open(JavisLink, "_blank");
              }}
            >
              Login Jarvis
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
