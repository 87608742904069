import { orchestrator } from "satcheljs";
import {
  uploadBizChatSkipScrapingFolder,
  uploadCWCSkipScrapingFolder,
  uploadOPGFolder,
  uploadSyntheticIngestionDataFolder,
} from "../../../helpers/apiHelper";
import { uploadFolderAction } from "../actions/uploadFolderAction";

orchestrator(uploadFolderAction, (actionMessage) => {
  const { folderType, folderName, folder, oldFolderName, onSuccess, onError } =
    actionMessage;
  const name = folderName.trim();

  if (name === "") {
    return;
  }

  switch (folderType) {
    case "OPG":
    case "UniversalSearch":
      uploadOPGFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;

    case "CWC":
      uploadCWCSkipScrapingFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;

    case "GeneralSkipScraping":
      uploadBizChatSkipScrapingFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;

    case "SyntheticIngestionData":
      uploadSyntheticIngestionDataFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;
  }
});
