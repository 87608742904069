import { mutatorAction } from "satcheljs";
import type { PrivacyFilterTemplateContentArray } from "@seval-portal/shared";
import type { PassFailRateByQuery } from "../helpers/lmchecklistMetricsHelper";
import type { GeneralMetricsResponse } from "../models/GeneralMetricsResponse";
import type { LMChecklistAssertionsResponse } from "../models/LMChecklistAssertionsResponse";
import type { LMChecklistDetailsResponse } from "../models/LMChecklistDetailsResponse";
import type { LMChecklistIcmsResponse } from "../models/LMChecklistIcmsResponse";
import type { MChatSValueResponse } from "../models/UtteranceResponse";
import type {
  IJobDetailStore,
  LMCheckListFilters,
  MChatUtteranceMetrics,
  MChatUtteranceMetricsSingleData,
  Sort,
} from "../store/jobDetailStore";
import {
  defaultLMCheckListFilters,
  jobDetailStore,
} from "../store/jobDetailStore";

export const updateGeneralMetricsResponse = mutatorAction(
  "updateGeneralMetricsResponse",
  (response: GeneralMetricsResponse | undefined) => {
    jobDetailStore.generalMetricsResponse = response;
  },
);

export const updateLMChecklistDetailsResponse = mutatorAction(
  "updateLMChecklistDetailsResponse",
  (response: LMChecklistDetailsResponse | undefined) => {
    jobDetailStore.lmchecklistDetailsResponse = response;
  },
);

export const updateLMChecklistAssertionsResponse = mutatorAction(
  "updateLMChecklistAssertionsResponse",
  (response: LMChecklistAssertionsResponse | undefined) => {
    jobDetailStore.lmchecklistAssertionsResponse = response;
  },
);

export const updateLMChecklistIcmsResponse = mutatorAction(
  "updateLMChecklistIcmsResponse",
  (response: LMChecklistIcmsResponse | undefined) => {
    jobDetailStore.lmchecklistIcmsResponse = response;
  },
);

export const updatePrivacyFilterResponse = mutatorAction(
  "updatePrivacyFilterResponse",
  (response: PrivacyFilterTemplateContentArray | undefined) => {
    jobDetailStore.privacyFilterResponse = response;
  },
);

export const updateFilters = mutatorAction(
  "updateFilters",
  (selected: string[]) => {
    jobDetailStore.filters = selected;
  },
);

export const updateUtterNum = mutatorAction(
  "updateUtterNum",
  (uttrNum: number) => {
    jobDetailStore.utterNum = uttrNum;
  },
);

export const updateExpandIndex = mutatorAction(
  "updateExpandIndex",
  (index: number | undefined) => {
    jobDetailStore.expandedIndex = index;
  },
);

export const updatedMetricsToDisplay = mutatorAction(
  "updatedMetricsToDisplay",
  (res: string) => {
    jobDetailStore.metricsToDisplay = [...jobDetailStore.metricsToDisplay, res];
  },
);

export const updateRaiEnabled = mutatorAction(
  "updateSelectedRaiContent",
  (isEnabled: boolean) => {
    jobDetailStore.isRaiEnabled = isEnabled;
  },
);

export const updatedDebugLinkList = mutatorAction(
  "updatedDebugLinkList",
  (res: MChatSValueResponse) => {
    jobDetailStore.debuglinkList = res;
  },
);

export const updatedAnnotationDebuglinkList = mutatorAction(
  "updatedAnnotationDebuglinkList",
  (res: MChatSValueResponse) => {
    jobDetailStore.annotationDebuglinkList = res;
  },
);

export const updatedUtteranceList = mutatorAction(
  "updatedUtteranceList",
  (res: Map<string, MChatUtteranceMetrics>) => {
    jobDetailStore.utteranceList = res;
  },
);

export const updatedUtteranceDetail = mutatorAction(
  "updatedUtteranceDetail",
  (queryHash: string, res: MChatUtteranceMetricsSingleData[]) => {
    jobDetailStore.utteranceMetricDetail.set(queryHash, res);
  },
);

export const updatedselectedMChatMetrics = mutatorAction(
  "updatedselectedMChatMetrics",
  (selected: string[]) => {
    jobDetailStore.selectedMChatMetrics = selected;
  },
);

export const updateLMchecklistAssertionViewType = mutatorAction(
  "updatelmchecklistAssertionViewType",
  (newData: "All" | "Regressions") => {
    jobDetailStore.lmchecklistAssertionViewType = newData;
    if (newData === "Regressions") {
      jobDetailStore.LMCheckListViewMode = "Assertion";
    } else {
      jobDetailStore.LMCheckListViewMode = "Query";
    }
  },
);

export const resetLMCheckListFilter = mutatorAction(
  "resetLMCheckListFilter",
  () => {
    jobDetailStore.lmCheckListFilters = defaultLMCheckListFilters;
    jobDetailStore.sorting = { ascending: true };
  },
);

export const updatedLMChecklistViewMode = mutatorAction(
  "updatedLMChecklistViewMode",
  (newData: "Query" | "Assertion") => {
    jobDetailStore.LMCheckListViewMode = newData;
  },
);

export const updatedQueryData = mutatorAction(
  "updatedQueryData",
  (newData: LMChecklistDetailsResponse) => {
    jobDetailStore.queryData = newData;
  },
);

export const updatedpfRatesByQuery = mutatorAction(
  "updatedpfRatesByQuery",
  (newData: PassFailRateByQuery[]) => {
    jobDetailStore.pfRatesByQuery = newData;
  },
);

export const updatedShowScores = mutatorAction(
  "updatedShowScores",
  (newData: boolean) => {
    jobDetailStore.showScores = newData;
  },
);

export const updatedSorting = mutatorAction(
  "updatedSorting",
  (newData: Sort) => {
    jobDetailStore.sorting = newData;
  },
);

export const updatedLMCheckListFilters = mutatorAction(
  "updatedLMCheckListFilters",
  (newData: LMCheckListFilters) => {
    jobDetailStore.lmCheckListFilters = newData;
  },
);

export const updatedIsDevUIDialogOpen = mutatorAction(
  "updatedIsDevUIDialogOpen",
  (newData: boolean) => {
    jobDetailStore.isDevUIDialogOpen = newData;
  },
);

export const restJobDetail = mutatorAction(
  "restJobDetail",
  (value: IJobDetailStore) => {
    Object.assign(jobDetailStore, value);
  },
);
