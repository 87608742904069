import { createStore } from "satcheljs";
import type { PrivacyFilterTemplateContentArray } from "@seval-portal/shared";
import type { PassFailRateByQuery } from "../helpers/lmchecklistMetricsHelper";
import type { GeneralMetricsResponse } from "../models/GeneralMetricsResponse";
import type {
  LMChecklistAssertion,
  LMChecklistAssertionsResponse,
} from "../models/LMChecklistAssertionsResponse";
import type {
  LMChecklistDetails,
  LMChecklistDetailsResponse,
} from "../models/LMChecklistDetailsResponse";
import type { LMChecklistIcmsResponse } from "../models/LMChecklistIcmsResponse";
import type { MChatSValueResponse } from "../models/UtteranceResponse";

export const defaultUttsPerPage = 20;

export interface MChatUtteranceMetricsSingleData {
  key: string;
  control: number;
  treatment: number;
}
export interface MChatUtteranceMetrics {
  segment: string;
  query_hash: string;
  query: string;
  metrics: MChatUtteranceMetricsSingleData[];
}

export type LMChecklistDetailsAndPassFailRateByQueryWithIcm = (
  | LMChecklistDetails
  | PassFailRateByQuery
) & { icms?: LMChecklistIcmsResponse | undefined };

export interface LMCheckListFilters {
  segment: string[];
  query: string;
  assertionStatus: (AssertionStatus | None)[];
  assertionLevel: (AssertionLevel | None)[];
  assertionText: string;
  assertionOwner: string;
  incompleteDataOnly: boolean;
  showPartialFailures: boolean;
  assertionsWithIcmOnly: boolean;
  assertionsWithoutIcmOnly: boolean;
  raiContent: boolean;
}

export type LMChecklistAssertionWithIcm = LMChecklistAssertion & {
  icm?: number | undefined;
};
export type LMChecklistAssertionWithIcmArray = ReturnType<
  typeof Array<LMChecklistAssertionWithIcm>
>;

type None = "";

export enum AssertionStatus {
  PIC = "Passed in Control",
  FIC = "Failed in Control",
  PIT = "Passed in Treatment",
  FIT = "Failed in Treatment",
}

export enum AssertionLevel {
  Critical = "Critical",
  Expected = "Expected",
  Aspirational = "Aspirational",
}

export type Sort = {
  field?: string;
  ascending: boolean;
};

export const LMCheckList_ITEMS_PER_PAGE = 20;

export const LMCheckListSortKeys = [
  "criticalControl",
  "criticalExperiment",
  "expectedControl",
  "expectedExperiment",
  "aspirationalControl",
  "aspirationalExperiment",
  "tControl",
  "tExperiment",
] as const;

export type LMCheckListSortKeyType = (typeof LMCheckListSortKeys)[number];

export interface IJobDetailStore {
  filters: string[];
  utterNum: number;
  expandedIndex: number | undefined;
  generalMetricsResponse: GeneralMetricsResponse | undefined;
  // NEW Updated
  metricsToDisplay: string[];
  selectedMChatMetrics: string[];
  debuglinkList: MChatSValueResponse;
  annotationDebuglinkList: MChatSValueResponse;
  isRaiEnabled: boolean;
  utteranceList: Map<string, MChatUtteranceMetrics>;
  utteranceMetricDetail: Map<string, MChatUtteranceMetricsSingleData[]>;
  lmchecklistDetailsResponse: LMChecklistDetailsResponse | undefined;
  lmchecklistAssertionsResponse: LMChecklistAssertionsResponse | undefined;
  lmchecklistIcmsResponse: LMChecklistIcmsResponse | undefined;
  privacyFilterResponse: PrivacyFilterTemplateContentArray | undefined;
  // LMCheckList view
  lmchecklistAssertionViewType: "All" | "Regressions";
  LMCheckListViewMode: "Query" | "Assertion";
  isDevUIDialogOpen: boolean;
  // LMChecklistQueryTable
  queryData: LMChecklistDetailsResponse;
  pfRatesByQuery: PassFailRateByQuery[];
  showScores: boolean;
  sorting: Sort;
  lmCheckListFilters: LMCheckListFilters;
}
export const defaultLMCheckListFilters: LMCheckListFilters = {
  segment: [],
  query: "",
  assertionStatus: [],
  assertionLevel: [],
  assertionText: "",
  assertionOwner: "",
  incompleteDataOnly: false,
  showPartialFailures: false,
  assertionsWithIcmOnly: false,
  assertionsWithoutIcmOnly: false,
  raiContent: true,
};

export const initJobDetailStore: IJobDetailStore = {
  filters: [],
  utterNum: defaultUttsPerPage,
  expandedIndex: undefined,
  generalMetricsResponse: undefined,
  metricsToDisplay: [],
  selectedMChatMetrics: [],
  debuglinkList: [],
  annotationDebuglinkList: [],
  isRaiEnabled: false,
  utteranceList: new Map<string, MChatUtteranceMetrics>(),
  utteranceMetricDetail: new Map<string, MChatUtteranceMetricsSingleData[]>(),
  lmchecklistDetailsResponse: undefined,
  lmchecklistAssertionsResponse: undefined,
  lmchecklistIcmsResponse: undefined,
  privacyFilterResponse: undefined,
  lmchecklistAssertionViewType: "All",
  LMCheckListViewMode: "Query",
  isDevUIDialogOpen: false,
  queryData: [],
  pfRatesByQuery: [],
  showScores: false,
  sorting: { ascending: true },
  lmCheckListFilters: defaultLMCheckListFilters,
};

export const jobDetailStore = createStore<IJobDetailStore>(
  "jobDetailStore",
  Object.assign({}, initJobDetailStore),
)();
