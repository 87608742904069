import { toInteger } from "lodash";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { perfWrapper } from "../../../../../helpers/telemetryHelper";
import { useWindowHeight } from "../../../../../hooks/useWindowHeight";
import { getDiagnosisSBSViewData } from "../../../helpers/bingResultHelper";
import type { BingDiagnosisMetricReasoningGroup } from "../../../models/BingDiagnosisFile";
import { type BingDiagnosisLoadedItem } from "../../../models/BingDiagnosisFile";
import {
  getBingDropdownValuesInDiagnosisPage,
  getDiagnosisTableData,
} from "../../../selectors/prepareBingJobResult";
import { resultStore } from "../../../store/resultStore";
import { BingDiagnosisTable } from "../Table/BingDiagnosisTable";
import { BingDropDown } from "./BingDropDown";

export const BingDiagnosisView = observer(() => {
  const [itemNumberInOnePage, setItemNumberInOnePage] = React.useState(10);
  const windowHeight = useWindowHeight();
  const [shownItemNumber, setShownItemNumber] =
    React.useState(itemNumberInOnePage);
  const [displayList, setDisplayList] = React.useState<
    BingDiagnosisLoadedItem[]
  >([]);
  const diagnosisList = getDiagnosisTableData.get();

  const refreshList = () => {
    const orderedListKey =
      diagnosisList.length > shownItemNumber
        ? diagnosisList.slice(0, shownItemNumber)
        : diagnosisList;
    const allPromise = orderedListKey.map((item) => {
      if (resultStore.loadedSBSViews.get(item) === undefined) {
        return Promise.resolve(getDiagnosisSBSViewData(item));
      }
      return Promise.resolve({
        Key: item,
        Conversation:
          resultStore.loadedSBSViews.get(item)?.baseline[0].Request ??
          "Loading...",
        Metrics: resultStore.loadedMetrics.get(item) ?? {},
      });
    });

    perfWrapper(
      "LoadBingDiagnosisData",
      Promise.all(allPromise).then((values) => {
        const validValues = values.filter(
          (item) => item !== undefined,
        ) as BingDiagnosisLoadedItem[];
        setDisplayList(validValues);
      }),
    );
  };

  useEffect(() => {
    const fixedHeigheAboveTable = 500;
    const itemHeight = 50;
    const maxItemNumberInOnePage = toInteger(
      (windowHeight - fixedHeigheAboveTable) / itemHeight,
    );
    setItemNumberInOnePage(maxItemNumberInOnePage);
    if (shownItemNumber < maxItemNumberInOnePage) {
      setShownItemNumber(maxItemNumberInOnePage);
    }
  }, [windowHeight]);

  useEffect(() => {
    refreshList();
  }, [diagnosisList, shownItemNumber]);

  const columns = computed(() =>
    resultStore.selectedFilter.get("Metrics")?.split(","),
  ).get();

  const getDeltaValue = (item: BingDiagnosisLoadedItem, metric: string) => {
    const metricData = item.Metrics[
      metric
    ] as BingDiagnosisMetricReasoningGroup;
    return metricData?.delta?.toFixed(2) ?? "";
  };

  const renderList = () => (
    <BingDiagnosisTable
      loadMore={() => setShownItemNumber(shownItemNumber + itemNumberInOnePage)}
      hasMore={shownItemNumber < diagnosisList.length}
      rows={displayList}
      columns={[
        {
          title: "Conversation",
          render: (item) => (
            <div style={{ maxHeight: "20px", overflow: "hidden" }}>
              {item.Conversation.match(/(^.*?[a-zA-Z]+[.!?。])/)?.[0] ??
                item.Conversation}
            </div>
          ),
          weight: 3,
          minWidth: "400px",
        },
        ...(columns?.map((metric) => {
          return {
            title: metric,
            render: (item: BingDiagnosisLoadedItem) => (
              <div>{getDeltaValue(item, metric)}</div>
            ),
            weight: 1,
            minWidth: "50px",
          };
        }) ?? []),
      ]}
    />
  );

  const dropdownOptions = getBingDropdownValuesInDiagnosisPage.get();
  return (
    <div>
      <BingDropDown options={dropdownOptions} multiSelect={["Metrics"]} />
      {displayList.length > 0 && renderList()}
    </div>
  );
});
