import { Label, shorthands, tokens } from "@fluentui/react-components";
import { Warning32Filled } from "@fluentui/react-icons";
import { TemplateType } from "@seval-portal/shared";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { getTemplateType } from "../../../selectors/getTemplateType";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      padding: "16px",
      border: "1px solid #EDEBE9",
      borderRadius: "1px",
      flexDirection: "column",
      gap: "8px",
      flex: 1,
    },
    warningBox: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "top",
      backgroundColor: tokens.colorStatusWarningBackground1,
      padding: "8px",
      border: `1px solid ${tokens.colorStatusWarningBorder1}`,
      borderRadius: tokens.borderRadiusMedium,
    },
    warningIcon: {
      verticalAlign: "middle",
      color: tokens.colorStatusWarningForeground1,
    },
    warningLabel: {
      fontSize: tokens.fontSizeBase400,
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobWarningView = observer(() => {
  const styles = useStyles();
  const isCWCShipRoomTemplate = computed(
    () =>
      getTemplateType.get() === TemplateType.CWC &&
      jobStore.selectedTemplate?.Name === "shiproom_full",
  ).get();

  if (isCWCShipRoomTemplate) {
    return (
      <>
        <div className={styles.warningBox}>
          <Warning32Filled className={styles.warningIcon} />
          <Label className={styles.warningLabel}>
            Attention: Please note, this is a <b>triage template</b>. It should
            only be ran when internal iterations are completed with other
            templates and you are planning to bring your feature to shiproom.
          </Label>
        </div>
      </>
    );
  }

  return <></>;
});
