import {
  Field,
  InfoLabel,
  Input,
  makeStyles,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { isDogfooder } from "../../../../../selectors/userPermission";
import {
  updatePropKeyActionV2,
  updatePropValueActionV2,
} from "../../../actions/jobActions";
import { generateFormattedDateString } from "../../../helpers/formatHelper";
import type { CachedConversation } from "../../../models/CachedConversation";
import { getCompeteRunMode, getValue } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { CachedConversationTableSelector } from "../ScrapingComponents/CachedConversationTableSelector";
import { QuerySetTableSelector } from "../ScrapingComponents/QuerySetTableSelector";
import { ScraperSelector } from "../ScrapingComponents/ScraperSelector";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  input: {
    backgroundColor: "#F8F7F9",
  },
});

export const ScrapingOnlyRender = observer(() => {
  const styles = useStyles();
  const [cacheName, setCacheName] = React.useState<string>("");
  const [selectedQuerySet, setSelectedQuerySet] = React.useState<
    string | undefined
  >("general_purpose");
  const [selectedCachedConversation, setSelectedCachedConversation] =
    React.useState<CachedConversation | undefined>();
  const templateEnv = jobStore.selectedTemplate?.EnvironmentId;
  const runMode = getCompeteRunMode.get();
  const hasPermission =
    isDogfooder.get() && isFeatureEnabled("cachedConversation");

  const handlerQuerySetChange = (querySet: string | undefined) => {
    const oldTestSetName = selectedQuerySet ?? "general_purpose";
    const newTestSetName = querySet ?? "general_purpose";
    setSelectedQuerySet(querySet);
    setSelectedCachedConversation(undefined);
    if (querySet) {
      const oldTestSetConfig = (getValue(`compete.${runMode}`) ?? {})[
        oldTestSetName
      ] ?? {
        metrics: ["sbsleov3"],
      };
      updatePropKeyActionV2({
        oldProp: `compete.${runMode}.${oldTestSetName}`,
        prop: `compete.${runMode}.${newTestSetName}`,
        newData: oldTestSetConfig,
      });
    }
    const expConfigs = getValue(`compete.exp_configs`) ?? [];
    const cacheResultExpIndex = expConfigs.findIndex(
      (v) => v.exp_name === "cache_result",
    );
    updatePropValueActionV2({
      prop: `compete.exp_configs[${cacheResultExpIndex}].scraping_result`,
      newData: "latest",
    });
  };
  const handlerCachedConversationChange = (
    cachedConversation: CachedConversation | undefined,
  ) => {
    setSelectedCachedConversation(cachedConversation);
    const expConfigs = getValue(`compete.exp_configs`) ?? [];
    const cacheResultExpIndex = expConfigs.findIndex(
      (v) => v.exp_name === "cache_result",
    );
    updatePropValueActionV2({
      prop: `compete.exp_configs[${cacheResultExpIndex}].scraping_result`,
      newData: cachedConversation?.ID
        ? Number(cachedConversation?.ID)
        : "latest",
    });
  };
  const handlerDisplayNameChange = (displayName: string) => {
    setCacheName(displayName);
    const scrapingResultJsonStr = JSON.stringify({ display_name: displayName });
    const expConfigs = getValue(`compete.exp_configs`) ?? [];
    const scrapingResultExpIndex = expConfigs.findIndex(
      (v) => v.exp_name === "scraping_result",
    );
    updatePropValueActionV2({
      prop: `compete.exp_configs[${scrapingResultExpIndex}].scraping_result`,
      newData: scrapingResultJsonStr,
    });
    updatePropValueActionV2({
      prop: `compete.exp_configs[${scrapingResultExpIndex}].skip_scraping`,
      newData: false,
    });
  };

  React.useEffect(() => {
    if (hasPermission) {
      const dateString = generateFormattedDateString();
      const defaultConversationName = `${selectedQuerySet}-${dateString}`;
      handlerDisplayNameChange(defaultConversationName);
    }
  }, [selectedQuerySet, hasPermission]);

  if (!hasPermission) {
    return (
      <MessageBar>
        <MessageBarBody>
          <MessageBarTitle>Whitelist Required</MessageBarTitle>
          Only whitelisted users within a security group can trigger this
          scraping job. Please contact this group (alias: competeanalysissg) to
          be added to the whitelist.{" "}
        </MessageBarBody>
      </MessageBar>
    );
  }

  return (
    <>
      <ScraperSelector />
      <QuerySetTableSelector
        selected={selectedQuerySet}
        envId={templateEnv}
        onChange={handlerQuerySetChange}
      />
      <div className={styles.block}>
        <Field
          label={
            <InfoLabel className={styles.blockTitle} required>
              Cached conversation name
            </InfoLabel>
          }
          validationState={cacheName ? "none" : "error"}
          validationMessage={
            cacheName ? "" : "Cached conversation name should not be empty"
          }
        >
          <Input
            data-testid="cached-conversation-name-input"
            className={styles.input}
            placeholder="Type the name of the cached conversation"
            value={cacheName}
            onChange={(_e, data) => {
              handlerDisplayNameChange(data.value);
            }}
          />
        </Field>
      </div>
      <CachedConversationTableSelector
        selected={selectedCachedConversation}
        querySet={selectedQuerySet}
        onChange={handlerCachedConversationChange}
      />
    </>
  );
});
