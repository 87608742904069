import { Button } from "@fluentui/react-components";
import { ArrowLeft32Filled } from "@fluentui/react-icons";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import {
  backToGroupListAction,
  saveSubJobAction,
} from "../../../actions/jobGroupActions";
import {
  isJobNameDuplicate,
  isReadyForSaveJob,
} from "../../../helpers/creationDataHelper";
import {
  confirmSBSValidator,
  isJobCreationEditDisabled,
  priorityJobValidator,
  servicePrevalidation,
  tokenPopupValidator,
} from "../../../helpers/jobCreationHelper";
import { updateStatusIsVerifying } from "../../../mutators/jobCreationMutators";
import { getJobCreationErrorMessage } from "../../../selectors/creationError/getJobCreationError";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { jobStore } from "../../../store/jobStore";
import { ShareButton } from "../../JobDetail/components/ShareButton";
import { useGroupStyles } from "../groupStyles";

export const JobCreationHeader = observer(() => {
  const styles = useGroupStyles();
  const toast = useToast();

  const onSaveClicked = () => {
    if (jobCreationStore.isSubmitting || jobCreationStore.isVerifying) {
      return;
    }

    toast.onToastStart("Verifying Job Creation...");
    updateStatusIsVerifying(true);

    if (jobStore.selectedTemplate === undefined) {
      toast.onToastFailure("Please select a template");
      updateStatusIsVerifying(false);
      return;
    }

    const errorMessage = getJobCreationErrorMessage.get();
    if (errorMessage !== undefined) {
      toast.onToastFailure(errorMessage);
      updateStatusIsVerifying(false);
      return;
    }

    Promise.resolve()
      .then(() => isJobNameDuplicate())
      .then(() => confirmSBSValidator())
      .then(() => priorityJobValidator())
      .then(() => tokenPopupValidator())
      .then(() => servicePrevalidation())
      .then(() => {
        toast.onToastSuccess("Job Creation Verified");
        saveSubJobAction();
      })
      .catch((error) => {
        toast.onToastFailure(error.toString());
      })
      .finally(() => {
        updateStatusIsVerifying(false);
      });
  };

  const shouldDisableSaveButton = computed(
    () => isJobCreationEditDisabled.get() || !isReadyForSaveJob.get(),
  ).get();

  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <ShareButton />
        <Button
          disabled={shouldDisableSaveButton}
          appearance={"primary"}
          onClick={onSaveClicked}
        >
          Save
        </Button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.rowWithSmallGap}>
            <Button
              data-testid="back-button"
              appearance={"subtle"}
              icon={<ArrowLeft32Filled />}
              onClick={() => {
                backToGroupListAction();
              }}
            ></Button>

            <span className={styles.headerTitle}>
              {jobGroupCreationStore.creationMode === "add"
                ? "Create a new job"
                : "Edit Job"}
            </span>
          </div>
        </div>
        <div>
          {!jobGroupCreationStore.isLoading && renderShareSubmitButton()}
        </div>
      </div>
    );
  };
  return <div>{renderHeader()}</div>;
});
