import { Field, Input } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { getGroupNameError } from "../../../helpers/creationDataHelper";
import { updateGroupName } from "../../../mutators/jobGroupCreationMutators";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { useGroupStyles } from "../groupStyles";

export const GroupNameView = observer(() => {
  const styles = useGroupStyles();

  const errorMessage = getGroupNameError.get();
  return (
    <div className={styles.block}>
      <Field
        required
        label={<span className={styles.blockTitle}>Group Name</span>}
        validationMessage={errorMessage}
        validationState={errorMessage ? "error" : undefined}
      >
        <Input
          size="medium"
          placeholder="Enter group name"
          value={jobGroupCreationStore.groupName}
          onChange={(_, data) => {
            updateGroupName(data.value);
          }}
        />
      </Field>
    </div>
  );
});
