"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSubmittedJobQualified = void 0;
const isSubmittedJobQualified = (jobs, settings) => {
    const submittedJobs = jobs.map((item) => item.ExperimentName);
    if (settings === undefined) {
        return true;
    }
    settings.forEach((template) => {
        if (template.isRequired) {
            const jobCount = submittedJobs.filter((item) => item === template.templateName).length;
            const minJobCount = parseInt(template.minJobCount ?? "1") ?? 1;
            if (jobCount < minJobCount) {
                return false;
            }
        }
    });
    return true;
};
exports.isSubmittedJobQualified = isSubmittedJobQualified;
