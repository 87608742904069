import { BrowserAuthError } from "@azure/msal-browser";
import type { DataBag } from "@seval-portal/shared";
import { AxiosError } from "axios";
import { ApiErrorResponse } from "../models/ApiResponse";
import { ApiError } from "./createRequest";
import { getRandomUUID } from "./getRandomUUID";
import type { TelemetryDataBag } from "./telemetryHelper";
import { telemetryHelper } from "./telemetryHelper";

type InternalTelemetryDataBag = TelemetryDataBag & { parentRequestId: string };
type ErrorInfoDataBag = DataBag & { message: string; skipRetry?: boolean };
const MAX_RETRY_COUNT = 2;

export type RetryOptions = { retryCount?: number };

export const apiErrorHandler = (e: ApiError) => {
  switch (e.code) {
    case "INVALID_REFRESH_TOKEN":
    case "QUERY_SET_NOT_FOUND":
    case "QUERY_SET_VALIDATION_FAILED":
    case "SECURITY_GROUP_NOT_FOUND":
    case "INVALID_USER_INPUT":
    case "SYNTHETIC_TENANT_NOT_FOUND":
    case "KUSTO_EXECUTION_ERROR":
    case "MISSING_SYNTHETIC_TENANT_TOKEN":
    case "MISSING_REQUIRED_BLOB_FILE":
    case "SCRAPING_SERVICE_ERROR":
      return {
        type: "ApiError",
        code: e.code,
        message: e.message,
        skipSuccessRate: true,
        skipRetry: true,
      };
    default:
      return {
        type: "ApiError",
        code: e.code,
        message: e.message,
      };
  }
};

export const axiosErrorHandler = (e: AxiosError) => {
  // Try get error message from response data
  const errorResponse = ApiErrorResponse(e.response?.data, "ApiErrorResponse");

  if (errorResponse) {
    return apiErrorHandler(
      new ApiError(errorResponse.message, errorResponse.code),
    );
  } else {
    return {
      type: "AxiosError",
      code: e.code,
      status: e.status,
      message: e.message,
    };
  }
};

const browserAuthError = (e: BrowserAuthError) => {
  return {
    type: "BrowserAuthError",
    code: e.errorCode,
    message: e.errorMessage,
    // For browser auth error, it is controlled by MSAL library,
    // we will show alert to force user to refresh the page when it happens
    // So we can skip the success rate calculation for this error
    skipSuccessRate: true,
  };
};

const generalErrorHandler = (e: Error) => {
  return {
    type: "Error",
    message: e.message,
  };
};

const getErrorInfo = (e: unknown): ErrorInfoDataBag => {
  if (e instanceof ApiError) {
    return apiErrorHandler(e);
  }
  if (e instanceof AxiosError) {
    return axiosErrorHandler(e);
  }
  if (e instanceof BrowserAuthError) {
    return browserAuthError(e);
  }
  if (e instanceof Error) {
    return generalErrorHandler(e);
  }
  return { message: JSON.stringify(e) };
};

const retryableWrapper = <T>(
  promise: Promise<T>,
  dataBag: InternalTelemetryDataBag,
): Promise<T> => {
  const startedTime = Date.now();
  const requestId = dataBag.parentRequestId;
  const parentDataBag = { ...dataBag, requestId };
  telemetryHelper.logNetworkEvent("SendRequestWithRetry", parentDataBag);

  return promise
    .then((result) => {
      telemetryHelper.logNetworkEvent("GetResponseWithRetry", {
        latency: Date.now() - startedTime,
        ...parentDataBag,
      });
      return result;
    })
    .catch((e) => {
      const errorInfo = getErrorInfo(e);
      telemetryHelper.logErrorEvent("RequestWithRetryError", {
        ...errorInfo,
        ...parentDataBag,
      });
      return Promise.reject(new Error(errorInfo.message));
    });
};

const retryableInternal = async <T>(
  requestGenerator: () => Promise<T>,
  dataBag: InternalTelemetryDataBag,
  options?: RetryOptions,
): Promise<T> => {
  let retryCount = 0;
  while (retryCount <= (options?.retryCount ?? MAX_RETRY_COUNT)) {
    const requestId = getRandomUUID();
    try {
      telemetryHelper.logNetworkEvent("SendSingleRequest", {
        ...dataBag,
        retryCount,
        requestId,
      });

      const startTime = Date.now();
      const result = await requestGenerator();

      telemetryHelper.logNetworkEvent("GetSingleResponse", {
        ...dataBag,
        latency: Date.now() - startTime,
        requestId,
      });

      return Promise.resolve(result);
    } catch (e) {
      const errorInfo = getErrorInfo(e);
      telemetryHelper.logErrorEvent("SingleRequestError", {
        ...dataBag,
        ...errorInfo,
        retryCount,
        requestId,
      });

      retryCount += 1;

      if (
        errorInfo.skipRetry === true ||
        retryCount > (options?.retryCount ?? MAX_RETRY_COUNT)
      ) {
        return Promise.reject(e);
      }
    }
  }

  return Promise.reject(new Error("Retryable failed"));
};

export const retryable = <T>(
  requestGenerator: () => Promise<T>,
  dataBag: TelemetryDataBag = {},
  options?: RetryOptions,
): Promise<T> => {
  const parentRequestId = getRandomUUID();
  const retryableDataBag = { ...dataBag, parentRequestId };
  return retryableWrapper(
    retryableInternal(requestGenerator, retryableDataBag, options),
    retryableDataBag,
  );
};
