import {
  Spinner,
  Toast,
  ToastBody,
  ToastTitle,
  Toaster,
  makeStyles,
  shorthands,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React, { createContext } from "react";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import type { WrapperProps } from "./WrapperProps";

export const SharedToasterPrefix = "shared-prefix-";
export const SharedToasterId = "shared-toaster";

export type Toaster = {
  onToastStart: (message: string) => void;
  onToastProgressUpdate: (message: string) => void;
  onToastSuccess: (message: string) => void;
  onToastFailure: (message: string, title?: string | undefined) => void;
  onToastDismiss: () => void;
};

const ToastContext = createContext<Toaster>({
  onToastStart: () => {
    telemetryHelper.logErrorEvent("UIError", {
      message: "onToastStart not implemented",
    });
  },
  onToastProgressUpdate: () => {
    telemetryHelper.logErrorEvent("UIError", {
      message: "onToastProgressUpdate not implemented",
    });
  },
  onToastSuccess: () => {
    telemetryHelper.logErrorEvent("UIError", {
      message: "onToastSuccess not implemented",
    });
  },
  onToastFailure: () => {
    telemetryHelper.logErrorEvent("UIError", {
      message: "onToastFailure not implemented",
    });
  },
  onToastDismiss: () => {
    telemetryHelper.logErrorEvent("UIError", {
      message: "onToastDismiss not implemented",
    });
  },
});

export const useToast = () => {
  return React.useContext(ToastContext);
};

const useStyles = makeStyles({
  text: {
    width: "100%",
    wordWrap: "break-word",
    ...shorthands.overflow("hidden"),
    paddingBottom: "6px",
  },
});

export const ToasterProvider = observer((props: WrapperProps) => {
  const styles = useStyles();
  const toastId = useId("actiontoast");
  const toasterId = useId(SharedToasterPrefix, SharedToasterId);
  const { dispatchToast, updateToast, dismissToast } =
    useToastController(toasterId);
  type toastType = "Progressing" | "Success" | "Error";

  const renderToast = (
    title: string | undefined,
    message: string,
    type: toastType,
  ) => {
    switch (type) {
      case "Progressing":
        return (
          <Toast>
            <ToastTitle media={<Spinner size="tiny" />}>{title}</ToastTitle>
            <ToastBody className={styles.text}>{message}</ToastBody>
          </Toast>
        );
      case "Success":
        return (
          <Toast>
            <ToastTitle>{"Success"}</ToastTitle>
            <ToastBody className={styles.text}>{message}</ToastBody>
          </Toast>
        );
      case "Error":
        return (
          <Toast>
            <ToastTitle className={styles.text}>
              {title ?? "Something went wrong"}
            </ToastTitle>
            <ToastBody className={styles.text}>{message}</ToastBody>
          </Toast>
        );
    }
  };

  const onToastStart = (message: string) => {
    dispatchToast(renderToast("Loading...", message, "Progressing"), {
      intent: "info",
      toastId,
      timeout: -1,
    });
  };

  const onToastProgressUpdate = (message: string) => {
    updateToast({
      content: renderToast("Loading...", message, "Progressing"),
      intent: "info",
      toastId,
      timeout: -1,
    });
  };

  const onToastSuccess = (message: string) => {
    updateToast({
      content: renderToast("Success", message, "Success"),
      intent: "success",
      toastId,
      timeout: 3000,
    });
  };

  const onToastFailure = (message: string, title: string | undefined) => {
    updateToast({
      content: renderToast(title, message, "Error"),
      intent: "error",
      toastId,
      timeout: 3000,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        onToastStart: onToastStart,
        onToastSuccess: onToastSuccess,
        onToastFailure: onToastFailure,
        onToastProgressUpdate: onToastProgressUpdate,
        onToastDismiss: () => dismissToast(toastId),
      }}
    >
      {props.children}
      <Toaster toasterId={toasterId} />
    </ToastContext.Provider>
  );
});
