"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LMChecklistAssertionsRouterParser = exports.RenameJobRouterParser = exports.RerunJobRouterParser = exports.CancelJobRouterParser = exports.ProductUpdateRouterParser = exports.ProductDeleteRouterParser = exports.ProductCreateRouterParser = exports.CustomizedDownloadRouterParser = exports.SearchScheduleJobParser = exports.UpdateScheduleJobRequestParser = exports.UploadSyntheticIngestionDataFolderRouterParser = exports.UploadBizChatSkipScrapingFolderRouterParser = exports.CreateScheduleJobRequestParser = exports.UpdateScheduleJobContentParser = exports.VerifyTokenRouterParser = exports.SetTokenRouterParser = exports.CreateConfigSnapshotRouterParser = exports.GetConfigSnapshotRouterParser = exports.ListConfigSnapshotRouterParser = exports.EditConfigRouterParser = exports.CheckOnlineQuerySetWarningRouterParser = exports.CheckOnlineQuerySetContentRouterParser = exports.ErrorNodeForFailedJobRouterParser = exports.QueryMChatAllMetricsRouterParser = exports.QueryMChatMetricsRouterParser = exports.QuerySvalueRouterParser = exports.GetQuerySetContentRouterParser = exports.ScrapingServiceTokenRevokeRouterParser = exports.PreSubmitJobRouterParser = exports.SearchSGRouterParser = exports.RemoveJobPermissionRouterParser = exports.BatchAddJobsPermissionRouterParser = exports.AddJobPermissionRouterParser = exports.GetJobPermissionRouterParser = exports.GetSGMembersRouterParser = exports.GetHeronJobOutputLinkParser = exports.GetOPGRAIResultParser = exports.GetDynamicPermissionRouterParser = exports.BingJobFileDownloadLinkRouterParser = exports.SetMetricsUpdatesRouterParser = exports.SetIncidentsRouterParser = exports.JobPerfMetricRouterParser = exports.CreateDetailsByJobIdRouterParser = exports.CreateBasicJobRouterParser = exports.SendPriorityJobNotificationRouterParser = exports.ValidateADOLinkRouterParser = exports.UpdateJobPriorityRouterParser = exports.CreateJobRouterParser = exports.JobsRouterParser = exports.JobRouterParser = void 0;
exports.GetJobPriorityRouterParser = exports.SetJobPriorityRouterParser = exports.AddJobToGroupRouterParser = exports.GetGroupRouterParser = exports.GetGroupListRouterParser = exports.CreateGroupJobRouterParser = exports.GPTListRouterParser = exports.LogRouterParser = exports.ShareByPassedRouterParser = exports.UtteranceConversationIdRouterParser = exports.QueryOPGMetricsByHashRouterParser = exports.QueryOPGMetricsRouterParser = exports.QueryOPGRAIAggregatedMetricsRouterParser = exports.QueryOPGRAIMetricsRouterParser = exports.QueryOPGQueriesRouterParser = exports.CallAvalonRouterParser = exports.UpdateRefreshTokenByCodeRouterParser = exports.LMChecklistDetailsByJobIdRouterParser = void 0;
const models_1 = require("../models");
const Incident_1 = require("../models/Incident");
const ScheduledJob_1 = require("../models/ScheduledJob");
const typeHelper_1 = require("../tools/typeHelper");
const settings_1 = require("./settings");
exports.JobRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
});
exports.JobsRouterParser = (0, typeHelper_1.object)({
    StartedId: (0, typeHelper_1.optional)(typeHelper_1.num),
    PageSize: (0, typeHelper_1.optional)(typeHelper_1.num),
    Keyword: (0, typeHelper_1.optional)(typeHelper_1.str),
    JobStatus: (0, typeHelper_1.optional)(typeHelper_1.str),
    Creator: (0, typeHelper_1.optional)(typeHelper_1.str),
    Filter: (0, typeHelper_1.optional)(typeHelper_1.str),
    Product: (0, typeHelper_1.optional)(typeHelper_1.str),
    ExperimentPrefix: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CreateJobRouterParser = (0, typeHelper_1.object)({
    JobName: typeHelper_1.str,
    Settings: typeHelper_1.str,
    JobTemplateId: (0, typeHelper_1.optional)(typeHelper_1.num),
    ExperimentName: typeHelper_1.str,
    ExperimentNameOverride: (0, typeHelper_1.optional)(typeHelper_1.str),
    DataSets: (0, typeHelper_1.optional)(typeHelper_1.str),
    PermissionObjects: (0, typeHelper_1.optional)((0, typeHelper_1.array)(typeHelper_1.str)),
    IsPriorityJob: (0, typeHelper_1.optional)(typeHelper_1.bool),
    PriorityJobADOLink: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.UpdateJobPriorityRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.num,
    IsPriorityJob: typeHelper_1.bool,
    ADOLink: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.ValidateADOLinkRouterParser = (0, typeHelper_1.object)({
    ADOLink: typeHelper_1.str,
});
exports.SendPriorityJobNotificationRouterParser = (0, typeHelper_1.object)({
    JobName: typeHelper_1.str,
    JobLink: typeHelper_1.str,
    PriorityDescription: typeHelper_1.str,
    LeaderName: typeHelper_1.str,
    LeaderAddress: typeHelper_1.str,
});
exports.CreateBasicJobRouterParser = (0, typeHelper_1.object)({
    JobName: typeHelper_1.str,
    Settings: typeHelper_1.str,
    ExperimentName: typeHelper_1.str,
    DataSets: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CreateDetailsByJobIdRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
    JobCreator: typeHelper_1.str,
    Query: typeHelper_1.str,
    MetricKey: typeHelper_1.str,
});
exports.JobPerfMetricRouterParser = (0, typeHelper_1.object)({
    SourceA: typeHelper_1.str,
    SourceB: typeHelper_1.str,
});
exports.SetIncidentsRouterParser = (0, typeHelper_1.object)({
    Incident: Incident_1.IncidentList,
});
exports.SetMetricsUpdatesRouterParser = (0, typeHelper_1.object)({
    MetricsChange: typeHelper_1.str,
});
exports.BingJobFileDownloadLinkRouterParser = (0, typeHelper_1.object)({
    filename: typeHelper_1.str,
    blob: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.GetDynamicPermissionRouterParser = (0, typeHelper_1.object)({
    SubstrateToken: typeHelper_1.str,
    CallerFileNameLine: typeHelper_1.str,
    ClientRequestId: typeHelper_1.str,
    ProcessName: typeHelper_1.str,
});
exports.GetOPGRAIResultParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
    Manifest: typeHelper_1.str,
});
exports.GetHeronJobOutputLinkParser = (0, typeHelper_1.object)({
    WorkspaceId: typeHelper_1.str,
    SandboxId: typeHelper_1.str,
    ContainerName: typeHelper_1.str,
    StoragePath: typeHelper_1.str,
    HeronAccessToken: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.GetSGMembersRouterParser = (0, typeHelper_1.object)({
    SubstrateToken: typeHelper_1.str,
    CallerFileNameLine: typeHelper_1.str,
    ClientRequestId: typeHelper_1.str,
    ProcessName: typeHelper_1.str,
    EmailAlias: typeHelper_1.str,
});
exports.GetJobPermissionRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
});
exports.AddJobPermissionRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
    UserIds: (0, typeHelper_1.array)(typeHelper_1.str),
});
exports.BatchAddJobsPermissionRouterParser = (0, typeHelper_1.object)({
    JobIds: (0, typeHelper_1.array)(typeHelper_1.str),
    UserIds: (0, typeHelper_1.array)(typeHelper_1.str),
});
exports.RemoveJobPermissionRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
    UserId: typeHelper_1.str,
});
exports.SearchSGRouterParser = (0, typeHelper_1.object)({
    SubstrateToken: typeHelper_1.str,
    CallerFileNameLine: typeHelper_1.str,
    ClientRequestId: typeHelper_1.str,
    ProcessName: typeHelper_1.str,
    groupId: typeHelper_1.str,
});
exports.PreSubmitJobRouterParser = (0, typeHelper_1.object)({
    experimentId: typeHelper_1.str,
    userid: typeHelper_1.str,
    optionSets: (0, typeHelper_1.optional)(typeHelper_1.str),
    serviceSettings: models_1.BizChatExp,
});
exports.ScrapingServiceTokenRevokeRouterParser = (0, typeHelper_1.object)({
    accessToken: typeHelper_1.str,
});
exports.GetQuerySetContentRouterParser = (0, typeHelper_1.object)({
    filename: typeHelper_1.str,
});
exports.QuerySvalueRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
    expName: typeHelper_1.str,
    metricKey: typeHelper_1.str,
});
exports.QueryMChatMetricsRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
    metricKey: (0, typeHelper_1.array)(typeHelper_1.str),
});
exports.QueryMChatAllMetricsRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
    queryHash: typeHelper_1.str,
});
exports.ErrorNodeForFailedJobRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
    JobTemplateType: typeHelper_1.str,
});
exports.CheckOnlineQuerySetContentRouterParser = (0, typeHelper_1.object)({
    FileName: (0, typeHelper_1.optional)(typeHelper_1.str),
    TemplateName: (0, typeHelper_1.optional)(typeHelper_1.str),
    UserId: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CheckOnlineQuerySetWarningRouterParser = (0, typeHelper_1.object)({
    FileName: typeHelper_1.str,
    UserId: typeHelper_1.str,
});
exports.EditConfigRouterParser = (0, typeHelper_1.object)({
    FileName: settings_1.SettingFileName,
    FileContent: typeHelper_1.str,
});
exports.ListConfigSnapshotRouterParser = (0, typeHelper_1.object)({
    FileName: settings_1.SettingFileName,
    IncludeEditHistory: (0, typeHelper_1.optional)(typeHelper_1.bool),
    IncludeIdentifier: (0, typeHelper_1.optional)(typeHelper_1.bool),
});
exports.GetConfigSnapshotRouterParser = (0, typeHelper_1.object)({
    FileName: settings_1.SettingFileName,
    Snapshot: (0, typeHelper_1.optional)(typeHelper_1.str),
    Identifier: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CreateConfigSnapshotRouterParser = (0, typeHelper_1.object)({
    FileName: settings_1.SettingFileName,
    Identifier: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.SetTokenRouterParser = (0, typeHelper_1.object)({
    SecretName: typeHelper_1.str,
    SecretValue: typeHelper_1.str,
    TokenType: (0, typeHelper_1.or)([
        (0, typeHelper_1.constStr)("password"),
        (0, typeHelper_1.constStr)("3S refresh token"),
        (0, typeHelper_1.constStr)("SEVAL refresh token"),
        (0, typeHelper_1.constStr)("Teams CLI refresh token"),
        (0, typeHelper_1.constStr)("Graph refresh token"),
    ]),
});
exports.VerifyTokenRouterParser = (0, typeHelper_1.object)({
    Email: typeHelper_1.str,
    TokenType: (0, typeHelper_1.or)([
        (0, typeHelper_1.constStr)("3S refresh token"),
        (0, typeHelper_1.constStr)("SEVAL refresh token"),
        (0, typeHelper_1.constStr)("Teams CLI refresh token"),
        (0, typeHelper_1.constStr)("General refresh token"),
        (0, typeHelper_1.constStr)("Augloop refresh token"),
        (0, typeHelper_1.constStr)("Scraping service refresh token"),
        (0, typeHelper_1.constStr)("Graph refresh token"),
    ]),
});
exports.UpdateScheduleJobContentParser = (0, typeHelper_1.object)({
    Name: (0, typeHelper_1.optional)(typeHelper_1.str),
    Settings: (0, typeHelper_1.optional)(typeHelper_1.str),
    Status: (0, typeHelper_1.optional)(typeHelper_1.str),
    IntervalInHours: (0, typeHelper_1.optional)(typeHelper_1.num),
    StartTime: (0, typeHelper_1.optional)(typeHelper_1.str),
    EndTime: (0, typeHelper_1.optional)(typeHelper_1.str),
    TemplateName: (0, typeHelper_1.optional)(typeHelper_1.str),
    Type: (0, typeHelper_1.optional)(typeHelper_1.str),
    EmailSubscriptionList: (0, typeHelper_1.optional)(typeHelper_1.str),
    Message: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CreateScheduleJobRequestParser = (0, typeHelper_1.object)({
    Content: ScheduledJob_1.ScheduledJob,
});
exports.UploadBizChatSkipScrapingFolderRouterParser = (0, typeHelper_1.object)({
    folderPath: (0, typeHelper_1.array)(typeHelper_1.str),
});
exports.UploadSyntheticIngestionDataFolderRouterParser = (0, typeHelper_1.object)({
    folderName: typeHelper_1.str,
    folderPath: (0, typeHelper_1.array)(typeHelper_1.str),
});
exports.UpdateScheduleJobRequestParser = (0, typeHelper_1.object)({
    Id: typeHelper_1.num,
    Content: exports.UpdateScheduleJobContentParser,
});
exports.SearchScheduleJobParser = (0, typeHelper_1.object)({
    Id: (0, typeHelper_1.optional)(typeHelper_1.num),
    Product: (0, typeHelper_1.optional)(typeHelper_1.str),
    Name: (0, typeHelper_1.optional)(typeHelper_1.str),
    CreateBy: (0, typeHelper_1.optional)(typeHelper_1.str),
    Status: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CustomizedDownloadRouterParser = (0, typeHelper_1.object)({
    link: typeHelper_1.str,
    filename: typeHelper_1.str,
});
exports.ProductCreateRouterParser = (0, typeHelper_1.object)({
    Product: models_1.Product,
});
exports.ProductDeleteRouterParser = (0, typeHelper_1.object)({
    ProductId: typeHelper_1.str,
});
exports.ProductUpdateRouterParser = (0, typeHelper_1.object)({
    Product: models_1.Product,
});
exports.CancelJobRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.num,
});
exports.RerunJobRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.num,
});
exports.RenameJobRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.num,
    newJobName: typeHelper_1.str,
});
exports.LMChecklistAssertionsRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
});
exports.LMChecklistDetailsByJobIdRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
});
exports.UpdateRefreshTokenByCodeRouterParser = (0, typeHelper_1.object)({
    code: typeHelper_1.str,
});
exports.CallAvalonRouterParser = (0, typeHelper_1.object)({
    AvalonAccessToken: (0, typeHelper_1.optional)(typeHelper_1.str),
    AvalonRequestHeader: (0, typeHelper_1.optional)((0, typeHelper_1.mapOf)(typeHelper_1.str)),
    AvalonRequestBody: typeHelper_1.anyObject,
});
exports.QueryOPGQueriesRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
});
exports.QueryOPGRAIMetricsRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
});
exports.QueryOPGRAIAggregatedMetricsRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
});
exports.QueryOPGMetricsRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
    page: (0, typeHelper_1.optional)(typeHelper_1.num),
    pageSize: (0, typeHelper_1.optional)(typeHelper_1.num),
});
exports.QueryOPGMetricsByHashRouterParser = (0, typeHelper_1.object)({
    jobId: typeHelper_1.str,
    queryHash: typeHelper_1.str,
});
exports.UtteranceConversationIdRouterParser = (0, typeHelper_1.object)({
    JobId: typeHelper_1.str,
    Query: typeHelper_1.str,
    QueryHash: typeHelper_1.str,
    ExperimentName: typeHelper_1.str,
});
exports.ShareByPassedRouterParser = (0, typeHelper_1.object)({ users: (0, typeHelper_1.array)(typeHelper_1.str) });
const LogEventType = (0, typeHelper_1.or)([(0, typeHelper_1.constStr)("Sign")]);
const LogSignEventName = (0, typeHelper_1.or)([
    (0, typeHelper_1.constStr)("SignInStart"),
    (0, typeHelper_1.constStr)("SignInSuccess"),
    (0, typeHelper_1.constStr)("SignInError"),
]);
const LogEventName = (0, typeHelper_1.or)([LogSignEventName]);
exports.LogRouterParser = (0, typeHelper_1.object)({
    EventType: LogEventType,
    EventName: LogEventName,
    DataBag: (0, typeHelper_1.optional)(typeHelper_1.anyObject),
});
exports.GPTListRouterParser = (0, typeHelper_1.object)({
    userid: typeHelper_1.str,
});
exports.CreateGroupJobRouterParser = (0, typeHelper_1.object)({
    GroupName: typeHelper_1.str,
    Settings: (0, typeHelper_1.optional)(models_1.JobGroupSettings),
    GroupTemplateId: (0, typeHelper_1.optional)(typeHelper_1.num),
    Jobs: (0, typeHelper_1.optional)((0, typeHelper_1.mapOf)(exports.CreateJobRouterParser)),
    LinkJobs: (0, typeHelper_1.optional)((0, typeHelper_1.array)(typeHelper_1.str)),
});
exports.GetGroupListRouterParser = (0, typeHelper_1.object)({
    GroupName: (0, typeHelper_1.optional)(typeHelper_1.str),
    CreateBy: (0, typeHelper_1.optional)(typeHelper_1.str),
    CreatorSmtpAddress: (0, typeHelper_1.optional)(typeHelper_1.str),
    GroupTemplateId: (0, typeHelper_1.optional)(typeHelper_1.num),
    StartId: (0, typeHelper_1.optional)(typeHelper_1.num),
});
exports.GetGroupRouterParser = (0, typeHelper_1.object)({
    GroupId: (0, typeHelper_1.optional)(typeHelper_1.num),
});
exports.AddJobToGroupRouterParser = (0, typeHelper_1.object)({
    GroupId: typeHelper_1.num,
    JobIds: (0, typeHelper_1.array)(typeHelper_1.num),
});
exports.SetJobPriorityRouterParser = (0, typeHelper_1.array)((0, typeHelper_1.object)({
    Id: typeHelper_1.num,
    PriorityLevel: typeHelper_1.num,
}));
exports.GetJobPriorityRouterParser = (0, typeHelper_1.array)((0, typeHelper_1.object)({
    Id: typeHelper_1.num,
}));
