import { mutatorAction } from "satcheljs";
import type { BizChatExp } from "@seval-portal/shared";
import type {
  JobPreValidationResult,
  JobPreValidationStep,
  JobPreValidationStepStatus,
} from "../models/JobPreValidationStep";
import {
  initJobPreValidationStore,
  jobPreValidationStore,
} from "../store/jobPreValidationStore";

export const resetJobPreValidationSteps = mutatorAction(
  "resetJobPreValidationStore",
  () => {
    Object.assign(jobPreValidationStore, {
      ...initJobPreValidationStore,
    });
  },
);

export const updateJobPreValidationJobTemplateName = mutatorAction(
  "updateJobPreValidationJobTemplateName",
  (jobTemplateName: string | undefined) => {
    jobPreValidationStore.jobTemplateName = jobTemplateName;
  },
);

export const updateJobPrevalidationSteps = mutatorAction(
  "updateResultJob",
  (jobPrevalidationSteps: JobPreValidationStep[]) => {
    jobPreValidationStore.jobPreValidationSteps = jobPrevalidationSteps;
  },
);

export const updateJobValidationStepStatus = mutatorAction(
  "updateJobValidationStepStatus",
  (
    index: number,
    status: JobPreValidationStepStatus,
    results?: JobPreValidationResult[],
  ) => {
    if (
      index >= 0 &&
      index < jobPreValidationStore.jobPreValidationSteps.length
    ) {
      jobPreValidationStore.jobPreValidationSteps[index].status = status;
      if (status === "skipped" || status === "scrapping passed") {
        jobPreValidationStore.jobPreValidationSteps[index].statusCode = 200;
      }
      if (results) {
        jobPreValidationStore.jobPreValidationSteps[index].results = results;
      }
    }
  },
);

export const updateJobValidationStepConfigInfo = mutatorAction(
  "updateJobValidationStepConfigInfo",
  (index: number, config: BizChatExp) => {
    if (
      index >= 0 &&
      index < jobPreValidationStore.jobPreValidationSteps.length
    ) {
      if (config) {
        jobPreValidationStore.jobPreValidationSteps[index].exp_config =
          JSON.stringify(config);
      }
    }
  },
);

export const updateJobPreValidationResolve = mutatorAction(
  "updateJobPreValidationResolve",
  (resolve: ((value: boolean) => void) | undefined) => {
    jobPreValidationStore.JobPreValidationResolve = resolve;
  },
);

export const updateJobPreValidationIsDispatched = mutatorAction(
  "updateJobPreValidationIsDispatched",
  (value: boolean) => {
    jobPreValidationStore.isDispatched = value;
  },
);

export const updateJobPreValidationIsCopyLinkClicked = mutatorAction(
  "updateJobPreValidationIsDispatched",
  (value: boolean[]) => {
    jobPreValidationStore.isCopyLinkClicked = value;
  },
);

export const updateJobPrevalidationToastTitle = mutatorAction(
  "updateJobPrevalidationtoastTitle",
  (title: string) => {
    jobPreValidationStore.toastTitle = title;
  },
);

export const updateJobPrevalidationToastBody = mutatorAction(
  "updateJobPrevalidationtoastBody",
  (body: string) => {
    jobPreValidationStore.toastBody = body;
  },
);

export const updateJobPrevalidationProgressbarValue = mutatorAction(
  "updateJobPrevalidationprogressbarValue",
  (value: number) => {
    jobPreValidationStore.progressbarValue = value;
  },
);

export const updateIsFinishedVerifing = mutatorAction(
  "updateIsFinishedVerifing",
  (value: boolean) => {
    jobPreValidationStore.isFinishedVerifing = value;
  },
);
