import { observer } from "mobx-react-lite";
import { capitalFirstLetter } from "../../../helpers/formatHelper";
import { useSharedTableStyles } from "./shardTableStyles";

type GeneralResultTableProps = {
  columns: string[];
  data: any[];
  displayColumns?: string[];
};

export const GeneralResultTable = observer((props: GeneralResultTableProps) => {
  const sharedStyles = useSharedTableStyles();
  const { columns, data, displayColumns } = props;

  const renderHeader = () => {
    return (
      <tr className={sharedStyles.rowStyle}>
        {columns.map((column, index) => {
          return (
            <th key={index} className={sharedStyles.cell}>
              {capitalFirstLetter(
                displayColumns ? displayColumns[index] : column,
              )}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderCellData = (cellData: unknown) => {
    if (typeof cellData !== "string") {
      return JSON.stringify(cellData);
    }
    if (!cellData || cellData.length === 0) {
      return cellData;
    }
    if (!Number.isNaN(Number(cellData))) {
      return Number(cellData).toFixed(2);
    }
    return cellData.replaceAll(":", " : ");
  };

  if (!data || data.length === 0) {
    return <div>No Data.</div>;
  }

  return (
    <table className={sharedStyles.table}>
      <thead className={sharedStyles.thead}>{renderHeader()}</thead>
      <tbody className={sharedStyles.tbody}>
        {data.map((row, index) => (
          <tr key={index} className={sharedStyles.rowStyle}>
            {columns.map((column, index) => {
              return (
                <td key={index} className={sharedStyles.cell}>
                  {renderCellData(row[column])}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
});
