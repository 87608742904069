import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  initJobGroupData,
  isEditGroupJob,
} from "../../helpers/creationDataHelper";
import { resetGroupCreationStore } from "../../mutators/jobGroupCreationMutators";
import { resetJobStore } from "../../mutators/jobMutators";
import { jobGroupCreationStore } from "../../store/jobGroupCreationStore";
import { JobCreationViewContent } from "../JobCreation/JobCreationViewContent";
import { GroupCreationHeader } from "./components/GroupCreationHeader";
import { GroupDismissDialog } from "./components/GroupDismissDialog";
import { GroupJobView } from "./components/GroupJobView";
import { GroupNameView } from "./components/GroupNameView";
import { GroupTemplateView } from "./components/GroupTemplatesView";
import { JobCreationHeader } from "./components/JobCreationHeader";
import { useGroupStyles } from "./groupStyles";

export const GroupCreationView = observer(() => {
  const styles = useGroupStyles();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const groupId = searchParams.get("copyGroupId");
    // Fetch job group templates
    initJobGroupData(groupId !== null ? parseInt(groupId) : undefined);
    return () => {
      resetGroupCreationStore();
      resetJobStore();
    };
  }, []);

  const isAddOrEditJob = isEditGroupJob.get();
  return (
    <div>
      {!isAddOrEditJob && (
        <div>
          <GroupCreationHeader />
          <div className={styles.root}>
            <GroupNameView />
            <GroupTemplateView />
            <GroupJobView />
          </div>
        </div>
      )}

      {isAddOrEditJob && (
        <div>
          <JobCreationHeader />
          <JobCreationViewContent
            key={jobGroupCreationStore.currentLocalJobId}
          />
        </div>
      )}
      <GroupDismissDialog />
    </div>
  );
});
