import type { AccountInfo } from "@azure/msal-browser";
import type { RefreshTokenType } from "../partners/OfflineEval/models/Types";
import { store } from "../store/store";

export const getUserAlias = () => {
  return store.account?.username?.split("@")?.[0] ?? "";
};

export const getRefreshTokenKey = (
  email: string,
  tokenType: RefreshTokenType,
) => {
  const name = email
    .replaceAll("@", "--AT--")
    .replaceAll(".", "--DOT--")
    .replaceAll("_", "--UNDERSCORE--");

  switch (tokenType) {
    case "Teams CLI refresh token":
      return `${name}-teamscli-refreshtoken`;
    case "SEVAL refresh token":
    case "3S refresh token":
      return `${name}-3s-refreshtoken`;
    case "Graph refresh token":
      return `${name}-graph-refreshtoken`;
    default:
      return `${name}-invalid-refreshtoken`;
  }
};

export const getTokenName = (email: string, tokenType: string) => {
  const username = email
    .replaceAll("@", "--AT--")
    .replaceAll(".", "--DOT--")
    .replaceAll("_", "--UNDERSCORE--");
  return `${username}-${tokenType}-refreshtoken`;
};

export const getTestAccountPasswordName = (email: string) => {
  const username = email
    .replaceAll("@", "--AT--")
    .replaceAll(".", "--DOT--")
    .replaceAll("_", "--UNDERSCORE--");
  return `${username}-test-password`;
};

export const getFilePrefix = (account: AccountInfo | undefined) => {
  if (account === undefined || account.username === undefined) {
    return undefined;
  }

  const username = account.username
    .split("@")[0]
    .replaceAll(".", "")
    .replaceAll("_", "")
    .trim()
    .toLocaleLowerCase();

  if (username === "") {
    return undefined;
  }

  return username;
};

export const getFileName = (
  account: AccountInfo | undefined,
  file: File | undefined,
) => {
  const prefix = getFilePrefix(account);

  if (prefix === undefined || file === undefined) {
    return undefined;
  }

  return `${prefix}-${file.name}`;
};
