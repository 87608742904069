import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { useDeviceSize } from "../Wrappers/ResponsiveProvider";
import type { WrapperProps } from "../Wrappers/WrapperProps";

type ColumnCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type Props = WrapperProps & {
  maxColumnCount: ColumnCount;
  maxColumnCountSmall?: ColumnCount;
  rowGap?: number;
  columnGap?: number;
};

const useStyles = makeStyles({
  row: {
    width: "100%",
    display: "grid",
  },
  columm1: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  columm2: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  columm3: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  columm4: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  columm5: {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  columm6: {
    gridTemplateColumns: "repeat(6, 1fr)",
  },
  columm7: {
    gridTemplateColumns: "repeat(7, 1fr)",
  },
  columm8: {
    gridTemplateColumns: "repeat(8, 1fr)",
  },
});

export const ResponsiveRow = observer((props: Props) => {
  const styles = useStyles();

  const childrenCount = React.Children.toArray(props.children).filter(
    (child) => {
      return React.isValidElement(child);
    },
  ).length;

  const { deviceSize } = useDeviceSize();

  const maxColumnCount = (() => {
    switch (deviceSize) {
      case "xs":
        return props.maxColumnCountSmall ?? props.maxColumnCount;
      case "sm":
        return Math.max(
          props.maxColumnCount - 2,
          props.maxColumnCountSmall ?? 1,
        );

      case "md":
        return Math.max(
          props.maxColumnCount - 1,
          props.maxColumnCountSmall ?? 1,
        );

      case "lg":
        return props.maxColumnCount;
    }
  })();

  const columnCount = Math.min(childrenCount, maxColumnCount);

  const columnStyle = (() => {
    switch (columnCount) {
      case 1:
        return styles.columm1;
      case 2:
        return styles.columm2;
      case 3:
        return styles.columm3;
      case 4:
        return styles.columm4;
      case 5:
        return styles.columm5;
      case 6:
        return styles.columm6;
      case 7:
        return styles.columm7;
      case 8:
        return styles.columm8;
      default:
        return styles.columm1;
    }
  })();

  return (
    <div
      className={mergeClasses(styles.row, columnStyle)}
      style={{
        rowGap: `${props.rowGap}px`,
        columnGap: `${props.columnGap}px`,
      }}
    >
      {props.children}
    </div>
  );
});
