import { orchestrator } from "satcheljs";
import type { ScheduledJob } from "@seval-portal/shared";
import {
  getJobs,
  getScheduledJobs,
  updateScheduledJob,
} from "../../../helpers/apiHelper";
import { updateCurrentPath } from "../../../mutators/updateContributions";
import { store } from "../../../store/store";
import {
  onScheduleJobAction,
  schedulerPageInitAcion,
  searchScheduleJobsAcion,
  updateSearchCriteriaAcion,
} from "../actions/scheduleJobActions";
import {
  get3MonthLaterDate,
  getCurrentLocalDatetime,
} from "../helpers/formatHelper";
import {
  clearSearchCriteria,
  switchPageMode,
  updateActiveScheduleJobList,
  updateErrorMessage,
  updateOwnedSchedulerCount,
  updateRecentCompletedJobs,
  updateScheduledJobLoadStatus,
  updateSearchCriteria,
  updateSelectedScheduleJob,
} from "../mutators/scheduleJobMutators";
import { scheduleJobStore } from "../store/scheduleJobStore";

const newEmptyJob: ScheduledJob = {
  Id: -1,
  Name: "<Create New Scheduled Job>",
  Settings: "{}",
  Status: "Active",
  IntervalInHours: 24,
  CreatedBy: store.account?.name ?? "",
  StartTime: getCurrentLocalDatetime(),
  EndTime: get3MonthLaterDate(new Date().toISOString()),
  LastExecuteTime: "",
  LastUpdateBy: "",
  TemplateName: "",
  Type: "Customized",
  EmailSubscriptionList: "",
  Message: "",
} as const;

const getCurrentSchedulerCount = () => {
  getScheduledJobs({
    Status: "Active",
    CreateBy: store.account?.name,
  }).then((jobs) => {
    updateOwnedSchedulerCount(jobs.length);
  });
};

const getRecentCompletedJobs = () => {
  getJobs({
    JobStatus: "Completed",
    Filter: "my",
  }).then((jobs) => {
    updateRecentCompletedJobs(jobs);
  });
};

export const initCreationPage = () => {
  getCurrentSchedulerCount();
  getRecentCompletedJobs();
  updateSelectedScheduleJob({
    ...(scheduleJobStore.selectedScheduledJob ?? newEmptyJob),
    EmailSubscriptionList: store.account?.username ?? "",
  });
  switchPageMode("Create");
  updateErrorMessage("Schedule Job should be based on a completed job.");
};

orchestrator(schedulerPageInitAcion, ({ mode, schedulerId }) => {
  clearSearchCriteria();
  searchScheduleJobsAcion();

  switch (mode) {
    case "Create":
      initCreationPage();
      break;

    case "list":
      if (schedulerId) {
        getScheduledJobs({ Id: parseInt(schedulerId) }).then((jobs) => {
          const firstItem = jobs[0];
          if (!firstItem) {
            updateCurrentPath("/scheduler/list");
            return;
          }
          updateSelectedScheduleJob(firstItem);
          onScheduleJobAction(firstItem, "ReadOnly");
        });
      }
      break;

    case "edit":
      if (schedulerId) {
        getScheduledJobs({ Id: parseInt(schedulerId) }).then((jobs) => {
          const firstItem = jobs[0];
          if (!firstItem) {
            updateCurrentPath("/scheduler/list");
            return;
          }
          updateSelectedScheduleJob(firstItem);
          onScheduleJobAction(firstItem, "Edit");
        });
      }
      break;
    default:
      break;
  }
});

orchestrator(onScheduleJobAction, ({ job, intent }) => {
  switch (intent) {
    case "Create":
      initCreationPage();
      break;
    case "Edit":
      if (job) {
        getRecentCompletedJobs();
        updateSelectedScheduleJob(job);
        switchPageMode("Edit");
        updateErrorMessage(undefined);
      }
      break;

    case "ReadOnly":
      if (job) {
        updateSelectedScheduleJob(job);
        switchPageMode("ReadOnly");
        updateErrorMessage(undefined);
      }
      break;
    case "Stop":
      if (job) {
        updateScheduledJob({
          Id: job.Id,
          Content: {
            Status: "Canceled",
          },
        }).then(() => {
          searchScheduleJobsAcion();
          if (scheduleJobStore.selectedScheduledJob?.Id === job.Id) {
            updateSelectedScheduleJob({
              ...scheduleJobStore.selectedScheduledJob,
              Status: "Canceled",
            });
          }
        });
      }
      break;
    case "Enable":
      if (job) {
        updateScheduledJob({
          Id: job.Id,
          Content: {
            Status: "Active",
          },
        }).then(() => {
          searchScheduleJobsAcion();
          if (scheduleJobStore.selectedScheduledJob?.Id === job.Id) {
            updateSelectedScheduleJob({
              ...scheduleJobStore.selectedScheduledJob,
              Status: "Active",
            });
          }
        });
      }
      break;
  }
});

orchestrator(searchScheduleJobsAcion, () => {
  const criteria = scheduleJobStore.searchCriteria;
  getScheduledJobs({
    Status: criteria.get("Status"),
    CreateBy:
      criteria.get("CreateBy") === undefined
        ? undefined
        : `%${criteria.get("CreateBy")}%`,
    Name:
      criteria.get("Name") === undefined
        ? undefined
        : `%${criteria.get("Name")}%`,
    Product: criteria.get("Product"),
  }).then((jobs) => {
    updateActiveScheduleJobList(jobs);

    updateScheduledJobLoadStatus(false);
  });

  getCurrentSchedulerCount();
});

orchestrator(updateSearchCriteriaAcion, ({ key, value }) => {
  updateSearchCriteria(key, value);
  searchScheduleJobsAcion();
});
