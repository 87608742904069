import { array, object, optional, str } from "@seval-portal/shared";

export const PhysicalAccount = object({
  userId: optional(str),
  userName: str,
});

export const VirtualAccount = object({
  userId: str,
  userName: str,
  userTags: array(str),
  physicalAccounts: array(PhysicalAccount),
});

export const PhysicalTenant = object({
  tenantId: str,
  tenantName: str,
  virtualAccounts: array(VirtualAccount),
});

export const VirtualTenant = object({
  tenantId: str,
  tenantName: str,
  tenantTags: array(str),
  createdBy: str,
  ring: optional(str),
  physicalTenants: array(PhysicalTenant),
});

export const VirtualAccountInfo = object({
  userId: str,
  userName: str,
  userTags: array(str),
});

export const VirtualTenantWithVirtualUserIds = object({
  tenantId: optional(str),
  tenantName: str,
  tenantTags: array(str),
  createdBy: str,
  ring: optional(str),
  virtualAccounts: array(VirtualAccountInfo),
});

export const TenantUserAccount = object({
  tenantId: str,
  tenantName: str,
  userId: str,
  userName: str,
});

export type VirtualTenant = ReturnType<typeof VirtualTenant>;
export type VirtualTenantWithVirtualUserIds = ReturnType<
  typeof VirtualTenantWithVirtualUserIds
>;
export type VirtualAccount = ReturnType<typeof VirtualAccount>;
export type VirtualAccountInfo = ReturnType<typeof VirtualAccountInfo>;
export type TenantUserAccount = ReturnType<typeof TenantUserAccount>;
