import { MenuButton } from "@fluentui/react-button";
import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { Add16Regular } from "@fluentui/react-icons";
import { compact } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { addExistingJobToCreationGroupAction } from "../../../actions/jobGroupActions";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { MyJobListDialog } from "../../Dialog/MyJobListDialog";

interface IAddNewJobButtonProps {
  localJobId?: string;
  onClick?: () => void;
}
export const AddNewJobButton = observer((props: IAddNewJobButtonProps) => {
  const [isOpenAddExistingJob, setIsOpenAddExistingJob] = React.useState(false);
  const toast = useToast();
  const isPredefined =
    jobGroupCreationStore.selectedGroupTemplate?.Type === "Predefined";
  const requiredTemplates = isPredefined
    ? jobGroupCreationStore.selectedGroupTemplateSettings?.map(
        (item) => item.templateName,
      )
    : [];
  const existingLinkJobs = compact(
    jobGroupCreationStore.jobs.map((job) => job.linkedJob),
  );
  return (
    <div>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton icon={<Add16Regular />}>Add</MenuButton>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem onClick={props.onClick}>Add New Job</MenuItem>
            <MenuItem onClick={() => setIsOpenAddExistingJob(true)}>
              Add Existing Job
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      <MyJobListDialog
        existingJobs={existingLinkJobs}
        isOpen={isOpenAddExistingJob}
        groupName={jobGroupCreationStore.groupName}
        onClose={() => {
          setIsOpenAddExistingJob(false);
        }}
        requiredTemplates={requiredTemplates}
        onAddJobs={(jobs) => {
          toast.onToastStart(`Adding ${jobs.length} existing jobs to group...`);
          addExistingJobToCreationGroupAction(jobs, props.localJobId);
          setIsOpenAddExistingJob(false);
          toast.onToastSuccess(
            `Added ${jobs.length} existing jobs to group successfully!`,
          );
        }}
      />
    </div>
  );
});
