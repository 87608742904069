"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientFeatureFlagConfig = exports.ClientFeatureFlagSets = exports.ClientFeatureFlagScopes = exports.ClientFeatureFlagDefaults = exports.ClientFeatureFlags = void 0;
const typeHelper_1 = require("../tools/typeHelper");
// Please refer the following wiki page for portal client feature rollout
// https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/Enterprise%20Sydney%20WIKI/503295/Build-Release-Features-for-SEVAL-Portal-Client
exports.ClientFeatureFlags = [
    ["shadowAB", false],
    ["use-dev-immunity", true],
    ["product-registration", false],
    ["eval-time-override", false],
    ["bypass-jobprevalidation", false],
    ["strict-permission-check", false],
    ["append-timezone", false],
    ["refresh-token-migration", false],
    ["scraping-service-migration", false],
    ["get-user-list-from-blob", false],
    ["generate-refresh-token", false],
    ["use-blob-cache", false],
    ["gpt-agent-list", false],
    ["job-priority", false],
    ["enable-new-metrics", true], // used to test to compare old and new metrics
    ["select-synthetic-tenant-test-user", false, ["LOCALHOST", "STAGING"]], // used to select test user & query set for Synthetic Tenants
    ["enable-synthetic-data-ingestion", false, ["LOCALHOST", "STAGING"]], // used to submit a request to trigger a data init ingestion workflow
    [
        "select-synthetic-tenant-virtual-test-user",
        false,
        ["LOCALHOST", "STAGING"],
    ], // used to select virtual test user
    ["enable-job-group", false],
    ["job-group-stage-2", false],
    ["job-group-share-enabled", false],
    ["UnifiedBizChatTemplate", false, ["LOCALHOST", "STAGING"]], // used to enable unified bizchat template
    ["active_region_for_Cloverport_centralus", false], // used to identify whether the centralus region is active for Cloverport, eg workspace: amlworkspacenc5ha7hf54ewm
    ["active_region_for_Cloverport_eastus", false], // used to identify whether the eastus region is active for Cloverport, eg workspace: amlworkspacefzppqu5lm3sek
    ["active_region_for_Cloverport_eastus2", false], // used to identify whether the eastus2 region is active for Cloverport, eg workspace: amlworkspacep7rwdmnxntcpg
    ["active_region_for_Consumer_centralus", false], // used to identify whether the centralus region is active for Consumer, eg workspace: deepleo-seval-ws
    ["active_region_for_Consumer_eastus", false], // used to identify whether the eastus region is active for Consumer
    ["active_region_for_Consumer_eastus2", false], // used to identify whether the eastus2 region is active for Consumer
    ["active_region_for_MSIT-eyesoff_centralus", false], // used to identify whether the centralus region is active for MSIT-eyesoff, eg workspace: amlworkspacengvacfarslewo
    ["active_region_for_MSIT-eyesoff_eastus", false], // used to identify whether the eastus region is active for MSIT-eyesoff
    ["active_region_for_MSIT-eyesoff_eastus2", false], // used to identify whether the eastus2 region is active for MSIT-eyesoff, eg workspace: amlworkspaceisg62ym3btsni
    ["cachedConversation", false], // used to enable cached conversation subTab
    ["enable-upload-graph-refresh-token", false], // used to enable upload graph refresh token
    ["cqs_queryset_templates", false], // used to enable CQS queryset templates integration
    ["use-partner-render", false], // used to enable partner render
];
exports.ClientFeatureFlagDefaults = exports.ClientFeatureFlags.reduce((acc, cur) => {
    acc[cur[0]] = cur[1];
    return acc;
}, {});
exports.ClientFeatureFlagScopes = exports.ClientFeatureFlags.reduce((acc, cur) => {
    if (cur.length === 3) {
        acc[cur[0]] = cur[2];
    }
    return acc;
}, {});
exports.ClientFeatureFlagSets = (0, typeHelper_1.object)(exports.ClientFeatureFlags.reduce((acc, cur) => {
    acc[cur[0]] = (0, typeHelper_1.optional)(typeHelper_1.bool);
    return acc;
}, {}));
exports.ClientFeatureFlagConfig = (0, typeHelper_1.object)({
    portal: exports.ClientFeatureFlagSets,
});
