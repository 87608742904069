import type { AccordionToggleEventHandler } from "@fluentui/react-components";
import {
  Accordion,
  Button,
  InfoLabel,
  makeStyles,
  shorthands,
  tokens,
  typographyStyles,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { getDefaultQuerySet } from "../../../../../helpers/configConvertHelper";
import {
  updatePropValueActionV2,
  updateUnifiedExpConfigWithScenarioAction,
} from "../../../actions/jobActions";
import { getUnifiedBizChatJobQueryList } from "../../../selectors/getJobPropV2";
import { TestSetAccordionItem } from "./TestSetAccordionItem";
import { unifiedMetaData } from "./metadata/unifiedMetaData";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  warning: typographyStyles.caption1Strong,
});

interface IJobTestSetBlockProps {
  limitPair?: number;
  defaultOpenItems?: number[];
}

export const JobUnifiedTestSetView = observer(
  (props: IJobTestSetBlockProps) => {
    const { limitPair = 1, defaultOpenItems } = props;
    const styles = useStyles();

    const [openItems, setOpenItems] = React.useState<number[]>(
      defaultOpenItems || [],
    );

    const convergeQuerySets = getUnifiedBizChatJobQueryList.get();

    const handleToggle: AccordionToggleEventHandler<number> = (event, data) => {
      setOpenItems(data.openItems);
    };

    const addNewQuery = () => {
      const newQuery = getDefaultQuerySet(unifiedMetaData);
      const index = convergeQuerySets?.length || 0;
      updatePropValueActionV2({
        prop: "queries.query_sets",
        newData: [...(convergeQuerySets || []), newQuery],
      });
      updateUnifiedExpConfigWithScenarioAction();
      setOpenItems([index]);
    };

    return (
      <div className={styles.block}>
        <InfoLabel className={styles.blockTitle} required>
          Test Set
        </InfoLabel>
        <div>
          <Accordion
            key="queries"
            openItems={openItems}
            onToggle={handleToggle}
            collapsible
            multiple
          >
            {convergeQuerySets?.map((querySet, index) => (
              <TestSetAccordionItem
                key={index}
                index={index}
                testSet={querySet.input_file || ""}
                value={querySet}
              />
            ))}
          </Accordion>
        </div>
        {(!convergeQuerySets || convergeQuerySets.length < limitPair) && (
          <div className={styles.buttonBox}>
            <Button appearance="primary" onClick={addNewQuery}>
              Add Test Set
            </Button>
          </div>
        )}
      </div>
    );
  },
);
