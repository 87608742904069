import type { JobGroupData, JobGroupTemplate } from "@seval-portal/shared";
import { action } from "satcheljs";
import type { Job } from "../models/Job";

export const createJobGroupAction = action("createJobGroupAction", () => ({}));

export const updateSelectedGroupTemplatesAction = action(
  "updateSelectedGroupTemplatesAction",
  (selected: JobGroupTemplate) => ({ selected }),
);

export const addEditSubJobAction = action(
  "addEditSubJobAction",
  (localId: string, mode: "add" | "edit", templateName?: string) => ({
    localId,
    mode,
    templateName,
  }),
);

export const dupSubJobAction = action("dupSubJobAction", (localId: string) => ({
  localId,
}));

export const backToGroupListAction = action(
  "backToGroupListAction",
  () => ({}),
);

export const deleteSubJobAction = action(
  "deleteSubJobAction",
  (localId: string) => ({ localId }),
);

export const saveSubJobAction = action("saveSubJobAction", () => ({}));

export const submitJobGroupAction = action("submitJobGroupAction", () => ({}));

export const duplicateGroupAction = action(
  "duplicateGroupAction",
  (groupData: JobGroupData) => ({ groupData }),
);

export const addExistingJobToCreationGroupAction = action(
  "addExistingJobToCreationGroupAction",
  (jobs: Job[], currentLocalJob: string | undefined) => ({
    jobs,
    currentLocalJob,
  }),
);
