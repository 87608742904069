import type { unifiedBizChatConfiguration } from "@seval-portal/shared";
import { compact } from "lodash";
import {
  UN_SELECTED_FILE_NAME,
  type ISydneyAdditionalSettings,
  type IUnifiedMetaData,
} from "../partners/OfflineEval/components/JobCreation/UnifiedTemplatesComponents/metadata/unifiedMetaData";

export const expandMetricsToObject = (metrics: string[] = []) => {
  return metrics.reduce(
    (acc, metric) => ({
      ...acc,
      [metric]: true,
    }),
    {},
  );
};

export const convertUnifiedConfigToBizchat = (
  unifiedConfig: unifiedBizChatConfiguration,
) => {
  if (unifiedConfig["config_type"] !== "v2") {
    throw new Error(
      "Input must be a V2 pipeline configuration, contact ziyuhe@microsoft.com to double check your template settings",
    );
  }

  const queries = unifiedConfig["queries"] || {};
  const global_config = unifiedConfig["global_config"] || {};

  const query_sets = queries["query_sets"] || [];
  const first_query_set = query_sets[0] || {};

  const metrics = expandMetricsToObject(first_query_set["metrics"]);

  const configs_in_json = {
    ...metrics,
    language_mismatch_rate: true,
    scenario: first_query_set["scenario"] || "",
    options: first_query_set["options"] || [],
    sydney_client_secret: queries["sydney_client_secret"] || "",
    drop_partial_conversation:
      first_query_set["drop_partial_conversation"] || false,
    conversations: {
      user_id: global_config["user_id"] || "",
      lmchecklist_input_file: queries["lmchecklist_input_file"] || "",
      input_file: first_query_set["input_file"] || "",
      has_header:
        first_query_set["has_header"] !== undefined
          ? first_query_set["has_header"]
          : true,
      cancel_threshold: first_query_set["cancel_threshold"],
      output_folder: first_query_set["output_folder"] || "output",
      entityAnnotationTypes: first_query_set["entityAnnotationTypes"] || [],
      language: first_query_set["language"] || "auto",
      exp_configs: unifiedConfig["exp_configs_unified"] || [],
    },
    parsers: queries["parsers"] || "",
  };

  return {
    configs_in_json: configs_in_json,
    ...(unifiedConfig.global_config || {}),
  };
};

export const mergeUniqueStrings = (stringArray: string[]) => {
  const arrays = stringArray.map((str) => (str ? str.split(",") : []));
  const mergedArray = arrays.reduce((acc, curr) => acc.concat(curr), []);
  const uniqueValues = new Set(mergedArray);
  return Array.from(uniqueValues).join(",");
};

export const getDefaultQuerySetTypeName = (metadata: IUnifiedMetaData) => {
  const defaultQuerySetType = metadata.querySetTypes.find((s) => s.isDefault);
  return defaultQuerySetType
    ? defaultQuerySetType.name
    : metadata.querySetTypes[0]?.name || "";
};

export const getQuerySetTypeInfoByName = (
  metadata: IUnifiedMetaData,
  querySetTypeName: string,
) => {
  const querySetType = metadata.querySetTypes.find(
    (s) => s.name === querySetTypeName,
  );
  return querySetType;
};

export const generateDefaultExpConfig = (
  querySetTypeList: string[],
  metadata: IUnifiedMetaData,
  platform: "web" | "work",
) => {
  const commonConfig = metadata.commonExpConfigs[platform] || {};
  const computeQuerySetTypeList =
    querySetTypeList.length > 0
      ? querySetTypeList
      : [getDefaultQuerySetTypeName(metadata)];
  const querySetTypeConfigs: ISydneyAdditionalSettings[] = compact(
    computeQuerySetTypeList.map((querySetName) => {
      const scenario = metadata.querySetTypes.find(
        (s) => s.name === querySetName,
      );
      return scenario?.[platform];
    }),
  );
  const option_sets = compact(querySetTypeConfigs.map((s) => s?.option_sets));
  const variants = compact(querySetTypeConfigs.map((s) => s?.variants));
  const mergedSyndeyConfig = {
    ...commonConfig,
    option_sets: mergeUniqueStrings(option_sets),
    variants: platform === "work" ? mergeUniqueStrings(variants) : undefined,
  };
  return mergedSyndeyConfig;
};

export const getDefaultQuerySet = (unifiedMetaData: IUnifiedMetaData) => {
  const defaultQuerySetType =
    unifiedMetaData.querySetTypes.find((querySetType) => querySetType.isDefault)
      ?.name || "general_purpose";
  return {
    has_header: true,
    input_file: UN_SELECTED_FILE_NAME,
    options: [],
    metrics: [],
    querySet_type: defaultQuerySetType,
    scenario: "bizchat",
    entityAnnotationTypes: ["People", "Event", "File"],
    language: "auto",
    output_folder: "output",
    drop_partial_conversation: false,
    run_type: "single_turn" as const,
  };
};
