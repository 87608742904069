import { observer } from "mobx-react-lite";
import { Counter } from "../components/Counter";

export const CreatePage = observer(() => {
  return (
    <div>
      <h1>Create Job</h1>
      <p>Fill out the form to create a new job.</p>
      <Counter />
    </div>
  );
});
