import {
  Link,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useMotion } from "@fluentui/react-motion-preview";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import type { Incident, IncidentList } from "@seval-portal/shared";
import { getIncident } from "../../helpers/apiHelper";
import { store } from "../../store/store";
import { IncidentInfoDialog } from "../Dialog/IncidentInfoDialog";
import { CloseIcon } from "../Shared/Icons";

const useStyles = makeStyles({
  bannerContainer: {
    flexShrink: 0,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#f7630c",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    zIndex: 1000001,
    transitionDuration: "0.25s",
    transitionDelay: "0s",
    transitionProperty: "opacity, height",
    willChange: "opacity, height",
    opacity: 0,
    height: 0,
  },
  incidentContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  incidentTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  visible: {
    height: "30px",
    opacity: 1,
  },
  linkText: {
    color: "black",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "30px",
    ...shorthands.textDecoration("underline"),
  },
  close: {
    cursor: "pointer",
  },
});

let timer: NodeJS.Timeout | undefined = undefined;

export const OngoingIssueBanner = observer(() => {
  const styles = useStyles();
  const [isClose, setIsClose] = React.useState(new Map<string, boolean>());
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [incidentList, setIncidentList] = React.useState<
    IncidentList | undefined
  >(undefined);
  const [incident, setIncident] = React.useState<Incident | undefined>(
    undefined,
  );

  useEffect(() => {
    const refresh = () => {
      if (
        shouldShow &&
        store.account !== undefined &&
        document.visibilityState === "visible"
      ) {
        getIncident()
          .then((res) => {
            setIncidentList(res);
          })
          .catch(() => {
            if (timer) {
              clearTimeout(timer);
            }
            timer = undefined;
          });
      }
    };

    if (store.account) {
      refresh();
      timer = setInterval(refresh, 5 * 60 * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
    };
  }, [store.account]);

  const incidentsListToShow = computed(() => {
    return incidentList?.filter((item) => !isClose.get(item?.title)) ?? [];
  });

  const shouldShow = computed(
    () => incidentList !== undefined && incidentsListToShow.get().length > 0,
  );
  const motion = useMotion<HTMLDivElement>(shouldShow.get());

  return (
    <>
      {motion.canRender && (
        <div
          ref={motion.ref}
          className={mergeClasses(
            styles.bannerContainer,
            motion.active && styles.visible,
          )}
          style={{ height: `${incidentsListToShow.get().length * 30}px` }}
        >
          {incidentsListToShow.get() &&
            incidentsListToShow.get().map((item) => (
              <div className={styles.incidentContainer} key={item?.title}>
                <div className={styles.incidentTitle}>
                  <Link
                    className={styles.linkText}
                    onClick={() => {
                      setIsDialogOpen(true);
                      setIncident(item);
                    }}
                  >{`Ongoing Incident: ${item?.title}`}</Link>
                </div>
                <div
                  className={styles.close}
                  onClick={() => {
                    const newClose = new Map(isClose);
                    newClose.set(item?.title, true);
                    setIsClose(newClose);
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            ))}
        </div>
      )}
      {isDialogOpen && incident && (
        <IncidentInfoDialog
          isOpen={isDialogOpen}
          incident={incident}
          onComplete={() => {
            setIsDialogOpen(false);
          }}
        />
      )}
    </>
  );
});
