/**
 * 
 * This manifest is based on the metrics that are stored in the database. The metrics are stored in the database in the following format:
CREATE TABLE [dbo].[aggregated_rai_metrics] (
    [date_time]           DATETIME  DEFAULT (getdate()) NULL,
    [test_type]           NVARCHAR (MAX) NOT NULL,
    [test_set]            NVARCHAR (MAX) NULL,
    [metric_type]         NVARCHAR (100) NOT NULL,
    [language]            NVARCHAR (100) NULL,
    [application]         NVARCHAR (100) NULL,
    [scenario]            NVARCHAR (100) NULL,
    [metric_key]          NVARCHAR (100) NOT NULL,
    [value]               NVARCHAR NOT NULL,
    [JobId]               VARCHAR (200)  NULL
);
 */
type FilterColumns =
  | "test_type"
  | "language"
  | "application"
  | "scenario"
  | "test_set"
  | "metric_type";

interface HighlightMetrics {
  metric_key: string;
  displayName?: string;
}

export interface RenderManifestItem {
  template: string;
  // Dropdown filter for whole page
  dropdownOptions?: FilterColumns[];
  // Display in Summary Metrics Table
  fixedColumnsInSummary?: FilterColumns[];
  highlightMetrics: HighlightMetrics[];
  // Can be hidden in Concise Metrics
  fixedColumnsInConcise?: FilterColumns[];
}
export const RAIFixedColumnsInSummary: FilterColumns[] = [
  "language",
  "application",
  "test_type",
  "metric_type",
  "scenario",
];
const RAIDropdownOptions: FilterColumns[] = [
  "metric_type",
  "language",
  "scenario",
];
const RAIFixedColumnsInConcise: FilterColumns[] = [
  "language",
  "metric_type",
  "scenario",
];

function createRenderManifestItem(
  template: string,
  highlightMetrics: HighlightMetrics[],
): RenderManifestItem {
  return {
    template: template,
    highlightMetrics: highlightMetrics,
    dropdownOptions: RAIDropdownOptions,
    fixedColumnsInSummary: RAIFixedColumnsInSummary,
    fixedColumnsInConcise: RAIFixedColumnsInConcise,
  };
}

export const RenderManifest: RenderManifestItem[] = [
  createRenderManifestItem("OfficeAIAugloopRAI", [
    {
      metric_key: "Defect Rate (%)",
      displayName: "Defect Rate",
    },
    {
      metric_key: "Number of Examples",
    },
    {
      metric_key: "Failure Count",
    },
    {
      metric_key: "Number of SEV-3+",
    },
    {
      metric_key: "Number of SEV-7",
    },
  ]),
  {
    template: "RAI_M365Chat_Content_Harms_Only",
    highlightMetrics: [],
    dropdownOptions: ["metric_type", "language", "scenario", "test_set"],
    fixedColumnsInSummary: RAIFixedColumnsInSummary,
    fixedColumnsInConcise: ["language", "metric_type", "scenario", "test_set"],
  },
  {
    template: "MCP_Single_Turn_Set_Runs",
    highlightMetrics: [],
    dropdownOptions: ["metric_type", "language", "scenario", "test_set"],
    fixedColumnsInSummary: RAIFixedColumnsInSummary,
    fixedColumnsInConcise: ["language", "metric_type", "scenario", "test_set"],
  },
  {
    template: "RAI_M365Chat_User_Utterance",
    highlightMetrics: [],
    dropdownOptions: ["metric_type", "language", "scenario", "test_set"],
    fixedColumnsInSummary: RAIFixedColumnsInSummary,
    fixedColumnsInConcise: ["language", "metric_type", "scenario", "test_set"],
  },
  {
    template: "RAI_M365Chat_DC_Response_User_Utterance",
    highlightMetrics: [],
    dropdownOptions: ["metric_type", "language", "scenario", "test_set"],
    fixedColumnsInSummary: RAIFixedColumnsInSummary,
    fixedColumnsInConcise: ["language", "metric_type", "scenario", "test_set"],
  },
];
