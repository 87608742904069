import { Spinner, Text } from "@fluentui/react-components";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { loadGroupDetailAction } from "../../actions/groupDetailActions";
import { groupDetailStore } from "../../store/groupDetailStore";
import { resultStore } from "../../store/resultStore";
import { ResultsView } from "../ResultsComparison/ResultsView";
import { GroupDetailHeader } from "./components/GroupDetailHeader";
import { GroupInforPane } from "./components/GroupInforPane";
import { JobInforPane } from "./components/JobInforPane";

export const GroupDetailsView = observer(() => {
  const { groupId } = useParams();
  const toast = useToast();
  useEffect(() => {
    // Fetch Group Details by groupId
    loadGroupDetailAction(parseInt(groupId ?? ""));
  }, [groupId]);

  useEffect(() => {
    switch (resultStore.perfStatus) {
      case "Begin":
        toast.onToastStart("Loading Performance Metrics Data...");
        break;
      case "Done":
        toast.onToastSuccess("Performance Metrics Data Loaded!");
        break;
      case "Failure":
        toast.onToastFailure("Failed to Load Performance Metrics Data!");
        break;
    }

    return () => {
      toast.onToastDismiss();
    };
  }, [resultStore.perfStatus]);
  const currentGroup = groupDetailStore.group;

  if (groupDetailStore.isLoadingGroup) {
    return <Spinner label="Loading..." />;
  }

  if (currentGroup === undefined) {
    return <Text>This group job is not found.</Text>;
  }

  const renderResultView = () => {
    if (groupDetailStore.isLoadingJob) {
      return <Spinner label="Loading..." />;
    }
    return <ResultsView />;
  };
  return (
    <>
      <GroupDetailHeader />
      <GroupInforPane />
      <JobInforPane />
      <div style={{ height: "20px" }} />
      {renderResultView()}
    </>
  );
});
