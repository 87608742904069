import { createStore } from "satcheljs";

interface ICounterStore {
  count: number;
}

export const initCounterStore: ICounterStore = {
  count: 0,
};

export const counterStore = createStore<ICounterStore>(
  "counterStore",
  Object.assign({}, initCounterStore),
)();
