import type {
  CreateJobRouterRequestData,
  JobConfiguration,
  JobGroupSettings,
  JobGroupTemplate,
} from "@seval-portal/shared";
import { createStore } from "satcheljs";
import type { Job } from "../models/Job";
export interface LocalJobSettings {
  jobName: string;
  templateName: string;
  configuration: JobConfiguration | undefined;
  localId: string;
  type?: string;
  PermissionObjects?: string[];
  submitData?: CreateJobRouterRequestData;
  linkedJob?: Job;
}

export type JobGroupCreationMode = "group" | "add" | "edit";

interface IToastStatus {
  message: string;
  state: "success" | "error" | "start" | "update";
}
interface IJobGroupCreationStore {
  isLoading: boolean;
  groupTemplates: JobGroupTemplate[];
  selectedGroupTemplate: JobGroupTemplate | undefined;
  selectedGroupTemplateSettings: JobGroupSettings | undefined;
  creationMode: JobGroupCreationMode | undefined;
  groupName: string;
  groupSettings: JobGroupSettings | undefined;
  jobs: LocalJobSettings[];
  currentLocalJobId: string | undefined;
  isDismissDialogOpen?: boolean;
  isSubmitting: boolean;
  toastStatus?: IToastStatus;
}

export const initJobGroupCreationStore: IJobGroupCreationStore = {
  isLoading: true,
  groupTemplates: [],
  selectedGroupTemplate: undefined,
  selectedGroupTemplateSettings: undefined,
  creationMode: undefined,
  groupName: "",
  groupSettings: undefined,
  jobs: [],
  currentLocalJobId: undefined,
  isSubmitting: false,
};

export const jobGroupCreationStore = createStore<IJobGroupCreationStore>(
  "jobGroupCreationStore",
  Object.assign({}, initJobGroupCreationStore),
)();
