import { Checkbox, Field, InfoLabel } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import type { Configure3pPluginsKey } from "@seval-portal/shared";
import { Configure3pPluginsKeys } from "@seval-portal/shared";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import {
  getUnified3pPluginsPathMap,
  getValue,
} from "../../../selectors/getJobPropV2";
import { useStyles } from "./JobBlockView.styles";

interface PluginConfigValues {
  key: "sideload_3p_plugins" | "unsideload_3p_plugins";
  value: boolean | undefined;
}

const unsideloadWarning = `Attention: if you select "unsideload", please don't submit any DA related job of the same account until this job completed.`;
export const JobConfigure3pPluginsBlockView = observer(() => {
  const styles = useStyles();
  const Unified3pPluginsPathMap = getUnified3pPluginsPathMap.get();
  const metrics = {
    sideload_3p_plugins: getValue(Unified3pPluginsPathMap.sideload_3p_plugins),
    unsideload_3p_plugins: getValue(
      Unified3pPluginsPathMap.unsideload_3p_plugins,
    ),
  };

  const configArray: PluginConfigValues[] = Configure3pPluginsKeys.map(
    (key) => {
      return {
        key,
        value: metrics[key],
      };
    },
  ).filter((item) => item.value !== undefined);

  // Hide 3P plugins Configuration block if configs does not exist
  if (
    configArray.length === 0 ||
    configArray.every((item) => item.value === undefined)
  ) {
    return null;
  }

  const getLabelComponent = (key: Configure3pPluginsKey): JSX.Element => {
    let labelString;
    let infoString;
    switch (key) {
      case "sideload_3p_plugins":
        labelString = "Sideload mocked 3P plugins before scraping";
        infoString =
          "This will sideload/install dummy 3P plugins, which return synthetic results for a few test utterances.  Plugins are installed in user scope, before scraping.";
        break;
      case "unsideload_3p_plugins":
        labelString = "Unsideload mocked 3P plugins after scraping";
        infoString =
          "This will unsideload/uninstall dummy 3P plugins after scraping.";
        break;
      default:
        labelString = key;
        infoString = key;
        break;
    }

    return <InfoLabel info={<>{infoString}</>}>{labelString}</InfoLabel>;
  };

  const shouldShowWarning = (config: PluginConfigValues) => {
    return config.value === true && config.key === "unsideload_3p_plugins";
  };
  return (
    <div className={styles.block}>
      <Field
        label={
          <>
            <div className={styles.blockTitle}>3P Plugins Configuration</div>
          </>
        }
      >
        <div className={styles.metricsContainer}>
          <ResponsiveRow maxColumnCount={4} maxColumnCountSmall={2}>
            {configArray.map((config) => {
              return (
                <Field
                  key={config.key}
                  className={styles.checkboxContainerWithWarning}
                  validationState={
                    shouldShowWarning(config) ? "warning" : undefined
                  }
                  validationMessage={
                    shouldShowWarning(config) ? unsideloadWarning : undefined
                  }
                >
                  <Checkbox
                    className={styles.checkbox}
                    id={config.key}
                    key={config.key}
                    checked={config.value}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      updatePropValueActionV2({
                        prop: Unified3pPluginsPathMap[config.key],
                        newData: checked,
                      });
                    }}
                    label={getLabelComponent(config.key)}
                  />
                </Field>
              );
            })}
          </ResponsiveRow>
        </div>
      </Field>
    </div>
  );
});
