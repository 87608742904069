import {
  Badge,
  Button,
  Dropdown,
  Input,
  Link,
  makeStyles,
  Option,
  Persona,
  shorthands,
  Switch,
  Text,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowDownload24Regular,
  CopyAdd24Regular,
} from "@fluentui/react-icons";
import type { CachedConversationSearchRequestData } from "@seval-portal/shared";
import { parseJsonStrOptional } from "@seval-portal/shared";
import { observer } from "mobx-react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { getHeronAccessToken } from "../../../../helpers/accessTokenHelper";
import {
  changeCachedConversationStatus,
  getCachedConversation,
  getHeronJobOutputLink,
  getQuerySetManagement,
} from "../../../../helpers/apiHelper";
import { getAppEnv } from "../../../../helpers/appEnvHelper";
import {
  perfWrapper,
  telemetryHelper,
} from "../../../../helpers/telemetryHelper";
import { isHeronJobOutputLinkErrorResponse } from "../../../../models/HeronJobOutputLinkResponse";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { PaginationTable } from "../../../ShadowAB/components/common/PaginationTable";
import { updateProductFilterAction } from "../../actions/jobActions";
import { resetJobPriorityStateAction } from "../../actions/jobPriorityActions";
import { refreshProductSettings } from "../../actions/productSettingsActions";
import {
  blobPathMeta,
  type CachedConversation,
} from "../../models/CachedConversation";
import { updateJobPermission } from "../../mutators/jobShareMutators";
import { PRODUCT_ID_M365_CHAT } from "../../selectors/productSelectors";
import { productSettingsStore } from "../../store/productSettingsStore";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "40px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "16px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  functionBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap-reverse",
    justifyContent: "space-between",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    ...shorthands.gap("8px"),
  },
  label: {
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
    marginTops: "4px",
    marginBottom: "4px",
  },
  noLabel: {
    marginTop: "16px",
  },
  combobox: {
    minWidth: "184px",
    width: "100%",
  },
  newButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "186px",
    height: "44px",
    color: "#fff",
    fontFamily: tokens.fontFamilyBase,
    fontStyle: "normal",
    fontSize: "16px",
  },
  list: {
    marginTop: "24px",
    paddingBottom: "24px",
    overflowX: "auto",
  },
  tableContainer: {
    backgroundColor: "#fff",
    borderRadius: tokens.borderRadiusMedium,
    minHeight: "76px",
    width: "100%",
  },
  truncateContent: {
    width: "100%",
    overflow: "hidden",
  },
  truncateLink: {
    display: "inline-block",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});
const ActivationStatusSet = new Set<string>([
  "All",
  "None",
  "Activated",
  "Inactivated",
]);
const ValidationStatusSet = new Set<string>([
  "All",
  "None",
  "Passed",
  "Failed",
]);

export const CachedConversationList = observer(() => {
  const styles = useStyles();
  const [results, setResults] = React.useState<CachedConversation[]>([]);
  const [listLoading, setListLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(20);
  const [totalNum, setTotalNum] = React.useState(0);
  const [showSearchName, setShowSearchName] = React.useState("");
  const [searchName, setSearchName] = React.useState("");
  const [searchQueryset, setSearchQueryset] = React.useState<string[]>([]);
  const [searchLatestVersion, setSearchLatestVersion] = React.useState(false);
  const [searchActivationStatus, setSearchActivationStatus] =
    React.useState("All");
  const [searchValidationStatus, setSearchValidationStatus] =
    React.useState("All");
  const activationStatusOptions = Array.from(ActivationStatusSet);
  const validationStatusOptions = Array.from(ValidationStatusSet);
  const [querysetOptions, setQuerysetOptions] = React.useState(["All"]);

  const toastContext = useToast();

  React.useEffect(() => {
    perfWrapper(
      "LoadQuerySetManagementList",
      getQuerySetManagement({ Status: "Activated" }).then((data) => {
        const querySetNameList = data.results.map((item) => item.Name);
        setQuerysetOptions(querySetNameList);
      }),
    );
  }, []);

  const loadResults = React.useCallback(() => {
    setListLoading(true);
    const params: CachedConversationSearchRequestData = {
      pageSize: String(pageSize),
      pageNumber: String(page),
    };
    if (searchName !== "") {
      params.DisplayName = searchName;
    }
    if (searchQueryset.length > 0) {
      params.QuerySet = searchQueryset.join(",");
    }
    if (searchActivationStatus !== "All") {
      params.ActivationStatus = searchActivationStatus;
    }
    if (searchValidationStatus !== "All") {
      params.ValidationStatus = searchValidationStatus;
    }
    if (searchLatestVersion === true) {
      params.LatestQuerySet = "true";
    }
    perfWrapper(
      "LoadCachedConversationList",
      getCachedConversation(params)
        .then((data) => {
          setListLoading(false);
          setResults(data.results);
          setTotalNum(data.totalCount);
        })
        .catch(() => {
          setListLoading(false);
          setResults([]);
          setTotalNum(0);
        }),
    );
  }, [
    searchLatestVersion,
    searchQueryset,
    searchName,
    searchActivationStatus,
    searchValidationStatus,
    page,
    pageSize,
  ]);

  React.useEffect(() => {
    loadResults();
  }, [loadResults]);

  React.useEffect(() => {
    refreshProductSettings();
  }, []);

  const handlerCreateScrapingJob = React.useCallback(() => {
    updateProductFilterAction(PRODUCT_ID_M365_CHAT);
    updateJobPermission([]);
    resetJobPriorityStateAction();
    const scrapingTemplate = productSettingsStore.templates.find(
      (v) => v.Type === "ScrapingOnly",
    );
    updateCurrentPath("/create", {
      clonedJobTemplateType: scrapingTemplate?.Type ?? "",
      clonedExperimentName: scrapingTemplate?.ExperimentName ?? "",
      clonedTemplateSettings: scrapingTemplate?.Settings ?? "",
    });
  }, []);

  const handlerDownload = React.useCallback(
    async (blobPath?: string) => {
      try {
        toastContext.onToastStart("Downloading blob file...");
        if (blobPath) {
          const blobMeta = parseJsonStrOptional(blobPath, blobPathMeta);
          if (blobMeta?.env && blobMeta?.path) {
            const blobEnv = getAppEnv(Number(blobMeta?.env) ?? 1);
            const heronAccessToken = await getHeronAccessToken();
            const result = await getHeronJobOutputLink({
              WorkspaceId: blobEnv.jobOutputInfo.workspaceId,
              SandboxId: blobEnv.jobOutputInfo.sandBoxId,
              ContainerName: blobEnv.jobOutputInfo.storageName,
              StoragePath: `${blobMeta.zippath ?? blobMeta.path}/scrape_output.zip`,
              HeronAccessToken: heronAccessToken,
            });
            if (result && !isHeronJobOutputLinkErrorResponse(result)) {
              setTimeout(() => {
                const customizedLink = `${
                  blobEnv.apiEndpoint
                }/customizedDownload?link=${encodeURIComponent(
                  result.value,
                )}&filename=${encodeURIComponent("scrape_output.zip")}`;
                window.open(customizedLink, "_blank");
              }, 300);
              telemetryHelper.logUserActionEvent("DownloadFile", {
                option: "scrape_output.zip",
              });
              toastContext.onToastSuccess("Start downloading blob file");
            } else {
              toastContext.onToastFailure("Failed to download blob file");
            }
          }
        } else {
          toastContext.onToastFailure("Invalid download file path");
        }
      } catch (_e) {
        toastContext.onToastFailure("Invalid download file path");
      }
    },
    [toastContext],
  );

  const handlerStatusChange = React.useCallback(
    (id: number, status: boolean) => {
      toastContext.onToastStart("Changing cache status...");
      const willStatus = status ? "Activated" : "Inactivated";
      changeCachedConversationStatus({
        ID: String(id),
        ActivationStatus: willStatus,
      })
        .then((row) => {
          if (row) {
            setResults((prev) => {
              const newResults = [...prev];
              const index = newResults.findIndex((item) => item.ID === id);
              if (index !== -1) {
                newResults[index].ActivationStatus = willStatus;
              }
              return newResults;
            });
            toastContext.onToastSuccess(`Success to change cache status`);
          } else {
            toastContext.onToastFailure(`Cached conversation not found`);
          }
        })
        .catch(() => {
          toastContext.onToastFailure(`Failed to change cache status`);
        });
    },
    [toastContext],
  );

  const columns = React.useMemo(
    () => [
      {
        key: "DisplayName",
        title: "Cached conversation name",
        minWidth: 200,
        width: 240,
        render: (data: CachedConversation) => (
          <Text
            className={styles.truncateContent}
            truncate={true}
            title={data.DisplayName}
            wrap={false}
            block={true}
          >
            {data.DisplayName}
          </Text>
        ),
      },
      {
        key: "QuerySet",
        title: "Query Set",
        minWidth: 160,
        width: 200,
        render: (data: CachedConversation) => (
          <Text
            className={styles.truncateContent}
            truncate={true}
            title={data.QuerySet}
            wrap={false}
            block={true}
          >
            {data.QuerySet}
          </Text>
        ),
      },
      {
        key: "ValidationStatus",
        title: "AA evaluation result",
        minWidth: 120,
        width: 140,
        render: (data: CachedConversation) => {
          const badgeColor =
            data.ValidationStatus === "Passed"
              ? "success"
              : data.ValidationStatus === "Failed"
                ? "danger"
                : "informative";
          return (
            <>
              <Badge
                color={badgeColor}
                size="extra-small"
                style={{ marginRight: "4px" }}
              />
              {data.ValidationStatus === "None" ? "N/A" : data.ValidationStatus}
            </>
          );
        },
      },
      {
        key: "CreatedAt",
        title: "Created At",
        minWidth: 160,
        width: 180,
        render: (data: CachedConversation) =>
          new Date(data.CreatedAt).toLocaleString(),
      },
      {
        key: "CreatedBy",
        title: "Created By",
        minWidth: 160,
        width: 180,
        render: (data: CachedConversation) => (
          <Persona textAlignment="center" name={data.CreatedBy} size="small" />
        ),
      },
      {
        key: "SevalJobId",
        title: "Job link",
        minWidth: 120,
        width: 160,
        render: (data: CachedConversation) => (
          <Text
            className={styles.truncateContent}
            truncate={true}
            title={data.SevalJobName ?? ""}
            wrap={false}
            block={true}
          >
            <RouterLink to={`/detail/${data.SevalJobId}`}>
              <Link className={styles.truncateLink}>
                {data.SevalJobName ?? ""}
              </Link>
            </RouterLink>
          </Text>
        ),
      },
      {
        key: "ActivationStatus",
        title: "Cache status",
        minWidth: 120,
        width: 140,
        render: (data: CachedConversation) => {
          return (
            <Switch
              label={data.ActivationStatus}
              disabled={data.ActivationStatus === "None"}
              checked={data.ActivationStatus === "Activated"}
              onChange={(ev) => {
                handlerStatusChange(data.ID, ev.target.checked);
              }}
            />
          );
        },
      },
      {
        key: "ID",
        title: "Action",
        minWidth: 60,
        width: 80,
        render: (data: CachedConversation) => {
          return (
            <Button
              data-testid="download-button"
              icon={<ArrowDownload24Regular />}
              disabled={!data.BlobPath}
              onClick={() => {
                handlerDownload(data.BlobPath);
              }}
            />
          );
        },
      },
    ],
    [styles],
  );

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.functionBarContainer}>
          <div className={styles.rowContainer}>
            <ResponsiveRow
              maxColumnCount={5}
              maxColumnCountSmall={1}
              columnGap={8}
            >
              <div className={styles.columnContainer}>
                <label className={styles.label}>Name</label>
                <Input
                  placeholder="Search a conversation name or query set name"
                  value={showSearchName}
                  onChange={(_e, data) => {
                    setShowSearchName(data.value);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      setSearchName(showSearchName);
                      setPage(1);
                    }
                  }}
                />
              </div>
              <div className={styles.columnContainer}>
                <label className={styles.label}>Cache Status</label>
                <Dropdown
                  className={styles.combobox}
                  aria-label="Cache Status"
                  value={searchActivationStatus}
                  onOptionSelect={(_, data) => {
                    setSearchActivationStatus(data.optionValue ?? "All");
                    setPage(1);
                  }}
                >
                  {activationStatusOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
              <div className={styles.columnContainer}>
                <label className={styles.label}>Query set</label>
                <Dropdown
                  className={styles.combobox}
                  aria-label="Query set"
                  value={
                    searchQueryset.length > 1
                      ? `${searchQueryset[0]} +${searchQueryset.length - 1}`
                      : searchQueryset.length > 0
                        ? searchQueryset[0]
                        : "All"
                  }
                  multiselect={true}
                  selectedOptions={searchQueryset}
                  onOptionSelect={(_, data) => {
                    setSearchQueryset(data.selectedOptions ?? []);
                    setPage(1);
                  }}
                >
                  {querysetOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
              <div className={styles.columnContainer}>
                <label className={styles.label}>AA Evaluation Result</label>
                <Dropdown
                  className={styles.combobox}
                  aria-label="AA Evaluation Result"
                  value={searchValidationStatus}
                  onOptionSelect={(_, data) => {
                    setSearchValidationStatus(data.optionValue ?? "All");
                    setPage(1);
                  }}
                >
                  {validationStatusOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
              <div className={styles.columnContainer}>
                <Switch
                  className={styles.noLabel}
                  label="Show latest version"
                  checked={searchLatestVersion}
                  onChange={(ev) => {
                    setSearchLatestVersion(ev.target.checked);
                    setPage(1);
                  }}
                />
              </div>
            </ResponsiveRow>
          </div>
          <Button
            className={styles.newButton}
            appearance="primary"
            icon={<CopyAdd24Regular />}
            onClick={handlerCreateScrapingJob}
          >
            New scraping job
          </Button>
        </div>
        <div className={styles.list}>
          <PaginationTable<CachedConversation>
            header={columns}
            data={results}
            keyName="ID"
            loading={listLoading}
            pagination={{
              pageSize,
              totalNum,
              page,
            }}
            options={{
              innerBorder: true,
              containerStyle: styles.tableContainer,
            }}
            onPageChange={setPage}
            onPageSizeChange={(newPageSize: number) => {
              setPage(1);
              setPageSize(newPageSize);
            }}
          />
        </div>
      </div>
    </div>
  );
});
