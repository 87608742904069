import { array, bool, num, object, optional, str } from "@seval-portal/shared";

export const CachedConversation = object({
  ID: num,
  DisplayName: str,
  QuerySet: str,
  ScraperService: str,
  SevalJobId: num,
  SevalJobName: optional(str),
  CreatedAt: str,
  CreatedBy: str,
  ValidationStatus: str,
  ActivationStatus: str,
  ScrapingStatus: str,
  BlobPath: optional(str),
  Properties: optional(str),
  Config: optional(str),
  JobId: str,
});

export const blobPathMeta = object({
  id: optional(str),
  datastore: optional(str),
  exp_name: optional(str),
  path: optional(str),
  zippath: optional(str),
  env: optional(str),
});

export const configMeta = object({
  exp_name: optional(str),
  enable_web_search: optional(bool),
  model: optional(str),
  service: optional(str),
});

export const propertiesMeta = object({
  queryset_version: optional(
    object({
      ID: optional(num),
      Queryset: optional(str),
      Version: optional(str),
    }),
  ),
});

export type CachedConversation = ReturnType<typeof CachedConversation>;
export const CachedConversationResponse = object({
  results: array(CachedConversation),
  totalCount: num,
});
export type CachedConversationResponse = ReturnType<
  typeof CachedConversationResponse
>;
