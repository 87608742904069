import { Button, Card, Link, Spinner } from "@fluentui/react-components";
import React, { useEffect } from "react";
import { formatCreateTime } from "../../helpers/formatHelper";

import {
  ArrowRightRegular,
  Beaker20Regular,
  Clock16Regular,
  Dismiss16Filled,
  Flag16Regular,
} from "@fluentui/react-icons";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { ShadowABExperimentSetupDialog } from "../../../../components/Shared/shadowABExperimentSetupDialog/ShadowABExperimentSetupDialog";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { openSidePane } from "../../../../mutators/sidePaneMutators";
import { isFeatureEnabled } from "../../../../selectors/features";
import {
  openJobSetAsPriorityDialogAction,
  openRemoveJobPriorityDialogAction,
} from "../../actions/jobPriorityActions";
import { hasDownloadPermission } from "../../helpers/downloadHelper";
import { initJobDetailPage } from "../../selectors/jobDetailHelper";
import { quickCreateScheduleJob } from "../../selectors/scheduleJobSelector";
import { jobStore } from "../../store/jobStore";
import { resultStore } from "../../store/resultStore";
import { JobSetAsPriorityDialog } from "../JobCreation/components/JobSetAsPriorityDialog";
import { RemoveJobPriorityDialog } from "../JobCreation/components/RemoveJobPriorityDialog";
import { JobActionsCell } from "../JobList/JobCell/JobActionsCell";
import { JobStatusCell } from "../JobList/JobCell/JobStatusCell";
import { ResultsView } from "../ResultsComparison/ResultsView";
import { BingDownloadButton } from "../ResultsComparison/components/BingDownloadButton";
import { NoPermissionDialog } from "../ResultsComparison/components/NoPermissionDialog";
import { OutputFilesView } from "../ResultsComparison/components/OutputFilesView";
import { BingMonitorView } from "./components/BingMonitorView";
import { InforButtonView } from "./components/InforButtonView";
import { SBSLinksButton } from "./components/SBSLinksButton";
import { SettingPane } from "./components/SettingPane";
import { ShareButton } from "./components/ShareButton";
import { StudioURLButton } from "./components/StudioURLButton";
import { useDetailPageStyles } from "./sharedStyles";

export const JobDetailView = observer(() => {
  const { jobId } = useParams();
  const { templateType } = resultStore;

  const job = resultStore.resultJob;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isSetupShadowABDialogOpen, setIsSetupShadowABDialogOpen] =
    React.useState<boolean>(false);
  const showDownloadButton = computed(
    () =>
      templateType !== "BingV2" &&
      templateType !== "BingGenerator" &&
      job?.ExperimentName !== "BizChat-Personalized-Query-Set-Generator-V2" &&
      templateType !== "ScrapingOnly" &&
      templateType !== "CWC" &&
      (job?.Status === "Completed" ||
        job?.Status === "Running" ||
        job?.Status === "Failed"),
  );

  const shouldShowBingOrCWCDownloadButton = computed(
    () =>
      (templateType === "BingV2" ||
        templateType === "CWC" ||
        templateType === "ScrapingOnly") &&
      job?.Properties?.HasReadDiagnosisPermission !== false &&
      job?.Status === "Completed",
  );

  const canCreateShadowAB = computed(
    () =>
      isFeatureEnabled("shadowAB") &&
      !["BingV2", "CWC", "OPG"].includes(templateType ?? "") &&
      job?.Status === "Completed",
  );

  const toast = useToast();

  useEffect(() => {
    const jobIdNumber = Number(jobId);

    if (isNaN(jobIdNumber)) {
      setIsLoading(false);
      return;
    }
    initJobDetailPage(jobIdNumber).finally(() => {
      setIsLoading(false);
    });
  }, [jobId]);

  useEffect(() => {
    switch (resultStore.perfStatus) {
      case "Begin":
        toast.onToastStart("Loading Performance Metrics Data...");
        break;
      case "Done":
        toast.onToastSuccess("Performance Metrics Data Loaded!");
        break;
      case "Failure":
        toast.onToastFailure("Failed to Load Performance Metrics Data!");
        break;
    }
    return () => {
      toast.onToastDismiss();
    };
  }, [resultStore.perfStatus]);

  const styles = useDetailPageStyles();

  if (isLoading) {
    return <Spinner />;
  }

  const isBingScheduleJob =
    job?.Properties?.IsBingJob && job?.JobType === "Scheduled";

  const renderCards = () => {
    if (!job?.HasMetrics) {
      return <></>;
    }

    return <ResultsView />;
  };

  const isGenerationJob = [
    "Bing_Test_Set_Generator",
    "BizChat-Personalized-Query-Set-Generator",
    "BizChat-Personalized-Query-Set-Generator_eyesoff",
    "BizChat-Personalized-Query-Set-Generator-V2",
    "BizChat-Personalized-Query-Set-Generator-V2_eyesoff",
  ].includes(job?.ExperimentName ?? "Unknown");

  const supportQuickSchedule =
    job?.Properties?.IsOwner === true &&
    !isGenerationJob &&
    job?.Status === "Completed";

  const isSetAsPriorityJobSupported = () => {
    return (
      isFeatureEnabled("job-priority") &&
      job?.Properties?.IsOwner &&
      (job?.Status === "Created" ||
        job?.Status === "Submitted" ||
        job?.Status === "Running")
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          {job?.JobName}
          {isSetAsPriorityJobSupported() && job?.IsPriorityJob && (
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                padding: "2px 5px",
                borderRadius: "3px",
                marginLeft: "10px",
                display: "inline-block",
                fontSize: "12px",
              }}
            >
              Priority
            </div>
          )}
        </div>
        <div className={styles.operationButtons}>
          <InforButtonView />
          {shouldShowBingOrCWCDownloadButton.get() && (
            <BingDownloadButton
              job={resultStore.resultJob}
              outputFiles={resultStore.bingJobOutputFiles ?? []}
              scenario="Single Job"
            />
          )}
          <ShareButton />
          {showDownloadButton.get() && (
            <OutputFilesView
              job={resultStore.resultJob}
              hasDownloadPermission={hasDownloadPermission(
                resultStore.resultJob,
              )}
            />
          )}
          {supportQuickSchedule && (
            <Button
              onClick={() => {
                quickCreateScheduleJob(job);
              }}
              icon={<Clock16Regular />}
            >
              Set up regular run
            </Button>
          )}
          {isSetAsPriorityJobSupported() && (
            <Button
              onClick={() => {
                job?.IsPriorityJob
                  ? openRemoveJobPriorityDialogAction(job?.ID)
                  : openJobSetAsPriorityDialogAction(job?.ID);
              }}
              icon={
                job?.IsPriorityJob ? <Dismiss16Filled /> : <Flag16Regular />
              }
            >
              {job?.IsPriorityJob ? "Remove priority" : "Set as priority job"}
            </Button>
          )}
          {canCreateShadowAB.get() && (
            <Button
              onClick={() => {
                setIsSetupShadowABDialogOpen(true);
              }}
              icon={<Beaker20Regular />}
            >
              New Shadow AB
            </Button>
          )}
          {job && (
            <JobActionsCell
              item={job}
              jobTemplates={jobStore.availableTemplates}
              scenario="detail"
            />
          )}
        </div>
      </div>
      <Card className={styles.infoCard}>
        <div className={styles.infoGridLayout}>
          <div>
            <div className={styles.infoGrid}>
              <div className={styles.infoTitle}>Created At</div>
              <div>{formatCreateTime(job?.CreatedAt)}</div>
              <div className={styles.infoTitle}>Created By</div>
              <div>{job?.CreatedBy}</div>
            </div>
          </div>
          <div>
            <div className={styles.infoGrid}>
              <div className={styles.infoTitle}>Status</div>
              <div>
                <JobStatusCell job={job}></JobStatusCell>
              </div>
              {job?.StudioUrl && <StudioURLButton />}
              {isBingScheduleJob && <BingMonitorView />}
            </div>
          </div>
        </div>

        {resultStore.sbsLinks && resultStore.sbsLinks.length > 0 && (
          <SBSLinksButton />
        )}

        <div className={styles.infoTitle}>
          <Link
            className={styles.infoTitle}
            onClick={() => {
              openSidePane(() => <SettingPane />, "wide");
            }}
          >
            Show Config File <ArrowRightRegular />
          </Link>
        </div>
      </Card>
      {renderCards()}
      {resultStore.loadingQueue.length > 0 && <Spinner />}
      <NoPermissionDialog />
      {job?.IsPriorityJob ? (
        <RemoveJobPriorityDialog />
      ) : (
        <JobSetAsPriorityDialog />
      )}
      <ShadowABExperimentSetupDialog
        isOpen={isSetupShadowABDialogOpen}
        onDismiss={() => {
          setIsSetupShadowABDialogOpen(false);
        }}
        upstreamJob={job}
      />
    </div>
  );
});
