import type { BingTestSet } from "@seval-portal/shared";

export const checkBingTestSetOptions = (
  value: BingTestSet | undefined,
  path: string,
) => {
  if (
    value === undefined ||
    value.options === undefined ||
    value.options.length === 0
  ) {
    return undefined;
  }

  if (
    value.options.indexOf("skip_scraping") !== -1 &&
    value.previous_scrape_output === undefined
  ) {
    return {
      path,
      message: `Please upload previous scrape output folder for test set ${path} !`,
    };
  }

  return undefined;
};
