import { action } from "satcheljs";
import type { Product, ProductTemplate } from "@seval-portal/shared";

export const refreshProductSettings = action("refreshProductSettings");

export const onBackClick = action("onBackClick");

export const onSaveClick = action("onSaveClick");

export const onNextClick = action("onNextClick");

export const onCancelClick = action("onCancelClick");

export const onReleaseClick = action(
  "onReleaseClick",
  (isConfirmed: boolean) => ({ isConfirmed }),
);

export const onEditProductClick = action(
  "onEditProductClick",
  (product: Product) => ({ product }),
);

export const onPublishProductClick = action(
  "onPublishProductClick",
  (product: Product) => ({ product }),
);

export const onDeleteProductClick = action(
  "onDeleteProductClick",
  (product: Product, isConfirmed: boolean) => ({ product, isConfirmed }),
);

/** Actions for editing product */

export const onProductNameChange = action(
  "onProductNameChange",
  (productName: string) => ({ productName }),
);

export const onPipelineNameChange = action(
  "onPipelineNameChange",
  (pipelineName: string) => ({ pipelineName }),
);

export const onPipelineSettingsChange = action(
  "onPipelineSettingsChange",
  (settingsOverride: string | undefined) => ({ settingsOverride }),
);

export const onAddPipelineClick = action(
  "onAddPipelineClick",
  (template: ProductTemplate) => ({ template }),
);

export const onDupPipelineClick = action(
  "onDupPipelineClick",
  (template: ProductTemplate) => ({ template }),
);

export const onEditPipelineClick = action(
  "onEditPipelineClick",
  (template: ProductTemplate) => ({ template }),
);

export const onDeletePipelineClick = action(
  "onDeletePipelineClick",
  (template: ProductTemplate) => ({ template }),
);

export const onSavePipelineClick = action(
  "onSavePipelineClick",
  (template: ProductTemplate) => ({ template }),
);

export const onSaveProductClick = action(
  "onSaveProductClick",
  (release: boolean) => ({ release }),
);
