import { createStore } from "satcheljs";
interface IJobCreationFileStore {
  customQuerySets: string[];
  bizchatBuiltInQuerySets: string[];
  customLMChecklistSets: string[];
  publicLMChecklistSets: string[];
}

export const initJobCreationFileStore: IJobCreationFileStore = {
  customQuerySets: [],
  bizchatBuiltInQuerySets: [],
  customLMChecklistSets: [],
  publicLMChecklistSets: [],
};

export const jobCreationFileStore = createStore<IJobCreationFileStore>(
  "jobCreationFileStore",
  Object.assign({}, initJobCreationFileStore),
)();
