import { MsalProvider } from "@azure/msal-react";
import { observer } from "mobx-react-lite";

import {
  BrowserAuthError,
  PublicClientApplication,
  type EventMessage,
} from "@azure/msal-browser";
import { useEffect } from "react";
import type { LogSignEventNameType } from "@seval-portal/shared";
import { loginAction } from "../../actions/loginAction";
import { msalConfig } from "../../authConfig";
import { reportLog } from "../../helpers/apiHelper";
import { clearLocalStorgeExceptLocalCache } from "../../helpers/cacheHelper";
import { updateTokenRefreshError } from "../../mutators/updateTokenRefreshError";
import type { WrapperProps } from "./WrapperProps";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

const getEventDatabag = (event: EventMessage) => {
  return {
    loginId:
      event.payload && "correlationId" in event.payload
        ? event.payload.correlationId
        : "",
    error: event.error?.message,
  };
};

const getAccountFromEvent = (event: EventMessage) => {
  return event.payload && "account" in event.payload
    ? event.payload.account
    : null;
};

const sendSignLog = (eventName: LogSignEventNameType, event: EventMessage) => {
  reportLog({
    EventName: eventName,
    EventType: "Sign",
    DataBag: getEventDatabag(event),
  }).catch(() => undefined);
};

const isIframeWindow = () => {
  return window.self !== window.top;
};

export const msalEventCallback = (event: EventMessage) => {
  switch (event.eventType) {
    case "msal:loginFailure":
      updateTokenRefreshError(event.error?.message ?? "Unknown Error");
      sendSignLog("SignInError", event);
      break;

    case "msal:acquireTokenFailure":
      if (
        event.error instanceof BrowserAuthError &&
        event.error.errorCode === "monitor_window_timeout"
      ) {
        // MSAL will launch a iframe to handle the login
        // But actually SEVAL portal disabled iframe and then the token refresh will fail
        // So in this case we just redirect the user to login again
        // Clear all local storage except LOCAL_CACHE
        clearLocalStorgeExceptLocalCache();
        loginAction(msalInstance, "redirect");
      }

      updateTokenRefreshError(event.error?.message ?? "Unknown Error");
      break;
    case "msal:loginSuccess":
      msalInstance.setActiveAccount(getAccountFromEvent(event) ?? null);
      sendSignLog("SignInSuccess", event);
      break;
    case "msal:loginStart":
      sendSignLog("SignInStart", event);
      break;

    default:
      break;
  }
};

export const AuthProvider = observer((props: WrapperProps) => {
  useEffect(() => {
    if (isIframeWindow()) {
      return;
    }

    msalInstance.addEventCallback(msalEventCallback);

    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }, []);

  // if running in popup, do nothing
  if (isIframeWindow()) {
    return <body>MSAL disable since it is running in iframe</body>;
  }

  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
});
