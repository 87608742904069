"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTemplateType = exports.isMultiServiceTemplate = exports.multiServiceTemplateNames = exports.TemplateType = void 0;
var TemplateType;
(function (TemplateType) {
    TemplateType["JSON"] = "JSON";
    TemplateType["Normal"] = "Normal";
    TemplateType["BingV2"] = "BingV2";
    TemplateType["OPG"] = "OPG";
    TemplateType["BizChatFlightReview"] = "BizChatFlightReview";
    TemplateType["GeneralSkipScraping"] = "GeneralSkipScraping";
    TemplateType["ScrapingOnly"] = "ScrapingOnly";
    TemplateType["Batch"] = "Batch";
    TemplateType["CWC"] = "CWC";
    TemplateType["AIHub"] = "SearchEval";
    TemplateType["UnifiedBizChat"] = "UnifiedBizChat";
    TemplateType["UnifiedBizChatFlightReview"] = "UnifiedBizChatFlightReview";
})(TemplateType || (exports.TemplateType = TemplateType = {}));
exports.multiServiceTemplateNames = [
    "AppChat_Copilot_Evaluation_All_Metrics",
    "AppChat_Copilot_Evaluation_All_Metrics_for_test_tenant",
];
const isMultiServiceTemplate = (templateName) => {
    if (templateName.includes("Product_")) {
        const templateNameSplit = templateName.split("_")[1];
        const subTemplate = templateName.replace("Product_" + templateNameSplit + "_", "");
        return exports.multiServiceTemplateNames.includes(subTemplate);
    }
    return exports.multiServiceTemplateNames.includes(templateName);
};
exports.isMultiServiceTemplate = isMultiServiceTemplate;
const getTemplateType = (templateType) => {
    switch (templateType) {
        case undefined:
            return undefined;
        case "BingV2":
            return TemplateType.BingV2;
        case "CWC":
            return TemplateType.CWC;
        case "RAI":
            return TemplateType.JSON;
        case "Default":
            return TemplateType.JSON;
        case "OPG":
            return TemplateType.OPG;
        case "BizChatFlightReview":
            return TemplateType.BizChatFlightReview;
        case "GeneralSkipScraping":
            return TemplateType.GeneralSkipScraping;
        case "ScrapingOnly":
            return TemplateType.ScrapingOnly;
        case "UnifiedBizChat":
            return TemplateType.UnifiedBizChat;
        case "UnifiedBizChatFlightReview":
            return TemplateType.UnifiedBizChatFlightReview;
        case "SearchEval":
            return TemplateType.AIHub;
        default:
            return TemplateType.Normal;
    }
};
exports.getTemplateType = getTemplateType;
