import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link,
  Textarea,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { getTokenName } from "../../../../helpers/accountHelper";
import { getLoginUrl, setToken } from "../../../../helpers/apiHelper";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { isFeatureEnabled } from "../../../../selectors/features";
import { generateRefreshTokenAction } from "../../actions/jobActions";
import {
  GuidanceLink3S,
  GuidanceLinkSEVAL,
  GuidanceLinkTeamsCli,
} from "../Other/GuidanceLink";

type TokenUploadingDialogProps = {
  isOpen: boolean;
  email: string;
  tokenInitDialogType: TokenInitDialogType;
  shouldUploadSssToken: boolean;
  shouldUploadSevalToken: boolean;
  shouldUploadTeamsCliToken: boolean;
  canSkipTokenUploading: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

export type TokenInitDialogType = "FromSettings" | "TokenMissingOrExpired";

const useStyles = makeStyles({
  dialog: {
    maxWidth: "672px",
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tokenTitle: {
    marginTop: "20px",
  },
  tokenInput: {
    backgroundColor: "#FAF9F8",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "solid", "#C8C6C4"),
  },
  footer: {
    color: "rgba(96, 94, 92, 1)",
  },
});

export const TokenUploadingDialog = observer(
  (props: TokenUploadingDialogProps) => {
    const styles = useStyles();
    const toast = useToast();

    const [sssToken, setSssToken] = useState<string>("");
    const [sevalToken, setSevalToken] = useState<string>("");
    const [teamsCliToken, setTeamsCliToken] = useState<string>("");

    const [isSssTokenSet, setIsSssTokenSet] = useState(
      !props.shouldUploadSssToken,
    );
    const [isSevalTokenSet, setIsSevalTokenSet] = useState(
      !props.shouldUploadSevalToken,
    );
    const [isTeamsCliTokenSet, setIsTeamsCliTokenSet] = useState(
      !props.shouldUploadTeamsCliToken,
    );

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
      setSssToken("");
      setSevalToken("");
      setTeamsCliToken("");
      setIsSssTokenSet(!props.shouldUploadSssToken);
      setIsSevalTokenSet(!props.shouldUploadSevalToken);
      setIsTeamsCliTokenSet(!props.shouldUploadTeamsCliToken);
      setIsSubmitting(false);
    }, [props.isOpen]);

    const preProcess = (
      tokenShortName: string,
      tokenValue: string,
    ): Promise<{ tokenName: string; token: string }> => {
      const tokenName = getTokenName(props.email, tokenShortName.toLowerCase());
      return Promise.resolve({
        tokenName,
        token: tokenValue,
      });
    };

    const onSubmit = async () => {
      toast.onToastStart("Uploading tokens...");
      setIsSubmitting(true);
      // These are to track verified status values in current rendering
      let isSssTokenSetLocal = isSssTokenSet;
      let isSevalTokenSetLocal = isSevalTokenSet;
      let isTeamsCliTokenSetLocal = isTeamsCliTokenSet;

      if (!isSssTokenSet) {
        try {
          const result = await preProcess("3S", sssToken);
          await setToken({
            SecretName: result.tokenName,
            SecretValue: sssToken,
            TokenType: "3S refresh token",
          });
          setIsSssTokenSet(true);
          isSssTokenSetLocal = true;
        } catch (error) {
          handleOnSubmitFailure("3S", error);
        }
      }

      if (!isSevalTokenSet) {
        try {
          const result = await preProcess("3S", sevalToken);
          await setToken({
            SecretName: result.tokenName,
            SecretValue: sevalToken,
            TokenType: "SEVAL refresh token",
          });
          setIsSevalTokenSet(true);
          isSevalTokenSetLocal = true;
        } catch (error) {
          handleOnSubmitFailure("Seval", error);
        }
      }

      if (!isTeamsCliTokenSet) {
        try {
          const result = await preProcess("TeamsCli", teamsCliToken);
          await setToken({
            SecretName: result.tokenName,
            SecretValue: teamsCliToken,
            TokenType: "Teams CLI refresh token",
          });
          setIsTeamsCliTokenSet(true);
          isTeamsCliTokenSetLocal = true;
        } catch (error) {
          handleOnSubmitFailure("TeamsCli", error);
        }
      }

      if (
        isSssTokenSetLocal &&
        isSevalTokenSetLocal &&
        isTeamsCliTokenSetLocal
      ) {
        props.onSuccess();
      }

      setIsSubmitting(false);
    };

    const handleOnSubmitFailure = (
      tokenShortName: "3S" | "Seval" | "TeamsCli",
      error: unknown,
    ) => {
      const parsedError = (() => {
        return error instanceof Error ? error.message : JSON.stringify(error);
      })();

      telemetryHelper.logUserActionEvent("SetTokenFailure", {
        inputType: "Refreshtoken",
        message: parsedError,
      });

      toast.onToastFailure(
        `${tokenShortName} token upload failed with message: ${parsedError}`,
      );
    };

    const disableUploadAndCreate =
      (!isSssTokenSet && sssToken.trim() === "") ||
      (!isTeamsCliTokenSet && teamsCliToken.trim() === "") ||
      (!isSevalTokenSet && sevalToken.trim() === "") ||
      isSubmitting;

    if (isFeatureEnabled("generate-refresh-token") && !isSevalTokenSet) {
      return <TokenGenerationDialog {...props} />;
    }

    if (isFeatureEnabled("scraping-service-migration")) {
      return <ScrapingServiceTokenInitDialog {...props} />;
    }

    return (
      <Dialog
        modalType="alert"
        open={props.isOpen}
        onOpenChange={props.onCancel}
      >
        <DialogSurface className={styles.dialog}>
          <DialogBody>
            <DialogTitle>{"Onboarding Setup"}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Body1>
                {`You are trying to create a job with email `}
                <strong>{props.email}</strong>
                {`, but the related tokens are not set up yet.`}
              </Body1>
              {!isSssTokenSet && (
                <TokenInputView
                  tokenShortName="3S"
                  tokenFullName="Substrate Search Service"
                  token={sssToken}
                  tokenGuidanceLink={<GuidanceLink3S />}
                  tokenStateSetter={setSssToken}
                  isSubmitting={isSubmitting}
                  email={props.email}
                />
              )}
              {!isSevalTokenSet && (
                <TokenInputView
                  tokenShortName="Seval"
                  tokenFullName="Sydney Evaluation"
                  token={sevalToken}
                  tokenGuidanceLink={<GuidanceLinkSEVAL />}
                  tokenStateSetter={setSevalToken}
                  isSubmitting={isSubmitting}
                  email={props.email}
                />
              )}
              {!isTeamsCliTokenSet && (
                <TokenInputView
                  tokenShortName="TeamsCli"
                  tokenFullName="Teams Command Line Interface"
                  token={teamsCliToken}
                  tokenGuidanceLink={<GuidanceLinkTeamsCli />}
                  tokenStateSetter={setTeamsCliToken}
                  isSubmitting={isSubmitting}
                  email={props.email}
                />
              )}
            </DialogContent>

            <DialogActions>
              {props.canSkipTokenUploading && (
                <Button
                  style={{ width: "200px" }}
                  onClick={props.onSuccess}
                  appearance="primary"
                >
                  Skip Token Uploading
                </Button>
              )}
              <Button
                style={{ width: "200px" }}
                onClick={onSubmit}
                disabled={disableUploadAndCreate}
                appearance="primary"
              >
                Upload Token And Create
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);

export const ScrapingServiceTokenInitDialog = observer(
  (props: TokenUploadingDialogProps) => {
    const styles = useStyles();
    const handleScrapingServiceTokenInit = () => {
      getLoginUrl().then((url) => {
        window.open(url, "_blank");
      });
      props.onSuccess();
    };

    const getGenerateTokenButton = () => {
      let buttonText;
      switch (props.tokenInitDialogType) {
        case "FromSettings":
          buttonText = "Apply";
          break;
        case "TokenMissingOrExpired":
          buttonText = "Generate Refresh Token";
          break;
        default:
          buttonText = "Generate Refresh Token";
      }
      return (
        <Button
          style={{ width: "200px" }}
          onClick={() => {
            handleScrapingServiceTokenInit();
          }}
          appearance="primary"
        >
          {buttonText}
        </Button>
      );
    };

    const getDialogContent = () => {
      const consentBody = (
        <div style={{ marginTop: "1em", marginBottom: "1em" }}>
          <ul>
            <li>
              {`Generate and securely store your Seval Refresh Token on Seval.`}
            </li>
            <li>
              {`Use your Seval Refresh Token solely for the purpose of retrieving Copilot responses on your personal account.`}
            </li>
          </ul>
          <p>
            {`Additionally, you have the option to consent with Test account for running Seval job with it. Please note that all Test accounts are currently accessible to all Seval users.`}
          </p>
        </div>
      );
      switch (props.tokenInitDialogType) {
        case "FromSettings":
          return (
            <DialogContent className={styles.dialogContent}>
              <Body1>{`Pop up notification before consent:`}</Body1>
              <>
                <br />
                <Body1>
                  {`By providing your consent, you authorize the Seval platform to:`}
                  <br />
                  {consentBody}
                  <br />
                </Body1>
              </>
            </DialogContent>
          );
        case "TokenMissingOrExpired":
          return (
            <DialogContent className={styles.dialogContent}>
              <Body1>
                {`You are attempting to create a job on account ‘`}
                <strong>{props.email}</strong>
                {`’, but the associated token has not been set up yet or has already retired.`}
              </Body1>
              <>
                <br />
                <Body1>
                  {`By clicking ‘Generate Refresh Token’ button and providing your consent, you authorize the Seval platform to:`}
                  <br />
                  {consentBody}
                  <br />
                </Body1>
              </>
            </DialogContent>
          );
      }
    };

    return (
      <Dialog
        modalType="alert"
        open={props.isOpen}
        onOpenChange={props.onCancel}
      >
        <DialogSurface className={styles.dialog}>
          <DialogBody>
            <DialogTitle>{"Onboarding Setup"}</DialogTitle>
            {getDialogContent()}
            <DialogActions>
              {getGenerateTokenButton()}
              <Button onClick={props.onCancel} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);

export const TokenGenerationDialog = observer(
  (props: TokenUploadingDialogProps) => {
    const styles = useStyles();

    return (
      <Dialog
        modalType="alert"
        open={props.isOpen}
        onOpenChange={props.onCancel}
      >
        <DialogSurface className={styles.dialog}>
          <DialogBody>
            <DialogTitle>{"Onboarding Setup"}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              {props.canSkipTokenUploading ? (
                <Body1>
                  {`You are trying to create a job with email `}
                  <strong>{props.email}</strong>
                  {`, but the target email is still using `}
                  <b>3S refresh token</b>
                  {`, which will be deprecated soon in December 2024 due to security concerns. `}
                  {`If you still use the deprecated token, your job may fail.`}
                </Body1>
              ) : (
                <Body1>
                  {`You are trying to create a job with email `}
                  <strong>{props.email}</strong>
                  {`, but the related tokens are not set up yet.`}
                </Body1>
              )}

              <>
                <br />
                <Body1>
                  {`You can click the generate button and sign-in your target account to generate the `}
                  <b>SEVAL refresh token</b>
                  {`, it will auto set your SEVAL refresh token. `}
                  {`After token generation, try submit job again.`}
                  {`If you are using test account and test tenant to run the evaluation.
                  Please obtain the test token/password information from the tenant owner as the `}
                  <Link
                    onClick={() =>
                      window.open(
                        "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/Enterprise%20Sydney%20WIKI/502877/Test-Tenants",
                        "_blank",
                      )
                    }
                  >
                    Wiki
                  </Link>
                  {`.`}
                </Body1>
                {props.canSkipTokenUploading && (
                  <>
                    <br />
                    <Body1>
                      {`Or you can just skip the token uploading for now and create the job with previous `}
                      <b>3S refresh token</b>
                      {`.`}
                    </Body1>
                  </>
                )}
              </>
            </DialogContent>

            <DialogActions>
              {props.canSkipTokenUploading && (
                <Button
                  style={{ width: "200px" }}
                  onClick={props.onSuccess}
                  appearance="secondary"
                >
                  Skip Token Generation
                </Button>
              )}
              <Button
                style={{ width: "200px" }}
                onClick={generateRefreshTokenAction.bind(null, props.onSuccess)}
                appearance="primary"
              >
                Generate Refresh Token
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);

const TokenInputView = observer(
  (props: {
    tokenShortName: "3S" | "Seval" | "TeamsCli";
    tokenFullName: string;
    token: string;
    tokenGuidanceLink: JSX.Element;
    tokenStateSetter: (value: string) => void;
    isSubmitting: boolean;
    email: string;
  }) => {
    const styles = useStyles();
    const {
      tokenShortName,
      tokenFullName,
      token,
      tokenGuidanceLink,
      tokenStateSetter,
      isSubmitting,
    } = props;

    return (
      <div>
        <br />
        <Body1>
          {`Please set up the ${tokenShortName} (${tokenFullName}) tokens of this email to ensure a safe and seamless experience.`}
        </Body1>

        <Field
          required
          className={styles.tokenTitle}
          label={
            <Body1 className={styles.tokenTitle}>
              {`Please provide the ${tokenShortName} refresh token of ${props.email}:`}
            </Body1>
          }
          validationMessage={
            token.trim() === ""
              ? `${tokenShortName} refresh token can not be empty`
              : undefined
          }
        >
          <Textarea
            disabled={isSubmitting}
            size="large"
            className={styles.tokenInput}
            value={token}
            onChange={(_, data) => {
              tokenStateSetter(data.value);
            }}
          />
        </Field>
        <div className={styles.tokenTitle}>
          <Body1 className={styles.footer}>
            {`${tokenShortName} Refresh Token will be stored as secret with name <username>-<tokentype>-token in Azure Key Vault. For the set up guidance, please check `}
          </Body1>
          <Body1 className={styles.footer}>{", "}</Body1>
          {tokenGuidanceLink}
          <Body1 className={styles.footer}>{"."}</Body1>
        </div>
      </div>
    );
  },
);
