import { Button } from "@fluentui/react-components";
import { ArrowLeft32Filled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { submitJobGroupAction } from "../../../actions/jobGroupActions";
import { isReadyForSubmit } from "../../../helpers/creationDataHelper";
import { updateIsDismissDialogOpen } from "../../../mutators/jobGroupCreationMutators";
import { jobGroupCreationStore } from "../../../store/jobGroupCreationStore";
import { ShareButton } from "../../JobDetail/components/ShareButton";
import { useGroupStyles } from "../groupStyles";

export const GroupCreationHeader = observer(() => {
  const styles = useGroupStyles();

  const isReadyToSubmit = isReadyForSubmit.get();
  const toast = useToast();

  useEffect(() => {
    return () => {
      toast.onToastDismiss();
    };
  }, []);
  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <ShareButton />
        <Button
          appearance={"primary"}
          onClick={() => {
            toast.onToastStart("Submitting Job Group...");
            submitJobGroupAction();
          }}
          disabled={!isReadyToSubmit || jobGroupCreationStore.isSubmitting}
        >
          Submit
        </Button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.rowWithSmallGap}>
            <Button
              data-testid="back-button"
              appearance={"subtle"}
              icon={<ArrowLeft32Filled />}
              onClick={() => updateIsDismissDialogOpen(true)}
            ></Button>
            <span className={styles.headerTitle}>Create a new group job</span>
          </div>
        </div>
        <div>
          {!jobGroupCreationStore.isLoading && renderShareSubmitButton()}
        </div>
      </div>
    );
  };
  return <div>{renderHeader()}</div>;
});
