import { mutatorAction } from "satcheljs";

import type { BingMetaDataResponse, Product } from "@seval-portal/shared";
import type { Toaster } from "../../../components/Wrappers/ToasterProvider";
import type { JobTemplate } from "../models/JobTemplate";
import {
  productSettingsStore,
  type ProductSettingsScenario,
} from "../store/productSettingsStore";

export const updateProductSettingScenario = mutatorAction(
  "updateProductSettingScenario",
  (scenario: ProductSettingsScenario) => {
    productSettingsStore.scenario = scenario;
  },
);

export const updateProducts = mutatorAction(
  "updateProducts",
  (products: Product[]) => {
    productSettingsStore.products = products;
  },
);

export const updateProductMetaData = mutatorAction(
  "updateMetaData",
  (
    bingMetaData: BingMetaDataResponse | undefined,
    cwcMetaData: BingMetaDataResponse | undefined,
  ) => {
    productSettingsStore.bingMetaData = bingMetaData;
    productSettingsStore.cwcMetaData = cwcMetaData;
  },
);

export const updateProductJobTemplates = mutatorAction(
  "updateProductJobTemplates",
  (jobTemplates: JobTemplate[]) => {
    productSettingsStore.templates = jobTemplates;
  },
);

export const updateToaster = mutatorAction(
  "updateToaster",
  (toaster: Toaster | undefined) => {
    productSettingsStore.toaster = toaster;
  },
);
