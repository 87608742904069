import { array, object, str } from "@seval-portal/shared";

export const MetricsChangeLog = object({
  title: str,
  time: str,
  description: str,
  author: str,
});
export const MetricsChangeLogList = array(MetricsChangeLog);

export type MetricsChangeLog = ReturnType<typeof MetricsChangeLog>;
export type MetricsChangeLogs = ReturnType<typeof MetricsChangeLogList>;
