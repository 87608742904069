import { createStore } from "satcheljs";
import type { JobGroupData, JobGroupTemplate } from "@seval-portal/shared";
import type { Job } from "../models/Job";
import type { IJobDetailStore } from "./jobDetailStore";
import type { IJobResult } from "./resultStore";

interface CachedJobResult {
  resultStore: IJobResult;
  detailStore: IJobDetailStore;
}
interface IGroupDetailStore {
  isLoadingGroup: boolean;
  isLoadingJob: boolean;
  group: JobGroupData | undefined;
  groupTemplates: JobGroupTemplate[];
  jobs: Job[];
  cachedJobResults: Map<number, CachedJobResult>;
  currentJob?: Job;
}

export const initGroupDetailStore: IGroupDetailStore = {
  isLoadingGroup: true,
  isLoadingJob: true,
  group: undefined,
  groupTemplates: [],
  jobs: [],
  cachedJobResults: new Map<number, CachedJobResult>(),
};

export const groupDetailStore = createStore<IGroupDetailStore>(
  "groupDetailStore",
  Object.assign({}, initGroupDetailStore),
)();
