import type {
  CreateJobRouterRequestData,
  SendPriorityJobNotificationRequestData,
} from "@seval-portal/shared";
import {
  BingMetaDataResponse,
  isSubmittedJobQualified,
  parseJsonStrOptional,
} from "@seval-portal/shared";
import { computed } from "mobx";
import {
  findGroup,
  getBingProductMetaData,
  getBizchatBuiltInQuerySets,
  getCustomLMChecklistSets,
  getGroupTemplates,
  getJobTemplates,
  getPublicLMChecklistSets,
  listProducts,
  sendPriorityJobNotification,
} from "../../../helpers/apiHelper";
import { getAppEnv } from "../../../helpers/appEnvHelper";
import { perfWrapper } from "../../../helpers/telemetryHelper";
import { isFeatureEnabled } from "../../../selectors/features";
import {
  updateJsonStringAction,
  updateProductFilterAction,
  updateSelectedTemplateAction,
} from "../actions/jobActions";
import {
  duplicateGroupAction,
  updateSelectedGroupTemplatesAction,
} from "../actions/jobGroupActions";
import { resetJobPriorityStateAction } from "../actions/jobPriorityActions";
import type { JobTemplate } from "../models/JobTemplate";
import {
  updateBizchatBuiltInQuerySets,
  updateCustomizedLMChecklistSets,
  updateCustomizedQuerySets,
  updatePublicLMChecklistSets,
} from "../mutators/jobCreationFileMutators";
import {
  resetJobCreationStore,
  updateStatusIsLoading,
} from "../mutators/jobCreationMutators";
import {
  updateAvailableGroupTemplate,
  updateLoadingMode,
  updateToastStatus,
} from "../mutators/jobGroupCreationMutators";
import {
  resetJobStore,
  updateAvailableTemplates,
  updateJobName,
  updateProductFilter,
} from "../mutators/jobMutators";
import {
  updateProductJobTemplates,
  updateProductMetaData,
  updateProducts,
} from "../mutators/updateProductSettings";
import { getJobCreationErrorMessages } from "../selectors/creationError/getJobCreationError";
import { removeGeneratorTemplates } from "../selectors/getQuickFilterTemplates";
import {
  getBaseProductFromTemplateType,
  PRODUCT_ID_M365_CHAT,
} from "../selectors/productSelectors";
import { jobGroupCreationStore } from "../store/jobGroupCreationStore";
import { jobStore } from "../store/jobStore";
import { getDefaultTemplate } from "./jobCloneHelper";
import { refreshQuerySets } from "./jobCreationHelper";

const handleJobTemplates = async (
  state: unknown,
  jobTemplates: JobTemplate[],
) => {
  const templates = jobTemplates;

  updateAvailableTemplates(templates);

  if (isEditGroupJob.get()) {
    initAddOrEditJobData();
  } else {
    const defaultTemplate = await getDefaultTemplate(state);
    if (defaultTemplate) {
      updateSelectedTemplateAction(defaultTemplate);
    }
  }
};

export const initCreationPageStore = (state: unknown) => {
  updateStatusIsLoading(true);
  perfWrapper(
    "LoadCreateJobPage",
    Promise.all([
      getJobTemplates(),
      refreshQuerySets(),
      getCustomLMChecklistSets(),
      getBizchatBuiltInQuerySets(),
      getPublicLMChecklistSets(),
      listProducts(),
      getBingProductMetaData("Bing").catch(() => undefined),
      getBingProductMetaData("CWC").catch(() => undefined),
    ]).then(
      ([
        jobTemplates,
        customQS,
        customLMChecklist,
        bizchatBuiltInQS,
        publicLMChecklist,
        products,
        bingMetaData,
        cwcMetaData,
      ]) => {
        updateProducts(products);
        updateProductJobTemplates(removeGeneratorTemplates(jobTemplates));
        updateProductMetaData(
          parseJsonStrOptional(bingMetaData, BingMetaDataResponse),
          parseJsonStrOptional(cwcMetaData, BingMetaDataResponse),
        );
        updateCustomizedQuerySets(customQS);
        updateBizchatBuiltInQuerySets(bizchatBuiltInQS);
        updateCustomizedLMChecklistSets(customLMChecklist);
        updatePublicLMChecklistSets(publicLMChecklist);
        handleJobTemplates(state, jobTemplates);
        updateStatusIsLoading(false);
      },
    ),
  );
  return () => {
    resetJobStore();
    resetJobCreationStore();
    resetJobPriorityStateAction();
  };
};

export const updateInfoAndSendNotification = (
  jobId: number,
  jobName: string,
  jobDescription: string,
  name: string,
  address: string,
) => {
  const envType = getAppEnv().type;
  const jobLink =
    envType === "PROD"
      ? `https://seval.microsoft.com/detail/${jobId}`
      : `https://seval-staging.microsoft.com/detail/${jobId}`;
  const requiredEmailInfo: SendPriorityJobNotificationRequestData = {
    JobName: jobName,
    JobLink: jobLink,
    PriorityDescription: jobDescription,
    LeaderName: name,
    LeaderAddress: address,
  };
  sendPriorityJobNotification(requiredEmailInfo);
};

export const isCreateGroup = computed(() => {
  if (isFeatureEnabled("enable-job-group")) {
    return jobGroupCreationStore.creationMode !== undefined;
  }
  return false;
});

export const initJobGroupData = (groupId?: number) => {
  const groupTemplates = getGroupTemplates();
  const getGroup =
    groupId === undefined
      ? Promise.resolve(undefined)
      : findGroup({ GroupId: groupId });
  updateToastStatus({
    message: "Prepare group data...",
    state: "start",
  });
  Promise.all([groupTemplates, getGroup]).then(([templates, groupData]) => {
    updateAvailableGroupTemplate(templates);
    if (templates.length > 0) {
      if (groupData === undefined) {
        updateSelectedGroupTemplatesAction(templates[0]);
        updateToastStatus({
          message: "Prepare group data completed.",
          state: "success",
        });
      } else {
        duplicateGroupAction(groupData[0]);
      }
    } else {
      updateToastStatus(undefined);
    }
  });
  updateLoadingMode(false);
};

export const isEditGroupJob = computed(() => {
  return (
    jobGroupCreationStore.creationMode === "add" ||
    jobGroupCreationStore.creationMode === "edit"
  );
});

export const isEditRequiredJob = computed(() => {
  if (!isEditGroupJob.get()) {
    return false;
  }
  const currentJob = jobGroupCreationStore.jobs.find(
    (job) => job.localId === jobGroupCreationStore.currentLocalJobId,
  );
  return currentJob?.type === "required";
});

export const initAddOrEditJobData = () => {
  if (!isEditGroupJob.get()) {
    return;
  }
  const currentJob = jobGroupCreationStore.jobs.find(
    (job) => job.localId === jobGroupCreationStore.currentLocalJobId,
  );

  if (currentJob) {
    const currentTemplate = jobStore.availableTemplates.find(
      (item) => item.Name === currentJob.templateName,
    );
    if (currentTemplate) {
      updateSelectedTemplateAction(currentTemplate);
      const productId = getBaseProductFromTemplateType(currentTemplate.Type);
      updateProductFilter(productId);
    } else {
      updateProductFilterAction(PRODUCT_ID_M365_CHAT);
    }
    updateJobName(currentJob.jobName);
    if (currentJob.configuration !== undefined) {
      updateJsonStringAction(JSON.stringify(currentJob.configuration, null, 2));
    }
  }
};

export const getGroupNameError = computed(() => {
  return jobGroupCreationStore.groupName.length > 0
    ? undefined
    : "Group name is required";
});

export const isJobNameDuplicate = () => {
  const jobName = jobStore.jobName;
  const isDupName = jobGroupCreationStore.jobs.some(
    (item) =>
      item.jobName === jobName &&
      item.localId !== jobGroupCreationStore.currentLocalJobId,
  );

  if (isDupName) {
    throw new Error("Job name already exists");
  }
  return undefined;
};

export const getJobCompletionError = computed(() => {
  const undefinedJob = jobGroupCreationStore.jobs.find(
    (item) => item.configuration === undefined && item.type === "required",
  );
  if (undefinedJob) {
    return "Not all the required jobs are added yet.";
  }
  if (jobGroupCreationStore.selectedGroupTemplate?.Type === "Predefined") {
    const jobsData = new Array<CreateJobRouterRequestData>();
    jobGroupCreationStore.jobs.forEach((item) => {
      jobsData.push({
        ExperimentName: item.templateName,
        JobName: item.jobName,
        Settings: JSON.stringify(item.configuration),
      });
    });
    if (
      !isSubmittedJobQualified(
        jobsData,
        jobGroupCreationStore.selectedGroupTemplateSettings,
      )
    ) {
      return "Please prepare all required jobs.";
    }
    return undefined;
  }
  return jobGroupCreationStore.jobs.length > 0
    ? undefined
    : "At least one job is required";
});

export const getCurrentCreationError = computed(() => {
  const groupNameError = getGroupNameError.get();
  const jobsCompletionError = getJobCompletionError.get();
  return [groupNameError, jobsCompletionError].filter(
    (item) => item !== undefined,
  );
});

export const isReadyForSubmit = computed(() => {
  return getCurrentCreationError.get().length === 0;
});

export const isReadyForSaveJob = computed(() => {
  return getJobCreationErrorMessages.get().length === 0;
});
