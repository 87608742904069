import type {
  OptionOnSelectData,
  SelectionEvents,
} from "@fluentui/react-components";
import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  Combobox,
  Dropdown,
  Field,
  InfoLabel,
  makeStyles,
  Option,
  OptionGroup,
  shorthands,
} from "@fluentui/react-components";
import { Delete16Regular } from "@fluentui/react-icons";
import React, { useEffect } from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import {
  checkOnlineQuerySetContent,
  checkOnlineQuerySetWarning,
} from "../../../../../helpers/apiHelper";
import {
  getDefaultQuerySetTypeName,
  getQuerySetTypeInfoByName,
} from "../../../../../helpers/configConvertHelper";
import { store } from "../../../../../store/store";
import {
  updatePropValueActionV2,
  updateUnifiedExpConfigWithScenarioAction,
} from "../../../actions/jobActions";
import { updateCustomizedQuerySets } from "../../../mutators/jobCreationFileMutators";
import {
  getUnifiedBizChatJobQueryList,
  getUserId,
} from "../../../selectors/getJobPropV2";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { UploadingFileDialog } from "../../Dialog/UploadingFileDialog";
import {
  UN_SELECTED_FILE_NAME,
  unifiedMetaData,
} from "./metadata/unifiedMetaData";

type runType = "single_turn" | "multi_turn";

interface IQueryProps {
  metrics?: string[];
  options?: string[];
  scenario?: string;
  run_type?: string;
  input_file?: string;
  has_header?: boolean;
  querySet_type?: string;
}

const useStyles = makeStyles({
  deleteButton: {
    marginLeft: "10px",
  },
  testSet: {
    ...shorthands.borderRadius("8px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.padding("10px", "12px"),
  },
  columnWithSmallerGap: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  rowWithSmallGap: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    ...shorthands.gap("16px"),
    alignItems: "center",
  },
  listbox: {
    maxHeight: "300px",
  },
  metricsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    ...shorthands.gap("0px", "8px"),
  },
  checkbox: {
    accentColor: "#773CFC",
    maxWidth: "fit-content",
    minWidth: "25%",
    '> input[type="checkbox"]:checked': {
      backgroundColor: "red",
      color: "red",
    },
  },
});
interface TestSetAccordionItemProps {
  index: number;
  testSet: string;
  value: object;
}

const getFileName = (option: string) => {
  const regex = /^(.+)\/([^/]+)$/;
  const match = option.match(regex);
  if (match) {
    const fileName = match[2].replaceAll(".tsv", "");
    return fileName;
  }
  return "";
};

const OptionsBlock: React.FC<{
  options: string[];
  query: IQueryProps;
  onUpdateQuery: (index: number, value: object) => void;
  index: number;
}> = ({ query, onUpdateQuery, index, options }) => {
  if (!query) return null;

  return (
    <Dropdown
      aria-label="Drop down for the options"
      multiselect
      value={query.options?.join(",") ?? ""}
      selectedOptions={query.options ?? []}
      onOptionSelect={(
        _: SelectionEvents,
        data: { selectedOptions?: string[] },
      ) => {
        if (!data.selectedOptions || data.selectedOptions.length === 0) {
          onUpdateQuery(index, {
            ...query,
            options: [],
          });
        } else {
          onUpdateQuery(index, {
            ...query,
            options: data.selectedOptions,
          });
        }
      }}
    >
      {options.map((option) => (
        <Option key={option}>{option}</Option>
      ))}
    </Dropdown>
  );
};

const MetricsBlock: React.FC<{
  metrics: readonly string[];
  query: IQueryProps;
  onUpdateQuery: (index: number, value: object) => void;
  index: number;
}> = ({ metrics, query, onUpdateQuery, index }) => {
  const styles = useStyles();
  if (!metrics || !query) return null;
  return (
    <div className={styles.metricsContainer}>
      {metrics.map((key) => (
        <Checkbox
          className={styles.checkbox}
          key={key}
          checked={query.metrics?.includes(key) ?? false}
          onChange={(event) => {
            const checked = event.target.checked;
            const currentValue = query.metrics ?? [];
            const newMetrics = checked
              ? [...currentValue, key]
              : currentValue.filter((value: string) => value !== key);

            onUpdateQuery(index, {
              ...query,
              metrics: newMetrics,
            });
          }}
          label={key}
        />
      ))}
    </div>
  );
};

export const TestSetAccordionItem: React.FC<TestSetAccordionItemProps> = ({
  index,
  testSet,
  value,
}) => {
  const styles = useStyles();
  const toast = useToast();
  const [isUploadOpen, setIsUploadOpen] = React.useState(false);
  const [validationStatus, setValidateStatus] = React.useState<
    "none" | "error" | "warning" | "success" | "validating" | undefined
  >(undefined);
  const [validationError, setValidationError] = React.useState<
    string | undefined
  >();

  const convergeQuerySets = getUnifiedBizChatJobQueryList.get();
  const personalTestSet = jobCreationFileStore.customQuerySets;
  const bulitInTestSet = jobCreationFileStore.bizchatBuiltInQuerySets;
  const query = convergeQuerySets?.[index] as IQueryProps;

  const isBuiltInTestSet = bulitInTestSet?.includes(query?.input_file || "");
  const supportQuerySetTypes = isBuiltInTestSet
    ? unifiedMetaData.querySetTypes.filter((scenario) =>
        query?.input_file?.includes(scenario.name),
      )
    : unifiedMetaData.querySetTypes;

  const getMetricsList = (scenario: string, runType: string) => {
    return (
      supportQuerySetTypes
        .find((item) => item.name === scenario)
        ?.metrics?.[
          runType as "single_turn" | "multi_turn"
        ].map((i) => i.name) || []
    );
  };

  const handleFileValidation = (fileName: string, run_type?: string) => {
    if (fileName.startsWith("data/")) {
      return;
    }
    if (fileName === UN_SELECTED_FILE_NAME) {
      return;
    }
    setValidateStatus("validating");
    setValidationError(undefined);

    const userId = getUserId.get();
    checkOnlineQuerySetWarning({
      FileName: fileName,
      UserId: userId ?? "Unknown",
    }).catch((error) => {
      toast.onToastFailure(`Validation failed: ${error.message}`);
    });

    checkOnlineQuerySetContent({
      FileName: fileName,
      UserId: userId,
      TemplateName:
        (jobStore.selectedTemplate?.Name || "") +
        (run_type === "multi_turn" ? "_Multi_Turn" : ""),
    })
      .then(() => {
        setValidateStatus("success");
      })
      .catch((error) => {
        setValidateStatus("error");
        setValidationError(error.message);
      });
  };

  useEffect(() => {
    handleFileValidation(query?.input_file || "", query?.run_type);
  }, [query?.input_file, query?.run_type]);

  const getValidationState = ():
    | "error"
    | "warning"
    | "success"
    | undefined => {
    if (validationStatus === "validating") {
      return "warning";
    } else if (validationStatus === "success") {
      return "success";
    } else if (validationStatus === "error") {
      return "error";
    }
    return undefined;
  };

  const getValidationMessage = () => {
    if (validationStatus === "validating") {
      return "Checking header...";
    } else if (validationStatus === "success") {
      return "QuerySet Validation Success.";
    } else if (validationStatus === "error") {
      return validationError;
    }
    return undefined;
  };

  const updateQueryValue = (queryIndex: number, queryValue: object) => {
    const updatedQueries = [...(convergeQuerySets || [])];
    updatedQueries[queryIndex] = {
      ...updatedQueries[queryIndex],
      ...queryValue,
    };
    updatePropValueActionV2({
      prop: "queries.query_sets",
      newData: updatedQueries,
    });
  };

  const deleteQuery = (queryIndex: number) => {
    const updatedQueries = [...(convergeQuerySets || [])];
    updatedQueries.splice(queryIndex, 1);
    updatePropValueActionV2({
      prop: "queries.query_sets",
      newData: updatedQueries,
    });
  };

  const renderQuerySetsOptions = (querySets: string[]) => {
    return querySets.map((queryName: string) => {
      const fileName = getFileName(queryName);
      return (
        <Option
          key={fileName}
          disabled={
            (convergeQuerySets || []).findIndex(
              (set) => set.input_file === queryName,
            ) >= 0
          }
          value={queryName}
        >
          {queryName}
        </Option>
      );
    });
  };

  const geQuerySetRelatedInfo = (
    querySetType: string,
    runType: runType = (query.run_type as runType) || "single_turn",
  ) => {
    const querySetInfo = getQuerySetTypeInfoByName(
      unifiedMetaData,
      querySetType || "",
    );
    return {
      scenario: querySetInfo?.scenario || "bizchat",
      metrics: querySetInfo?.metrics?.[runType]
        .filter((metric) => metric.default)
        ?.map((metric) => metric.name),
      options: querySetInfo?.options?.[runType] || [],
    };
  };

  const onSelectedTestSet = (_: SelectionEvents, data: OptionOnSelectData) => {
    const selectedOption = data.optionValue;
    if (selectedOption) {
      const isSelectedBuilt = bulitInTestSet?.includes(selectedOption);
      const querySetType = isSelectedBuilt
        ? unifiedMetaData.querySetTypes
            .filter((scenario) => selectedOption?.includes(scenario.name))
            .map((scenario) => scenario.name)[0]
        : getDefaultQuerySetTypeName(unifiedMetaData);
      const querySetRelatedInfo = geQuerySetRelatedInfo(querySetType);
      updateQueryValue(index, {
        ...value,
        input_file: selectedOption,
        querySet_type: querySetType,
        ...querySetRelatedInfo,
      });
      updateUnifiedExpConfigWithScenarioAction();
    }
  };

  const onSelectedQuerySetType = (querySetType?: string) => {
    if (querySetType) {
      const querySetRelatedInfo = geQuerySetRelatedInfo(querySetType);
      updateQueryValue(index, {
        ...value,
        querySet_type: querySetType,
        ...querySetRelatedInfo,
      });
      updateUnifiedExpConfigWithScenarioAction();
    }
  };

  if (!query) return null;

  const showBuiltIn = bulitInTestSet?.length > 0;

  return (
    <AccordionItem value={index}>
      <AccordionHeader>
        {testSet}
        <Delete16Regular
          aria-label="Delete current test set."
          className={styles.deleteButton}
          onClick={() => deleteQuery(index)}
        />
      </AccordionHeader>
      <AccordionPanel>
        <div className={styles.testSet}>
          <div className={styles.columnWithSmallerGap}>
            <div className={styles.block}>
              <InfoLabel info={"Select a test set from available list"}>
                File Name
              </InfoLabel>
              <div className={styles.block}>
                <div className={styles.rowWithSmallGap}>
                  <Combobox
                    key={testSet}
                    aria-label="The selected file name for the test set."
                    style={{ flex: 1 }}
                    listbox={{ className: styles.listbox }}
                    value={testSet}
                    onOptionSelect={onSelectedTestSet}
                  >
                    {showBuiltIn && (
                      <OptionGroup label="Built-in">
                        {renderQuerySetsOptions(bulitInTestSet || [])}
                      </OptionGroup>
                    )}
                    <OptionGroup label={store.account?.username}>
                      {renderQuerySetsOptions(personalTestSet || [])}
                    </OptionGroup>
                  </Combobox>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      setIsUploadOpen(true);
                    }}
                  >
                    Upload Test Set
                  </Button>
                </div>
                <UploadingFileDialog
                  type="Query"
                  isOpen={isUploadOpen}
                  onCancel={() => setIsUploadOpen(false)}
                  onStart={() => {
                    toast.onToastStart("Uploading file...");
                  }}
                  onSuccess={(fileName) => {
                    updateCustomizedQuerySets([
                      ...(personalTestSet || []),
                      fileName,
                    ]);
                    updateQueryValue(index, {
                      ...value,
                      input_file: fileName,
                    });
                    toast.onToastSuccess("File uploaded successfully");
                    setIsUploadOpen(false);
                  }}
                  onFailure={(error) => {
                    toast.onToastFailure(
                      `File upload failed with message: ${error.message}`,
                    );
                    setIsUploadOpen(false);
                  }}
                />
                <Field
                  validationState={getValidationState()}
                  validationMessage={getValidationMessage()}
                />
              </div>
              <div className={styles.rowWithSmallGap}>
                <Field>
                  <InfoLabel>QuerySet Type</InfoLabel>
                  <Combobox
                    aria-label="QuerySet Type"
                    value={query.querySet_type}
                    selectedOptions={[query.querySet_type || ""]}
                    onOptionSelect={(_, data) => {
                      onSelectedQuerySetType(data.optionValue);
                    }}
                  >
                    {supportQuerySetTypes.map((scenario) => (
                      <Option key={scenario.name} value={scenario.name}>
                        {scenario.name}
                      </Option>
                    ))}
                  </Combobox>
                </Field>
                <Field>
                  <InfoLabel>Run Type</InfoLabel>
                  <Combobox
                    aria-label="Run type selection"
                    value={query.run_type}
                    onOptionSelect={(_, data) => {
                      if (data.optionValue) {
                        updateQueryValue(index, {
                          ...value,
                          run_type: data.optionValue,
                          metrics: [], // Reset metrics when run type changes
                        });
                      }
                    }}
                  >
                    <Option value="single_turn">Single Turn</Option>
                    <Option value="multi_turn" disabled>
                      Multi Turn
                    </Option>
                  </Combobox>
                </Field>
              </div>
            </div>
            <div className={styles.block}>
              <InfoLabel
                info={"Specify the metrics to be evaluated in this test set"}
              >
                Metrics
              </InfoLabel>
              <MetricsBlock
                metrics={getMetricsList(
                  query.querySet_type || "general_purpose",
                  query.run_type || "single_turn",
                )}
                query={query}
                onUpdateQuery={updateQueryValue}
                index={index}
              />
            </div>
            <div className={styles.block}>
              <InfoLabel info={"Input valid options, separate by ','"}>
                Options
              </InfoLabel>
              <OptionsBlock
                options={unifiedMetaData.commonOptions.sort()}
                query={query}
                onUpdateQuery={updateQueryValue}
                index={index}
              />
            </div>
          </div>
        </div>
      </AccordionPanel>
    </AccordionItem>
  );
};
