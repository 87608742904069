import { observer } from "mobx-react";
import React from "react";
import { JobSettingsDialog } from "../../../../../components/Dialog/JobSettingsDialog";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { removeJobFromGroup } from "../../../../../helpers/apiHelper";
import { isFeatureEnabled } from "../../../../../selectors/features";
import {
  refreshGroupJobListAction,
  switchSelectedJobAction,
} from "../../../actions/groupDetailActions";
import { getJobSettings } from "../../../helpers/groupListHelper";
import type { Job } from "../../../models/Job";
import { updateGroupJobs } from "../../../mutators/groupDetailMutators";
import {
  canRemoveJobFromGroup,
  notGroupOwner,
} from "../../../selectors/groupJob";
import { groupDetailStore } from "../../../store/groupDetailStore";
import { GeneralTable } from "../../JobList/GeneralTable";
import { JobActionsCell } from "../../JobList/JobCell/JobActionsCell";
import { JobCreateDateCell } from "../../JobList/JobCell/JobCreateDateCell";
import { JobCreatorCell } from "../../JobList/JobCell/JobCreatorCell";
import { JobMetricsCell } from "../../JobList/JobCell/JobMetricsCell";
import { JobNameCell } from "../../JobList/JobCell/JobNameCell";
import { JobStatusCell } from "../../JobList/JobCell/JobStatusCell";

export const JobInforPane = observer(() => {
  const displayJobs = groupDetailStore.jobs;
  const [currentJob, setCurrentJob] = React.useState<Job | undefined>(
    undefined,
  );
  const emptyFunc = () => void 0;

  const toast = useToast();
  const onRemoveFromGroup = (job: Job) => {
    const groupID = groupDetailStore.group?.ID;
    toast.onToastStart("Removing job from group...");
    if (groupID === undefined) {
      toast.onToastFailure("Group ID is undefined");
      return;
    }
    removeJobFromGroup({
      GroupId: groupID,
      JobIds: [job.ID],
    }).then(() => {
      toast.onToastSuccess("Job removed from group");
      const newJobList = groupDetailStore.jobs.filter((_) => _.ID !== job.ID);
      updateGroupJobs(newJobList);
      switchSelectedJobAction(newJobList[0]);
    });
  };

  const isJobGroupStage2 = isFeatureEnabled("job-group-stage-2");

  const showRemove =
    isJobGroupStage2 && canRemoveJobFromGroup.get() && !notGroupOwner.get();
  const renderJobList = () => (
    <GeneralTable
      key={displayJobs.length}
      loadMore={emptyFunc}
      hasMore={false}
      rows={displayJobs}
      columns={[
        {
          title: "Name",
          render: (job) => (
            <>
              <JobNameCell job={job} />
            </>
          ),
          weight: 3,
          minWidth: "400px",
        },
        {
          title: "",
          render: (job) => (
            <JobActionsCell
              item={job}
              jobTemplates={[]}
              onJobSettingsClick={() => {
                setCurrentJob(job);
                getJobSettings(job).then((settings) => {
                  setCurrentJob({ ...job, Settings: settings });
                });
              }}
              scenario="list"
              onRemoveFromGroup={showRemove ? onRemoveFromGroup : undefined}
              onRefreshJobList={() => refreshGroupJobListAction()}
            />
          ),
          weight: 0,
          minWidth: "50px",
        },
        {
          title: "Created By",
          render: (job) => <JobCreatorCell job={job} />,
          weight: 1,
          minWidth: "150px",
        },
        {
          title: "Date",
          render: (job) => <JobCreateDateCell job={job} />,
          weight: 1,
          minWidth: "250px",
        },
        {
          title: "Status",
          render: (job) => <JobStatusCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
        {
          title: "Metrics",
          render: (job) => <JobMetricsCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
      ]}
    />
  );

  return (
    <>
      {renderJobList()}

      <JobSettingsDialog
        isOpen={currentJob !== undefined}
        jobName={currentJob?.JobName ?? "Unknown Job"}
        settings={currentJob?.Settings ?? ""}
        dataSets={currentJob?.DataSets ?? ""}
        lastSelectedTab={"settings"}
        onClose={() => setCurrentJob(undefined)}
      />
    </>
  );
});
