import { action } from "satcheljs";
import type { Job } from "../models/Job";

export const loadGroupDetailAction = action(
  "loadGroupDetailAction",
  (groupId: number) => ({ groupId }),
);

export const refreshGroupJobListAction = action(
  "refreshGroupJobListAction",
  () => ({}),
);

export const switchSelectedJobAction = action(
  "switchSelectedJobAction",
  (job: Job | undefined) => ({ job }),
);
