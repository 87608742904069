import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  Field,
  Spinner,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  CheckmarkCircle20Filled,
  Dismiss24Regular,
  LinkSquare24Filled,
  LinkSquare24Regular,
  PersonFeedback24Filled,
  PersonFeedback24Regular,
  Share24Filled,
  Share24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react";
import React from "react";
import { Tip } from "../../../../../components/Shared/Tip";
import { getUserAlias } from "../../../../../helpers/accountHelper";
import { uploadQuerySet } from "../../../../../helpers/apiHelper";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { store } from "../../../../../store/store";
import {
  batchInviteUsersAction,
  inviteUsersAction,
  openJobShareDialog,
  removeUsersAction,
} from "../../../actions/jobShareActions";
import {
  setIsOpenShareDialog,
  updateCurrentShareJob,
  updateIsCopyShareLink,
  updateIsGroupSharedNotificationShow,
} from "../../../mutators/jobShareMutators";
import {
  checkHasSharePermission,
  getCurrentScenario,
  getIsGroupShareDialog,
  getIsRestrictedSharing,
  getShareLink,
  getSharedJobName,
  getSharedRecordId,
} from "../../../selectors/getJobShareInfo";
import { jobShareStore } from "../../../store/jobShareStore";
import { SevalUserSelector } from "./SevalUserSelector";

const ShareIcon = bundleIcon(Share24Filled, Share24Regular);
const LinkIcon = bundleIcon(LinkSquare24Filled, LinkSquare24Regular);
const FeedbackIcon = bundleIcon(
  PersonFeedback24Filled,
  PersonFeedback24Regular,
);

const useStyles = makeStyles({
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonsRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end", // This aligns items to the right
  },
  columnWithBigGap: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("16px"),
  },
  columnWithNoGap: {
    display: "flex",
    flexDirection: "column",
  },
  permittedListContainer: {
    maxHeight: "800px",
    ...shorthands.overflow("auto"),
    ...shorthands.margin("5px", "0px"),
  },
  selectorWidth: {
    width: "90%",
    maxHeight: "300px",
    overflowY: "auto",
  },
  ownerText: {
    fontSize: "11px",
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 500,
    color: tokens.colorNeutralForeground3,
  },
  sharedUserListText: {
    fontSize: "12px",
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    color: "black",
  },
  expalanationText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    fontWeight: 400,
    color: "black",
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "black",
    fontFamily: tokens.fontFamilyBase,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
  },
  popupContainer: {
    width: "500px",
  },
  groupShareContainer: {
    width: "560px",
  },
  divider: {
    color: "#EDEBE9",
    flexShrink: 10,
  },
  icon: {
    lineHeight: "20px",
    color: "white",
    height: "20px",
    width: "20px",
  },
  button: {
    marginRight: "8px",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
  },
  bodyContent: {
    display: "flex",
    flexDirection: "column",
    maiginTop: "16px",
    marginBottom: "40px",
    whiteSpace: "pre-line",
  },
});

export const ShareButton = observer(() => {
  const RecordId = getSharedRecordId.get();
  const hasShareButton = checkHasSharePermission.get();
  const isJobGroupShareEnabled = isFeatureEnabled("job-group-share-enabled");
  const isGroupShareButton =
    isJobGroupShareEnabled && getCurrentScenario() === "group";
  const isGroupShareDialog = isJobGroupShareEnabled && getIsGroupShareDialog();

  const jobName = getSharedJobName.get() ?? "Unknown";
  const styles = useStyles();
  const sharedUrl = getShareLink.get() ?? "";
  const isRestrictedSharing = getIsRestrictedSharing.get();
  const complianceString = `You cannot share the evaluation result per compliance requirement, \
     please raise feedback if you confirm it's over filtering, currently the policy is applied \
     to \n 1) Employee of L68 or higher; \n 2) Employee from HR, Business Development, CELA, \
     Finance, Sales organizations; \n 3) Vendor users.`;
  const groupShareExplanationString = `Group sharing will batch grant the invited users access to each sub-jobs in this group. \
      If you need to remove a granted user, please operate it on each job individually.`;

  const onCopyLink = React.useCallback(() => {
    navigator.clipboard.writeText(sharedUrl).then(() => {
      updateIsCopyShareLink(true);
    });
  }, [sharedUrl]);

  const timeStamp = new Date().toISOString().replace(/:/g, "-");

  const generateFeedbackFileName = () => {
    return `${RecordId}_${getUserAlias()}_${timeStamp}.json`;
  };

  const createFeedbackFile = () => {
    const feedback = {
      userAlias: getUserAlias(),
      RecordId: RecordId,
      profileInfo: jobShareStore.userProfile,
      timeStamp: new Date().toISOString(),
    };
    const createdFile = new File(
      [JSON.stringify(feedback, null, 2)],
      generateFeedbackFileName(),
      {
        type: "text/plain",
      },
    );
    return createdFile;
  };

  const renderUserSelector = () => {
    return (
      <Field
        validationState={
          jobShareStore.invalidErrorMessage !== undefined ? "error" : "none"
        }
        validationMessage={
          jobShareStore.invalidErrorMessage !== undefined
            ? jobShareStore.invalidErrorMessage
            : "Please input single email or emails split by comma or semicolon."
        }
      >
        <div className={styles.row} style={{ gap: "8px" }}>
          <div className={styles.selectorWidth}>
            <SevalUserSelector />
          </div>
          <Button
            disabled={
              jobShareStore.selectUser.length === 0 ||
              jobShareStore.invalidErrorMessage !== undefined
            }
            onClick={() => {
              if (jobShareStore.selectUser.length === 0) {
                return;
              }
              if (isGroupShareDialog) {
                jobShareStore.isLoading = true;
                batchInviteUsersAction();
                updateIsGroupSharedNotificationShow(true);
              } else {
                inviteUsersAction();
              }
            }}
          >
            Invite
          </Button>
        </div>
      </Field>
    );
  };

  const buildPermittedUsers = () => {
    if (isGroupShareDialog) {
      return <></>;
    }
    const userList = jobShareStore.permissionList;
    if (userList.length === 0) {
      return (
        <div>
          <div
            className={styles.row}
            style={{ justifyContent: "space-between" }}
          >
            <div>
              {store.account?.name}
              <span className={styles.ownerText}>{" (You)"}</span>
            </div>
            <div>Owner</div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={styles.row} style={{ justifyContent: "space-between" }}>
          <div>
            {store.account?.name}
            <span className={styles.ownerText}>{" (You)"}</span>
          </div>
          <div>Owner</div>
        </div>
        <div
          className={mergeClasses(
            styles.columnWithNoGap,
            styles.permittedListContainer,
          )}
          style={{
            height: Math.min(32 * 5, 32 * userList.length),
            overflow: "auto",
          }}
        >
          {userList.map((user) => {
            return (
              <div
                key={user.ObjectId}
                className={styles.row}
                style={{ justifyContent: "space-between" }}
              >
                {`${user.DisplayName}<${user.PrimarySmtpAddress}>`}
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {
                    removeUsersAction(user.ObjectId);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTriggerButton = () => {
    if (!hasShareButton) {
      return <></>;
    }
    return (
      <DialogTrigger disableButtonEnhancement>
        <Tip
          content={isGroupShareButton ? "Share Group" : "Share Job"}
          relationship={"label"}
        >
          <Button
            aria-label="open share dialog"
            title={"Share"}
            icon={<ShareIcon />}
            onClick={() => {
              if (isGroupShareButton) {
                updateCurrentShareJob(undefined);
              }
              openJobShareDialog();
              setIsOpenShareDialog(true);
            }}
          >
            Share
          </Button>
        </Tip>
      </DialogTrigger>
    );
  };
  return (
    <Dialog
      open={jobShareStore.isDialogOpen}
      onOpenChange={(_, data) => {
        setIsOpenShareDialog(data.open);
        if (!data.open) {
          updateIsCopyShareLink(false);
        }
      }}
    >
      {renderTriggerButton()}
      <DialogSurface
        className={
          isGroupShareDialog && !jobShareStore.isGroupSharedNotificationShow
            ? styles.groupShareContainer
            : styles.popupContainer
        }
      >
        {jobShareStore.isLoading ? <Spinner /> : null}
        {isRestrictedSharing &&
          !jobShareStore.isLoading &&
          !jobShareStore.isGroupSharedNotificationShow && (
            <DialogBody>
              <DialogTitle>{`Sharing is not allowed`}</DialogTitle>
              <DialogContent>
                <Body1 className={styles.bodyContent}>{complianceString}</Body1>
              </DialogContent>
              <DialogActions>
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.button}
                    appearance="primary"
                    icon={<FeedbackIcon className={styles.icon} />}
                    onClick={() => {
                      window.open("https://aka.ms/sevalbug", "_blank");
                      setIsOpenShareDialog(false);
                      uploadQuerySet({
                        File: createFeedbackFile(),
                        FileName:
                          "userFilterFeedback/" + generateFeedbackFileName(),
                        FileType: "MChat",
                      });
                    }}
                  >
                    Provide feedback
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpenShareDialog(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </DialogActions>
            </DialogBody>
          )}
        {!isRestrictedSharing &&
          !jobShareStore.isLoading &&
          !jobShareStore.isGroupSharedNotificationShow && (
            <>
              <div
                className={styles.row}
                style={{ justifyContent: "space-between" }}
              >
                <div className={styles.title}>
                  {isGroupShareDialog
                    ? "Batch share jobs in current group"
                    : "Share " + jobName}
                </div>
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                  />
                </DialogTrigger>
              </div>
              <DialogBody>
                <DialogContent>
                  <div className={styles.columnWithBigGap}>
                    {isGroupShareDialog && (
                      <div className={styles.expalanationText}>
                        {groupShareExplanationString}
                      </div>
                    )}
                    {renderUserSelector()}
                    <Divider className={styles.divider} />
                    {buildPermittedUsers()}
                    {!isGroupShareDialog &&
                      Object.keys(jobShareStore.permissionList).length !==
                        0 && <Divider className={styles.divider} />}
                    <div className={styles.buttonsRow}>
                      {RecordId !== undefined && (
                        <div className={styles.row}>
                          <Button
                            data-testid="copy-link-button"
                            appearance="subtle"
                            size={"medium"}
                            onClick={onCopyLink}
                            icon={<LinkIcon />}
                          >
                            Copy Link
                          </Button>
                          {jobShareStore.isCopyLink && (
                            <CheckmarkCircle20Filled primaryFill={"#26a03b"} />
                          )}
                        </div>
                      )}
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          data-testid="share-cancel-button"
                        >
                          Cancel
                        </Button>
                      </DialogTrigger>
                    </div>
                  </div>
                </DialogContent>
              </DialogBody>
            </>
          )}
        {!jobShareStore.isLoading &&
          jobShareStore.isGroupSharedNotificationShow && (
            <>
              <div
                className={styles.row}
                style={{ justifyContent: "space-between" }}
              >
                <div className={styles.row} style={{ gap: "8px" }}>
                  <CheckmarkCircle20Filled primaryFill={"#26a03b"} />
                  <div className={styles.title}>Jobs shared</div>
                </div>
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                  />
                </DialogTrigger>
              </div>
              <DialogBody>
                <DialogContent>
                  <div className={styles.columnWithNoGap}>
                    <div className={styles.expalanationText}>
                      Jobs in current group have been shared with following
                      users:
                    </div>
                    <div className={styles.sharedUserListText}>
                      {jobShareStore.permissionList
                        .map((user) => {
                          return user.DisplayName;
                        })
                        .join(", ")}
                    </div>
                  </div>
                </DialogContent>
              </DialogBody>
            </>
          )}
      </DialogSurface>
    </Dialog>
  );
});
