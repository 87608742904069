"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJobSupportShare = exports.isJobUsingTestTenant = exports.isCurrentUserScrapingOwner = void 0;
const JobConfiguration_1 = require("../models/JobConfiguration");
const parseHelper_1 = require("./parseHelper");
const templateTypeHelper_1 = require("./templateTypeHelper");
const userId = (settings) => {
    const settingObject = (0, parseHelper_1.parseJsonStrOptional)(settings, JobConfiguration_1.JobConfiguration);
    return settingObject?.configs_in_json?.conversations?.user_id;
};
const isCurrentUserScrapingOwner = (jobConfig, email) => {
    const userIdInConfig = userId(jobConfig);
    return userIdInConfig === email;
};
exports.isCurrentUserScrapingOwner = isCurrentUserScrapingOwner;
const isJobUsingTestTenant = (jobConfig) => {
    const userIdInConfig = userId(jobConfig);
    const isTestTenant = userIdInConfig
        ? !userIdInConfig?.endsWith("@microsoft.com")
        : false;
    return isTestTenant;
};
exports.isJobUsingTestTenant = isJobUsingTestTenant;
const isJobSupportShare = (jobConfig, JobTemplateType, JobType, ExperimentName) => {
    const IsBingJob = JobTemplateType === "BingV2" ||
        JobTemplateType === "BingGenerator" ||
        JobTemplateType === "CWC";
    const IsScheduledJob = JobType === "Scheduled";
    const IsOPGPRValidationJob = JobTemplateType === "OPG" && !(0, templateTypeHelper_1.isMultiServiceTemplate)(ExperimentName);
    const IsRAIJob = JobTemplateType === "RAI";
    const IsScrapingJob = JobTemplateType === "ScrapingOnly";
    // Bing job, sheduled job and OPG job, RAI job do not support share
    if (IsBingJob ||
        IsScheduledJob ||
        IsOPGPRValidationJob ||
        IsRAIJob ||
        IsScrapingJob) {
        return false;
    }
    // Test tenant job do not support share
    if ((0, exports.isJobUsingTestTenant)(jobConfig)) {
        return false;
    }
    return true;
};
exports.isJobSupportShare = isJobSupportShare;
