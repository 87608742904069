import { array, num, object, optional, str } from "@seval-portal/shared";

export const QuerySetManagement = object({
  ID: num,
  Name: str,
  Description: optional(str),
  CreatedTime: str,
  CreatedBy: str,
  Status: str,
  Env: num,
  UpdatedTime: optional(str),
  UpdatedBy: optional(str),
});

export const QuerySetVersionManagement = object({
  ID: num,
  QuerySet: str,
  Version: num,
  CreatedTime: str,
  CreatedBy: str,
  Status: str,
  BlobPath: str,
  SevalJobId: optional(str),
});

export const blobPathMeta = object({
  id: optional(str),
  datastore: optional(str),
  exp_name: optional(str),
  path: optional(str),
});

export type QuerySetManagement = ReturnType<typeof QuerySetManagement>;
export const QuerySetManagementResponse = object({
  results: array(QuerySetManagement),
  totalCount: num,
});
export type QuerySetManagementResponse = ReturnType<
  typeof QuerySetManagementResponse
>;

export type QuerySetVersionManagement = ReturnType<
  typeof QuerySetVersionManagement
>;
export const QuerySetVersionManagementResponse = object({
  results: array(QuerySetVersionManagement),
  totalCount: num,
});
export type QuerySetVersionManagementResponse = ReturnType<
  typeof QuerySetVersionManagementResponse
>;
