import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
} from "@fluentui/react-components";
import {
  Info16Regular,
  MoreHorizontal16Filled,
  MoreHorizontal16Regular,
  SettingsFilled,
  SettingsRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { registeredSettingsContribution } from "../../contribution/register";
import { getAppVersion } from "../../helpers/appEnvHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { openSidePane } from "../../mutators/sidePaneMutators";
import { updateSelectedContributionDialogId } from "../../mutators/updateContributions";
import {
  isDogfooder,
  isJobPriorityContributor,
} from "../../selectors/userPermission";
import { store } from "../../store/store";
import { DevCenter } from "../DevCenter/DevCenter";
import { JobPriorityPane } from "../Other/JobPriorityPane";

const useStyle = makeStyles({
  container: {
    marginRight: "20px",
  },
  icon: {
    lineHeight: "20px",
    color: "white",
    height: "20px",
    width: "20px",
  },
  title: {
    marginTop: "30px",
  },
});

const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
const MoreIcon = bundleIcon(MoreHorizontal16Filled, MoreHorizontal16Regular);

export const AppBarSettings = observer(() => {
  const styles = useStyle();

  return (
    <>
      {store.account && (
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button
              aria-label="Settings Menu Trigger"
              className={styles.container}
              appearance="transparent"
              icon={<MoreIcon className={styles.icon} />}
              onClick={() => {
                telemetryHelper.logUserActionEvent("ClickSettingsButton");
              }}
            />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {registeredSettingsContribution
                .filter((_) => _.featureContribution.isEnabled())
                .map((_) => {
                  if (_.isEnabled !== undefined && !_.isEnabled()) {
                    return undefined;
                  }

                  switch (_.type) {
                    case "click":
                      return (
                        <MenuItem
                          key={_.guid}
                          onClick={() => {
                            _.onClick();
                            telemetryHelper.logUserActionEvent(
                              "ClickContributionSetting",
                              {
                                text: _.text(),
                                type: _.type,
                              },
                            );
                          }}
                          icon={_.icon()}
                        >
                          {_.text()}
                        </MenuItem>
                      );

                    case "dialog":
                      return (
                        <MenuItem
                          key={_.guid}
                          onClick={() => {
                            updateSelectedContributionDialogId(_.guid);
                            telemetryHelper.logUserActionEvent(
                              "ClickContributionSetting",
                              {
                                text: _.text(),
                                type: _.type,
                              },
                            );
                          }}
                          icon={_.icon()}
                        >
                          {_.text()}
                        </MenuItem>
                      );
                  }
                })}
              {isJobPriorityContributor.get() && (
                <MenuItem
                  onClick={() => {
                    openSidePane(() => <JobPriorityPane />, "narrow");
                  }}
                  icon={<SettingsIcon />}
                >
                  Job Priority Setting
                </MenuItem>
              )}
              {isDogfooder.get() && (
                <MenuItem
                  onClick={() => {
                    openSidePane(() => <DevCenter />, "narrow");
                    telemetryHelper.logDevEvent("OpenDevCenter");
                  }}
                  icon={<SettingsIcon />}
                >
                  Open Dev Center
                </MenuItem>
              )}
              <MenuDivider />
              <MenuItem icon={<Info16Regular />} disabled={true}>
                Version {getAppVersion()}
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </>
  );
});
