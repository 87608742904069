import { observer } from "mobx-react-lite";
import { Counter } from "../components/Counter";

export const ResultPage = observer(() => {
  return (
    <div>
      <h1>Job Result</h1>
      <p>Your job has been successfully created.</p>
      <Counter />
    </div>
  );
});
