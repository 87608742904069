import { object, parseJsonStrOptional, str } from "@seval-portal/shared";
import { computed } from "mobx";
import { getAzureMLFileContent } from "../../../helpers/apiHelper";
import { Job } from "../models/Job";
import { jobListStore } from "../store/jobListStore";

export const displayGroupList = computed((): Job[] => {
  const displayJobs: Job[] = new Array<Job>();
  jobListStore.groups.forEach((group) => {
    displayJobs.push({
      ID: group.ID,
      JobName: `[Group Job]${group.Name}`,
      CreatedBy: group.CreatedBy,
      CreatedAt: group.CreatedAt,
    });
    if (jobListStore.groupExpandIndex.includes(group.ID)) {
      if (group.Jobs) {
        for (const job of group.Jobs) {
          const subJobs = Job(job, "Job");
          displayJobs.push(subJobs);
        }
      }
    }
  });
  return displayJobs;
});

const CWCJobConfig = object({
  config_file: object({
    type: str,
    path: str,
  }),
});

export const getJobSettings = (job: Job) => {
  const relatedTemplate = jobListStore.jobTemplates.find(
    (template) => template.Name === job.ExperimentName,
  );
  if (
    (relatedTemplate?.Type === "BingV2" || relatedTemplate?.Type === "CWC") &&
    job.Settings !== undefined
  ) {
    const settingObj = parseJsonStrOptional(job.Settings, CWCJobConfig);
    if (settingObj) {
      // Fetching json from storage
      const path = settingObj.config_file.path;
      return getAzureMLFileContent(path)
        .then((json) => {
          return json;
        })
        .catch(() => {
          return job.Settings;
        });
    }
  }
  return Promise.resolve(job.Settings);
};
