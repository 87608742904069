import { createStore } from "satcheljs";
import type { JobGroupData } from "@seval-portal/shared";
import type { Job } from "../models/Job";
import type { JobTemplate } from "../models/JobTemplate";

interface IJobListStore {
  mode: "job" | "group";
  jobTemplates: JobTemplate[];
  jobs: Job[];
  groups: JobGroupData[];
  groupExpandIndex: number[];
}

export const initJobListStore: IJobListStore = {
  mode: "job",
  jobTemplates: [],
  jobs: [],
  groups: [],
  groupExpandIndex: [],
};

export const jobListStore = createStore<IJobListStore>(
  "jobListStore",
  Object.assign({}, initJobListStore),
)();
