import {
  array,
  mapOf,
  object,
  optional,
  str,
  unknown,
} from "@seval-portal/shared";

export interface BingJobFile {
  folderName: string;
  fileName: string;
  fullPath: string;
  sizeInBytes: number;
  // While the text is small, we keep the content as string.
  // While the text is large, we keep the content as ArrayBuffer to avoid V8 max error.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length
  content?: string | ArrayBuffer;
}

export interface BingMetricsBasicTableData {
  metrics: string[] | undefined;
  columns: string[];
  data: any[];
}

export interface BingGeneralMetrics {
  testset: string;
  tableData: BingMetricsBasicTableData;
}

export interface BingControlVsTreatment {
  control: string;
  treatment: string | undefined;
}

export interface BingMetricsQuery {
  metrics?: string;
  CT?: BingControlVsTreatment;
  segments?: string;
}

export const BingFirstColumnItemInConvTsvFile = object({
  text: optional(str),
  rules: optional(array(str)),
  author: optional(str),
});

export const BingFirstColumnArrayInConvTsvFile = array(
  BingFirstColumnItemInConvTsvFile,
);

export const BingSeventhColumnItemInConvTsvFile = object({
  Human: optional(str),
  attributions: optional(mapOf(unknown)),
});

export const BingSeventhColumnArrayInConvTsvFile = array(
  BingSeventhColumnItemInConvTsvFile,
);
