import { array, num, object, optional, str } from "@seval-portal/shared";

export const AvalonSuggestion = object({
  text: str,
  hiddenText: str,
  author: str,
  contentOrigin: str,
});

export const SourceAttribution = object({
  cardJson: str,
  seeMoreUrl: str,
  sourceType: str,
  providerDisplayName: str,
});

export const AdaptiveCardBody = object({
  type: str,
  id: optional(str),
  text: optional(str),
  facts: array(object({ title: str, value: str })),
});

export const AdaptiveCard = object({
  type: str,
  body: array(AdaptiveCardBody),
});

export const AvalonMessage = object({
  author: str,
  text: str,
  hiddenText: optional(str),
  timestamp: str,
  sourceAttributions: optional(array(SourceAttribution)),
  messageType: optional(str),
  adaptiveCards: optional(array(AdaptiveCard)),
  suggestedResponses: optional(array(AvalonSuggestion)),
  invocation: optional(str),
  createdAt: str,
  locationInfo: optional(
    object({
      timeZoneOffset: optional(num),
      timeZone: optional(str),
    }),
  ),
});

export const AvalonMetric = object({
  serviceName: str,
  input: optional(str),
  output: optional(str),
  startTime: str,
});

export const AvalonResponse = object({
  conversationId: str,
  messages: array(AvalonMessage),
  telemetry: object({
    metrics: array(AvalonMetric),
  }),
});

export type AvalonResponse = ReturnType<typeof AvalonResponse>;
