import { computed } from "mobx";
import { parseJsonStr, SettingFileValues } from "@seval-portal/shared";
import type { AppConfigurationSnapshot } from "../models/AppConfigurationSnapshot";
import { appConfigurationStore } from "../store/appConfigurationStore";

export const isCurrentConfig = (
  snapshot: AppConfigurationSnapshot | undefined,
) => {
  if (snapshot === undefined) {
    return false;
  }
  return (
    snapshot.snapshot === undefined &&
    !isConfigEditHistory(snapshot) &&
    !isConfigWithIdentifiers(snapshot)
  );
};

export const isConfigEditHistory = (
  snapshot: AppConfigurationSnapshot | undefined,
) => {
  if (snapshot === undefined) {
    return false;
  }
  return (
    snapshot.blobTags?.blobTagSet.find((_) => _.key === "creatorAlias") !==
    undefined
  );
};

export const isConfigWithIdentifiers = (
  snapshot: AppConfigurationSnapshot | undefined,
) => {
  if (snapshot === undefined) {
    return false;
  }
  return (
    snapshot.blobTags?.blobTagSet.find((_) => _.key === "identifier") !==
    undefined
  );
};

export const isUpdateEnabled = computed(() => {
  return (
    appConfigurationStore.selectedFileName !== undefined &&
    appConfigurationStore.selectedSnapshot !== undefined &&
    appConfigurationStore.selectedSnapshotContent !== undefined &&
    !isConfigEditHistory(appConfigurationStore.selectedSnapshot) &&
    !isConfigWithIdentifiers(appConfigurationStore.selectedSnapshot) &&
    getConfigurationError.get() === undefined
  );
});

export const getConfigurationError = computed(() => {
  const schema = SettingFileValues.find(
    (_) => _[0] === appConfigurationStore.selectedFileName,
  )?.[1];

  try {
    if (appConfigurationStore.selectedSnapshotContent === undefined) {
      throw new Error("Invalid Content");
    }
    if (schema === undefined) {
      throw new Error("Invalid Schema");
    }
    parseJsonStr(appConfigurationStore.selectedSnapshotContent, schema);
    return undefined;
  } catch (error) {
    if (error instanceof Error) {
      return error.message;
    } else {
      return JSON.stringify(error);
    }
  }
});
