"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobConfiguration = exports.unifiedBizChatConfiguration = exports.ConvergeQueries = exports.ConvergeQuerySet = exports.ConvergeGlobalConfig = exports.JobTemplatesDefaultExpConfig = exports.BizChatExp = exports.WpsServiceSetting = exports.UserpServiceSetting = exports.AugloopServiceSetting = exports.SydneyServiceSetting = exports.locationInfo = exports.Configure3pPluginsKeys = exports.BizChatMetricsKeys = exports.BingTestSetList = exports.BingTestSet = exports.CompeteSydneyEngine = exports.BingSydneyEngine = exports.BingSydneyExp = exports.GPTServiceSetting = void 0;
const tools_1 = require("../tools");
exports.GPTServiceSetting = (0, tools_1.strictObject)({
    model: (0, tools_1.undefinedOr)(tools_1.str),
    enable_web_search: (0, tools_1.undefinedOr)(tools_1.bool),
});
exports.BingSydneyExp = (0, tools_1.strictObject)({
    url: tools_1.str,
    option_sets: (0, tools_1.undefinedOr)(tools_1.str),
    extra_headers: (0, tools_1.undefinedOr)(tools_1.anyObject),
    extra_params: (0, tools_1.undefinedOr)(tools_1.anyObject),
    config_params: (0, tools_1.undefinedOr)(tools_1.anyObject),
    plugins: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.anyObject)),
    options: (0, tools_1.undefinedOr)(tools_1.anyObject),
});
exports.BingSydneyEngine = (0, tools_1.strictObject)({
    exp_name: tools_1.str,
    baseline_names: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    sydney: exports.BingSydneyExp,
    extra_fields: (0, tools_1.undefinedOr)(tools_1.anyObject),
    service: (0, tools_1.undefinedOr)(tools_1.str),
    skip_scraping: (0, tools_1.undefinedOr)(tools_1.bool),
    scraping_result: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.CompeteSydneyEngine = (0, tools_1.strictObject)({
    exp_name: tools_1.str,
    baseline_names: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    sydney: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
        option_sets: (0, tools_1.undefinedOr)(tools_1.str),
    })),
    service: (0, tools_1.undefinedOr)(tools_1.str),
    skip_scraping: (0, tools_1.undefinedOr)(tools_1.bool),
    scraping_result: (0, tools_1.undefinedOr)((0, tools_1.or)([tools_1.num, tools_1.str])),
    gpt: (0, tools_1.undefinedOr)(exports.GPTServiceSetting),
});
exports.BingTestSet = (0, tools_1.strictObject)({
    metrics: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    input_folder: (0, tools_1.undefinedOr)(tools_1.str),
    options: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    data_uri: (0, tools_1.undefinedOr)(tools_1.str),
    previous_scrape_output: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.BingTestSetList = (0, tools_1.mapOf)(exports.BingTestSet);
exports.BizChatMetricsKeys = [
    "scleo",
    "apology",
    "stewieleo",
    "pileo",
    "groundleo",
    "lmchecklist",
    "stewieleosbs",
    "tcr",
    "groundleo_claimbreak",
    "groundleo_claimbreakv1_1",
    "sbsleov2",
    "sbsleo",
    "reasoningleo",
    "teamsgroundleo",
    "stewieleov5",
    "codeleo",
    "pptstorytellerquality",
    "screlevance",
    "DCG",
    "NDCG",
    "Precision",
    "Recall",
    "CiteDCG",
];
const BizChatMetrics = exports.BizChatMetricsKeys.reduce((acc, key) => {
    acc[key] = (0, tools_1.undefinedOr)(tools_1.bool);
    return acc;
}, {});
exports.Configure3pPluginsKeys = [
    "sideload_3p_plugins",
    "unsideload_3p_plugins",
];
const Configure3pPlugins = exports.Configure3pPluginsKeys.reduce((acc, key) => {
    acc[key] = (0, tools_1.undefinedOr)(tools_1.bool);
    return acc;
}, {});
exports.locationInfo = (0, tools_1.strictObject)({
    country: (0, tools_1.undefinedOr)(tools_1.str),
    countryConfidence: (0, tools_1.undefinedOr)(tools_1.num),
    state: (0, tools_1.undefinedOr)(tools_1.str),
    city: (0, tools_1.undefinedOr)(tools_1.str),
    cityConfidence: (0, tools_1.undefinedOr)(tools_1.num),
    zipCode: (0, tools_1.undefinedOr)(tools_1.str),
    timeZoneOffset: (0, tools_1.undefinedOr)(tools_1.num),
    dma: (0, tools_1.undefinedOr)(tools_1.num),
    latitude: (0, tools_1.undefinedOr)(tools_1.num),
    longitude: (0, tools_1.undefinedOr)(tools_1.num),
});
exports.SydneyServiceSetting = (0, tools_1.strictObject)({
    url: (0, tools_1.undefinedOr)(tools_1.str),
    option_sets: (0, tools_1.undefinedOr)(tools_1.str),
    variants: (0, tools_1.undefinedOr)(tools_1.str),
    options: (0, tools_1.undefinedOr)(tools_1.anyObject),
    locationInfo: (0, tools_1.undefinedOr)(exports.locationInfo),
    market: (0, tools_1.undefinedOr)(tools_1.str),
    sydney_scenario: (0, tools_1.undefinedOr)(tools_1.str),
    gpt_identifier: (0, tools_1.undefinedOr)(tools_1.str),
    gpt_name: (0, tools_1.undefinedOr)(tools_1.str),
    fulldebug3s: (0, tools_1.undefinedOr)(tools_1.bool),
    verbosity: tools_1.unknown,
    plugins: (0, tools_1.undefinedOr)(tools_1.anyObject),
    service_type: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.AugloopServiceSetting = (0, tools_1.strictObject)({
    copilot: (0, tools_1.undefinedOr)(tools_1.str),
    url: (0, tools_1.undefinedOr)(tools_1.str),
    annotation_types: (0, tools_1.undefinedOr)(tools_1.str),
    augloop_scenario: (0, tools_1.undefinedOr)(tools_1.str),
    annotation_processor_mode: (0, tools_1.undefinedOr)(tools_1.str),
    workflow_names: (0, tools_1.undefinedOr)(tools_1.str),
    signal_type: (0, tools_1.undefinedOr)(tools_1.str),
    signal_base_type: (0, tools_1.undefinedOr)(tools_1.str),
    paths_to_messages: (0, tools_1.undefinedOr)(tools_1.str),
    annotation_message_param_names: (0, tools_1.undefinedOr)(tools_1.str),
    signal_message_param_name: (0, tools_1.undefinedOr)(tools_1.str),
    signal_other_params: (0, tools_1.undefinedOr)(tools_1.str),
    signal_other_params_dict: (0, tools_1.undefinedOr)(tools_1.anyObject),
    signal_other_param_names: (0, tools_1.undefinedOr)(tools_1.str),
    cv_base: (0, tools_1.undefinedOr)(tools_1.str),
    websocket_timeout: (0, tools_1.undefinedOr)(tools_1.num),
    flights: (0, tools_1.undefinedOr)(tools_1.str),
    resource_id: (0, tools_1.undefinedOr)(tools_1.str),
    allowedMessageTypes: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    fulldebug3s: (0, tools_1.undefinedOr)(tools_1.bool),
    options: (0, tools_1.undefinedOr)(tools_1.anyObject),
    locationInfo: (0, tools_1.undefinedOr)(exports.locationInfo),
    market: (0, tools_1.undefinedOr)(tools_1.str),
    client_app_name: (0, tools_1.undefinedOr)(tools_1.str),
    client_app_platform: (0, tools_1.undefinedOr)(tools_1.str),
    paths_to_error: (0, tools_1.undefinedOr)(tools_1.str),
    paths_to_status_code: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.UserpServiceSetting = (0, tools_1.strictObject)({
    url: (0, tools_1.undefinedOr)(tools_1.str),
    variants: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.WpsServiceSetting = (0, tools_1.strictObject)({
    url: (0, tools_1.undefinedOr)(tools_1.str),
    variants: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.BizChatExp = (0, tools_1.strictObject)({
    exp_name: (0, tools_1.undefinedOr)(tools_1.str),
    is_baseline: (0, tools_1.undefinedOr)(tools_1.bool),
    service: (0, tools_1.undefinedOr)(tools_1.str),
    sydney: (0, tools_1.undefinedOr)(exports.SydneyServiceSetting),
    augloop: (0, tools_1.undefinedOr)(exports.AugloopServiceSetting),
    userp: (0, tools_1.undefinedOr)(exports.UserpServiceSetting),
    wps: (0, tools_1.undefinedOr)(exports.WpsServiceSetting),
});
exports.JobTemplatesDefaultExpConfig = (0, tools_1.strictObject)({
    exp_configs: (0, tools_1.strictObject)({
        service: (0, tools_1.array)(tools_1.str),
        sydney: (0, tools_1.undefinedOr)(exports.SydneyServiceSetting),
        userp: (0, tools_1.undefinedOr)(exports.UserpServiceSetting),
        wps: (0, tools_1.undefinedOr)(exports.WpsServiceSetting),
        augloop: (0, tools_1.strictObject)({
            ui_controls: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
            copilot: (0, tools_1.undefinedOr)((0, tools_1.mapOf)(exports.AugloopServiceSetting)),
        }),
    }),
});
exports.ConvergeGlobalConfig = (0, tools_1.strictObject)({
    use_parallel_scraping: (0, tools_1.undefinedOr)(tools_1.bool),
    use_bizchat_parallel_batch_inference: (0, tools_1.undefinedOr)(tools_1.bool),
    sideload_3p_plugins: (0, tools_1.undefinedOr)(tools_1.bool),
    unsideload_3p_plugins: (0, tools_1.undefinedOr)(tools_1.bool),
    use_tool_entity_precision_recall: (0, tools_1.undefinedOr)(tools_1.bool),
    use_entity_annotation_metrics: (0, tools_1.undefinedOr)(tools_1.bool),
    execute_batch_inference: (0, tools_1.undefinedOr)(tools_1.bool),
    create_sbs_experiment: (0, tools_1.undefinedOr)(tools_1.bool),
    dv3_token_file_path: (0, tools_1.undefinedOr)(tools_1.str),
    thread_size: (0, tools_1.undefinedOr)(tools_1.num),
    output_folder: (0, tools_1.undefinedOr)(tools_1.str),
    cluster: (0, tools_1.undefinedOr)(tools_1.str),
    scenario: (0, tools_1.undefinedOr)(tools_1.str),
    user_id: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.ConvergeQuerySet = (0, tools_1.strictObject)({
    name: (0, tools_1.undefinedOr)(tools_1.str),
    has_header: (0, tools_1.undefinedOr)(tools_1.bool),
    output_folder: (0, tools_1.undefinedOr)(tools_1.str),
    entityAnnotationTypes: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    language: (0, tools_1.undefinedOr)(tools_1.str),
    metrics: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    drop_partial_conversation: (0, tools_1.undefinedOr)(tools_1.bool),
    options: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    input_file: (0, tools_1.undefinedOr)(tools_1.str),
    run_type: (0, tools_1.undefinedOr)(tools_1.str),
    scenario: (0, tools_1.undefinedOr)(tools_1.str),
    querySet_type: (0, tools_1.undefinedOr)(tools_1.str),
    cancel_threshold: (0, tools_1.undefinedOr)(tools_1.num),
});
exports.ConvergeQueries = (0, tools_1.strictObject)({
    query_type: (0, tools_1.undefinedOr)(tools_1.str),
    use_lmchecklist: (0, tools_1.undefinedOr)(tools_1.bool),
    lmchecklist_input_file: (0, tools_1.undefinedOr)(tools_1.str),
    sydney_client_secret: (0, tools_1.undefinedOr)(tools_1.str),
    parsers: (0, tools_1.undefinedOr)(tools_1.str),
    query_sets: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.ConvergeQuerySet)),
});
exports.unifiedBizChatConfiguration = (0, tools_1.strictObject)({
    config_type: (0, tools_1.undefinedOr)(tools_1.str),
    queries: (0, tools_1.undefinedOr)(exports.ConvergeQueries),
    global_config: (0, tools_1.undefinedOr)(exports.ConvergeGlobalConfig),
    exp_configs_unified: (0, tools_1.array)(exports.BizChatExp),
});
exports.JobConfiguration = (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
    // BizChat Schema
    create_sbs_experiment: (0, tools_1.undefinedOr)(tools_1.bool),
    // This config if set will enable auto sideloading and unsideloading of mocked 3p plugins as part of job execution
    ...Configure3pPlugins,
    configs_in_json: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
        ...BizChatMetrics,
        options: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
        sydney_client_secret: (0, tools_1.undefinedOr)(tools_1.str),
        conversations: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
            data_generation_max_turns: (0, tools_1.undefinedOr)(tools_1.num),
            gpt_identifier: (0, tools_1.undefinedOr)(tools_1.str),
            lmchecklist_input_file: (0, tools_1.undefinedOr)(tools_1.str),
            user_id: (0, tools_1.undefinedOr)(tools_1.str),
            input_file: (0, tools_1.undefinedOr)(tools_1.str),
            input_folder: (0, tools_1.undefinedOr)(tools_1.str),
            exp_configs: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.BizChatExp)),
            eval_time_override: (0, tools_1.undefinedOr)(tools_1.str),
            is_synthetic: (0, tools_1.undefinedOr)(tools_1.bool),
        })),
    })),
    // Multi service default settings
    default_settings: (0, tools_1.undefinedOr)(exports.JobTemplatesDefaultExpConfig),
    // Bing Schema
    exp_configs: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.BingSydneyEngine)),
    multi_turn: (0, tools_1.undefinedOr)(exports.BingTestSetList),
    single_turn: (0, tools_1.undefinedOr)(exports.BingTestSetList),
    cluster: (0, tools_1.undefinedOr)(tools_1.str),
    scenario: (0, tools_1.undefinedOr)(tools_1.str),
    user_id: (0, tools_1.undefinedOr)(tools_1.str),
    config_type: (0, tools_1.undefinedOr)(tools_1.str),
    global_config: (0, tools_1.undefinedOr)(exports.ConvergeGlobalConfig),
    queries: (0, tools_1.undefinedOr)(exports.ConvergeQueries),
    exp_configs_unified: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.BizChatExp)),
    // Compete Schema
    enable_aa_evaluation: (0, tools_1.undefinedOr)(tools_1.bool),
    compete: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
        exp_configs: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.CompeteSydneyEngine)),
        multi_turn: (0, tools_1.undefinedOr)(exports.BingTestSetList),
        single_turn: (0, tools_1.undefinedOr)(exports.BingTestSetList),
        cluster: (0, tools_1.undefinedOr)(tools_1.str),
        scenario: (0, tools_1.undefinedOr)(tools_1.str),
        user_id: (0, tools_1.undefinedOr)(tools_1.str),
    })),
}));
