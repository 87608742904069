import { mutatorAction } from "satcheljs";
import type { JobGroupData, JobGroupTemplate } from "@seval-portal/shared";
import type { Job } from "../models/Job";
import { groupDetailStore } from "../store/groupDetailStore";
import { jobDetailStore } from "../store/jobDetailStore";
import { resultStore } from "../store/resultStore";

export const updateDetailGroup = mutatorAction(
  "updateDetailGroup",
  (value: JobGroupData | undefined) => {
    groupDetailStore.group = value;
  },
);

export const updateGroupTemplate = mutatorAction(
  "updateGroupTemplate",
  (templates: JobGroupTemplate[]) => {
    groupDetailStore.groupTemplates = templates;
  },
);

export const updateGroupJobs = mutatorAction(
  "updateGroupJobs",
  (value: Job[]) => {
    groupDetailStore.jobs = value;
  },
);

export const cacheCurrentJobResult = mutatorAction(
  "cacheCurrentJobResult",
  () => {
    groupDetailStore.cachedJobResults.set(resultStore.resultJob?.ID ?? 0, {
      detailStore: jobDetailStore,
      resultStore: resultStore,
    });
  },
);

export const updateIsLoadingGroup = mutatorAction(
  "updateIsLoadingGroup",
  (value: boolean) => {
    groupDetailStore.isLoadingGroup = value;
  },
);

export const updateIsLoadingJobs = mutatorAction(
  "updateIsLoadingJobs",
  (value: boolean) => {
    groupDetailStore.isLoadingJob = value;
  },
);

export const updateCurrentJob = mutatorAction(
  "updateCurrentJob",
  (value: Job | undefined) => {
    groupDetailStore.currentJob = value;
  },
);
