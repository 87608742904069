import { getGPTList } from "../../../helpers/apiHelper";
import { updateGPTAgents } from "../mutators/jobCreationMutators";
import { getUserId } from "../selectors/getJobPropV2";

const controlPath =
  "configs_in_json.conversations.exp_configs[0].sydney.gpt_identifier" as const;
const treatmentPath =
  "configs_in_json.conversations.exp_configs[1].sydney.gpt_identifier" as const;

const controlNamePath =
  "configs_in_json.conversations.exp_configs[0].sydney.gpt_name" as const;
const treatmentNamePath =
  "configs_in_json.conversations.exp_configs[1].sydney.gpt_name" as const;

export const getExpAgentPropPath = (scope: "control" | "treatment") => {
  switch (scope) {
    case "control":
      return controlPath;
    case "treatment":
      return treatmentPath;
    default:
      throw new Error("Invalid scope");
  }
};

export const getExpAgentPropNamePath = (scope: "control" | "treatment") => {
  switch (scope) {
    case "control":
      return controlNamePath;
    case "treatment":
      return treatmentNamePath;
    default:
      throw new Error("Invalid scope");
  }
};

export const getGPTAgentList = () => {
  const currentUser = getUserId.get();
  if (currentUser === undefined) {
    throw new Error("User ID is not found.");
  }
  getGPTList({
    userid: currentUser,
  }).then((response) => {
    updateGPTAgents(response.gptList ?? []);
  });
};
