import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link,
  Textarea,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  setCachedAvalonAccessToken,
  setCachedHeronAccessToken,
  setCachedScrapingServiceToken,
  setCachedSubstrateToken,
} from "../../helpers/cacheHelper";
import {
  updateIsSettingAvalonAccessToken,
  updateIsSettingHeronAccessToken,
  updateIsSettingScrapingServiceToken,
  updateIsSettingSubstrateToken,
} from "../../mutators/updateDevelopStatus";
import { store } from "../../store/store";

type Props = {
  type:
    | "SubstrateAccessToken"
    | "HeronAccessToken"
    | "AvalonAccessToken"
    | "ScrapingServiceToken";
};

const LocalTokenInputDialog = observer((props: Props) => {
  const [token, setToken] = React.useState<string>("");

  const getTiTle = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        return "Set Substrate Search Access Token";
      case "HeronAccessToken":
        return "Set Heron Access Token";
      case "AvalonAccessToken":
        return "Set Avalon Access Token";
      case "ScrapingServiceToken":
        return "Set Scraping Service Token";
    }
  };

  const getContent = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        return (
          <Body1>
            {
              "Input Your Substrate Search Access Token Manually For Local Development "
            }
            <Link
              href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/Enterprise%20Sydney%20WIKI/502669/SEVAL-Portal-Development"
              target="_blank"
            >
              (How to get your token?)
            </Link>
          </Body1>
        );

      case "HeronAccessToken":
        return (
          <Body1>
            {"Input Your Heron Access Token Manually For Local Development."}
            <br />
            {
              "Please go to SEVAL Production, open Dev Center, and click 'Get Managed Identity Token' and use scope '3e9313d0-debc-4f0d-85a7-171f92333ba1' to get your token."
            }
          </Body1>
        );
      case "AvalonAccessToken":
        return (
          <Body1>
            {"Input Your Avalon Access Token Manually For Local Development."}
            <br />
            {
              "Please go to SEVAL Production, open Dev Center, and click 'Get User Access Token' and use scope 'https://substrate.office.com/sydney/.default' to get your token."
            }
          </Body1>
        );
      case "ScrapingServiceToken":
        return (
          <Body1>
            {
              "Input Your Scraping Service Access Token Manually For Local Development."
            }
            <br />
            {
              "Please go to SEVAL Production, open Dev Center, and click 'Get User Access Token' and use scope 'https://sevalscraper.office.net/.default' to get your token."
            }
          </Body1>
        );
    }
  };

  const isOpen = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        return store.develop.isSettingSubstrateToken;
      case "HeronAccessToken":
        return store.develop.isSettingHeronAccessToken;
      case "AvalonAccessToken":
        return store.develop.isSettingAvalonAccessToken;
      case "ScrapingServiceToken":
        return store.develop.isSettingScrapingServiceToken;
    }
  };

  const onOpenChange = () => {
    updateIsSettingSubstrateToken(false);
    updateIsSettingHeronAccessToken(false);
    store.develop.tokenResolver?.("");
  };

  const onConfirm = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        updateIsSettingSubstrateToken(false);
        setCachedSubstrateToken(token);
        store.develop.tokenResolver?.(token);
        break;
      case "HeronAccessToken":
        updateIsSettingHeronAccessToken(false);
        setCachedHeronAccessToken(token);
        store.develop.tokenResolver?.(token);
        break;
      case "AvalonAccessToken":
        updateIsSettingAvalonAccessToken(false);
        setCachedAvalonAccessToken(token);
        store.develop.tokenResolver?.(token);
        break;
      case "ScrapingServiceToken":
        updateIsSettingScrapingServiceToken(false);
        setCachedScrapingServiceToken(token);
        store.develop.tokenResolver?.(token);
        break;
    }
  };

  return (
    <Dialog open={isOpen()} onOpenChange={onOpenChange}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{getTiTle()}</DialogTitle>
          <DialogContent>
            {getContent()}
            <Field label={"Token"}>
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={token}
                onChange={(_, data) => {
                  setToken(data.value);
                }}
              />
            </Field>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onConfirm}>
              Set
            </Button>
            <Button onClick={onOpenChange}>Close</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});

export const SubstrateTokenInputDialog = () => (
  <LocalTokenInputDialog type="SubstrateAccessToken" />
);

export const HeronTokenInputDialog = () => (
  <LocalTokenInputDialog type="HeronAccessToken" />
);

export const AvalonTokenInputDialog = () => (
  <LocalTokenInputDialog type="AvalonAccessToken" />
);

export const ScrapingServiceTokenInputDialog = () => (
  <LocalTokenInputDialog type="ScrapingServiceToken" />
);
