import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React, { useEffect, useRef } from "react";

import { observer } from "mobx-react-lite";
import { uploadFolderAction } from "../../actions/uploadFolderAction";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    lineHeight: "22px",
  },
  typeSelect: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  errorMsg: {
    color: "red",
  },
  successMsg: {
    color: "green",
  },
});

interface IUploadingSyntheticIngestionDataFolderDialog {
  folderName: string;
  isOpen: boolean;
  onStart?: (folderName: string) => void;
  onSuccess?: (folderName: string) => void;
  onFailure?: (error: Error) => void;
  onCancel?: () => void;
}

export const UploadingSyntheticIngestionDataFolderDialog = observer(
  (props: IUploadingSyntheticIngestionDataFolderDialog) => {
    const inputFolder = useRef<HTMLInputElement | null>(null);
    const { isOpen } = props;
    const styles = useStyles();

    const [currentFolderName, setCurrentFolderName] =
      React.useState<string>("");
    const folderName = `${props.folderName}${currentFolderName}`;
    const [selectedFolder, setSelectedFolder] = React.useState<
      File[] | undefined
    >(undefined);

    useEffect(() => {
      setCurrentFolderName("");
      setSelectedFolder(undefined);
    }, [isOpen]);

    const checkFolderFormat = () => {
      return;
    };

    const validateFolder = (folder: File[]) => {
      if (folder !== undefined) {
        checkFolderFormat();
      }
    };

    const handleFileDialogConfirm = () => {
      if (selectedFolder !== undefined && selectedFolder.length > 0) {
        props.onStart?.(folderName);

        uploadFolderAction(
          "SyntheticIngestionData",
          props.folderName,
          selectedFolder,
          currentFolderName,
          () => {
            props.onSuccess?.(folderName);
          },
          props.onFailure,
        );
      }
    };

    return (
      <Dialog
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === false) {
            props.onCancel?.();
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Upload Synthetic Ingestion Data Folder</DialogTitle>
            <DialogContent>
              <Field>
                <div className={styles.block}>
                  <div className={styles.blockTitle}>Select your folder</div>

                  {selectedFolder && (
                    <div className={styles.successMsg}>
                      <Body1>
                        {`This folder `}
                        <strong>{`${currentFolderName}`}</strong>
                        {` will be stored with name `}
                        <strong>{`${folderName}`}</strong>
                      </Body1>
                    </div>
                  )}
                  <div>
                    <input
                      type="file"
                      role="textbox"
                      ref={(node) => {
                        inputFolder.current = node;

                        if (node) {
                          [
                            "webkitdirectory",
                            "directory",
                            "mozdirectory",
                          ].forEach((attr) => {
                            node.setAttribute(attr, "");
                          });
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        if (event.target?.files) {
                          const fileList = new Array<File>();
                          Array.from(event.target?.files).forEach(
                            (file, index) => {
                              if (file.name !== ".DS_Store") {
                                fileList.push(file);
                              }
                              if (index === 0) {
                                setCurrentFolderName(
                                  file.webkitRelativePath.split("/")[0],
                                );
                              }
                            },
                          );
                          setSelectedFolder(fileList);

                          validateFolder(fileList);
                        }
                      }}
                      style={{ display: "none" }}
                    />
                    <Button
                      appearance="primary"
                      onClick={() => {
                        inputFolder.current?.click();
                      }}
                    >
                      Select Folder
                    </Button>
                  </div>
                </div>
              </Field>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleFileDialogConfirm}
                disabled={selectedFolder === undefined}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
