import { orchestrator } from "satcheljs";
import {
  decrementCounterAction,
  incrementCounterAction,
  resetCounterAction,
  setCounterAction,
} from "../action/counterAction";
import {
  decrementCounter,
  incrementCounter,
  resetCounter,
  setCounter,
} from "../mutator/counterMutator";

export const registerCounterOrchestrator = () => {
  orchestrator(setCounterAction, (number) => {
    setCounter(number.count);
  });

  orchestrator(resetCounterAction, () => {
    resetCounter();
  });

  orchestrator(incrementCounterAction, () => {
    incrementCounter();
  });

  orchestrator(decrementCounterAction, () => {
    decrementCounter();
  });
};
