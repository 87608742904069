import {
  isMultiServiceTemplate,
  getTemplateType as sharedGetTemplateType,
  TemplateType,
} from "@seval-portal/shared";
import { computed } from "mobx";
import { jobStore } from "../store/jobStore";

export const getTemplateType = computed(() => {
  return sharedGetTemplateType(jobStore.selectedTemplate?.Type);
});

export const getMultiServiceTemplate = computed(() => {
  if (jobStore.selectedTemplate === undefined) {
    return false;
  }
  return isMultiServiceTemplate(jobStore.selectedTemplate?.Name);
});

export const getWhetherUnifiedTemplate = computed(() => {
  if (jobStore.selectedTemplate === undefined) {
    return false;
  }
  return (
    getTemplateType.get() === TemplateType.UnifiedBizChat ||
    getTemplateType.get() === TemplateType.UnifiedBizChatFlightReview
  );
});

export const supportEvaluationType = computed(() => {
  return (
    (getTemplateType.get() === TemplateType.Normal ||
      getTemplateType.get() === TemplateType.UnifiedBizChat ||
      getTemplateType.get() === TemplateType.AIHub ||
      getMultiServiceTemplate.get()) &&
    jobStore.selectedTemplate !== undefined
  );
});

export const hideJsonConfiguration = computed(() => {
  return (
    getTemplateType.get() === TemplateType.ScrapingOnly &&
    jobStore.selectedTemplate !== undefined
  );
});
