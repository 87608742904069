import type { TypeGuard } from "@seval-portal/shared";
import { anyObject, constStr, object, optional } from "@seval-portal/shared";

export const AzureMLPath: TypeGuard<string> = (
  value: unknown,
  trace: string,
): string => {
  if (
    typeof value === "string" &&
    value.startsWith("azureml://datastores/") &&
    value.endsWith(".json")
  ) {
    return value;
  }

  throw new Error(`[${trace}] Expected string, got ${value}`);
};

export const AzureMLConfigFile = object({
  type: constStr("uri_file"),
  path: AzureMLPath,
});

export const BingConfigFileSchema = object({
  config_file: optional(AzureMLConfigFile),
  manifest_file: optional(AzureMLConfigFile),
  api_file: optional(AzureMLConfigFile),
  resume_from: optional(anyObject),
});
