import {
  Combobox,
  Field,
  InfoLabel,
  makeStyles,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getValue } from "../../../selectors/getJobPropV2";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  dropdownMenu: {
    backgroundColor: "#F8F7F9",
  },
  listbox: {
    maxHeight: "300px",
  },
});

const Scrapers: { [key: string]: string } = { gpt: "ChatGPT" };
const ChatGPTModels = [
  "gpt-4o",
  "gpt-4-5",
  "o3-mini",
  "m3-mini-high",
  "gpt-4o-mini",
  "gpt-4",
];

export const ScraperSelector = observer(() => {
  const styles = useStyles();
  const [scraper, setScraper] = React.useState<string>(
    Object.keys(Scrapers)[0],
  );
  const [chatGPTModel, setChatGPTModel] = React.useState<string | undefined>();
  const [enabledChatGPTSearch, setEnabledChatGPTSearch] = React.useState<
    string | undefined
  >("Yes");

  const handlerScraperChange = (scraper: string) => {
    const expConfigs = getValue(`compete.exp_configs`) ?? [];
    const scrapingResultExpIndex = expConfigs.findIndex(
      (v) => v.exp_name === "scraping_result",
    );
    updatePropValueActionV2({
      prop: `compete.exp_configs[${scrapingResultExpIndex}].service`,
      newData: scraper,
    });
  };

  const handlerScraperGptModelChange = (value: string) => {
    const expConfigs = getValue(`compete.exp_configs`) ?? [];
    const scrapingResultExpIndex = expConfigs.findIndex(
      (v) => v.exp_name === "scraping_result",
    );
    updatePropValueActionV2({
      prop: `compete.exp_configs[${scrapingResultExpIndex}].gpt.model`,
      newData: value,
    });
  };

  const handlerScraperGptSearchChange = (value: string) => {
    const boolValue = value.toLowerCase() === "yes" ? true : false;
    const expConfigs = getValue(`compete.exp_configs`) ?? [];
    const scrapingResultExpIndex = expConfigs.findIndex(
      (v) => v.exp_name === "scraping_result",
    );
    updatePropValueActionV2({
      prop: `compete.exp_configs[${scrapingResultExpIndex}].gpt.enable_web_search`,
      newData: boolValue,
    });
  };

  return (
    <>
      <div className={styles.block}>
        <InfoLabel required>
          <span className={styles.blockTitle}>Scrapers</span>
        </InfoLabel>
        <Combobox
          data-testid="scraper-combobox"
          className={styles.dropdownMenu}
          listbox={{ className: styles.listbox }}
          placeholder="Select a scraper"
          selectedOptions={[`${scraper}`]}
          value={Scrapers[scraper]}
          onOptionSelect={(_, data) => {
            setScraper(data.optionValue ?? "");
            handlerScraperChange(data.optionValue ?? "");
          }}
        >
          {Object.keys(Scrapers).map((option) => {
            return (
              <Option key={option} value={`${option}`}>
                {Scrapers[option]}
              </Option>
            );
          })}
        </Combobox>
      </div>

      {scraper === "gpt" && (
        <ResponsiveRow
          maxColumnCount={2}
          maxColumnCountSmall={1}
          rowGap={20}
          columnGap={20}
        >
          <div className={styles.block}>
            <Field
              label={
                <InfoLabel required>
                  <span className={styles.blockTitle}>ChatGPT models</span>
                </InfoLabel>
              }
              validationState={chatGPTModel ? "none" : "error"}
              validationMessage={
                chatGPTModel ? "" : "Model should not be empty"
              }
            >
              <Combobox
                data-testid="chatgpt-model-combobox"
                className={styles.dropdownMenu}
                listbox={{ className: styles.listbox }}
                placeholder="Select a chatgpt model"
                selectedOptions={[`${chatGPTModel}`]}
                value={chatGPTModel ?? ""}
                onOptionSelect={(_, data) => {
                  setChatGPTModel(data.optionValue);
                  handlerScraperGptModelChange(data.optionValue ?? "");
                }}
              >
                {ChatGPTModels.map((option) => {
                  return (
                    <Option key={option} value={`${option}`}>
                      {option}
                    </Option>
                  );
                })}
              </Combobox>
            </Field>
          </div>

          <div className={styles.block}>
            <Field
              label={
                <InfoLabel required>
                  <span className={styles.blockTitle}>
                    Enable ChatGPT search
                  </span>
                </InfoLabel>
              }
              validationState={enabledChatGPTSearch ? "none" : "error"}
              validationMessage={
                enabledChatGPTSearch ? "" : "ChatGPT search should not be empty"
              }
            >
              <Combobox
                data-testid="enabled-chatgpt-search-combobox"
                className={styles.dropdownMenu}
                listbox={{ className: styles.listbox }}
                placeholder="Enable chatgpt search"
                selectedOptions={[`${enabledChatGPTSearch}`]}
                value={enabledChatGPTSearch ?? ""}
                onOptionSelect={(_, data) => {
                  setEnabledChatGPTSearch(data.optionValue);
                  handlerScraperGptSearchChange(data.optionValue ?? "");
                }}
              >
                <Option key="Yes" value="Yes">
                  Yes
                </Option>
                <Option key="No" value="No">
                  No
                </Option>
              </Combobox>
            </Field>
          </div>
        </ResponsiveRow>
      )}
    </>
  );
});
