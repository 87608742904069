import { createStore } from "satcheljs";
import type { BingMetaDataResponse } from "@seval-portal/shared";
import { type Product, type ProductTemplate } from "@seval-portal/shared";
import type { Toaster } from "../../../components/Wrappers/ToasterProvider";
import type { JobTemplate } from "../models/JobTemplate";

export type ProductSettingsScenario =
  | { scenarioType: "Loading" }
  | {
      scenarioType: "Listing";
      isDeleting?: Product;
    }
  | {
      scenarioType: "Creating";
      currentProductName: string;
      currentPipelines: ProductTemplate[];
    }
  | {
      scenarioType: "EditingProduct";
      selectedProduct: Product;
      currentProductName: string;
      currentPipeline: ProductTemplate | undefined;
      currentPipelines: ProductTemplate[];
      isReleasing?: Product;
    }
  | {
      scenarioType: "Error";
      errorMessage: string;
    };

export type ProductSettingsStore = {
  scenario: ProductSettingsScenario;
  products: Product[];
  templates: JobTemplate[];
  bingMetaData: BingMetaDataResponse | undefined;
  cwcMetaData: BingMetaDataResponse | undefined;
  toaster: Toaster | undefined;
};

export const initialStore: ProductSettingsStore = {
  scenario: { scenarioType: "Listing" },
  products: [],
  templates: [],
  toaster: undefined,
  bingMetaData: undefined,
  cwcMetaData: undefined,
};

export const productSettingsStore = createStore<ProductSettingsStore>(
  "productSettingsStore",
  Object.assign({}, initialStore),
)();
