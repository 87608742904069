"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuerySetManagementSearchParser = exports.CachedConversationDownloadParser = exports.CachedConversationStatusParser = exports.CachedConversationSearchParser = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.CachedConversationSearchParser = (0, typeHelper_1.object)({
    DisplayName: (0, typeHelper_1.optional)(typeHelper_1.str),
    ActivationStatus: (0, typeHelper_1.optional)(typeHelper_1.str),
    ValidationStatus: (0, typeHelper_1.optional)(typeHelper_1.str),
    QuerySet: (0, typeHelper_1.optional)(typeHelper_1.str),
    LatestQuerySet: (0, typeHelper_1.optional)(typeHelper_1.str),
    ScraperService: (0, typeHelper_1.optional)(typeHelper_1.str),
    pageSize: (0, typeHelper_1.optional)(typeHelper_1.str),
    pageNumber: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CachedConversationStatusParser = (0, typeHelper_1.object)({
    ID: typeHelper_1.str,
    ActivationStatus: (0, typeHelper_1.optional)(typeHelper_1.str),
    ValidationStatus: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.CachedConversationDownloadParser = (0, typeHelper_1.object)({
    filename: typeHelper_1.str,
    blob: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.QuerySetManagementSearchParser = (0, typeHelper_1.object)({
    Name: (0, typeHelper_1.optional)(typeHelper_1.str),
    Status: (0, typeHelper_1.optional)(typeHelper_1.str),
    CreatedBy: (0, typeHelper_1.optional)(typeHelper_1.str),
    Env: (0, typeHelper_1.optional)(typeHelper_1.str),
    pageSize: (0, typeHelper_1.optional)(typeHelper_1.str),
    pageNumber: (0, typeHelper_1.optional)(typeHelper_1.str),
});
