import { Field, InfoLabel, Input } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getValue } from "../../../selectors/getJobPropV2";
import { JobEnginesJsonFieldView } from "../components/JobEnginesJsonFieldView";
import { useExpStyles } from "./expSharedStyles";

// Exp* components: render input fields for experiment configurations
export const expTextPath = [
  "configs_in_json.conversations.exp_configs[0].exp_name",
  "configs_in_json.conversations.exp_configs[1].exp_name",
  "configs_in_json.conversations.exp_configs[0].sydney.url",
  "configs_in_json.conversations.exp_configs[1].sydney.url",
  "configs_in_json.conversations.exp_configs[0].sydney.option_sets",
  "configs_in_json.conversations.exp_configs[1].sydney.option_sets",
  "configs_in_json.conversations.exp_configs[0].sydney.variants",
  "configs_in_json.conversations.exp_configs[1].sydney.variants",
  "configs_in_json.conversations.exp_configs[0].augloop.url",
  "configs_in_json.conversations.exp_configs[1].augloop.url",
  "configs_in_json.conversations.exp_configs[0].augloop.annotation_types",
  "configs_in_json.conversations.exp_configs[1].augloop.annotation_types",
  "configs_in_json.conversations.exp_configs[0].augloop.workflow_names",
  "configs_in_json.conversations.exp_configs[1].augloop.workflow_names",
  "configs_in_json.conversations.exp_configs[0].augloop.signal_type",
  "configs_in_json.conversations.exp_configs[1].augloop.signal_type",
  "configs_in_json.conversations.exp_configs[0].augloop.flights",
  "configs_in_json.conversations.exp_configs[1].augloop.flights",
] as const;

export const expTextExtensionPath = [
  "configs_in_json.conversations.exp_configs[0].userp.url",
  "configs_in_json.conversations.exp_configs[1].userp.url",
  "configs_in_json.conversations.exp_configs[0].userp.variants",
  "configs_in_json.conversations.exp_configs[1].userp.variants",
  "configs_in_json.conversations.exp_configs[0].wps.url",
  "configs_in_json.conversations.exp_configs[1].wps.url",
  "configs_in_json.conversations.exp_configs[0].wps.variants",
  "configs_in_json.conversations.exp_configs[1].wps.variants",
] as const;
export const expTextJsonPath = [
  "configs_in_json.conversations.exp_configs[0].sydney.plugins",
  "configs_in_json.conversations.exp_configs[1].sydney.plugins",
] as const;
type ExpTextType = (typeof expTextPath)[number];
type ExpTextExtensionType = (typeof expTextExtensionPath)[number];
interface ExpTextInputViewProps {
  label: string;
  isRequired?: boolean;
  infor?: string | undefined;
  ariaLabel?: string | undefined;
  path: ExpTextType;
}

interface ExpTextExtensionViewProps {
  label: string;
  isRequired?: boolean;
  infor?: string | undefined;
  ariaLabel?: string | undefined;
  path: ExpTextExtensionType;
}

export const ExpTextInputView = observer((props: ExpTextInputViewProps) => {
  const { label, infor, path, ariaLabel } = props;
  const styles = useExpStyles();
  const valueError = getJobErrorByPath(path);
  const currentValue = getValue(path);

  return (
    <div className={styles.experimentationFieldContainer}>
      <InfoLabel
        required={props.isRequired}
        className={styles.experimentationFieldTitle}
        info={infor}
      >
        {`${label}:`}
      </InfoLabel>
      <Field
        style={{ flexGrow: 1 }}
        validationState={valueError?.message === undefined ? "none" : "error"}
        validationMessage={valueError?.message}
      >
        <Input
          data-testid="exp-text-input"
          aria-label={ariaLabel}
          size="medium"
          value={currentValue}
          onChange={(_, data) => {
            updatePropValueActionV2({
              prop: path,
              newData: data.value,
            });
          }}
        />
      </Field>
    </div>
  );
});

export const ExpTextExtensionView = observer(
  (props: ExpTextExtensionViewProps) => {
    const { label, infor, path, ariaLabel } = props;
    const styles = useExpStyles();
    const valueError = getJobErrorByPath(path);
    const currentValue = getValue(path);

    return (
      <div className={styles.experimentationFieldContainer}>
        <InfoLabel
          required={props.isRequired}
          className={styles.experimentationFieldTitle}
          info={infor}
        >
          {`${label}:`}
        </InfoLabel>
        <Field
          style={{ flexGrow: 1 }}
          validationState={valueError?.message === undefined ? "none" : "error"}
          validationMessage={valueError?.message}
        >
          <Input
            data-testid="exp-text-input"
            aria-label={ariaLabel}
            size="medium"
            value={currentValue}
            onChange={(_, data) => {
              updatePropValueActionV2({
                prop: path,
                newData: data.value,
              });
            }}
          />
        </Field>
      </div>
    );
  },
);

type ExpTextJsonType = (typeof expTextJsonPath)[number];
interface ExpTextInputJsonViewProps {
  label: string;
  infor?: string | undefined;
  ariaLabel?: string | undefined;
  path: ExpTextJsonType;
}

export const ExpTextInputJsonView = observer(
  (props: ExpTextInputJsonViewProps) => {
    const { label, infor, path } = props;
    const styles = useExpStyles();
    const valueError = getJobErrorByPath(path);
    const currentValue = getValue(path);

    return (
      <div className={styles.experimentationFieldContainer}>
        <InfoLabel className={styles.experimentationFieldTitle} info={infor}>
          {`${label}:`}
        </InfoLabel>
        <Field
          style={{ flexGrow: 1 }}
          validationState={valueError === undefined ? "none" : "error"}
          validationMessage={valueError?.message}
        >
          <JobEnginesJsonFieldView
            fieldInfor="The plugins in json format."
            key={path}
            initValue={JSON.stringify(currentValue)}
            path={path}
            isRequired={true}
          />
        </Field>
      </div>
    );
  },
);

export const renderExpTextView = (path: string) => {
  const labelText = path.split(".").pop() ?? "";
  const capitalizedLabel =
    labelText.charAt(0).toUpperCase() + labelText.slice(1);
  if (expTextPath.includes(path as ExpTextType)) {
    return (
      <ExpTextInputView
        key={path}
        label={capitalizedLabel}
        path={path as ExpTextType}
      />
    );
  }
  if (expTextJsonPath.includes(path as ExpTextJsonType)) {
    return (
      <ExpTextInputJsonView
        key={path}
        label={capitalizedLabel}
        path={path as ExpTextJsonType}
      />
    );
  }
  return null;
};
