import { observer } from "mobx-react-lite";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { getValue } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { CopilotDropdownView } from "../ConfigComponents/CopilotDropdownView";
import { useExpStyles } from "../ConfigComponents/expSharedStyles";
import { JobGPTAgentView } from "../components/JobGPTAgentView";
import {
  ExpTextInputJsonView,
  ExpTextInputView,
  ExpTextExtensionView,
  renderExpTextView,
} from "./ExpTextViews";
export const JobPairExpMultiServiceView = observer(() => {
  const styles = useExpStyles();
  const experiments = getValue("configs_in_json.conversations.exp_configs");

  const renderSydneyExp = (isControl: boolean) => {
    const prefix = isControl
      ? ("configs_in_json.conversations.exp_configs[0]" as const)
      : ("configs_in_json.conversations.exp_configs[1]" as const);
    return (
      <div className={styles.columnWithSmallerGap}>
        <ExpTextInputView
          label="Exp name"
          isRequired
          infor="The name of flight. Default value for control flight is control and treatment is experiment."
          path={`${prefix}.exp_name`}
        />
        <CopilotDropdownView isControl={isControl} />
        <ExpTextInputView
          label="URL"
          isRequired
          infor="The url used for scraping."
          path={`${prefix}.sydney.url`}
        />
        <ExpTextInputView
          label="Option_sets"
          infor="Option sets for the flight."
          path={`${prefix}.sydney.option_sets`}
        />
        <ExpTextInputView
          label="Variants"
          infor="Option sets for the flight."
          path={`${prefix}.sydney.variants`}
        />
        <ExpTextInputJsonView
          label="Plugins"
          infor="The plugins used for scraping."
          path={`${prefix}.sydney.plugins`}
        />
        {isFeatureEnabled("gpt-agent-list") && (
          <JobGPTAgentView scope={isControl ? "control" : "treatment"} />
        )}
      </div>
    );
  };

  const renderAugloopExp = (isControl: boolean) => {
    const prefix = isControl
      ? ("configs_in_json.conversations.exp_configs[0]" as const)
      : ("configs_in_json.conversations.exp_configs[1]" as const);
    const defaultTemplateSettings = jobStore.defaultTemplateSettings;
    const uiControl = defaultTemplateSettings?.exp_configs.augloop
      .ui_controls ?? [
      "url",
      "annotation_types",
      "workflow_names",
      "signal_type",
      "flights",
    ];
    return (
      <div className={styles.columnWithSmallerGap}>
        <ExpTextInputView
          label="Exp name"
          isRequired
          infor="The name of flight. Default value for control flight is control and treatment is experiment."
          path={`${prefix}.exp_name`}
        />
        <CopilotDropdownView isControl={isControl} />
        {uiControl &&
          uiControl.map((control) => {
            return renderExpTextView(`${prefix}.augloop.${control}`);
          })}
      </div>
    );
  };

  const renderUserpExp = (isControl: boolean) => {
    const prefix = isControl
      ? ("configs_in_json.conversations.exp_configs[0]" as const)
      : ("configs_in_json.conversations.exp_configs[1]" as const);
    return (
      <div className={styles.columnWithSmallerGap}>
        <ExpTextInputView
          label="Exp name"
          isRequired
          infor="The name of flight. Default value for control flight is control and treatment is experiment."
          path={`${prefix}.exp_name`}
        />
        <CopilotDropdownView isControl={isControl} />
        <ExpTextExtensionView
          label="URL"
          isRequired
          infor="The url used for scraping."
          path={`${prefix}.userp.url`}
        />
        <ExpTextExtensionView
          label="Variants"
          infor="Option sets for the flight."
          path={`${prefix}.userp.variants`}
        />
      </div>
    );
  };

  const renderWpsExp = (isControl: boolean) => {
    const prefix = isControl
      ? ("configs_in_json.conversations.exp_configs[0]" as const)
      : ("configs_in_json.conversations.exp_configs[1]" as const);
    return (
      <div className={styles.columnWithSmallerGap}>
        <ExpTextInputView
          label="Exp name"
          isRequired
          infor="The name of flight. Default value for control flight is control and treatment is experiment."
          path={`${prefix}.exp_name`}
        />
        <CopilotDropdownView isControl={isControl} />
        <ExpTextExtensionView
          label="URL"
          isRequired
          infor="The url used for scraping."
          path={`${prefix}.wps.url`}
        />
        <ExpTextExtensionView
          label="Variants"
          infor="Option sets for the flight."
          path={`${prefix}.wps.variants`}
        />
      </div>
    );
  };

  const renderBlockContent = (isControl: boolean) => {
    const prefix = isControl
      ? ("configs_in_json.conversations.exp_configs[0]" as const)
      : ("configs_in_json.conversations.exp_configs[1]" as const);
    const service = getValue(`${prefix}.service`);
    switch (service) {
      case "augloop":
        return renderAugloopExp(isControl);
      case "userp":
        return renderUserpExp(isControl);
      case "wps":
        return renderWpsExp(isControl);
      case "sydney":
      default:
        return renderSydneyExp(isControl);
    }
  };

  if (experiments === undefined || experiments.length !== 2) {
    return <></>;
  }

  return (
    <ResponsiveRow
      maxColumnCount={2}
      maxColumnCountSmall={1}
      columnGap={32}
      rowGap={20}
    >
      <div className={styles.block}>
        <div className={styles.blockTitle}>Control</div>
        {renderBlockContent(true)}
      </div>
      {experiments.length > 1 && (
        <div className={styles.block}>
          <div className={styles.blockTitle}>Treatment</div>
          {renderBlockContent(false)}
        </div>
      )}
    </ResponsiveRow>
  );
});
