import { object, optional, str } from "@seval-portal/shared";

export const TokenGenerationResponse = optional(
  object({
    token: optional(str),
    accessToken: optional(str),
  }),
);

export type TokenGenerationResponse = ReturnType<
  typeof TokenGenerationResponse
>;
