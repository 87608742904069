import {
  array,
  num,
  object,
  optional,
  or,
  str,
  unknown,
} from "@seval-portal/shared";

const SEVALPerfMetrics = object({
  Flight: str,
  P50: or([num, unknown]),
  P75: or([num, unknown]),
  P90: or([num, unknown]),
  P95: or([num, unknown]),
  Volume: or([num, unknown]),
  Ratio: str,
  pValue: optional(num),
});

const KustoQueryResultTable = object({
  name: str,
  data: optional(array(SEVALPerfMetrics)),
});

export const KustoQueryResult = object({
  metric: str,
  tables: array(KustoQueryResultTable),
});

export const KustoQueryResultResponse = array(KustoQueryResult);

export type SEVALPerfMetrics = ReturnType<typeof SEVALPerfMetrics>;

export type KustoQueryResultResponse = ReturnType<
  typeof KustoQueryResultResponse
>;
