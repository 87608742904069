import {
  Link,
  makeStyles,
  mergeClasses,
  ProgressBar,
  Text,
} from "@fluentui/react-components";
import { ChevronRight16Regular } from "@fluentui/react-icons";
import { uniqByObject } from "@seval-portal/shared";
import { observer } from "mobx-react-lite";
import React from "react";
import { JobSettingsDialog } from "../../../../components/Dialog/JobSettingsDialog";
import { getGroupList } from "../../../../helpers/apiHelper";
import {
  perfWrapper,
  telemetryHelper,
} from "../../../../helpers/telemetryHelper";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { store } from "../../../../store/store";
import {
  displayGroupList,
  getJobSettings,
} from "../../helpers/groupListHelper";
import type { Job } from "../../models/Job";
import {
  updateExpandedIndex,
  updateGroupList,
} from "../../mutators/jobListMutators";
import { jobListStore } from "../../store/jobListStore";
import { GeneralTable } from "./GeneralTable";
import { JobActionsCell } from "./JobCell/JobActionsCell";
import { JobCreateDateCell } from "./JobCell/JobCreateDateCell";
import { JobCreatorCell } from "./JobCell/JobCreatorCell";
import { JobMetricsCell } from "./JobCell/JobMetricsCell";
import { JobNameCell } from "./JobCell/JobNameCell";
import { JobStatusCell } from "./JobCell/JobStatusCell";

export const useGroupTableStyles = makeStyles({
  expand: {
    width: "28px",
    height: "28px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "4px",
    ":hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  expanded: {
    transform: "rotate(90deg)",
  },
  padding: {
    width: "52px",
  },
});
interface GroupTableProps {
  jobNameKeyword?: string;
  creator?: string;
  filter?: string;
}
export const GroupsTable = observer((props: GroupTableProps) => {
  const [isLoadingInitial, setIsLoadingInitial] = React.useState<boolean>(true);
  const [isFetchingNext, setIsFetchingNext] = React.useState<boolean>(false);
  const [canFetchNext, setCanFetchNext] = React.useState<boolean>(true);
  const [currentJob, setCurrentJob] = React.useState<Job | undefined>(
    undefined,
  );
  const sharedStyles = useGroupTableStyles();

  const getExpandStyle = (index: number) =>
    jobListStore.groupExpandIndex.includes(index)
      ? mergeClasses(sharedStyles.expand, sharedStyles.expanded)
      : sharedStyles.expand;
  const groups = jobListStore.groups;
  const displayJobs = displayGroupList.get();
  const jobTemplates = jobListStore.jobTemplates;
  const creator = props.filter === "my" ? undefined : props.creator;
  const creatorEmail =
    props.filter === "my" ? store.account?.username : undefined;

  const fetchNext = React.useCallback(() => {
    if (isFetchingNext || !canFetchNext) {
      return;
    }

    telemetryHelper.logUserActionEvent("FetchNextGroupJobs");
    setIsFetchingNext(true);

    const startId =
      groups.length > 0 ? groups[groups.length - 1].ID : undefined;
    perfWrapper(
      "FetchNextGroupJobs",
      getGroupList({
        GroupName: props.jobNameKeyword,
        CreateBy: creator,
        StartId: startId,
        CreatorSmtpAddress: creatorEmail,
      }).then((fetchedData) => {
        groups.push(...fetchedData);
        updateGroupList(uniqByObject(groups));
        setIsLoadingInitial(false);
        setIsFetchingNext(false);
        if (fetchedData.length === 0) {
          setCanFetchNext(false);
          return;
        }
      }),
    );
  }, [isFetchingNext, canFetchNext, groups.length]);

  const refreshList = () => {
    telemetryHelper.logUserActionEvent("SearchGroupJob");

    updateGroupList([]);
    setIsLoadingInitial(true);
    setIsFetchingNext(false);
    setCanFetchNext(true);

    perfWrapper(
      "FetchNextGroupJobs",
      getGroupList({
        GroupName: props.jobNameKeyword,
        CreateBy: creator,
        CreatorSmtpAddress: creatorEmail,
      })
        .then((fetchedData) => {
          updateGroupList(uniqByObject(fetchedData));
          if (fetchedData.length === 0) {
            setCanFetchNext(false);
            return;
          }
        })
        .finally(() => {
          setIsLoadingInitial(false);
        }),
    );
  };

  React.useEffect(() => {
    refreshList();
  }, [props.jobNameKeyword, props.creator, props.filter]);

  const handleExpand = (index: number) => {
    if (jobListStore.groupExpandIndex.includes(index)) {
      const filtered = jobListStore.groupExpandIndex.filter((i) => i !== index);
      updateExpandedIndex(filtered);
      return;
    }
    updateExpandedIndex([...jobListStore.groupExpandIndex, index]);
  };
  const renderList = () => (
    <GeneralTable
      key={displayJobs.length}
      loadMore={fetchNext}
      hasMore={canFetchNext}
      rows={displayJobs}
      columns={[
        {
          title: "Name",
          render: (job) => {
            if (job.JobName?.startsWith("[Group Job]")) {
              return (
                <>
                  <div
                    className={getExpandStyle(job.ID)}
                    data-testid={"expand-icon-" + job.ID}
                    onClick={(event) => {
                      handleExpand(job.ID);
                      event?.stopPropagation();
                    }}
                  >
                    <ChevronRight16Regular />
                  </div>
                  <Link
                    onClick={() => {
                      updateCurrentPath(`/group/${job.ID}`);
                    }}
                  >
                    {job.JobName}
                  </Link>
                </>
              );
            }
            return (
              <>
                <div className={sharedStyles.padding} />
                <JobNameCell job={job} />
              </>
            );
          },
          weight: 3,
          minWidth: "400px",
        },
        {
          title: "",
          render: (job) => {
            if (job.JobName?.startsWith("[Group Job]")) {
              return <></>;
            }
            return (
              <JobActionsCell
                item={job}
                jobTemplates={jobTemplates}
                onJobSettingsClick={() => {
                  setCurrentJob(job);
                  getJobSettings(job).then((settings) => {
                    setCurrentJob({ ...job, Settings: settings });
                  });
                }}
                onRefreshJobList={refreshList}
                scenario="list"
              />
            );
          },
          weight: 0,
          minWidth: "50px",
        },
        {
          title: "Created By",
          render: (job) => <JobCreatorCell job={job} />,
          weight: 1,
          minWidth: "150px",
        },
        {
          title: "Date",
          render: (job) => <JobCreateDateCell job={job} />,
          weight: 1,
          minWidth: "250px",
        },
        {
          title: "Status",
          render: (job) => <JobStatusCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
        {
          title: "Metrics",
          render: (job) => <JobMetricsCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
      ]}
    />
  );

  const renderEmptyTable = () => (
    <div>
      <Text>No jobs matching found</Text>
    </div>
  );

  return (
    <>
      {isLoadingInitial && <ProgressBar />}
      {!isLoadingInitial &&
        (displayJobs.length > 0 ? renderList() : renderEmptyTable())}

      <JobSettingsDialog
        isOpen={currentJob !== undefined}
        jobName={currentJob?.JobName ?? "Unknown Job"}
        settings={currentJob?.Settings}
        dataSets={currentJob?.DataSets}
        lastSelectedTab={"settings"}
        onClose={() => setCurrentJob(undefined)}
      />
    </>
  );
});
