import { shorthands, tokens } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { getValue } from "../../../selectors/getJobPropV2";
import { ExpPairExperimentView } from "./ExpPairExperimentView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobSimplifyPairExperimentationView = observer(() => {
  const styles = useStyles();
  const experiments = getValue("configs_in_json.conversations.exp_configs");

  if (experiments === undefined || experiments.length !== 2) {
    return <></>;
  }
  return (
    <ResponsiveRow
      maxColumnCount={2}
      maxColumnCountSmall={1}
      columnGap={32}
      rowGap={20}
    >
      <div className={styles.block}>
        <div className={styles.blockTitle}>Control</div>
        <ExpPairExperimentView
          isControl={true}
          config={experiments[0]}
          path={"configs_in_json.conversations.exp_configs[0].sydney.variants"}
        />
      </div>
      {experiments.length > 1 && (
        <div className={styles.block}>
          <div className={styles.blockTitle}>Treatment</div>
          <ExpPairExperimentView
            isControl={false}
            config={experiments[1]}
            path={
              "configs_in_json.conversations.exp_configs[1].sydney.variants"
            }
          />
        </div>
      )}
    </ResponsiveRow>
  );
});
