import {
  JobConfiguration,
  parseJsonStrWithDefault,
} from "@seval-portal/shared";
import { store } from "../../../store/store";
import type { Job } from "../models/Job";

export const downloadFile = (content: string, fileName: string) => {
  // Create a Blob object and create a download link
  const blob = new Blob([content]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}`;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getFileBasename = (path: string) => {
  return path.split("/").pop() ?? "";
};

export const prettyDownloadSetting = (settings: string) => {
  try {
    const jsonObj = JSON.parse(settings);
    return JSON.stringify(jsonObj, null, 2);
  } catch {
    return settings;
  }
};

const checkDownloadPermission = (job: Job) => {
  if (job?.CreatorSmtpAddress === store.account?.username) {
    // return true if the user is the creator
    return true;
  }
  const config = parseJsonStrWithDefault(job?.Settings, JobConfiguration, {});
  const userIdInConfig = config?.configs_in_json?.conversations?.user_id;
  if (
    userIdInConfig === undefined ||
    !userIdInConfig.endsWith("@microsoft.com")
  ) {
    // for public job, no limitation for download
    return true;
  }
  // for personal job, need to check if the user is the creator
  return userIdInConfig === store.account?.username;
};

export const hasDownloadPermission = (job: Job | undefined) => {
  if (!job) {
    return false;
  }
  const hasReadPermission = job?.Properties?.HasReadPermission === true;

  const isValidForOwner = checkDownloadPermission(job);

  const isValidJobType =
    job?.ExperimentName !== "BizChat-Personalized-Query-Set-Generator-V2" &&
    job?.JobTemplateType !== "BingV2" &&
    job?.JobTemplateType !== "CWC" &&
    job?.JobTemplateType !== "BingGenerator";

  return isValidJobType && isValidForOwner && hasReadPermission;
};
