import { TemplateType, checkFakeTenantValidation } from "@seval-portal/shared";
import { computed } from "mobx";
import { store } from "../../../../store/store";
import { jobStore } from "../../store/jobStore";
import { getUnifiedUserId } from "../getJobPropV2";
import {
  getMultiServiceTemplate,
  getTemplateType,
  getWhetherUnifiedTemplate,
} from "../getTemplateType";

export const getUserIdError = computed(() => {
  // First we only check user id in Normal template & Flight Review template
  const templateType = getTemplateType.get();

  // Also check multi service template
  const isMultiServiceTemplate = getMultiServiceTemplate.get();
  const isUnifiedTemplateType = getWhetherUnifiedTemplate.get();
  if (
    templateType !== TemplateType.Normal &&
    templateType !== TemplateType.BizChatFlightReview &&
    isMultiServiceTemplate === false &&
    isUnifiedTemplateType === false
  ) {
    return undefined;
  }

  // Then we check the evaluation type
  // For personal account, we should check user id exactly match the current user
  // For test tenant, we should check user id is not empty and not end with @microsoft.com
  const userId = getUnifiedUserId.get();
  const evaluationType = jobStore.selectedEvaluationType;
  switch (evaluationType) {
    case "PersonalAccount":
      if (userId === undefined) {
        return "User ID is undefined. Please enter a valid user ID.";
      }
      if (userId.trim() === "") {
        return "User ID is empty. Please enter a valid user ID.";
      }
      if (userId !== store.account?.username) {
        return "User ID should match the current user.";
      }
      return undefined;

    case "FakeTenant":
      if (userId === undefined) {
        return "Test tenant ID is undefined. Please enter a valid test tenant user ID.";
      }
      if (userId.trim() === "") {
        return "Test tenant ID is empty. Please enter a valid test tenant user ID.";
      }
      if (userId.endsWith("@microsoft.com")) {
        return "Test tenant ID should not belong to @microsoft.com";
      }
      if (!checkFakeTenantValidation(userId)) {
        return "Test tenant ID should match the email pattern.";
      }
      return undefined;
  }
});
