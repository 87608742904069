import type { JobSBSExpResult, SBSExp } from "../models/JobSBSExpResult";

const getDisplaySbsLinks = (sbsLinks: SBSExp[] | undefined) => {
  const links = sbsLinks ?? [];
  const filterLinks =
    links.length > 1 ? links.filter((_) => _.isMainExperiment) : links;
  return filterLinks;
};

const sbsHost = "https://sbs-tool.microsoft.com/";

export const calculateSbsExpLinks = (sbsExpResult: JobSBSExpResult) => {
  const sbsExperimentLinks = getDisplaySbsLinks(sbsExpResult?.sbsExperiments)
    .map((_) => [
      {
        link: `${sbsHost}substratesearchsbs?expName=${_.name}`,
        text: "Judge Link",
      },
      {
        link: `${sbsHost}substratesearchsbsresults?expName=${_.name}`,
        text: "Result Link",
      },
    ])
    .reduce((acc, val) => acc.concat(val), []);
  const sbsControlAnnotationLinks = getDisplaySbsLinks(
    sbsExpResult?.controlAnnotationExperiments,
  )
    .map((_) => [
      {
        link: `${sbsHost}substrateannotationjudge?expName=${_.name}`,
        text: "Control Annotation Judge Link",
      },
      {
        link: `${sbsHost}substrateannotationresult?expName=${_.name}`,
        text: "Control Annotation Result Link",
      },
    ])
    .reduce((acc, val) => acc.concat(val), []);
  const sbsTreatmentAnnotationLinks = getDisplaySbsLinks(
    sbsExpResult?.treatmentAnnotationExperiments,
  )
    .map((_) => [
      {
        link: `${sbsHost}substrateannotationjudge?expName=${_.name}`,
        text: "Treatment Annotation Judge Link",
      },
      {
        link: `${sbsHost}substrateannotationresult?expName=${_.name}`,
        text: "Treatment Annotation Result Link",
      },
    ])
    .reduce((acc, val) => acc.concat(val), []);
  const sbsExpLinks = sbsExpResult.sbsExpLinks ?? [];
  const processedSbsExpLinks = sbsExpLinks.map((link) => ({
    link: link.link,
    text: link.text,
  }));

  const allLinks = [
    sbsExperimentLinks,
    sbsControlAnnotationLinks,
    sbsTreatmentAnnotationLinks,
    processedSbsExpLinks,
  ].reduce((acc, val) => acc.concat(val), []);

  return allLinks.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.text === item.text),
  );
};
