import { UN_SELECTED_FILE_NAME } from "../../../components/JobCreation/UnifiedTemplatesComponents/metadata/unifiedMetaData";

export const checkUnifiedQuerySetName = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined || value === "") {
    return { path, message: "Query set name should not be empty!" };
  }
  if (value === UN_SELECTED_FILE_NAME) {
    return { path, message: "Query set name should be selected!" };
  }
  return undefined;
};
