import { object, or, str } from "@seval-portal/shared";

export const HeronJobOutputLinkSuccessResponse = object({
  value: str,
});

export const HeronJobOutputLinkErrorResponse = object({
  error: object({
    code: str,
    message: str,
  }),
});

export const HeronJobOutputLinkResponse = or([
  HeronJobOutputLinkSuccessResponse,
  HeronJobOutputLinkErrorResponse,
]);

export type HeronJobOutputLinkSuccessResponse = ReturnType<
  typeof HeronJobOutputLinkSuccessResponse
>;
export type HeronJobOutputLinkErrorResponse = ReturnType<
  typeof HeronJobOutputLinkErrorResponse
>;
export type HeronJobOutputLinkResponse = ReturnType<
  typeof HeronJobOutputLinkResponse
>;

export const isHeronJobOutputLinkSuccessResponse = (
  response: HeronJobOutputLinkResponse,
): response is HeronJobOutputLinkSuccessResponse => {
  return "value" in response;
};

export const isHeronJobOutputLinkErrorResponse = (
  response: HeronJobOutputLinkResponse,
): response is HeronJobOutputLinkErrorResponse => {
  return "error" in response;
};
