import type { JobGroupSettings, JobGroupTemplate } from "@seval-portal/shared";
import { mutatorAction } from "satcheljs";
import type {
  JobGroupCreationMode,
  LocalJobSettings,
} from "../store/jobGroupCreationStore";
import {
  initJobGroupCreationStore,
  jobGroupCreationStore,
} from "../store/jobGroupCreationStore";

export const resetGroupCreationStore = mutatorAction(
  "resetGroupCreationStore",
  () => {
    Object.assign(jobGroupCreationStore, {
      ...initJobGroupCreationStore,
    });
  },
);

export const initJobGroupTemplates = mutatorAction(
  "initJobGroupTemplates",
  (templates: JobGroupTemplate[]) => {
    jobGroupCreationStore.groupTemplates = templates;
  },
);

export const updateCreationMode = mutatorAction(
  "updateCreationMode",
  (mode: JobGroupCreationMode) => {
    jobGroupCreationStore.creationMode = mode;
  },
);

export const updateLoadingMode = mutatorAction(
  "updateLoadingMode",
  (isLoading: boolean) => {
    jobGroupCreationStore.isLoading = isLoading;
  },
);

export const updateGroupName = mutatorAction(
  "updateGroupName",
  (groupName: string) => {
    jobGroupCreationStore.groupName = groupName;
  },
);

export const updateAvailableGroupTemplate = mutatorAction(
  "updateAvailableGroupTemplate",
  (templates: JobGroupTemplate[]) => {
    jobGroupCreationStore.groupTemplates = templates;
  },
);

export const updateSelectedGroupTemplate = mutatorAction(
  "updateSelectedGroupTemplate",
  (template: JobGroupTemplate) => {
    jobGroupCreationStore.selectedGroupTemplate = template;
  },
);
export const updateSelectedGroupTemplateSettings = mutatorAction(
  "updateSelectedGroupTemplateSettings",
  (template: JobGroupSettings | undefined) => {
    jobGroupCreationStore.selectedGroupTemplateSettings = template;
  },
);

export const updateGroupJobs = mutatorAction(
  "updateGroupJobs",
  (jobs: LocalJobSettings[]) => {
    jobGroupCreationStore.jobs = jobs;
  },
);

export const updateCurrentLocalJobId = mutatorAction(
  "updateCurrentLocalJobId",
  (localId: string | undefined) => {
    jobGroupCreationStore.currentLocalJobId = localId;
  },
);

export const updateIsDismissDialogOpen = mutatorAction(
  "updateIsDismissDialogOpen",
  (isOpen: boolean) => {
    jobGroupCreationStore.isDismissDialogOpen = isOpen;
  },
);

export const updateIsSubmitGroup = mutatorAction(
  "updateIsSubmitGroup",
  (isSubmitting: boolean) => {
    jobGroupCreationStore.isSubmitting = isSubmitting;
  },
);

export const updateToastStatus = mutatorAction(
  "updateToastStatus",
  (
    status:
      | { message: string; state: "success" | "error" | "start" | "update" }
      | undefined,
  ) => {
    jobGroupCreationStore.toastStatus = status;
  },
);
