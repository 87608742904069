import { array, bool, object, optional, str } from "@seval-portal/shared";

const SBSExp = object({
  name: str,
  isMainExperiment: bool,
});

export const SBSExpLink = object({
  link: str,
  text: str,
});

export const JobSBSExpResult = object({
  sbsExperiments: optional(array(SBSExp)),
  controlAnnotationExperiments: optional(array(SBSExp)),
  treatmentAnnotationExperiments: optional(array(SBSExp)),
  sbsExpLinks: optional(array(SBSExpLink)),
});

export type JobSBSExpResult = ReturnType<typeof JobSBSExpResult>;
export type SBSExp = ReturnType<typeof SBSExp>;

export type SBSLink = {
  link: string;
  text: string;
};
