import { Card } from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { formatCreateTime } from "../../../helpers/formatHelper";
import { groupDetailStore } from "../../../store/groupDetailStore";
import { useGroupDetailPageStyles } from "../sharedStyles";

export const GroupInforPane = observer(() => {
  const styles = useGroupDetailPageStyles();
  const currentGroup = groupDetailStore.group;
  const currentGroupTemplate = computed(() =>
    groupDetailStore.groupTemplates.find(
      (_) => _.Id === currentGroup?.GroupTemplateId,
    ),
  ).get();
  return (
    <Card className={styles.infoCard}>
      <ResponsiveRow maxColumnCount={3} maxColumnCountSmall={1}>
        <div className={styles.rowWithGap}>
          <div className={styles.infoTitle}>Group Template</div>
          <div>
            [{currentGroup?.GroupTemplateId}]{" "}
            {currentGroupTemplate?.Name ?? currentGroup?.TemplateName}
          </div>
        </div>
        <div className={styles.rowWithGap}>
          <div className={styles.infoTitle}>Created By</div>
          <div>{currentGroup?.CreatedBy}</div>
        </div>
        <div className={styles.rowWithGap}>
          <div className={styles.infoTitle}>Created At</div>
          <div>{formatCreateTime(currentGroup?.CreatedAt)}</div>
        </div>
      </ResponsiveRow>
    </Card>
  );
});
