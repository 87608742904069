import { array, constStr, or } from "@seval-portal/shared";

export interface SnapshotQuery {
  id: string;
  query: string;
  segmentTwo?: string;
  segmentOne?: string;
  queryOwner?: string;
  environment?: string;
  term?: string;
  freOrSparklePrompt?: string;
  groundingDataSource?: string;
  dataSourceInfo?: string;
  sets?: string[];
  flags?: string[];
  assertions?: Assertion[];
  tags?: string[];
  annotations?: Annotation[];
  label?: string;
  plugins?: string;
  pluginoptions?: string;
  timestamp?: string;
  userId?: string;
  gptName?: string;
}

export type Snapshot = {
  queries: SnapshotQuery[];
  taggedAssertions: TaggedAssertions[];
};

export type QueriesTSV = {
  Utterance: string;
  "Segment 1": string;
  "Segment 2": string;
  "Query Owner": string;
  Environment: string;
  Term: string;
  "FRE or Sparkle Prompt": string;
  CIQ: string;
  Type: string;
  "Grounding Data Source": string;
  "Data source information": string;
  Sets: string;
  annotation?: string;
  label: string;
  plugins: string;
  pluginoptions: string;
  timestamp: string;
  user_id: string;
};

export const AssertionPropertyValues = [
  "SydneyReply",
  "SydneySearches",
  "SydneySuggestion",
  "SydneyCitations",
  "EntityRepresentations",
] as const;

export type AssertionProperty = (typeof AssertionPropertyValues)[number];

export const AssertionProperties = array(
  or(AssertionPropertyValues.map((v) => constStr(v))),
);

export type AssertionExample = {
  response: string;
  score: number;
  rationale: string;
};

export type Assertion = {
  text: string;
  level?: string;
  examples?: AssertionExample[];
  properties?: AssertionProperty[];
  owner?: string;
  id?: string;
  draft?: boolean;
  fromTag?: string;
};

export type Annotation = {
  id: string;
  text: string;
  queryAnnotationType: string;
};

export interface Query extends SnapshotQuery {
  draft?: boolean;
  archived?: boolean;
  edited?: boolean;
}

export type TaggedAssertions = {
  tag: string;
  tags?: string[];
  assertions?: Assertion[];
};

export type ExportedAssertion = {
  text: string;
  level?: string;
  examples?: AssertionExample[];
  properties?: AssertionProperty[];
  owner?: string;
};

export type AssertionsYaml = {
  query: string;
  assertions: ExportedAssertion[];
  tags?: string[];
};
