import { action } from "satcheljs";
import type { SettingFileName } from "@seval-portal/shared";
import type { AppConfigurationSnapshot } from "../models/AppConfigurationSnapshot";

export const resetAppConfiguration = action("resetAppConfiguration");

export const selectAppConfigurationFile = action(
  "selectAppConfigurationFile",
  (fileName: SettingFileName) => ({
    fileName,
  }),
);

export const selectAppConfigurationSnapshot = action(
  "selectAppConfigurationSnapshot",
  (snapshot: AppConfigurationSnapshot) => ({
    snapshot,
  }),
);

export const updateAppConfigurationContent = action(
  "updateAppConfigurationContent",
);
