import { computed } from "mobx";
import { TemplateType } from "@seval-portal/shared";
import type { UserActionTelemetryEventName } from "../../../constants/telemetryName";
import { getFilePrefix } from "../../../helpers/accountHelper";
import { getCustomQuerySets, verifyToken } from "../../../helpers/apiHelper";
import { telemetryHelper } from "../../../helpers/telemetryHelper";
import { is3pExtPluginsConfigured } from "../../../helpers/tokenHelper";
import { isFeatureEnabled } from "../../../selectors/features";
import { store } from "../../../store/store";
import { updatePropValueActionV2 } from "../actions/jobActions";
import type { RefreshTokenType } from "../models/Types";
import { updateCustomizedQuerySets } from "../mutators/jobCreationFileMutators";
import {
  updateIsPreValidating,
  updateStatusIsTokenDialogOpen,
  updateTokenDialogEmail,
  updateTokenUploadingProps,
} from "../mutators/jobCreationMutators";
import { updateSBSConfirmationResolve } from "../mutators/jobMutators";
import {
  resetJobPreValidationSteps,
  updateJobPreValidationJobTemplateName,
  updateJobPreValidationResolve,
} from "../mutators/jobPreValidationMutators";
import { getUserId, getValue } from "../selectors/getJobPropV2";
import {
  getMultiServiceTemplate,
  getTemplateType,
} from "../selectors/getTemplateType";
import { jobCreationStore } from "../store/jobCreationStore";
import { jobPriorityStore } from "../store/jobPriorityStore";
import { jobStore } from "../store/jobStore";
import { getTargetAccount } from "./jobSyntheticUserHelper";
import { getCreatorName } from "./querySetHelper";
import {
  isPriorityJobADOLinkValid,
  updateADOLinkValidationStatus,
} from "./validateADOLinkHelper";

export const isJobCreationEditDisabled = computed(() => {
  return (
    jobCreationStore.isSubmitting ||
    jobCreationStore.isVerifying ||
    jobCreationStore.isPreValidating
  );
});

export const refreshQuerySets = () => {
  return getCustomQuerySets().then((customQS) => {
    return customQS
      .filter((_) => getCreatorName(_.name) === getFilePrefix(store.account))
      .map((_) => _.name);
  });
};

export const refreshCustomQuerySets = () =>
  refreshQuerySets()
    .then(updateCustomizedQuerySets)
    .catch(() => undefined);

// If the job create_sbs_experiment is true, we should pop up the confirmation dialog.
// After that We should verify the token when all the conditions are met:
// 1. The template type is BizChatFlightReview or Normal, in this case the job is MChat job.
// 2. The user has provided the target account
export const confirmSBSValidator = () => {
  if (getValue("create_sbs_experiment") === true) {
    return new Promise<boolean>((resolve) => {
      updateSBSConfirmationResolve(resolve);
    }).then((result) => {
      switch (result) {
        case true:
          telemetryHelper.logUserActionEvent("ClickAcceptSBS");
          break;
        case false:
          telemetryHelper.logUserActionEvent("ClickDeclineSBS");
          break;
      }
      updatePropValueActionV2({
        prop: "create_sbs_experiment",
        newData: result,
      });
    });
  } else {
    return Promise.resolve();
  }
};

export const priorityJobValidator = async () => {
  if (isFeatureEnabled("job-priority") && jobPriorityStore.isPriorityJob) {
    if (!jobPriorityStore.jobPriorityDescription?.trim().length) {
      throw new Error("The description for priority job is empty");
    }
    await updateADOLinkValidationStatus();
    if (isPriorityJobADOLinkValid() === false) {
      throw new Error("The ADO link for priority job is invalid");
    }
  }
};

export const verifyTokenAndLogTelemetry = async (
  tokenType: RefreshTokenType,
  tokenShortName: string,
  targetAccount: string,
) => {
  let verifyTokenParsedResult: {
    isSuccess: boolean;
    errorMessage?: string;
  };
  telemetryHelper.logUserActionEvent(
    `Verify${tokenShortName}` as UserActionTelemetryEventName,
    {
      inputType: "Refreshtoken",
      source: "JobCreationViewContent",
    },
  );
  try {
    const verifyTokenResult = await verifyToken({
      Email: targetAccount,
      TokenType: tokenType,
    });
    if (!verifyTokenResult?.isValid) {
      verifyTokenParsedResult = {
        isSuccess: false,
        errorMessage: verifyTokenResult.message,
      };
      telemetryHelper.logUserActionEvent(
        `Verify${tokenShortName}Failure` as UserActionTelemetryEventName,
        {
          inputType: "Refreshtoken",
          message: verifyTokenResult.message,
          source: "JobCreationViewContent",
        },
      );
    } else {
      verifyTokenParsedResult = {
        isSuccess: true,
      };
      telemetryHelper.logUserActionEvent(
        `Verify${tokenShortName}Success` as UserActionTelemetryEventName,
        {
          inputType: "Refreshtoken",
          source: "JobCreationViewContent",
        },
      );
    }
  } catch (err) {
    verifyTokenParsedResult = {
      isSuccess: false,
      errorMessage: err?.toString(),
    };
    telemetryHelper.logUserActionEvent(
      `Verify${tokenShortName}Failure` as UserActionTelemetryEventName,
      {
        inputType: "Refreshtoken",
        message: err?.toString(),
        source: "JobCreationViewContent",
      },
    );
  }

  return verifyTokenParsedResult;
};

export const tokenPopupValidator = async () => {
  const jobTemplateType = getTemplateType.get();
  const targetAccount = await getTargetAccount(getUserId.get());

  const isBizChatTemplate =
    jobTemplateType === TemplateType.BizChatFlightReview ||
    jobTemplateType === TemplateType.Normal;
  const isMultiServiceTemplate = getMultiServiceTemplate.get();
  const templateMatch = isBizChatTemplate || isMultiServiceTemplate;

  const emailMatch = targetAccount !== undefined && targetAccount !== "";

  if (templateMatch && emailMatch) {
    // For all jobs, for the feature "refresh token migration"
    // * when feature enabled, we only need to verify the SEVAL token
    // * when feature disabled, we need to verify the 3S token
    const isMigrationEnabled = isFeatureEnabled("refresh-token-migration");
    const isScrapingServiceMigration = isFeatureEnabled(
      "scraping-service-migration",
    );

    if (isScrapingServiceMigration) {
      // No need to verify the token, only know token missing or expired after calling the scraping service API
      updateTokenDialogEmail(targetAccount);
      updateStatusIsTokenDialogOpen(false);
      return Promise.resolve();
    }

    // 3S token verification, always verify 3S token
    const substrateVerifyTokenResult = await verifyTokenAndLogTelemetry(
      "3S refresh token",
      "Token",
      targetAccount,
    );

    // SEVAL token verification we always treat the SEVAL token as valid
    const sevalVerifyTokenResult = await verifyTokenAndLogTelemetry(
      "SEVAL refresh token",
      "SevalRefreshToken",
      targetAccount,
    );

    // Teams cli token verification
    const teamsCliVerifyTokenResult = is3pExtPluginsConfigured()
      ? await verifyTokenAndLogTelemetry(
          "Teams CLI refresh token",
          "TeamsCliRefreshToken",
          targetAccount,
        )
      : {
          isSuccess: true,
        };

    // If teams cli token is invalid, we should update the token
    const shouldUpdateTeamsCliToken = !teamsCliVerifyTokenResult.isSuccess;

    // In those cases, we should ask user update substrate token
    // * Feature is not enabled and 3S token is invalid
    const shouldUpdateSubstrateToken =
      !isMigrationEnabled && !substrateVerifyTokenResult.isSuccess;

    // In those cases, we should ask user update seval token (this step can skip)
    // * Feature is enabled and SEVAL token is invalid
    const shouldUpdateSevalToken =
      isMigrationEnabled && !sevalVerifyTokenResult.isSuccess;

    // If user only need to update the SEVAL token, and the 3S token is valid,
    // we can let user skip the token uploading to ensure a smooth experience
    const canSkipTokenUploading = false;

    if (
      shouldUpdateTeamsCliToken ||
      shouldUpdateSubstrateToken ||
      shouldUpdateSevalToken
    ) {
      updateTokenUploadingProps(
        shouldUpdateSubstrateToken,
        shouldUpdateSevalToken,
        shouldUpdateTeamsCliToken,
        canSkipTokenUploading,
      );

      updateTokenDialogEmail(targetAccount);
      updateStatusIsTokenDialogOpen(true);
      throw new Error(
        `Account Token Invalid: ${JSON.stringify({
          "3S refresh token": substrateVerifyTokenResult.errorMessage,
          "SEVAL refresh token": sevalVerifyTokenResult.errorMessage,
          "Teams CLI refresh token": teamsCliVerifyTokenResult.errorMessage,
        })}`,
      );
    }
  } else {
    return Promise.resolve();
  }
};

// The templates that need prevalidation
// BizChat templates exclude: MCP, skip scraping
// OPG sydney augloop compare templates
const preValidationRequiredTemplates = [
  "BizChat_Evaluation_Flight_Review",
  "BizChat_Evaluation_All_Metrics",
  "BizChat_Shared_Leo_Only",
  "BizChat_Sydney_Scraping_Only",
  "AppChat_Copilot_Evaluation_All_Metrics",
  "AppChat_Copilot_Evaluation_All_Metrics_for_test_tenant",
];
export const servicePrevalidation = () => {
  const needPreValidation = computed(() => {
    return preValidationRequiredTemplates.some((templateName) =>
      jobStore.selectedTemplate?.ExperimentName.toLocaleLowerCase().includes(
        templateName.toLocaleLowerCase(),
      ),
    );
  });

  if (needPreValidation.get()) {
    updateIsPreValidating(true);
    resetJobPreValidationSteps();
    updateJobPreValidationJobTemplateName(jobStore.selectedTemplate?.Name);
    return new Promise<boolean>((resolve) => {
      updateJobPreValidationResolve(resolve);
    })
      .then((result) => {
        switch (result) {
          case true:
            return Promise.resolve();
          case false:
            throw new Error("Job Creation Verification Failed");
        }
      })
      .finally(() => {
        updateIsPreValidating(false);
      });
  } else {
    return Promise.resolve();
  }
};
