import { isValidSettingsThrowError } from "@seval-portal/shared";
import yaml from "yaml";
import { getFileName } from "../../../helpers/accountHelper";
import { getCustomLMChecklistSets } from "../../../helpers/apiHelper";
import { store } from "../../../store/store";
import { checkFileEncoding } from "./querySetHelper";
import schema from "./schema/lmchecklist_data_schema.json";

export const checkFileExistence = (file: File) => {
  const fileName = getFileName(store.account, file);
  const filePath = fileName ? `lmchecklist/${fileName}` : "";

  return getCustomLMChecklistSets().then((customLMChecklistSets) => {
    if (customLMChecklistSets.includes(filePath)) {
      throw new Error(`The file ${fileName} already exists.`);
    }

    return Promise.resolve(file);
  });
};

export const checkFileSchema = async (file: File) => {
  try {
    const fileText = await file.text();
    isValidSettingsThrowError(
      JSON.stringify(yaml.parse(fileText)),
      JSON.stringify(schema),
    );

    return file;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(
        `LMChecklist file is invalid. YAML parsing error: ${e.message}`,
      );
    } else {
      throw new Error(`LMChecklist file is invalid.`);
    }
  }
};

export const checkLMChecklistSet = (file: File) => {
  return Promise.resolve(file)
    .then(checkFileEncoding)
    .then(checkFileExistence)
    .then(checkFileSchema);
};
