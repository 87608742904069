import { Partner } from "@seval-portal/client-utils";
import { CreatePage } from "./pages/Create";
import { ResultPage } from "./pages/Result";

export const partnerExample: Partner = {
  name: "example",
  pages: {
    jobCreatePage: CreatePage,
    jobResultPage: ResultPage,
  },
};
