import { computed } from "mobx";
import { anyObject, parseJsonStr } from "@seval-portal/shared";
import { jobStore } from "../store/jobStore";
import { getJSONConfigError } from "./creationError/getJSONConfigError";

export const getJobConfigFromJSON = computed(() => {
  // if there is JSONConfig error, return undefined
  if (getJSONConfigError.get() !== undefined) {
    return undefined;
  }

  try {
    return parseJsonStr(jobStore.configurationJsonString, anyObject);
  } catch {
    return undefined;
  }
});
