import { uniqByObject } from "@seval-portal/shared";
import { compact } from "lodash";
import { computed } from "mobx";
import type { JobConfigurationUpdateAction } from "../../models/JobConfigurationAction";
import { getConfigPathErrorsByRule } from "./getConfigPathErrorsByRule";
import { getConfigPathErrorsBySchema } from "./getConfigPathErrorsBySchema";
import { getDataSetsError } from "./getDataSetsError";
import { getJSONConfigError } from "./getJSONConfigError";
import { getJSONSchemaError } from "./getJSONSchemaError";
import { getJobNameError } from "./getJobNameError";
import { getUserIdError } from "./getUserIdError";

export type JobCreationError = {
  state: "error" | "none";
  message: string | undefined;
};

export type JobCreationPathError = {
  path: string;
  message: string;
};

type JobCreationErrors = {
  userIdError: JobCreationError;
  jobNameError: JobCreationError;
  dataSetsError: JobCreationError;
  jsonConfigError: JobCreationError;
  jsonSchemaError: JobCreationError;
};

export const convertPathToKey = (
  path: JobConfigurationUpdateAction["prop"],
) => {
  return `#/${path}`
    .replaceAll(".", "/")
    .replaceAll("[", "/")
    .replaceAll("]", "");
};

const wrapper = (message: string | undefined) => {
  return {
    state: message !== undefined ? ("error" as const) : ("none" as const),
    message,
  };
};

export const getJobCreationError = computed<JobCreationErrors>(() => {
  return {
    userIdError: wrapper(getUserIdError.get()),
    jobNameError: wrapper(getJobNameError.get()),
    dataSetsError: wrapper(getDataSetsError.get()),
    jsonConfigError: wrapper(getJSONConfigError.get()),
    jsonSchemaError: wrapper(getJSONSchemaError.get()),
  };
});

export const getJobCreationPathErrors = computed<JobCreationPathError[]>(() => {
  const errosByRule = getConfigPathErrorsByRule.get();
  const errorsBySchema = getConfigPathErrorsBySchema.get();
  return errosByRule.concat(errorsBySchema);
});

export const getJobErrorByPath = (
  path: JobConfigurationUpdateAction["prop"],
) => {
  const errors = getJobCreationPathErrors.get();
  const pathKey = convertPathToKey(path);
  const targetErrors = errors.filter((_) => _.path === pathKey);

  if (targetErrors.length === 0) {
    return undefined;
  }

  return targetErrors[0];
};

export const getJobCreationErrorMessages = computed(() => {
  const basicErrors = Object.values(getJobCreationError.get()).filter(
    (_) => _.state === "error",
  );

  const pathErrors = getJobCreationPathErrors.get();

  return compact(uniqByObject(basicErrors).map((_) => _.message)).concat(
    uniqByObject(pathErrors).map((_) => _.message),
  );
});

export const getJobCreationErrorMessage = computed((): string | undefined => {
  const messages = getJobCreationErrorMessages.get();

  switch (messages.length) {
    case 0:
      return undefined;
    case 1:
      return messages[0];
    default:
      return (
        messages.length +
        " errors found in the form, please fix them before submitting"
      );
  }
});
