import { useEffect } from "react";

import { Spinner } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import type { Metric } from "../../models/GeneralMetricsResponse";
import {
  updateResultSegments,
  updateResultSourceMap,
} from "../../mutators/jobResultMutators";
import { getGeneralMetrics } from "../../selectors/getGeneralMetrics";
import { getCurrentScenario } from "../../selectors/getJobShareInfo";
import { resultStore } from "../../store/resultStore";
import { JobResultHeader } from "../GroupDetails/components/JobResultHeader";
import { BingDetailCard } from "./BingDetailCard";
import { DetailCard } from "./DetailCard";
import { RAIResultView } from "./RAIResultView";
import { useResultViewStyles } from "./sharedStyles";

export const ResultsView = observer(() => {
  const { resultJob, templateType, resultSegments } = resultStore;

  const metrics = getGeneralMetrics.get();

  useEffect(() => {
    if (resultJob === undefined) {
      return;
    }

    if (metrics !== undefined) {
      const segments = new Set<string>();
      const sourceMap = new Map<string, Metric[]>();

      const addSegment = (segment: string, record: Metric) => {
        if (sourceMap.get(segment) === undefined) {
          sourceMap.set(segment, [record]);
        } else {
          sourceMap.get(segment)?.push(record);
        }
      };

      metrics.forEach((record) => {
        const segment = record.segment;
        addSegment(segment, record);
        segments.add(record.segment);
      });

      const segmentsArray = Array.from(segments).sort((a, b) => {
        if (a === "All") {
          return -1;
        }
        if (b === "All") {
          return 1;
        }
        return a.localeCompare(b);
      });

      updateResultSegments(segmentsArray);
      updateResultSourceMap(sourceMap);
    }
  }, [resultJob, metrics]);

  const styles = useResultViewStyles();

  if (resultJob === undefined) {
    return <div>Job not found</div>;
  }

  const renderDetailCard = () => {
    if (
      templateType === "BingV2" ||
      templateType === "CWC" ||
      templateType === "ScrapingOnly"
    ) {
      return <BingDetailCard />;
    } else {
      if (resultStore.manifestItem) {
        return <RAIResultView />;
      }
      if (resultJob && resultSegments.length !== 0) {
        return <DetailCard />;
      }
    }
    const currentScenario = getCurrentScenario();
    if (currentScenario === "group") {
      return (
        <div>
          <div className={styles.scenarioRow}>
            <div className={styles.buttonRow}>
              <JobResultHeader />
            </div>
          </div>
          {resultStore.loadingQueue.length > 0 && <Spinner />}
          {resultStore.loadingQueue.length === 0 && (
            <div className={styles.detailedCard}>No Data.</div>
          )}
        </div>
      );
    }
    return <div></div>;
  };

  return <div className={styles.root}>{renderDetailCard()}</div>;
});
