import { mutatorAction } from "satcheljs";
import { counterStore } from "../store/counterStore";

export const incrementCounter = mutatorAction("incrementCounter", () => {
  counterStore.count++;
});

export const decrementCounter = mutatorAction("decrementCounter", () => {
  counterStore.count--;
});

export const resetCounter = mutatorAction("resetCounter", () => {
  counterStore.count = 0;
});

export const setCounter = mutatorAction("setCounter", (count: number) => {
  counterStore.count = count;
});
