import { Dropdown, InfoLabel, Option } from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import {
  deletePropValueActionV2,
  updatePropValueActionV2,
} from "../../../actions/jobActions";
import { getValue } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { useExpStyles } from "./expSharedStyles";
export const expServicePath = [
  "configs_in_json.conversations.exp_configs[0].service",
  "configs_in_json.conversations.exp_configs[1].service",
] as const;
export const expAugloopPath = [
  "configs_in_json.conversations.exp_configs[0].augloop",
  "configs_in_json.conversations.exp_configs[1].augloop",
] as const;
export const expSydneyPath = [
  "configs_in_json.conversations.exp_configs[0].sydney",
  "configs_in_json.conversations.exp_configs[1].sydney",
] as const;
export const expAugloopCopilotPath = [
  "configs_in_json.conversations.exp_configs[0].augloop.copilot",
  "configs_in_json.conversations.exp_configs[1].augloop.copilot",
] as const;
interface ExpTextInputViewProps {
  isControl: boolean;
}

export const CopilotDropdownView = observer((props: ExpTextInputViewProps) => {
  const { isControl } = props;
  const styles = useExpStyles();
  const currentServicePath = isControl ? expServicePath[0] : expServicePath[1];
  const currentAugloopPath = isControl ? expAugloopPath[0] : expAugloopPath[1];
  const currentSydneyPath = isControl ? expSydneyPath[0] : expSydneyPath[1];
  const currentAugloopCopilotPath = isControl
    ? expAugloopCopilotPath[0]
    : expAugloopCopilotPath[1];
  const currentService =
    computed(() => getValue(currentServicePath)).get() || "";
  const defaultServiceList = computed(
    () => jobStore.defaultTemplateSettings?.exp_configs.service,
  ).get();
  const defaultCopilots = computed(
    () => jobStore.defaultTemplateSettings?.exp_configs.augloop.copilot || {},
  ).get();
  const defaultCopilotsName = computed(() =>
    defaultCopilots ? Object.keys(defaultCopilots) : [],
  ).get();
  const initCopilot = computed(() => getValue(currentAugloopCopilotPath)).get();
  const [selectedCopilot, setSelectedCopilot] = useState<string>(
    initCopilot ||
      (defaultCopilotsName.length > 0 ? defaultCopilotsName[0] : ""),
  );

  return (
    <>
      {defaultServiceList && (
        <div className={styles.experimentationFieldContainer}>
          <InfoLabel className={styles.experimentationFieldTitle}>
            Service:
          </InfoLabel>
          <Dropdown
            data-testid="service-dropdown"
            style={{ flexGrow: 1 }}
            value={currentService}
            defaultSelectedOptions={[currentService]}
            onOptionSelect={(_, data) => {
              updatePropValueActionV2({
                prop: currentServicePath,
                newData: data.optionValue,
              });
              if (data.optionValue === "sydney") {
                deletePropValueActionV2(currentAugloopPath);
                updatePropValueActionV2({
                  prop: currentSydneyPath,
                  newData:
                    jobStore.defaultTemplateSettings?.exp_configs?.sydney,
                });
              } else if (data.optionValue === "augloop") {
                deletePropValueActionV2(currentSydneyPath);
                updatePropValueActionV2({
                  prop: currentAugloopPath,
                  newData: defaultCopilots[selectedCopilot],
                });
              }
            }}
          >
            {defaultServiceList.map((service) => (
              <Option key={service} value={service}>
                {service}
              </Option>
            ))}
          </Dropdown>
        </div>
      )}
      {currentService === "augloop" && defaultCopilotsName.length > 0 && (
        <div className={styles.experimentationFieldContainer}>
          <InfoLabel className={styles.experimentationFieldTitle}>
            Copilot:
          </InfoLabel>
          <Dropdown
            data-testid="copilot-dropdown"
            style={{ flexGrow: 1 }}
            value={selectedCopilot}
            defaultSelectedOptions={[selectedCopilot]}
            onOptionSelect={(_, data) => {
              if (
                defaultCopilots &&
                data.optionValue &&
                Object.keys(defaultCopilots).includes(data.optionValue) &&
                jobStore.defaultTemplateSettings?.exp_configs.augloop.copilot
              ) {
                setSelectedCopilot(data.optionValue);
                updatePropValueActionV2({
                  prop: currentAugloopPath,
                  newData: {
                    ...jobStore.defaultTemplateSettings?.exp_configs.augloop
                      .copilot[data.optionValue],
                    copilot: data.optionValue,
                  },
                });
              }
            }}
          >
            {defaultCopilotsName.map((copliot) => (
              <Option key={copliot} value={copliot}>
                {copliot}
              </Option>
            ))}
          </Dropdown>
        </div>
      )}
    </>
  );
});
