import {
  Button,
  Field,
  Textarea,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import {
  GetJobPriorityRouterParser,
  SetJobPriorityRouterParser,
} from "@seval-portal/shared";
import { useState } from "react";
import { getJobPriority, setJobPriority } from "../../helpers/apiHelper";
import { closeSidePane } from "../../mutators/sidePaneMutators";

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);

// Add styles for the close button
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },
  buttonGroup: {
    display: "flex",
    gap: "8px",
  },
  responseText: {
    flex: 1, // Allow the response box to take up remaining space
    marginTop: "16px",
    padding: "8px",
    backgroundColor: tokens.colorNeutralBackground1,
    border: `1px solid ${tokens.colorNeutralStroke1}`,
    borderRadius: tokens.borderRadiusSmall,
    whiteSpace: "pre-wrap", // Preserve formatting for JSON
    fontFamily: "monospace", // Use monospace font for better readability
    overflowY: "auto", // Enable vertical scrolling if content exceeds available space
  },
  inputField: {
    height: "320px",
    boxSizing: "border-box", // Ensure padding and border are included in height
  },

  field: {
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    alignSelf: "flex-start",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row-reverse", // Reverse the order to place the close button left of the title
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export const JobPriorityPane = () => {
  const styles = useStyles();
  const [requestParams, setRequestParams] = useState("");
  const [response, setResponse] = useState("");

  const handleSetPriority = async () => {
    try {
      const params = SetJobPriorityRouterParser(
        JSON.parse(requestParams),
        "setJobPriority",
      );
      const data = await setJobPriority(params);
      setResponse(JSON.stringify(data, null, 2));
    } catch (error) {
      setResponse(
        `Error: ${error instanceof Error ? error.message : "Unknown error"}`,
      );
    }
  };

  const handleGetPriority = async () => {
    try {
      const params = GetJobPriorityRouterParser(
        JSON.parse(requestParams),
        "getJobPriority",
      );
      const data = await getJobPriority(params);
      setResponse(JSON.stringify(data, null, 2));
    } catch (error) {
      setResponse(
        `Error: ${error instanceof Error ? error.message : "Unknown error"}`,
      );
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleRow}>
        <div className={styles.title}>Job Priority Management</div>
        <div
          className={styles.closeButton}
          role="button"
          aria-label="Close Button"
          onClick={closeSidePane}
        >
          <BackIcon />
        </div>
      </div>
      <Field label="Request Parameters" className={styles.field}>
        <Textarea
          className={styles.inputField}
          value={requestParams}
          onChange={(e) => setRequestParams(e.target.value)}
          placeholder="Enter request parameters as JSON"
          size="large"
        />
      </Field>
      <div className={styles.buttonGroup}>
        <Button onClick={handleSetPriority} appearance="primary">
          Set Priority
        </Button>
        <Button onClick={handleGetPriority} appearance="secondary">
          Get Priority
        </Button>
      </div>
      {response && <div className={styles.responseText}>{response}</div>}
    </div>
  );
};
