import { uniqByObject } from "@seval-portal/shared";
import { compact } from "lodash";
import { orchestrator } from "satcheljs";
import { getSubstrateToken } from "../../../helpers/accessTokenHelper";
import {
  addJobPermission,
  batchAddJobsPermission,
  getJobPermission,
  getSevalUserList,
  removeJobPermission,
  searchSGById,
} from "../../../helpers/apiHelper";
import { getRandomUUID } from "../../../helpers/getRandomUUID";
import type { SubstrateSingleUserInfor } from "../../../models/SearchUserByIdResponse";
import {
  batchInviteUsersAction,
  inviteUsersAction,
  openJobShareDialog,
  removeUsersAction,
} from "../actions/jobShareActions";
import { getUserProfileResponse } from "../helpers/getUserProfileResponseHelper";
import {
  removeUserFromJobPermission,
  setIsOpenShareDialog,
  updateJobPermission,
  updateJobShareInvalidErrorMessage,
  updateSevalUserList,
  updateShareIsLoading,
  updateUserProfile,
} from "../mutators/jobShareMutators";
import {
  getCurrentScenario,
  getGroupJobIdsNeedShare,
  getIsGroupShareDialog,
  getSharedRecordId,
} from "../selectors/getJobShareInfo";
import { jobGroupCreationStore } from "../store/jobGroupCreationStore";
import { jobShareStore } from "../store/jobShareStore";

const checkCurrentPermission = (jobId: string | undefined) => {
  if (jobId === undefined) {
    const currentScenario = getCurrentScenario();
    if (getIsGroupShareDialog()) {
      return Promise.resolve([]);
    }

    if (currentScenario === "groupCreate") {
      const currentJob = jobGroupCreationStore.jobs.find(
        (job) => job.localId === jobGroupCreationStore.currentLocalJobId,
      );

      const newCache = jobShareStore.permissionList.map(
        (item) => item.ObjectId,
      );
      const uniqueArray = uniqByObject([
        ...newCache,
        ...(currentJob?.PermissionObjects ?? []),
      ]);
      return Promise.resolve(uniqueArray);
    }
    return Promise.resolve(undefined);
  }
  return getJobPermission({
    JobId: jobId,
  });
};
const convertDisplayUser = async (
  oid: string,
  userlist: SubstrateSingleUserInfor[],
) => {
  const validUser = userlist.find((item) => item.ObjectId === oid);
  if (validUser) {
    return validUser;
  }
  try {
    const validSG = await getSubstrateToken().then((_) => {
      return searchSGById({
        SubstrateToken: _,
        CallerFileNameLine: "ShareButton",
        ClientRequestId: getRandomUUID(),
        ProcessName: "SEVAL",
        groupId: oid,
      });
    });
    return validSG ?? undefined;
  } catch {
    return undefined;
  }
};

const getCurrentPermissionList = (
  objectIds: string[] | undefined,
  sevaluserlist: SubstrateSingleUserInfor[],
) => {
  if (getIsGroupShareDialog()) {
    return [];
  }

  const validArray =
    objectIds ?? jobShareStore.permissionList.map((item) => item.ObjectId);
  const convertedArray = validArray.map((item) =>
    convertDisplayUser(item, sevaluserlist),
  );
  return convertedArray;
};

orchestrator(openJobShareDialog, () => {
  updateJobShareInvalidErrorMessage(undefined);
  updateShareIsLoading(true);
  const recordId = getSharedRecordId.get();
  const currentScenario = getCurrentScenario();

  // 1. check current user has share permission
  // 2. get sevel user list
  Promise.all([
    checkCurrentPermission(recordId),
    getUserProfileResponse(),
    getSubstrateToken().then((_) => {
      return getSevalUserList({
        SubstrateToken: _,
        CallerFileNameLine: "ShareButton",
        ClientRequestId: getRandomUUID(),
        ProcessName: "SEVAL",
      });
    }),
  ])
    .then(([jobPermission, userProfile, userList]) => {
      const jobPermissionToUserList = getCurrentPermissionList(
        jobPermission,
        userList.userlist ?? [],
      );
      Promise.all(jobPermissionToUserList).then((result) => {
        const filteredJobPermission = compact(
          result.filter((item) => item !== undefined),
        );
        if (currentScenario !== "create") {
          updateJobPermission(filteredJobPermission);
        }
      });
      updateUserProfile(userProfile);
      updateSevalUserList(uniqByObject(userList.userlist ?? []) ?? []);
    })
    .finally(() => {
      updateShareIsLoading(false);
    });
  // 3. open dialog
  setIsOpenShareDialog(true);
});

orchestrator(inviteUsersAction, () => {
  const recordId = getSharedRecordId.get();
  updateShareIsLoading(true);
  const newUserList = jobShareStore.selectUser;
  const newPermissionList = jobShareStore.permissionList.concat(newUserList);

  const currentScenario = getCurrentScenario();
  if (currentScenario === "create" || currentScenario === "groupCreate") {
    const cachedSharedList = uniqByObject(newPermissionList);
    updateJobPermission(cachedSharedList);
    updateShareIsLoading(false);
  } else {
    if (recordId === undefined) {
      updateShareIsLoading(false);
      return;
    }
    const newUserIdList = newUserList.map((item) => item.ObjectId);
    addJobPermission({
      JobId: recordId,
      UserIds: newUserIdList,
    })
      .then(() => {
        updateJobPermission(newPermissionList);
        updateShareIsLoading(false);
      })
      .catch(() => {
        updateShareIsLoading(false);
      });
  }
});

orchestrator(batchInviteUsersAction, () => {
  const jobIds: string[] | undefined = getGroupJobIdsNeedShare.get();
  updateShareIsLoading(true);
  const newUserList = jobShareStore.selectUser;

  if (jobIds === undefined) {
    updateShareIsLoading(false);
    return;
  }
  const newUserIdList = newUserList.map((item) => item.ObjectId);
  batchAddJobsPermission({
    JobIds: jobIds,
    UserIds: newUserIdList,
  })
    .then(() => {
      updateJobPermission(newUserList);
      updateShareIsLoading(false);
    })
    .catch(() => {
      updateShareIsLoading(false);
    });
});

orchestrator(removeUsersAction, ({ userToRemove }) => {
  const recordId = getSharedRecordId.get();
  updateShareIsLoading(true);
  const currentScenario = getCurrentScenario();
  if (currentScenario === "create" || currentScenario === "groupCreate") {
    removeUserFromJobPermission(userToRemove);
    updateShareIsLoading(false);
  } else {
    if (recordId === undefined) {
      updateShareIsLoading(false);
      return;
    }
    removeJobPermission({
      JobId: recordId,
      UserId: userToRemove,
    })
      .then(() => {
        removeUserFromJobPermission(userToRemove);
        updateShareIsLoading(false);
      })
      .catch(() => {
        updateShareIsLoading(false);
      });
  }
});
