import {
  Combobox,
  InfoLabel,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { TemplateType } from "@seval-portal/shared";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getBingCluster } from "../../../selectors/getJobPropV2";
import { getTemplateType } from "../../../selectors/getTemplateType";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    listbox: {
      maxHeight: "300px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobClusterView = observer(() => {
  const styles = useStyles();

  if (
    getTemplateType.get() !== TemplateType.BingV2 &&
    getTemplateType.get() !== TemplateType.CWC
  ) {
    return <></>;
  }
  const selectedTemplate = jobStore.selectedTemplate;
  const availableClusters = jobStore.availableClusters;
  if (selectedTemplate === undefined) {
    return <></>;
  }

  const selectedCluster = getBingCluster.get();

  return (
    <div className={styles.block}>
      <InfoLabel
        required
        className={styles.blockTitle}
        info={<div>Cluster information</div>}
      >
        Cluster
      </InfoLabel>
      <Combobox
        className={styles.dropdownMenu}
        listbox={{ className: styles.listbox }}
        placeholder="Select cluster"
        value={selectedCluster}
        disabled={selectedTemplate === undefined}
        selectedOptions={[selectedCluster]}
        onOptionSelect={(_, data) => {
          if (data.optionValue) {
            updatePropValueActionV2({
              newData: data.optionValue,
              prop: "cluster",
            });
          }
        }}
      >
        {availableClusters.map((cluster) => (
          <Option key={cluster} value={cluster}>
            {cluster}
          </Option>
        ))}
      </Combobox>
    </div>
  );
});
