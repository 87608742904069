import { computed } from "mobx";
import { JobConfiguration, parseJsonStr } from "@seval-portal/shared";
import { jobStore } from "../../store/jobStore";

export const getJSONConfigError = computed(() => {
  try {
    parseJsonStr(jobStore.configurationJsonString, JobConfiguration);
  } catch (e) {
    if (e instanceof Error) {
      return `Configuration JSON is not valid: ${e.message}`;
    } else {
      return "Configuration JSON is not valid";
    }
  }
});
