"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorUserAliasPrefixes = exports.BlockedDepartmentKeyword = exports.BlockedDepartmentPharse = exports.BlockedTitle = void 0;
exports.BlockedTitle = [
    "CEO",
    "CHIEF",
    "CTO",
    "CVP",
    "DIR",
    "DIRECTOR",
    "DISTINGUISHED",
    "EVP",
    "EXECUTIVE",
    "FELLOW",
    "GM",
    "PARTNER",
    "PRESIDENT",
    "VP",
];
exports.BlockedDepartmentPharse = [
    "AI FOR GOOD LAB",
    "CAPABILITY MGMT",
    "CLOUD TRANSPARENCY SVCS",
    "COMP PROGRAMS",
    "CORPORATE STRATEGY",
    "CTZ & PA BRUSSELS",
    "CXP MGMT",
    "DEMOCRACY FORWARD",
    "DEVELOP TRUST",
    "G&A",
    "LI_100",
    "M&A",
    "M&O",
    "NON CHANNEL",
    "OFFICE FOR RESPONSIBILE AI",
    "PROACTIVE RISK ENGAGEMENT",
    "REALIZE VALUE CATALOG",
    "SOCIAL IMPACT",
    "SYSTEM BUILDERS",
    "US-CMG-MSCOM",
    "2262-ZEL",
    "2241-ZMI",
    "LENS IRELAND - COGS",
];
exports.BlockedDepartmentKeyword = [
    "AFFAIRS",
    "BDSV",
    "BD",
    "BENEFITS",
    "BUSINESS",
    "CEO",
    "CELA",
    "CFHR",
    "CLG",
    "COMPLIANCE",
    "CRIMES",
    "CSA",
    "CPO",
    "CST",
    "CTO",
    "CYBERSECURITY",
    "DIPLOMACY",
    "DIVERSITY",
    "DPS",
    "DPSS",
    "EQUITY",
    "EMPLOYEE",
    "EMPLOYABILITY",
    "EMPLOYMENT",
    "EMPOWERMENT",
    "GOVERNANCE",
    "GTA",
    "HR",
    "HRS",
    "IMMIGRATION",
    "IPG",
    "IPS",
    "LAW",
    "LEAP",
    "LEGAL",
    "LENS",
    "M12",
    "MCAPS",
    "NONPROFIT",
    "NONPROFITS",
    "NUANCE_PRA",
    "NTP",
    "OEM",
    "PEOPLE",
    "PHILANTHROPIES",
    "PLAYREADY",
    "PRIVACY",
    "PSRA",
    "REGULATORY",
    "RELATIONS",
    "RETAIL",
    "SAFETY",
    "SALE",
    "SALES",
    "SBC",
    "SECURITY",
    "SECURITIES",
    "SMC",
    "SSGTM",
    "STANCARDS",
    "SUSTAINABILITY",
    "TALENT",
    "TEALS",
    "TELECOM",
    "TECHSPARK",
    "TFR",
    "TPM",
    "TSI",
    "USGA",
    "WIT",
    "612ENTERPRISE",
    "672GDOT",
];
exports.VendorUserAliasPrefixes = ["a-", "t-", "v-"];
