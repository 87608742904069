import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Field,
  Input,
  Option,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsRefreshTokenDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { getRefreshTokenKey } from "../../../../helpers/accountHelper";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { isFeatureEnabled } from "../../../../selectors/features";
import { store } from "../../../../store/store";
import { uploadToken } from "../../actions/uploadToken";
import type { RefreshTokenType } from "../../models/Types";
import {
  AvailableRefreshTokenTypes,
  UploadableRefreshTokenTypes,
} from "../../models/Types";
import { GuidanceLinkByTokenType } from "../Other/GuidanceLink";

export const UploadRefreshTokenDialog = observer(
  (props: FeatureSettingsRefreshTokenDialogContributionProps) => {
    const { tokenType, dialogTitle, loggingSource } = props;

    const [tokenTypeValue, setTokenTypeValue] =
      React.useState<RefreshTokenType>(tokenType);
    const tokenNameValue = computed(
      () =>
        getRefreshTokenKey(store.account?.username ?? "", tokenTypeValue) ?? "",
    ).get();

    const [tokenValue, setTokenValue] = React.useState<string>("");
    const toast = useToast();
    const refreshTokenTypes = computed(() => {
      if (isFeatureEnabled("enable-upload-graph-refresh-token")) {
        return UploadableRefreshTokenTypes;
      } else {
        return UploadableRefreshTokenTypes.filter(
          (token) => token !== "Graph refresh token",
        ) as typeof UploadableRefreshTokenTypes;
      }
    }).get();

    const handleUploadToken = () => {
      toast.onToastStart("Uploading token...");

      telemetryHelper.logUserActionEvent("SetToken", {
        inputType: "Refreshtoken",
        source: loggingSource,
      });

      uploadToken(
        tokenTypeValue,
        tokenNameValue,
        tokenValue,
        () => {
          telemetryHelper.logUserActionEvent("SetTokenSuccess", {
            inputType: "Refreshtoken",
            source: loggingSource,
          });

          toast.onToastSuccess("Token uploaded successfully");
          props.close();
        },
        (error) => {
          telemetryHelper.logUserActionEvent("SetTokenFailure", {
            inputType: "Refreshtoken",
            message: error.message,
            source: loggingSource,
          });

          toast.onToastFailure(
            `Token upload failed with message: ${error.message}`,
          );
          props.close();
        },
      );
    };

    useEffect(() => {
      if (props.isOpen) {
        setTokenValue("");
      }
    }, [props.isOpen]);

    const guidanceLinkElement = GuidanceLinkByTokenType(tokenTypeValue);
    const guidanceElement = guidanceLinkElement && (
      <>
        <br />
        <Body1>{" For the set up guidance, please check "}</Body1>
        {guidanceLinkElement}
        <Body1>{"."}</Body1>
      </>
    );

    const onTokenTypeSelect = (data: string) => {
      const findTokenType = AvailableRefreshTokenTypes.find(
        (type) => type === data,
      );
      if (findTokenType) {
        setTokenTypeValue(findTokenType);
      }
    };
    return (
      <Dialog
        open={props.isOpen}
        onOpenChange={() => {
          props.close();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <Field
                required
                label={<>Select the token type you want to upload.</>}
              >
                <Dropdown
                  placeholder="Select a service"
                  value={tokenTypeValue}
                  onOptionSelect={(e, data) =>
                    onTokenTypeSelect(data.optionValue ?? "")
                  }
                >
                  {refreshTokenTypes.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </Field>
              <Field
                required
                label={
                  <>
                    <Body1>{`You can input your token here.`}</Body1>
                    <br />
                    <Body1>{`This token will be stored as secret with name `}</Body1>
                    <strong>{`${tokenNameValue}`}</strong>
                    <Body1>{` in Azure Key Vault.`}</Body1>
                    {guidanceElement}
                  </>
                }
                validationState={tokenValue.trim() === "" ? "error" : "success"}
                validationMessage={
                  tokenValue.trim() === ""
                    ? "token value can not be empty"
                    : undefined
                }
              >
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={tokenValue}
                  onChange={(_, data) => {
                    setTokenValue(data.value);
                  }}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={tokenValue.trim() === ""}
                onClick={() => {
                  handleUploadToken();
                }}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  props.close();
                }}
                appearance="secondary"
              >
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
