import { Dropdown, Option } from "@fluentui/react-components";

import { observer } from "mobx-react-lite";
import { RAIFixedColumnsInSummary } from "../../../../constants/renderManifest";
import { capitalFirstLetter } from "../../helpers/formatHelper";
import { updateSelectedFilter } from "../../mutators/jobResultMutators";
import {
  getConciseTableColumns,
  getConciseTableData,
  getRAIDropdownValues,
  getSummaryTableColumns,
  getSummaryTableData,
  getSummaryTableDisplayColumns,
} from "../../selectors/prepareRAIJobResult";
import { JobResultHeader } from "../GroupDetails/components/JobResultHeader";
import { GeneralResultTable } from "./Table/GeneralResultTable";
import { useResultViewStyles } from "./sharedStyles";

export const RAIResultView = observer(() => {
  const styles = useResultViewStyles();

  const dropdownOptions: Map<string, string[]> = getRAIDropdownValues.get();
  const summaryTableColumns = getSummaryTableColumns.get();
  const summartTableDisplayColumns = getSummaryTableDisplayColumns.get();
  const summaryTable = getSummaryTableData.get();
  const conciseTableColumns = getConciseTableColumns.get();
  const conciseTable = getConciseTableData.get();

  const renderDropdown = () => {
    const renderSingleDropdown = (key: string, values: string[]) => {
      return (
        <Dropdown
          key={key}
          defaultValue={"All"}
          defaultSelectedOptions={["UnSelected"]}
          onOptionSelect={(_, option) => {
            if (option.optionValue !== "UnSelected") {
              updateSelectedFilter(key, option.optionValue);
            } else {
              updateSelectedFilter(key, undefined);
            }
          }}
        >
          <Option key="All" value="UnSelected">
            All
          </Option>
          {values.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Dropdown>
      );
    };
    return (
      <div className={styles.rowWithSmallerGap}>
        {Array.from(dropdownOptions.keys()).map((key) => {
          const values = dropdownOptions.get(key);
          if (values !== undefined) {
            return (
              <div className={styles.filterContainer} key={key}>
                <div className={styles.filterLabel}>
                  {capitalFirstLetter(key)}
                </div>
                {renderSingleDropdown(key, values)}
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div>
      <JobResultHeader />
      <div className={styles.detailedCard}>
        <div className={styles.dataPane}>
          <div className={styles.buttonRow}>{renderDropdown()}</div>
          {summaryTableColumns.length > RAIFixedColumnsInSummary.length && (
            <>
              <div className={styles.tableTitle}>Metric Summary</div>
              <GeneralResultTable
                columns={summaryTableColumns}
                data={summaryTable}
                displayColumns={summartTableDisplayColumns}
              />
            </>
          )}
          <div className={styles.tableTitle}>E2E Concise Metrics</div>

          <GeneralResultTable
            columns={conciseTableColumns}
            data={conciseTable}
          />
        </div>
      </div>
    </div>
  );
});
